<template>
  <div class="overflow-x-auto">
    <div class="py-12 min-w-640">
      <!-- SORT AREA -->
      <div class="flex flex-wrap gap-4">
        <!-- FIRST -->
        <div class="flex flex-nowrap gap-4">
          <div>
            <AgButton variant="ghost" @click="sortCustomerName" class="bg-neutral-300 rounded-full pl-24 px-8">
              <span :class="{ 'font-normal': !customerName }">{{ t('processedData.customerName') }}</span>
              <template #icon>
                <span v-if="customerName === Order.ASC"><IcChevronDown class="h-12 w-12" /></span>
                <span v-else-if="customerName === Order.DESC"><IcChevronUp class="h-12 w-12" /></span>
                <span v-else><IcChevronUp class="h-12 w-12 invisible" /></span>
              </template>
            </AgButton>
          </div>
          <div>
            <AgButton variant="ghost" @click="sortCustomerCode" class="bg-neutral-300 rounded-full pl-24 px-8">
              <span :class="{ 'font-normal': !customerCode }">{{ t('processedData.customerCode') }}</span>
              <template #icon>
                <span v-if="customerCode === Order.ASC"><IcChevronDown class="h-12 w-12" /></span>
                <span v-else-if="customerCode === Order.DESC"><IcChevronUp class="h-12 w-12" /></span>
                <span v-else><IcChevronUp class="h-12 w-12 invisible" /></span>
              </template>
            </AgButton>
          </div>
          <div>
            <AgButton variant="ghost" @click="sortOrderOfferNumber" class="bg-neutral-300 rounded-full pl-24 px-8">
              <span :class="{ 'font-normal': !orderOfferNumber }">{{ t('processedData.number') }}</span>
              <template #icon>
                <span v-if="orderOfferNumber === Order.ASC"><IcChevronDown class="h-12 w-12" /></span>
                <span v-else-if="orderOfferNumber === Order.DESC"><IcChevronUp class="h-12 w-12" /></span>
                <span v-else><IcChevronUp class="h-12 w-12 invisible" /></span>
              </template>
            </AgButton>
          </div>
          <div>
            <AgButton variant="ghost" @click="sortOrderOfferDate" class="bg-neutral-300 rounded-full pl-24 px-8">
              <span :class="{ 'font-normal': !orderOfferDate }">{{ t('processedData.date') }}</span>
              <template #icon>
                <span v-if="orderOfferDate === Order.ASC"><IcChevronDown class="h-12 w-12" /></span>
                <span v-else-if="orderOfferDate === Order.DESC"><IcChevronUp class="h-12 w-12" /></span>
                <span v-else><IcChevronUp class="h-12 w-12 invisible" /></span>
              </template>
            </AgButton>
          </div>
        </div>
        <!-- SECOND -->
        <div class="flex flex-nowrap gap-4">
          <div>
            <AgButton variant="ghost" @click="sortInvoiceNumber" class="bg-neutral-300 rounded-full pl-24 px-8">
              <span :class="{ 'font-normal': !invoiceNumber }">{{ t('processedData.invoiceNumber') }}</span>
              <template #icon>
                <span v-if="invoiceNumber === Order.ASC"><IcChevronDown class="h-12 w-12" /></span>
                <span v-else-if="invoiceNumber === Order.DESC"><IcChevronUp class="h-12 w-12" /></span>
                <span v-else><IcChevronUp class="h-12 w-12 invisible" /></span>
              </template>
            </AgButton>
          </div>
          <div>
            <AgButton variant="ghost" @click="sortInvoiceDate" class="bg-neutral-300 rounded-full pl-24 px-8">
              <span :class="{ 'font-normal': !invoiceDate }">{{ t('processedData.invoiceDate') }}</span>
              <template #icon>
                <span v-if="invoiceDate === Order.ASC"><IcChevronDown class="h-12 w-12" /></span>
                <span v-else-if="invoiceDate === Order.DESC"><IcChevronUp class="h-12 w-12" /></span>
                <span v-else><IcChevronUp class="h-12 w-12 invisible" /></span>
              </template>
            </AgButton>
          </div>
          <div>
            <AgButton variant="ghost" @click="sortInvoiceState" class="bg-neutral-300 rounded-full pl-24 px-8">
              <span :class="{ 'font-normal': !invoiceState }">{{ t('processedData.invoiceState') }}</span>
              <template #icon>
                <span v-if="invoiceState === Order.ASC"><IcChevronDown class="h-12 w-12" /></span>
                <span v-else-if="invoiceState === Order.DESC"><IcChevronUp class="h-12 w-12" /></span>
                <span v-else><IcChevronUp class="h-12 w-12 invisible" /></span>
              </template>
            </AgButton>
          </div>
        </div>
        <!-- THIRD -->
        <div class="flex flex-nowrap gap-4">
          <div>
            <AgButton variant="ghost" @click="sortDeliveryNoteNumber" class="bg-neutral-300 rounded-full pl-24 px-8">
              <span :class="{ 'font-normal': !deliveryNoteNumber }">{{ t('processedData.deliveryNoteNumber') }}</span>
              <template #icon>
                <span v-if="deliveryNoteNumber === Order.ASC"><IcChevronDown class="h-12 w-12" /></span>
                <span v-else-if="deliveryNoteNumber === Order.DESC"><IcChevronUp class="h-12 w-12" /></span>
                <span v-else><IcChevronUp class="h-12 w-12 invisible" /></span>
              </template>
            </AgButton>
          </div>
          <div>
            <AgButton variant="ghost" @click="sortDeliveryNoteDate" class="bg-neutral-300 rounded-full pl-24 px-8">
              <span :class="{ 'font-normal': !deliveryNoteDate }">{{ t('processedData.deliveryNoteDate') }}</span>
              <template #icon>
                <span v-if="deliveryNoteDate === Order.ASC"><IcChevronDown class="h-12 w-12" /></span>
                <span v-else-if="deliveryNoteDate === Order.DESC"><IcChevronUp class="h-12 w-12" /></span>
                <span v-else><IcChevronUp class="h-12 w-12 invisible" /></span>
              </template>
            </AgButton>
          </div>
          <div>
            <AgButton variant="ghost" @click="sortCourierName" class="bg-neutral-300 rounded-full pl-24 px-8">
              <span :class="{ 'font-normal': !courierName }">{{ t('processedData.courierName') }}</span>
              <template #icon>
                <span v-if="courierName === Order.ASC"><IcChevronDown class="h-12 w-12" /></span>
                <span v-else-if="courierName === Order.DESC"><IcChevronUp class="h-12 w-12" /></span>
                <span v-else><IcChevronUp class="h-12 w-12 invisible" /></span>
              </template>
            </AgButton>
          </div>
        </div>
      </div>

      <!-- CONTENT -->
      <div class="min-w-640">
        <div v-if="props.processedData">
          <AgBox v-for="data in props.processedData" :key="data.id" variant="strong-accent" class="text-s-20 mt-12">
            <AgCollapsible disabledAutoCollapse showIcon>
              <template #header>
                <div class="text-base flex gap-8 py-8">
                  <!-- TITLE -->
                  <div :class="{ 'font-bold bg-yellow': orderOfferDate }">
                    {{ formatDate(data.orderOfferDate) }}
                  </div>
                  <div>
                    <span class="font-bold">
                      <span v-if="data.state === 1">{{ t('processedData.order') }}</span>
                      <span v-if="data.state === 2">{{ t('processedData.offer') }}</span>
                      <span v-if="data.state === 4">{{ t('processedData.pevad') }}</span>
                      <span v-if="data.state === 5">{{ t('processedData.evad') }}</span>
                      <span v-if="data.state === 6">{{ t('processedData.invo') }}</span>
                    </span>
                  </div>
                  <div :class="{ 'font-bold bg-yellow': orderOfferNumber }">
                    {{ data.orderOfferNumber }}
                  </div>

                  <div>
                    <span :class="{ 'font-bold bg-yellow': customerName }">{{ data.customerName }}</span>
                    &nbsp;
                    <span :class="{ 'font-bold bg-yellow': customerCode }">({{ data.customerCode }})</span>
                    &nbsp;
                  </div>
                  <div :class="{ hidden: !data.paymentTerm }">
                    <span>{{ data.paymentTerm }}</span>
                  </div>
                  <div :class="{ hidden: !data.note }">
                    <span>{{ data.note }}</span>
                  </div>
                </div>
              </template>

              <template #default>
                <div class="p-12">
                  <div class="row justify-between py-8">
                    <!-- FIRST GROUP  -->
                    <div>
                      <div>
                        <span class="label">{{ t('processedData.agent') }}:</span>
                        <span v-if="agents[data.agentId]">{{ agents[data.agentId] }}</span>
                        <span v-else>{{ data.agentId }}</span>
                      </div>
                      <div>
                        <span class="label">{{ t('processedData.customerName') }}:</span>
                        <span :class="{ 'font-bold bg-yellow': customerName }">{{ data.customerName }}</span>
                      </div>
                      <div>
                        <span class="label"> {{ t('processedData.customerCode') }}:</span>
                        <span :class="{ 'font-bold bg-yellow': customerCode }">{{ data.customerCode }}</span>
                      </div>
                      <div>
                        <span class="label">{{ t('processedData.invoiceState') }}:</span>
                        <span :class="{ 'font-bold bg-yellow': invoiceState }">
                          {{ t(getInvoiceState(data.invoiceState)) }}
                        </span>
                      </div>
                      <div>
                        <span class="label">{{ t('processedData.invoiceNumber') }}:</span>
                        <span :class="{ 'font-bold bg-yellow': invoiceNumber }">{{ data.invoiceNumber }}</span>
                      </div>
                    </div>

                    <!-- SECOND GROUP -->
                    <div>
                      <div>
                        <span class="label">{{ t('processedData.invoiceDate') }}:</span>
                        <span :class="{ 'font-bold bg-yellow': invoiceDate }">{{ formatDate(data.invoiceDate) }}</span>
                      </div>
                      <div>
                        <span class="label">{{ t('processedData.invoiceTotal') }}:</span>
                        {{ formatNumberToEuro(data.invoiceTotal) }}
                      </div>
                      <div>
                        <span class="label">{{ t('processedData.invoiceDiscount') }}:</span>
                        {{ formatNumberToPercentage(data.invoiceDiscount) }}
                      </div>
                      <div>
                        <span class="label">{{ t('processedData.courierName') }}:</span>
                        <span :class="{ 'font-bold bg-yellow': courierName }">{{ data.courierName }}</span>
                      </div>
                    </div>

                    <!-- THIRD GROUP -->
                    <div>
                      <div>
                        <span class="label">{{ t('processedData.courierTracking') }}:</span>
                        {{ data.courierTracking }}
                      </div>
                      <div>
                        <span class="label">{{ t('processedData.deliveryNoteNumber') }}:</span>
                        <span :class="{ 'font-bold bg-yellow': deliveryNoteNumber }">
                          {{ data.deliveryNoteNumber }}
                        </span>
                      </div>
                      <div>
                        <span class="label">{{ t('processedData.deliveryNoteDate') }}:</span>
                        <span :class="{ 'font-bold bg-yellow': deliveryNoteDate }">
                          {{ formatDate(data.deliveryNoteDate) }}
                        </span>
                      </div>
                      <div>
                        <span class="label">{{ t('processedData.paymentTerm') }}:</span>
                        {{ data.paymentTerm }}
                      </div>
                    </div>
                  </div>

                  <div class="py-8">
                    <span class="label">{{ t('processedData.note') }}:</span>
                    <div class="whitespace-pre-wrap max-h-[70px] overflow-y-scroll">
                      {{ data.note }}
                    </div>
                  </div>

                  <!-- TABLE CONTENT OF PROCESSED DATA -->
                  <div class="text-base font-bold py-8">
                    <span class="label">{{ t('processedData.position') }}:&nbsp;{{ data.positions.length }}</span>
                  </div>
                  <div>
                    <ProcessedDataPosition :positions="data.positions" class="border border-neutral-550" />
                  </div>
                </div>
              </template>
            </AgCollapsible>
          </AgBox>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { ProcessedDataDto } from '@/domain/ProcessedDataDto';

  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';
  import AgBox from '@/components/library/box/AgBox.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgCollapsible from '@/components/library/collapsible/AgCollapsible.vue';
  import ProcessedDataPosition from '@/modules/processed-data/components/ProcessedDataPosition.vue';

  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { useHierarchy } from '@/composables/useHierarchy';
  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { i18n } from '@/plugins/i18n';
  import { Order } from '@/util/Order';
  import { Sorting } from '@/util/Sorting';
  import { onMounted, ref } from 'vue';

  const { t } = i18n.global;
  const { formatDate } = useDateFormatting();
  const { formatNumberToEuro, formatNumberToPercentage } = useNumberFormatting();

  interface Props {
    processedData: Array<ProcessedDataDto>;
    modelValue: Sorting;
  }
  const props = defineProps<Props>();

  const { resolveHierarchyUsers } = useHierarchy();

  const emit = defineEmits(['update:modelValue']);

  const agents = ref<{ [key: string]: string }>({});

  const orderOfferDate = ref<Order | null>(null);
  const sortOrderOfferDate = () => emit('update:modelValue', getSorting(ColumnName.ORDER_OFFER_DATE));

  const orderOfferNumber = ref<Order | null>(null);
  const sortOrderOfferNumber = () => emit('update:modelValue', getSorting(ColumnName.ORDER_OFFER_NUMBER));

  const customerName = ref<Order | null>(null);
  const sortCustomerName = () => emit('update:modelValue', getSorting(ColumnName.CUSTOMER_NAME));

  const customerCode = ref<Order | null>(null);
  const sortCustomerCode = () => emit('update:modelValue', getSorting(ColumnName.CUSTOMER_CODE));

  const invoiceNumber = ref<Order | null>(null);
  const sortInvoiceNumber = () => emit('update:modelValue', getSorting(ColumnName.INVOICE_NUMBER));

  const invoiceDate = ref<Order | null>(null);
  const sortInvoiceDate = () => emit('update:modelValue', getSorting(ColumnName.INVOICE_DATE));

  const invoiceState = ref<Order | null>(null);
  const sortInvoiceState = () => emit('update:modelValue', getSorting(ColumnName.INVOICE_STATE));

  const deliveryNoteNumber = ref<Order | null>(null);
  const sortDeliveryNoteNumber = () => emit('update:modelValue', getSorting(ColumnName.DELIVERY_NOTE_NUMBER));

  const deliveryNoteDate = ref<Order | null>(null);
  const sortDeliveryNoteDate = () => emit('update:modelValue', getSorting(ColumnName.DELIVERY_NOTE_DATE));

  const courierName = ref<Order | null>(null);
  const sortCourierName = () => emit('update:modelValue', getSorting(ColumnName.COURIER_NAME));

  enum ColumnName {
    ORDER_OFFER_DATE = 'orderOfferDate',
    ORDER_OFFER_NUMBER = 'orderOfferNumber',
    CUSTOMER_NAME = 'customerName',
    CUSTOMER_CODE = 'customerCode',
    INVOICE_NUMBER = 'invoiceNumber',
    INVOICE_DATE = 'invoiceDate',
    INVOICE_STATE = 'invoiceState',
    DELIVERY_NOTE_NUMBER = 'deliverynotenumber',
    DELIVERY_NOTE_DATE = 'deliveryNoteDate',
    COURIER_NAME = 'courierName',
  }

  const getInvoiceState = (istate?: number | null) => {
    if (istate === 0) {
      return 'processedData.no_info';
    } else if (istate === 1) {
      return 'processedData.paid';
    } else if (istate === 2) {
      return 'processedData.not_paid';
    } else if (istate === 3) {
      return 'processedData.partially_paid';
    }
    return 'processedData.no_info';
  };

  const getSorting = (colum: ColumnName): Sorting => {
    if (colum === ColumnName.ORDER_OFFER_NUMBER) {
      if (orderOfferNumber.value === Order.ASC) {
        orderOfferNumber.value = Order.DESC;
      } else {
        orderOfferNumber.value = Order.ASC;
      }
      orderOfferDate.value = null;
      customerName.value = null;
      customerCode.value = null;
      invoiceNumber.value = null;
      invoiceDate.value = null;
      invoiceState.value = null;
      deliveryNoteNumber.value = null;
      deliveryNoteDate.value = null;
      courierName.value = null;
      return new Sorting(ColumnName.ORDER_OFFER_NUMBER, orderOfferNumber.value);
    } else if (colum === ColumnName.ORDER_OFFER_DATE) {
      if (orderOfferDate.value === Order.ASC) {
        orderOfferDate.value = Order.DESC;
      } else {
        orderOfferDate.value = Order.ASC;
      }
      orderOfferNumber.value = null;
      customerName.value = null;
      customerCode.value = null;
      invoiceNumber.value = null;
      invoiceDate.value = null;
      invoiceState.value = null;
      deliveryNoteNumber.value = null;
      deliveryNoteDate.value = null;
      courierName.value = null;
      return new Sorting(ColumnName.ORDER_OFFER_DATE, orderOfferDate.value);
    } else if (colum === ColumnName.CUSTOMER_NAME) {
      if (customerName.value === Order.ASC) {
        customerName.value = Order.DESC;
      } else {
        customerName.value = Order.ASC;
      }
      orderOfferDate.value = null;
      orderOfferNumber.value = null;
      customerCode.value = null;
      invoiceNumber.value = null;
      invoiceDate.value = null;
      invoiceState.value = null;
      deliveryNoteNumber.value = null;
      deliveryNoteDate.value = null;
      courierName.value = null;
      return new Sorting(ColumnName.CUSTOMER_NAME, customerName.value);
    } else if (colum === ColumnName.CUSTOMER_CODE) {
      if (customerCode.value === Order.ASC) {
        customerCode.value = Order.DESC;
      } else {
        customerCode.value = Order.ASC;
      }
      orderOfferDate.value = null;
      orderOfferNumber.value = null;
      customerName.value = null;
      invoiceNumber.value = null;
      invoiceDate.value = null;
      invoiceState.value = null;
      deliveryNoteNumber.value = null;
      deliveryNoteDate.value = null;
      courierName.value = null;
      return new Sorting(ColumnName.CUSTOMER_CODE, customerCode.value);
    } else if (colum === ColumnName.INVOICE_NUMBER) {
      if (invoiceNumber.value === Order.ASC) {
        invoiceNumber.value = Order.DESC;
      } else {
        invoiceNumber.value = Order.ASC;
      }
      orderOfferDate.value = null;
      orderOfferNumber.value = null;
      customerName.value = null;
      customerCode.value = null;
      invoiceDate.value = null;
      invoiceState.value = null;
      deliveryNoteNumber.value = null;
      deliveryNoteDate.value = null;
      courierName.value = null;
      return new Sorting(ColumnName.INVOICE_NUMBER, invoiceNumber.value);
    } else if (colum === ColumnName.INVOICE_DATE) {
      if (invoiceDate.value === Order.ASC) {
        invoiceDate.value = Order.DESC;
      } else {
        invoiceDate.value = Order.ASC;
      }
      orderOfferDate.value = null;
      orderOfferNumber.value = null;
      customerName.value = null;
      customerCode.value = null;
      invoiceNumber.value = null;
      invoiceState.value = null;
      deliveryNoteNumber.value = null;
      deliveryNoteDate.value = null;
      courierName.value = null;
      return new Sorting(ColumnName.INVOICE_DATE, invoiceDate.value);
    } else if (colum === ColumnName.INVOICE_STATE) {
      if (invoiceState.value === Order.ASC) {
        invoiceState.value = Order.DESC;
      } else {
        invoiceState.value = Order.ASC;
      }
      orderOfferDate.value = null;
      orderOfferNumber.value = null;
      customerName.value = null;
      customerCode.value = null;
      invoiceNumber.value = null;
      invoiceDate.value = null;
      deliveryNoteNumber.value = null;
      deliveryNoteDate.value = null;
      courierName.value = null;
      return new Sorting(ColumnName.INVOICE_STATE, invoiceState.value);
    } else if (colum === ColumnName.DELIVERY_NOTE_NUMBER) {
      if (deliveryNoteNumber.value === Order.ASC) {
        deliveryNoteNumber.value = Order.DESC;
      } else {
        deliveryNoteNumber.value = Order.ASC;
      }
      orderOfferDate.value = null;
      orderOfferNumber.value = null;
      customerName.value = null;
      customerCode.value = null;
      invoiceNumber.value = null;
      invoiceDate.value = null;
      invoiceState.value = null;
      deliveryNoteDate.value = null;
      courierName.value = null;
      return new Sorting(ColumnName.DELIVERY_NOTE_NUMBER, deliveryNoteNumber.value);
    } else if (colum === ColumnName.DELIVERY_NOTE_DATE) {
      if (deliveryNoteDate.value === Order.ASC) {
        deliveryNoteDate.value = Order.DESC;
      } else {
        deliveryNoteDate.value = Order.ASC;
      }
      orderOfferDate.value = null;
      orderOfferNumber.value = null;
      customerName.value = null;
      customerCode.value = null;
      invoiceNumber.value = null;
      invoiceDate.value = null;
      invoiceState.value = null;
      deliveryNoteNumber.value = null;
      courierName.value = null;
      return new Sorting(ColumnName.DELIVERY_NOTE_DATE, deliveryNoteDate.value);
    } else if (colum === ColumnName.COURIER_NAME) {
      if (courierName.value === Order.ASC) {
        courierName.value = Order.DESC;
      } else {
        courierName.value = Order.ASC;
      }
      orderOfferDate.value = null;
      orderOfferNumber.value = null;
      customerName.value = null;
      customerCode.value = null;
      invoiceNumber.value = null;
      invoiceDate.value = null;
      invoiceState.value = null;
      deliveryNoteNumber.value = null;
      deliveryNoteDate.value = null;
      return new Sorting(ColumnName.COURIER_NAME, courierName.value);
    }
    return new Sorting(ColumnName.ORDER_OFFER_DATE, Order.ASC);
  };

  const defaultSorting = () => {
    // SORTING DEFAULT VALUE ORDER DATE
    orderOfferDate.value = Order.ASC;
    orderOfferNumber.value = null;
    customerName.value = null;
    customerCode.value = null;
    invoiceNumber.value = null;
    invoiceDate.value = null;
    invoiceState.value = null;
    deliveryNoteNumber.value = null;
    deliveryNoteDate.value = null;
    courierName.value = null;
  };

  const loadHierarchyList = async function () {
    const hierarchyUserList = await resolveHierarchyUsers();

    hierarchyUserList.forEach((currentAgent) => {
      agents.value[currentAgent.id] = currentAgent.name;
    });
  };

  onMounted(async () => {
    await loadHierarchyList();
    defaultSorting();
  });
</script>

<style scoped lang="scss">
  .row {
    @apply flex flex-wrap justify-between;
  }
  .label {
    @apply pr-4 font-light text-neutral-550;
  }
  .collapsibleWidth {
    @apply md:max-w-[calc(100vw-10em)] sm:md:w-[100vw] lg:max-w-[calc(100vw-35em)] lg:w-[100%];
  }
  .positionTableWidth {
    @apply max-w-[1200px] w-[100vw];
  }
</style>
