<template>
  <div v-if="order" class="md:mx-[80px] max-md:mx-16 mt-12 mb-32 space-y-12 min-w-fit">
    <OrderInfo />

    <div class="flex flex-col gap-12">
      <span class="font-bold">{{ t('orders.selectDeliveryAddress') }}:</span>
      <div :class="{ 'font-bold': selectedAddressCode === defaultAddressCode }">
        <AgSearchSelect
          :options="selectAdressesOptions"
          :placeholder="t('orders.deliveryAddress')"
          v-model="selectedAddressCode"
          @update:model-value="() => (selectedNewDeliveryAddress = false)"
        />
      </div>
      <div v-if="selectedAddressCode === 'fromBilling' || order.deliveryAddressEqualToBilling" class="w-full">
        <AgAlert variant="info">
          {{ t('orders.editingBillingAndDelivery') }}
          <template #icon><IcInfo /></template>
        </AgAlert>
      </div>

      <div v-if="isNewCustomer()">
        <input
          type="radio"
          id="fromBilling"
          name="newAddrOrFromBilling"
          value="fromBilling"
          class="hover:cursor-pointer"
          :checked="wizzardAddressOrrigin[order.gid] === 'fromBilling'"
          @change="selectAddressFromBilling"
        />
        <label for="fromBilling" class="ml-4 font-bold cursor-pointer">
          {{ t('orders.deliveryLikeBillingAddress') }}
        </label>
      </div>
      <div class="pb-24" v-if="selectedDeliveryAddress">
        <div class="w-fit">
          <input
            type="checkbox"
            id="phoneNotice"
            name="phoneNotice"
            value="phoneNotice"
            class="mt-8 cursor-pointer"
            v-model="selectedDeliveryAddress.deliveryPhoneNotification"
          />
          <label
            for="phoneNotice"
            class="ml-4 cursor-pointer"
            @click.prevent="
              selectedDeliveryAddress.deliveryPhoneNotification = !selectedDeliveryAddress.deliveryPhoneNotification
            "
          >
            <span class="font-bold">{{ t('orders.telephoneNotice') }}&nbsp;</span>
            <span>{{ t('orders.telephoneNoticeAdvie') }}</span>
          </label>
        </div>
        <div class="flex justify-start">
          {{ t('orders.selectedAdress') }}:&nbsp;
          <span v-if="selectedDeliveryAddress.code">{{ selectedDeliveryAddress.name }}</span>
          <span v-else>{{ t('orders.newDeliveryAddress').toLowerCase() }}</span>
        </div>
        <div class="space-y-4">
          <div>
            <AgFormGroup :label="t('orders.name')" required>
              <AgFormInput
                v-model="selectedDeliveryAddress.name"
                :validation-state="validationAddress.name.state"
                :errorMessage="validationAddress.name.msg"
              />
            </AgFormGroup>
          </div>
          <div class="flex flex-row max-md:flex-col gap-12">
            <!-- ADDRESS STREET -->
            <AgFormGroup :label="t('orders.street')" class="basis-1/3" required>
              <AgFormInput
                v-model="selectedDeliveryAddress.street"
                :validation-state="validationAddress.street.state"
                :errorMessage="validationAddress.region.msg"
              />
            </AgFormGroup>
            <!-- ADDRESS CITY -->
            <AgFormGroup :label="t('orders.city')" class="basis-1/3" required>
              <AgFormInput
                v-model="selectedDeliveryAddress.city"
                :validation-state="validationAddress.city.state"
                :errorMessage="validationAddress.city.msg"
              />
            </AgFormGroup>
            <!-- ADDRESS DISTRICT -->
            <AgFormGroup :label="t('orders.district')" class="basis-1/3">
              <AgFormInput v-model="selectedDeliveryAddress.district" />
            </AgFormGroup>
          </div>
          <div class="flex max-sm:flex-col gap-12 py-4">
            <!-- COUNTRY -->
            <AgFormGroup class="basis-2/3" :label="t('orders.country')" required>
              <AgSearchSelect
                :options="countryOptions"
                v-model="selectedDeliveryAddress.nation"
                :validation-state="validationAddress.nation.state"
                :errorMessage="validationAddress.nation.msg"
              />
            </AgFormGroup>
            <!-- ZIP CODE -->
            <AgFormGroup class="basis-1/3" :label="t('orders.zipCode')" required>
              <AgFormInput
                v-model="selectedDeliveryAddress.zip"
                :validation-state="validationAddress.zip.state"
                :errorMessage="validationAddress.zip.msg"
              />
            </AgFormGroup>
            <!-- ADDRESS REGION -->
            <AgFormGroup class="basis-1/3" :label="t('orders.region')" required>
              <AgFormInput
                v-model="selectedDeliveryAddress.region"
                :validation-state="validationAddress.region.state"
                :errorMessage="validationAddress.region.msg"
              />
            </AgFormGroup>
          </div>
          <div class="flex flex-row max-md:flex-col gap-12">
            <!-- PHONE -->
            <AgFormGroup class="basis-1/3" :label="t('orders.phone')" required>
              <AgFormInput
                type="tel"
                v-model="selectedDeliveryAddress.phoneNumber"
                :validation-state="validationAddress.phone.state"
                :errorMessage="validationAddress.phone.msg"
              />
            </AgFormGroup>
            <!-- DELIVERY PHONE ADVICE-->
            <AgFormGroup
              class="basis-1/3"
              :label="t('orders.deliveryPhoneNumber')"
              :required="selectedDeliveryAddress.deliveryPhoneNotification"
            >
              <AgFormInput
                type="tel"
                v-model="selectedDeliveryAddress.deliveryPhoneNumber"
                :validation-state="validationAddress.deliveryPhone.state"
                :errorMessage="validationAddress.deliveryPhone.msg"
              />
            </AgFormGroup>
            <!-- MAIL -->
            <AgFormGroup class="basis-1/3" :label="t('orders.mail')" :required="order.deliveryAddressEqualToBilling">
              <AgFormInput
                type="email"
                v-model="selectedDeliveryAddress.email"
                :validation-state="validationAddress.email.state"
                :errorMessage="validationAddress.email.msg"
              />
            </AgFormGroup>
            <!-- PEC -->
            <AgFormGroup class="basis-1/3" :label="t('orders.pecMail')">
              <AgFormInput
                type="email"
                v-model="selectedDeliveryAddress.pec"
                :validation-state="validationAddress.pec.state"
                :errorMessage="validationAddress.pec.msg"
              />
            </AgFormGroup>
          </div>
          <div class="flex max-sm:flex-col gap-12">
            <!-- LATITUDE -->
            <AgFormGroup class="basis-1/3" :label="t('orders.latitude')">
              <AgFormInputGeolocation v-model="selectedDeliveryAddress.latitude" geoType="Latitude" />
            </AgFormGroup>
            <!-- LONGITUDE -->
            <AgFormGroup class="basis-1/3" :label="t('orders.longitude')">
              <AgFormInputGeolocation v-model="selectedDeliveryAddress.longitude" geoType="Longitude" />
            </AgFormGroup>
            <!-- BUSINESS CATEGORY -->
            <AgFormGroup class="basis-1/3" :label="t('orders.section')">
              <AgSearchSelect :options="businessCategoryOptions" v-model="selectedBusinessCategory" />
            </AgFormGroup>
          </div>
        </div>
        <div class="py-8">
          <!-- DELIVERY ADDRESS DAYS OF REST -->
          <div class="text-l pt-12 font-bold">{{ t('orders.daysOfRest') }}</div>

          <div class="flex max-md:flex-wrap gap-12 py-8 justify-between">
            <div v-for="(dayOfRest, index) in daysOfRest" :key="index">
              <div class="flex flex-col items-center hover:cursor-pointer" @click="openPopupDayOfRest(dayOfRest.day)">
                <AgCircleProgressBar :start="dayOfRest.start" :end="dayOfRest.end" :max="12" :size="90" rounded>
                  {{ t(`orders.day${dayOfRest.day}`) }}
                </AgCircleProgressBar>
                <div>{{ timeToDate(dayOfRest.timeStart, locale) }} - {{ timeToDate(dayOfRest.timeEnd, locale) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="py-8">
          <!-- DELIVERY ADDRESS UNLOADING TIMES -->
          <div class="flex justify-between items-center gap-16">
            <div class="text-l pt-12 font-bold">
              {{ t('orders.unloadingTimes') }}
            </div>
            <AgButton
              variant="ghost"
              class="px-8 text-link"
              @click="openPopupAddUnloadingTime"
              :disabled="selectedDeliveryAddress.unloadingTimes.length > 3"
            >
              {{ t('orders.add') }} <template #icon><IcAdd /></template>
            </AgButton>
          </div>
          <div class="flex flex-wrap max-md:items-center gap-12 mb-8">
            <div v-for="(unloadingTime, index) in unloadingTimes" :key="index">
              <div
                class="flex gap-4 hover:cursor-pointer w-[253px] hover:bg-neutral-500 hover:rounded-lg p-4"
                @click="openPopupEditUnloadingTime(index)"
              >
                <div class="w-full">{{ t('orders.from') }}:&nbsp;{{ timeToDate(unloadingTime.timeStart, locale) }}</div>
                <div class="w-full">{{ t('orders.to') }}:&nbsp;{{ timeToDate(unloadingTime.timeEnd, locale) }}</div>
              </div>
            </div>
          </div>
        </div>

        <PhotoVisualizer editable :order="order" />
      </div>
    </div>
    <PopupUnloadingTime v-model="showUnloadingTime" :indexUnloadingTime="selectUnloadingTime" />
    <PopupDayOfRest v-model="showDayOfRest" :day="selectDayOfRest" />
  </div>
</template>

<script setup lang="ts">
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import type { AddressDto } from '@/domain/masterData/AddressDto';
  import type { CountryDto } from '@/domain/masterData/CountryDto';
  import type { DayOfRestDto } from '@/domain/masterData/DayOfRestDto';
  import type { OrderAddressDto } from '@/domain/orderData/OrderAddressDto';
  import type { OrderBusinessCategoryDto } from '@/domain/orderData/OrderBusinessCategoryDto';

  import IcAdd from '@/components/icons/IcAdd.vue';
  import IcInfo from '@/components/icons/IcInfo.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormInputGeolocation from '@/components/library/form-geolocation/AgFormInputGeolocation.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgCircleProgressBar from '@/components/library/progress/AgCircleProgressBar.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';
  import PopupDayOfRest from '@/modules/orders/components/order-wizard/steps/address/PopupDayOfRest.vue';
  import PopupUnloadingTime from '@/modules/orders/components/order-wizard/steps/address/PopupUnloadingTime.vue';
  import OrderInfo from '@/modules/orders/components/order-wizard/steps/OrderInfo.vue';
  import PhotoVisualizer from '@/modules/orders/components/order-wizard/steps/PhotoVisualizer.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { CustomerAddressType } from '@/domain/enumeration/CustomerAddressType';
  import {
    isModifiedAddressValid,
    validateModifiedAddressForm,
  } from '@/modules/offers/components/offer-wizard/steps/Address/AddressValidation';
  import { useOrderFactory } from '@/modules/orders/composables/useOrderFactory';
  import { useOrderWizardStore } from '@/modules/orders/stores/useOrderWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { Utilities } from '@/util/Utilities';
  import { storeToRefs } from 'pinia';
  import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';

  interface DayOfRest {
    day: number;
    start: number;
    end: number;
    timeStart: string;
    timeEnd: string;
  }

  const { t, locale } = i18n.global;

  const { tables } = useMasterData();

  const { timeToDate } = Utilities();
  const { getTranslatedText } = useTranslatedText();
  const {
    getCustomer,
    initializeAddress,
    copyOrderAddress,
    copyToOrderAddress,
    calculateAddressDeliveryMethod,
    calculateAddressDeliveryTerm,
  } = useOrderFactory();

  const store = useOrderWizardStore();
  const { order } = storeToRefs(store);
  const { wizzardAddressOrrigin } = storeToRefs(store);

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('orders.order', 'orders.addressWizard');

  const theFooter = useTheFooter();
  theFooter.enabled(false);
  theFooter.visible(false);

  const selectedDeliveryAddress = ref<OrderAddressDto>();

  const deliveryAddresses = ref<Array<AddressDto>>([]);
  const businessCategories = ref<Array<OrderBusinessCategoryDto>>([]);
  const countries = ref<Array<CountryDto>>([]);

  const selectDayOfRest = ref<number>(-1);
  const selectUnloadingTime = ref<number>(-1);

  const selectedNewDeliveryAddress = ref<boolean>(false);
  const showUnloadingTime = ref<boolean>(false);
  const showDayOfRest = ref<boolean>(false);

  const selectedAddressCode = ref<string>();
  const defaultAddressCode = ref<string>('');

  const isNewCustomer = function () {
    if (!order.value) {
      return false;
    }
    return order.value.customer.id === null;
  };

  const openPopupEditUnloadingTime = (indexUnloadingTime: number) => {
    showUnloadingTime.value = true;
    selectUnloadingTime.value = indexUnloadingTime;
  };

  const unloadingTimes = computed(() => {
    if (!order.value) {
      return [];
    }
    if (order.value.deliveryAddressEqualToBilling) {
      return order.value.invoiceAddress.unloadingTimes ?? [];
    } else if (order.value.deliveryAddress) {
      return order.value.deliveryAddress.unloadingTimes ?? [];
    }
    return [];
  });

  const openPopupAddUnloadingTime = () => {
    if (order.value && selectedDeliveryAddress.value) {
      selectedDeliveryAddress.value.unloadingTimes.push({
        id: null,
        timeStart: '00:00',
        timeEnd: '00:00',
      });
      selectUnloadingTime.value = selectedDeliveryAddress.value.unloadingTimes.length - 1;
      showUnloadingTime.value = true;
    }
  };

  const openPopupDayOfRest = (dayOfRest: number) => {
    selectDayOfRest.value = dayOfRest;
    showDayOfRest.value = true;
  };

  const daysOfRest = computed(() => {
    const daysOfRest: Array<DayOfRest> = [];
    let orderDaysOfRest: Array<DayOfRestDto> = [];

    if (order.value) {
      const address = order.value.deliveryAddressEqualToBilling
        ? order.value.invoiceAddress
        : order.value.deliveryAddress;
      if (address) {
        orderDaysOfRest = address.daysOfRest.length > 0 ? address.daysOfRest : [];

        for (let i = 1; i < 8; i++) {
          const dayOfRest = orderDaysOfRest.find((d) => d.day === i);
          if (dayOfRest) {
            const start = dayOfRest.timeStart ? parseInt(dayOfRest.timeStart.split(':')[0]) : 0;
            let end = dayOfRest.timeEnd ? parseInt(dayOfRest.timeEnd.split(':')[0]) : 0;

            if (start === 0 && end === 0) {
              end = 12;
            }
            daysOfRest.push({
              day: dayOfRest.day,
              start: start,
              end: end,
              timeStart: dayOfRest.timeStart ?? '00:00',
              timeEnd: dayOfRest.timeEnd ?? '00:00',
            });
          } else {
            daysOfRest.push({
              day: i,
              start: 0,
              end: 0,
              timeStart: '',
              timeEnd: '',
            });
          }
        }
      }
    }
    return daysOfRest;
  });

  const loadDeliveryAddresses = async function () {
    if (!order.value) {
      return;
    }
    if (order.value.customer.code) {
      const customer = await getCustomer(order.value.customer.code);
      if (customer) {
        deliveryAddresses.value = customer.addresses.filter((a) => a.type != CustomerAddressType.BILLING);
      }
    }
  };

  const loadCountries = async function () {
    countries.value = await tables.countries.toArray();
  };

  const countryOptions = computed((): Array<AgSearchSelectOption> => {
    return countries.value.map((c) => {
      return {
        value: c.code,
        label: getTranslatedText(c.title).toUpperCase(),
        searchableString: getTranslatedText(c.title),
      };
    });
  });

  const selectedBusinessCategory = computed({
    get: () => {
      const id = selectedDeliveryAddress.value?.businessCategory?.id;
      if (id && businessCategories.value.some((bc) => bc.id === id)) {
        return id;
      }
      return null;
    },
    set: (businessCategoryId) => {
      const businessCategory = businessCategories.value.find((bc) => bc.id === businessCategoryId);
      if (!businessCategory || !order.value) {
        return;
      }
      if (selectedDeliveryAddress.value) {
        selectedDeliveryAddress.value.businessCategory = businessCategory;
        selectedDeliveryAddress.value.businessCategoryId = businessCategory.id;
      }
    },
  });

  const loadBusinessCategories = async function () {
    businessCategories.value = await tables.businessCategories.toArray();
  };

  const businessCategoryOptions = computed((): Array<AgSearchSelectOption> => {
    return businessCategories.value.map((bc) => {
      return {
        value: bc.id,
        label: getTranslatedText(bc.title).charAt(0).toUpperCase() + getTranslatedText(bc.title).slice(1),
        searchableString: getTranslatedText(bc.title),
      };
    });
  });

  const getAddressString = function (address: AddressDto) {
    if (address.type === CustomerAddressType.BOTH) {
      return t('orders.deliveryLikeBillingAddress');
    }
    return [
      address.name || '',
      address.street || '',
      address.zip || '',
      address.city || '',
      address.region || '',
      address.district || '',
      address.nation || '',
    ]
      .map((x) => x.trim())
      .filter((x) => x)
      .join(' / ');
  };

  const emit = defineEmits(['update:modelValue']);

  const validationAddress = computed(() => {
    emit('update:modelValue', {
      id: 1,
      error: isModifiedAddressValid(
        selectedDeliveryAddress.value,
        countries.value,
        order.value?.deliveryAddressEqualToBilling
      ),
    });
    return validateModifiedAddressForm(
      selectedDeliveryAddress.value,
      countries.value,
      order.value?.deliveryAddressEqualToBilling
    );
  });

  const selectAdressesOptions = computed((): Array<AgSearchSelectOption> => {
    let addressesOptions = new Array<AgSearchSelectOption>();

    const onlyDeliveryAddresses = deliveryAddresses.value.filter((da) => da.type !== CustomerAddressType.BOTH);
    const bothDeliveryAndBilling = deliveryAddresses.value.find((da) => da.type === CustomerAddressType.BOTH);

    if (bothDeliveryAndBilling) {
      addressesOptions.push({
        value: 'fromBilling',
        label: getAddressString(bothDeliveryAndBilling),
        searchableString: getAddressString(bothDeliveryAndBilling),
        class: bothDeliveryAndBilling.default ? 'font-bold' : '',
      });
    }
    addressesOptions = addressesOptions.concat(
      onlyDeliveryAddresses.map((da) => ({
        value: da.code,
        label: getAddressString(da),
        searchableString: getAddressString(da),
        class: da.default ? 'font-bold' : '',
      }))
    );

    addressesOptions.push({
      value: 'newAddress',
      label: t('orders.newDeliveryAddress'),
      searchableString: t('orders.newDeliveryAddress'),
    });

    return addressesOptions;
  });

  const selectAddressFromBilling = function () {
    if (order.value?.customer.isPrivate) {
      const fullName = order.value.customer.name.split(',');
      const name = fullName[0];
      const surname = fullName[1];
      order.value.invoiceAddress.name = `${name} ${surname}`;
    } else if (order.value) {
      order.value.invoiceAddress.name = order.value.customer.name;
    }
    selectedAddressCode.value = 'fromBilling';
  };

  watch(order, async () => await loadDeliveryAddresses(), { immediate: true });

  watch(
    selectedAddressCode,
    async (newValue, oldValue) => {
      if (!order.value) {
        return;
      }
      if (newValue) {
        if (newValue === 'newAddress') {
          wizzardAddressOrrigin.value[order.value.gid] = 'newAddress';
          if (oldValue) {
            selectedDeliveryAddress.value = initializeAddress();
          } else {
            if (order.value.deliveryAddress) {
              selectedDeliveryAddress.value = copyOrderAddress(order.value.deliveryAddress);
            }
          }
        } else if (newValue === 'fromBilling') {
          wizzardAddressOrrigin.value[order.value.gid] = 'fromBilling';
          selectedDeliveryAddress.value = order.value.invoiceAddress;
          selectedDeliveryAddress.value.type = CustomerAddressType.BOTH;
          order.value.deliveryAddressEqualToBilling = true;
          order.value.deliveryAddress = null;
        } else {
          wizzardAddressOrrigin.value[order.value.gid] = newValue;
          const address = deliveryAddresses.value.find((da) => da.code === newValue);
          if (address) {
            selectedDeliveryAddress.value = await copyToOrderAddress(address);
          }
        }
        if (oldValue && oldValue !== newValue) {
          order.value.deliveryMethod = await calculateAddressDeliveryMethod(
            order.value.customer.id,
            selectedDeliveryAddress.value?.id ?? null
          );
          order.value.deliveryTerm = await calculateAddressDeliveryTerm(
            order.value.customer.id,
            selectedDeliveryAddress.value?.id ?? null
          );
        }
      }
    },
    { immediate: true }
  );

  watch(
    selectedDeliveryAddress,
    async (newAddress) => {
      if (!order.value || !newAddress) {
        return;
      }
      if (newAddress.type === CustomerAddressType.BOTH) {
        order.value.deliveryAddressEqualToBilling = true;
        order.value.deliveryAddress = null;

        selectedDeliveryAddress.value = order.value.invoiceAddress;
      } else {
        order.value.deliveryAddressEqualToBilling = false;
        order.value.deliveryAddress = copyOrderAddress(newAddress);
      }
    },
    { immediate: true, deep: true }
  );

  onBeforeMount(async () => {
    await loadCountries();
    await loadBusinessCategories();
    await loadDeliveryAddresses();

    const defaultDeliveryAddresses = deliveryAddresses.value.filter((d) => d.default);
    let defaultAddress: OrderAddressDto | null = null;

    if (defaultDeliveryAddresses && defaultDeliveryAddresses.length > 0) {
      const defaultDeliveryAddress = defaultDeliveryAddresses[0];
      defaultAddressCode.value = defaultDeliveryAddress.code;
      defaultAddress = await copyToOrderAddress(defaultDeliveryAddress);
    }

    const selectAdressesOptionsFiltered = selectAdressesOptions.value.filter((a) => a.value !== 'newAddress');

    if (order.value && wizzardAddressOrrigin.value[order.value.gid]) {
      selectedAddressCode.value = wizzardAddressOrrigin.value[order.value.gid];
    } else if (defaultAddress) {
      selectedAddressCode.value = defaultAddressCode.value;
      selectedDeliveryAddress.value = defaultAddress;
    } else if (
      (order.value && order.value.deliveryAddressEqualToBilling) ||
      (order.value &&
        selectAdressesOptionsFiltered.length === 1 &&
        selectAdressesOptionsFiltered[0].value === 'fromBilling')
    ) {
      selectedAddressCode.value = 'fromBilling';
      selectedDeliveryAddress.value = copyOrderAddress(order.value.invoiceAddress);
    } else if (order.value && order.value.deliveryAddress && selectAdressesOptionsFiltered.length === 1) {
      selectedAddressCode.value = order.value.deliveryAddress.code;
      selectedDeliveryAddress.value = copyOrderAddress(order.value.deliveryAddress);
    } else {
      if (order.value) {
        wizzardAddressOrrigin.value[order.value.gid] = 'newAddress';
      }
      selectedAddressCode.value = 'newAddress';
      selectedDeliveryAddress.value = initializeAddress();
    }
  });

  onBeforeUnmount(() => {
    if (!selectedDeliveryAddress.value) {
      return;
    }
    if (order.value && order.value.deliveryAddressEqualToBilling) {
      order.value.invoiceAddress = selectedDeliveryAddress.value;
    } else if (order.value?.deliveryAddress) {
      order.value.deliveryAddress = selectedDeliveryAddress.value;
    }
  });
</script>
