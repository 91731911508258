<template>
  <div>
    <TheSubheader class="flex items-center justify-between">
      <Filter v-model:searchText="searchText" />
      <div class="flex items-center justify-end space-x-64 md:mx-[80px] max-md:mx-16">
        <AgButton variant="ghost" iconBeforeText @click="update">
          <template #icon>
            <IcUpdate />
          </template>
          <div class="text-xl">{{ t('admin.update') }}</div>
        </AgButton>

        <AgButton variant="ghost" iconBeforeText @click="newPermissionGroup">
          <template #icon>
            <IcAdd />
          </template>
          <div class="text-xl">{{ t('admin.create') }}</div>
        </AgButton>
      </div>
    </TheSubheader>

    <div class="overflow-y-auto">
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <div v-else class="md:mx-[80px] max-md:mx-16 min-w-fit pt-8 max-h-[calc(100dvh-160px)]">
        <div class="my-24">
          <AgPaginatorInfo
            :current-page="currentPage"
            :page-size="itemsPerPage"
            :number-items="permissionGroups.length"
          />
          <AgPaginator v-model="currentPage" :total-pages="pageCount" />
        </div>
        <PermissionGroupTable :permissionGroups="displayedItems" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcAdd from '@/components/icons/IcAdd.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcUpdate from '@/components/icons/IcUpdate.vue';
  import AgButton from '@/components/library/button/AgButton.vue';

  import type { PermissionGroupDto } from '@/admin/domain/PermissionGroupDto';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import Filter from '../components/filter/Filter.vue';
  import PermissionGroupTable from '../components/PermissionGroupTable.vue';
  import { usePermissiongroups } from '../composables/usePermissionGroups';

  const { t } = i18n.global;
  const { loadData } = usePermissiongroups();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('admin.permissionGroups');

  const permissionGroups = ref<Array<PermissionGroupDto>>([]);
  const searchText = ref<string>('');
  const router = useRouter();
  const isLoading = ref<boolean>(true);
  const currentPage = ref<number>(1);
  const itemsPerPage = 10;

  const filteredPermissionGroups = computed(() => {
    if (!searchText.value) {
      return permissionGroups.value;
    }

    return permissionGroups.value.filter((group) => group.code.toLowerCase().includes(searchText.value.toLowerCase()));
  });

  const pageCount = computed(() => Math.ceil(filteredPermissionGroups.value.length / itemsPerPage));

  const displayedItems = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredPermissionGroups.value.length);
    const itmxpage = filteredPermissionGroups.value.slice(startIndex, endIndex);

    return itmxpage;
  });

  const newPermissionGroup = async () => {
    try {
      await router.push({
        name: '/new-permission-group',
      });
    } catch (error) {
      console.error('Failed to navigate to permissionGroup:', error);
    }
  };

  const update = async () => {
    isLoading.value = true;
    permissionGroups.value = await loadData();
    isLoading.value = false;
  };

  onMounted(async () => {
    isLoading.value = true;
    await update();
    isLoading.value = false;
  });
</script>
