import type { CompanyDto } from '@/admin/domain/CompanyDto';
import { useApi } from '@/composables/api/useApi';
import type { ContainerDto } from '@/domain/data/ContainerDto';
import { useError } from '@/util/useError';

export default function useCompanyInfo() {
  const api = useApi();

  const { handleCatchServerError, handleCatchLocalError } = useError();

  const loadCompanyInfo = async function (): Promise<CompanyDto | undefined> {
    let company: CompanyDto | undefined = undefined;

    try {
      const requestUri = '/adminapi/company';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.company) {
        company = container.data.company as CompanyDto;
      }
      return company;
    } catch (error) {
      handleCatchLocalError(error);
    }
    return company;
  };

  return {
    loadCompanyInfo,
  };
}
