<template>
  <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
    <IcLoading />
  </div>
  <div v-else-if="campaign">
    <TheAdminSubHeader @save="saveCampaign" hasNotDeleteButton />
    <div class="space-y-8 md:mx-[80px]">
      <div class="font-bold text-primary">{{ t('admin.name').toUpperCase() }}</div>
      <AgFormTextarea
        v-model="campaign.name"
        :validation-state="!nameError"
        :error-message="nameErrorText"
        @update:model-value="resetValidation"
      />
    </div>
  </div>
  <div v-else>
    <AgAlert variant="info"> {{ t('admin.campaignCategoryNotFound') }} </AgAlert>
  </div>
</template>

<script setup lang="ts">
  import type { CampaignDto } from '@/admin/domain/CampaignDto';

  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgFormTextarea from '@/components/library/form-textarea/AgFormTextarea.vue';

  import { useCampaigns } from '@/admin/modules/campaigns/composables/useCampaigns';
  import TheAdminSubHeader from '@/components/framework/admin/the-admin-sub-header/TheAdminSubHeader.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';

  const { t } = i18n.global;
  const router = useRouter();
  const { loadData, createData } = useCampaigns();
  const theHeader = useTheHeader();

  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.newCampaign');

  const campaigns = ref<Array<CampaignDto>>([]);
  const nameError = ref<boolean>(false);
  const nameErrorText = ref<string>('');
  const isLoading = ref<boolean>(false);
  const campaign = ref<CampaignDto>({
    id: 0,
    name: '',
    activitiesNumber: 0,
  });

  const resetValidation = () => {
    nameError.value = false;
    nameErrorText.value = '';
  };

  const saveCampaign = async () => {
    if (!campaign.value.name || campaign.value.name.length === 0) {
      nameError.value = true;
      nameErrorText.value = t('admin.nameRequired');
      return;
    }

    if (campaign.value && !nameError.value) {
      await createData(campaign.value);
      try {
        await router.push({
          path: '/admin/checklists/campaigns',
        });
      } catch (error) {
        console.error('Failed to navigate to campaigns:', error);
      }
    }
  };

  onMounted(async () => {
    campaigns.value = await loadData();
  });
</script>
