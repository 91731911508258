<template>
  <div
    :class="classes"
    :style="{ backgroundImage: imgPath }"
    v-if="'/dashboard' === route.path || '/login' === route.path || '/admin/dashboard' === route.path"
  >
    <notifications position="top left" pauseOnHover class="m-24" width="40%" />

    <component v-if="layout" :is="layout">
      <router-view />
    </component>
  </div>
  <div v-else>
    <notifications position="top left" pauseOnHover class="m-24" width="40%" />

    <component v-if="layout" :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script setup lang="ts">
  import DefaultLayout from '@/layouts/DefaultLayout.vue';

  import { useAppInsights } from '@/composables/insights/useAppInsights';
  import { config } from '@/config/config';
  import { router } from '@/plugins/router';
  import { notify } from '@kyvg/vue3-notification';
  import { computed, markRaw, onErrorCaptured, ref } from 'vue';
  import { useRoute } from 'vue-router';

  const route = useRoute();

  const layout = ref();

  router.afterEach(async (to) => {
    try {
      const metaLayout = to.meta?.layout;

      const importedLayout = await import(`@/layouts/${metaLayout}.vue`);

      const component = metaLayout && importedLayout;
      layout.value = markRaw(component?.default || DefaultLayout);
    } catch (error) {
      layout.value = markRaw(DefaultLayout);
      console.warn('TheApp error, apply default layout!', error);
    }
  });

  const imgPath = computed(() => {
    if ('/admin/dashboard' === route.path) {
      return 'url(/images/bg_agent2goadmin.webp)';
    }
    if ('/dashboard' === route.path || '/login' === route.path) {
      return `url(/images/${config.company.code}.webp),url(/images/bg_agent2go.webp)`;
    }
  });

  const classes = computed(() => ({
    [`bg-cover bg-fixed bg-center bg-no-repeat`]:
      '/dashboard' === route.path || '/login' === route.path || '/admin/dashboard' === route.path,
  }));

  onErrorCaptured((error) => {
    notify({
      title: `Error: ${error}`,
      text: `${error}`,
    });
    useAppInsights().trackException(error);
  });
</script>
