<template>
  <div>
    <div class="tableHeader">
      <div class="headerColumn">{{ t('admin.name').toUpperCase() }}</div>
    </div>
    <div v-for="campaign in props.campaigns" :key="campaign.id" class="tableRow" @click="viewCampaign(campaign.id)">
      <div class="tableColumn w-full">{{ campaign.name }}</div>
      <AgButton class="text-infoBlue" variant="ghost" @click.stop="goToActivities(campaign.id)">
        {{ t('admin.resume') }}
        <template #icon>
          <IcShowDocument class="h-24 w-24" />
        </template>
      </AgButton>
      <div class="tableColumn iconColumn"><IcChevronForward /></div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { CampaignDto } from '@/admin/domain/CampaignDto';

  import IcChevronForward from '@/components/icons/IcChevronForward.vue';
  import IcShowDocument from '@/components/icons/IcShowDocument.vue';
  import AgButton from '@/components/library/button/AgButton.vue';

  import { i18n } from '@/plugins/i18n';
  import { useRouter } from 'vue-router';

  interface Props {
    campaigns: Array<CampaignDto>;
  }

  const props = defineProps<Props>();

  const router = useRouter();
  const { t } = i18n.global;

  const viewCampaign = async function (campaignId: number) {
    await router.push({
      name: 'campaign',
      params: { campaignId: campaignId },
    });
  };

  const goToActivities = async (campaignId: number) => {
    try {
      await router.push({
        name: '/campaign/activities',
        params: { campaignId: campaignId },
      });
    } catch (error) {
      console.error('Failed to navigate to campaign activities:', error);
    }
  };
</script>

<style scoped>
  .tableHeader {
    @apply gap-12 py-8 font-light text-neutral-550;
  }

  .headerColumn {
    @apply flex items-center;
    color: #04a040;
    font-weight: bold;
  }

  .tableRow {
    @apply flex w-full  gap-12 border-t border-neutral-300 py-8 hover:bg-neutral-200 hover:cursor-pointer;
  }

  .tableColumn {
    @apply flex items-center;
  }
</style>
