<template>
  <div>
    <div class="overflow-y-auto">
      <TheSubheader class="flex items-center justify-between">
        <Filter v-model:searchText="searchText" />
        <div class="flex items-center justify-end space-x-64 md:mx-[80px] max-md:mx-16">
          <AgButton variant="ghost" iconBeforeText @click="reload">
            <template #icon>
              <IcUpdate />
            </template>
            <div class="text-xl">{{ t('admin.update') }}</div>
          </AgButton>
          <AgButton variant="ghost" iconBeforeText @click="goToNewUser">
            <template #icon>
              <IcAdd />
            </template>
            <div class="text-xl">{{ t('admin.create') }}</div>
          </AgButton>
        </div>
      </TheSubheader>
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <div v-else class="md:mx-[80px] max-md:mx-16 min-w-fit pt-8 max-h-[calc(100dvh-160px)]">
        <div class="my-24">
          <AgPaginatorInfo :current-page="currentPage" :page-size="itemsPerPage" :number-items="authUsersList.length" />
          <AgPaginator v-model="currentPage" :total-pages="pageCount" />
        </div>
        <div class="tableHeader">
          <div class="headerColumn">{{ t('admin.name').toUpperCase() }}</div>
          <div class="headerColumn">{{ t('admin.email').toUpperCase() }}</div>
          <div class="headerColumn">{{ t('admin.code').toUpperCase() }}</div>
          <div class="headerColumn">{{ t('admin.password').toUpperCase() }}</div>
          <div class="headerColumn">{{ t('admin.active').toUpperCase() }}</div>
          <div class="headerColumn justify-center">{{ t('admin.actions').toUpperCase() }}</div>
        </div>
        <div v-for="user in displayedItems" :key="user.id">
          <div class="table justify-start items-center" @click="viewUser(user.id)">
            <div class="tableColumn">{{ user.name }}</div>
            <div class="tableColumn emailColumn">{{ user.email }}</div>
            <div class="tableColumn">{{ user.code }}</div>
            <div class="tableColumn">
              <component
                :is="passwordVisible.get(user.id) ? IcHide : IcShow"
                @click.stop="togglePasswordVisibility(user)"
                class="cursor-pointer ml-2"
              />
              <span v-if="passwordVisible.get(user.id)">{{ user.endOfPassword }}</span>
              <span v-else>****</span>
            </div>
            <div class="tableColumn">
              <span v-if="user.active"> {{ t('admin.active') }}</span>
              <span v-else> {{ t('admin.inactive') }}</span>
            </div>
            <div class="tableColumn justify-center" @click.stop="showModal(user)">
              <input
                class="w-24 h-24 hover:cursor-pointer"
                type="checkbox"
                :id="'user_' + user.id"
                :value="'user_' + user.id"
                v-model="activeUsers"
              />
            </div>
          </div>
        </div>
        <div class="mt-32 mb-12">
          <div>{{ t('admin.numberActiveUsers') }}:&nbsp;{{ numberActiveUsers }}</div>
          <div>{{ t('admin.adminUsersInfo') }}:&nbsp;{{ numberAdminUsers }}</div>
          <div>{{ t('admin.maxActiveUsers') }}:&nbsp;{{ maxCompanyAuthUsers }}</div>
          <div>
            {{ t('admin.activeUsersInfo') }}:&nbsp;
            <span>
              {{ numberActiveUsers - numberAdminUsers }}&nbsp;({{ numberActiveUsers }} - {{ numberAdminUsers }})
            </span>
          </div>
        </div>

        <UserActivationModal
          v-if="currentAgent"
          :userId="currentAgent.id"
          :actived="currentAgent.active"
          :user-name="currentAgent.name"
          :isOverMaxUsers="reachedMaxUsers()"
          v-model="showActivateModal"
          @update:user="reload"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { AuthUserDto } from '@/admin/domain/AuthUserDto';
  import type { CompanyDto } from '@/admin/domain/CompanyDto';

  import useCompanyInfo from '@/admin/composables/useCompanyInfo';
  import Filter from '@/admin/modules/users/components/filter/Filter.vue';
  import UserActivationModal from '@/admin/modules/users/components/UserActivationModal.vue';
  import useUsers from '@/admin/modules/users/composables/useUsers';
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcAdd from '@/components/icons/IcAdd.vue';
  import IcHide from '@/components/icons/IcHide.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcShow from '@/components/icons/IcShow.vue';
  import IcUpdate from '@/components/icons/IcUpdate.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';

  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';

  const { t } = i18n.global;
  const { loadCompanyInfo } = useCompanyInfo();
  const { loadUsers } = useUsers();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.users');

  const maxCompanyAuthUsers = ref<number>(0);
  const numberActiveUsers = ref<number>(0);
  const numberAdminUsers = ref<number>(0);

  const allUsers = ref<Array<AuthUserDto>>([]);
  const authUsersList = ref<Array<AuthUserDto>>([]);

  const isLoading = ref<boolean>(true);
  const router = useRouter();
  const passwordVisible = ref<Map<number, boolean>>(new Map());
  const currentPage = ref<number>(1);
  const itemsPerPage = 10;
  const showActivateModal = ref<boolean>(false);
  const currentAgent = ref<AuthUserDto>();
  const searchText = ref<string>('');

  const activeUsers = computed(() => allUsers.value.map((user) => (user.active ? 'user_' + user.id : '')));

  const filteredUsers = computed(() => {
    return authUsersList.value.filter((user) => {
      return (
        user.name.toLowerCase().includes(searchText.value.toLowerCase()) ||
        user.email.toLowerCase().includes(searchText.value.toLowerCase())
      );
    });
  });

  const pageCount = computed(() => Math.ceil(filteredUsers.value.length / itemsPerPage));

  const displayedItems = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredUsers.value.length);
    const itmxpage = filteredUsers.value.slice(startIndex, endIndex);

    return itmxpage;
  });

  const showModal = (user: AuthUserDto) => {
    currentAgent.value = user;
    showActivateModal.value = user ? true : false;
  };

  const viewUser = async function (userId: number) {
    await router.push({
      name: 'user',
      params: { userId },
    });
  };

  const reachedMaxUsers = () => {
    const maxUser = numberActiveUsers.value - numberAdminUsers.value;
    if (maxUser >= maxCompanyAuthUsers.value) {
      return true;
    }
    return false;
  };

  const reload = function () {
    isLoading.value = true;

    loadCompanyInfo().then((info: CompanyDto | undefined) => {
      if (info && info.configuration) {
        var configuration = JSON.parse(info.configuration);
        maxCompanyAuthUsers.value = configuration.MaxAuthUser;
        numberAdminUsers.value = configuration.NumberAdminAuthUser;
      }
    });

    loadUsers().then((users) => {
      allUsers.value = users;

      numberActiveUsers.value = users.filter((user) => user.active).length;

      authUsersList.value = users
        .filter((user) => !user.isEmployee)
        .sort((a, b) => b.modifyTimeStamp - a.modifyTimeStamp);

      users.forEach((user) => {
        passwordVisible.value.set(user.id, false);
      });
      isLoading.value = false;
    });
  };

  const goToNewUser = function () {
    router.push({ name: '/newUser' });
  };

  onMounted(() => reload());

  const togglePasswordVisibility = (user: AuthUserDto) => {
    const pwdVisibleElement = passwordVisible.value.get(user.id);
    passwordVisible.value.set(user.id, !pwdVisibleElement);
  };
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid grid-cols-[1fr_1fr_1fr_1fr_1fr_80px] grid-flow-col-dense gap-12 py-8 font-light text-neutral-550;
  }

  .headerColumn {
    @apply flex items-center hover:cursor-pointer;
    color: #04a040;
    font-weight: bold;
    word-break: break-word;
    overflow-wrap: break-word;
  }

  .table {
    @apply grid grid-cols-[1fr_1fr_1fr_1fr_1fr_80px] grid-flow-col-dense gap-12 border-t border-neutral-500 py-8;
  }

  .table:hover {
    @apply bg-neutral-200 cursor-pointer;
  }

  .tableColumn {
    @apply flex items-center;
    word-break: break-word;
    overflow-wrap: break-word;
  }

  .emailColumn {
    word-break: break-word;
    overflow-wrap: break-word;
  }

  .fill-success {
    color: #04a040; /* Assuming this is your success color */
  }

  .infoRed {
    color: red; /* Adjust this to your desired info red color */
  }

  @media (max-width: 1024px) {
    .tableHeader,
    .table {
      grid-template-columns: repeat(6, 1fr); /* Adjust grid layout for smaller screens */
      gap: 8px; /* Reduce gap for smaller screens */
    }

    .tableColumn {
      min-width: 0; /* Allow columns to shrink */
    }

    .emailColumn {
      min-width: 0; /* Allow email column to shrink */
    }
  }
</style>
