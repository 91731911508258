<template>
  <div>
    <TheSubheader class="flex items-center justify-end">
      <div class="flex space-x-64 md:mx-[80px] max-md:mx-16">
        <AgButton variant="ghost" iconBeforeText @click="update">
          <template #icon>
            <IcUpdate />
          </template>
          <div class="text-xl">{{ t('admin.update') }}</div>
        </AgButton>

        <AgButton variant="ghost" iconBeforeText @click="newLevel">
          <template #icon>
            <IcAdd />
          </template>
          <div class="text-xl">{{ t('admin.create') }}</div>
        </AgButton>
      </div>
    </TheSubheader>

    <div class="overflow-y-auto h-[calc(100vh-210px)]">
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <div v-else class="md:mx-[80px] max-md:mx-16 min-w-fit pt-8 max-h-[calc(100dvh-160px)]">
        <LevelsTable :levels="levels" />
      </div>
    </div>
    <div v-if="showErrorMaxLeveles" class="md:mx-[80px] max-md:mx-16 min-w-fit bottom-0">
      <AgAlert variant="info">
        {{ t('admin.maxLevelInfoText') }}
      </AgAlert>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { HierarchyLevelDto } from '@/admin/domain/HierarchyLevelDto';
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcAdd from '@/components/icons/IcAdd.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcUpdate from '@/components/icons/IcUpdate.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import LevelsTable from '../components/LevelsTable.vue';
  import { useHierarchies } from '../composables/useHierarchies';

  const { t } = i18n.global;

  const { loadLevels } = useHierarchies();

  const router = useRouter();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.hierarchyLevels');

  const levels = ref<Array<HierarchyLevelDto>>([]);
  const isLoading = ref<boolean>(false);
  const showErrorMaxLeveles = ref<boolean>(false);

  const newLevel = async () => {
    if (levels.value.length > 2) {
      showErrorMaxLeveles.value = true;
      setTimeout(() => {
        showErrorMaxLeveles.value = false;
      }, 5000);
      return;
    }
    try {
      await router.push({
        name: '/new-level',
      });
    } catch (error) {
      console.error('Failed to navigate to new level page:', error);
    }
  };

  const update = async () => {
    isLoading.value = true;
    levels.value = await loadLevels();
    isLoading.value = false;
  };

  onMounted(async () => await update());
</script>
