<template>
  <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
    <IcLoading />
  </div>
  <div v-else-if="hierarchy">
    <TheAdminSubHeader hasNotSaveButton hasNotDeleteButton />
    <div class="space-y-8 md:mx-[80px]">
      <div class="children">
        <HierarchyTree
          :original-tree="hierarchy"
          :tree="hierarchy"
          :deept="1"
          :levels="levels"
          @update:hierarchy="update"
        />
      </div>
    </div>
  </div>
  <div v-else>
    <AgAlert variant="info"> {{ t('admin.hierarchyNotFound') }} </AgAlert>
  </div>
</template>

<script setup lang="ts">
  import type { UserHierarchyDto } from '@/domain/UserHierarchyDto';

  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import HierarchyTree from '../components/HierarchyTree.vue';

  import type { HierarchyLevelDto } from '@/admin/domain/HierarchyLevelDto';
  import TheAdminSubHeader from '@/components/framework/admin/the-admin-sub-header/TheAdminSubHeader.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { useHierarchies } from '../composables/useHierarchies';

  const { t } = i18n.global;
  const { loadData, loadLevels } = useHierarchies();

  const route = useRoute();

  const theHeader = useTheHeader();

  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.hierarchyDetail');

  const levels = ref<Array<HierarchyLevelDto>>([]);
  const hierarchy = ref<UserHierarchyDto | null>(null);
  const isLoading = ref<boolean>(true);

  const hierarchyId = computed(() => {
    const hId = route.params.hierarchyId;
    if (Array.isArray(hId)) {
      return parseInt(hId[0]);
    }
    return parseInt(hId);
  });

  const update = async function () {
    isLoading.value = true;
    const hierarchies = await loadData();
    levels.value = await loadLevels();
    hierarchy.value = hierarchies.find((h) => h.id === hierarchyId.value) || null;
    isLoading.value = false;
  };

  onMounted(async () => update());
</script>
