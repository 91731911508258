<template>
  <div>
    <TheSubheader class="flex items-center justify-between">
      <Filter v-model:searchText="searchText" />
      <div class="flex items-center justify-end space-x-64 md:mx-[80px] max-md:mx-16">
        <AgButton variant="ghost" iconBeforeText @click="update">
          <template #icon>
            <IcUpdate />
          </template>
          <div class="text-xl">{{ t('admin.update') }}</div>
        </AgButton>

        <AgButton variant="ghost" iconBeforeText @click="newDeliveryTerm">
          <template #icon>
            <IcAdd />
          </template>
          <div class="text-xl">{{ t('admin.create') }}</div>
        </AgButton>
      </div>
    </TheSubheader>

    <div class="overflow-y-auto">
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <div v-else class="md:mx-[80px] max-md:mx-16 min-w-fit pt-8 max-h-[calc(100dvh-160px)]">
        <div class="my-24">
          <AgPaginatorInfo :current-page="currentPage" :page-size="itemsPerPage" :number-items="deliveryTerms.length" />
          <AgPaginator v-model="currentPage" :total-pages="pageCount" />
        </div>
        <DeliveryTermTable :deliveryTerms="displayedItems" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { DeliveryTermDto } from '@/admin/domain/DeliveryTermDto';

  import DeliveryTermTable from '@/admin/modules/deliveryTerms/components/DeliveryTermTable.vue';
  import Filter from '@/admin/modules/deliveryTerms/components/filter/Filter.vue';
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcAdd from '@/components/icons/IcAdd.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcUpdate from '@/components/icons/IcUpdate.vue';
  import AgButton from '@/components/library/button/AgButton.vue';

  import { useDeliveryTerms } from '@/admin/modules/deliveryTerms/composables/useDeliveryTerms';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';

  const { t, locale } = i18n.global;
  const { loadData } = useDeliveryTerms();
  const theHeader = useTheHeader();

  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.deliveryTerms');

  const deliveryTerms = ref<Array<DeliveryTermDto>>([]);
  const currentPage = ref<number>(1);
  const itemsPerPage = 10;
  const searchText = ref<string>('');
  const router = useRouter();
  const isLoading = ref<boolean>(true);

  const filteredDeliveryTerms = computed(() => {
    if (!searchText.value) {
      return deliveryTerms.value;
    }

    return deliveryTerms.value.filter(
      (term) =>
        term.code.toLowerCase().includes(searchText.value.toLowerCase()) ||
        term.title[locale.value]?.toString().toLowerCase().includes(searchText.value.toLowerCase())
    );
  });

  const pageCount = computed(() => Math.ceil(filteredDeliveryTerms.value.length / itemsPerPage));

  const displayedItems = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredDeliveryTerms.value.length);
    const itmxpage = filteredDeliveryTerms.value.slice(startIndex, endIndex);

    return itmxpage;
  });

  const newDeliveryTerm = async () => {
    try {
      await router.push({
        name: '/new-delivery-term',
      });
    } catch (error) {
      console.error('Failed to navigate to delivery term:', error);
    }
  };

  const update = async () => {
    isLoading.value = true;
    const terms = await loadData();
    deliveryTerms.value = terms;
    isLoading.value = false;
  };

  onMounted(async () => {
    isLoading.value = true;
    await update();
    isLoading.value = false;
  });
</script>
