import type { ContainerDto } from '@/domain/data/ContainerDto';

import { useAuthentication } from '@/composables/auth/useAuthentication';
import { config } from '@/config/config';
import { PageImpl, PageRequest, type Page } from '@/util/Paging';

export function useApi() {
  const { getToken } = useAuthentication();

  async function download(url: string): Promise<Blob> {
    const companyCode: string = config.company.code;
    const token: string = (await getToken()) ?? '';

    const result = await fetch(url, {
      mode: 'no-cors',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Agent2Go-Company': companyCode,
      },
    });
    if (result.status === 404) {
      return await result.json();
    }
    return await result.blob();
  }

  async function useFetch<T>(
    path: string,
    method: 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE' = 'GET',
    body: unknown = undefined,
    isAdmin: boolean = false
  ): Promise<T> {
    const companyCode: string = config.company.code;
    const baseUrl: string = isAdmin ? config.admin.url : config.backend.url;
    const url: URL = new URL(path, baseUrl);

    const token: string = (await getToken()) ?? '';

    let bodyOrNull: null | string | FormData = null;
    const headers: HeadersInit = {
      Authorization: `Bearer ${token}`,
      'Agent2Go-Company': companyCode,
    };
    if (body !== undefined) {
      if (body instanceof FormData) {
        bodyOrNull = body;
      } else {
        headers['Content-Type'] = 'application/json';
        bodyOrNull = JSON.stringify(body);
      }
    }
    const result = await fetch(url, {
      method,
      headers,
      body: bodyOrNull,
    });
    return (await result.json()) as T;
  }

  const getResultPage = function <T>(container: ContainerDto, data: Array<T>): Page<T> {
    const meta = container.meta as {
      pagination: { records: number; page: number };
      totalRecords: number;
    };
    return new PageImpl<T>(data, new PageRequest(meta.pagination.records, meta.pagination.page), meta.totalRecords);
  };

  return {
    fetch: useFetch,
    download,
    getResultPage,
  };
}
