<template>
  <div>
    <div class="overflow-y-auto">
      <TheSubheader class="flex items-center justify-between">
        <Filter v-model:searchText="searchText" />
        <div class="flex items-center justify-end space-x-64 md:mx-[80px] max-md:mx-16">
          <AgButton variant="ghost" iconBeforeText @click="update">
            <template #icon>
              <IcUpdate />
            </template>
            <div class="text-xl">{{ t('admin.update') }}</div>
          </AgButton>

          <AgButton variant="ghost" iconBeforeText @click="newCollaborator">
            <template #icon>
              <IcAdd />
            </template>
            <div class="text-xl">{{ t('admin.create') }}</div>
          </AgButton>
        </div>
      </TheSubheader>
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <div v-else class="md:mx-[80px] max-md:mx-16 min-w-fit pt-8 max-h-[calc(100dvh-160px)]">
        <div class="my-24">
          <AgPaginatorInfo :current-page="currentPage" :page-size="itemsPerPage" :number-items="usersList.length" />
          <AgPaginator v-model="currentPage" :total-pages="pageCount" />
        </div>
        <div class="tableHeader">
          <div class="headerColumn">{{ t('admin.name').toUpperCase() }}</div>
          <div class="headerColumn">{{ t('admin.email').toUpperCase() }}</div>
          <div class="headerColumn">{{ t('admin.code').toUpperCase() }}</div>
          <div class="headerColumn">{{ t('admin.password').toUpperCase() }}</div>
          <div class="headerColumn">{{ t('admin.active').toUpperCase() }}</div>
          <div class="headerColumn justify-center">{{ t('admin.actions').toUpperCase() }}</div>
        </div>
        <div v-for="collaborator in displayedItems" :key="collaborator.id">
          <div class="table justify-start items-center" @click="viewCollaborator(collaborator.id)">
            <div class="tableColumn">{{ collaborator.name }}</div>
            <div class="tableColumn emailColumn">{{ collaborator.email }}</div>
            <div class="tableColumn">{{ collaborator.code }}</div>
            <div class="tableColumn">
              <component
                :is="passwordVisible.get(collaborator.id) ? IcHide : IcShow"
                @click.stop="togglePasswordVisibility(collaborator)"
                class="cursor-pointer ml-2"
              />
              <span v-if="passwordVisible.get(collaborator.id)">{{ collaborator.endOfPassword }}</span>
              <span v-else>****</span>
            </div>

            <div class="tableColumn">
              <span v-if="collaborator.active">{{ t('admin.active') }}</span>
              <span v-else>{{ t('admin.inactive') }}</span>
            </div>

            <div class="flex justify-center" @click.stop="showModal(collaborator)">
              <input
                class="w-24 h-24 hover:cursor-pointer"
                type="checkbox"
                :id="'user_' + collaborator.id"
                :value="'user_' + collaborator.id"
                v-model="activeUsers"
              />
            </div>
          </div>
        </div>
        <div class="mt-32 mb-12">
          <div>{{ t('admin.numberActiveUsers') }}:&nbsp;{{ numberActiveUsers }}</div>
          <div>{{ t('admin.adminUsersInfo') }}:&nbsp;{{ numberAdminUsers }}</div>
          <div>{{ t('admin.maxActiveUsers') }}:&nbsp;{{ maxCompanyAuthUsers }}</div>
          <div>
            {{ t('admin.activeUsersInfo') }}:&nbsp;
            <span>
              {{ numberActiveUsers - numberAdminUsers }}&nbsp;({{ numberActiveUsers }} - {{ numberAdminUsers }})
            </span>
          </div>
        </div>

        <UserActivationModal
          v-if="currentCollaborator"
          :userId="currentCollaborator.id"
          :actived="currentCollaborator?.active"
          :user-name="currentCollaborator?.name"
          :isOverMaxUsers="reachedMaxUsers()"
          v-model="showActivateModal"
          @update:user="update"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { AuthUserDto } from '@/admin/domain/AuthUserDto';
  import type { CompanyDto } from '@/admin/domain/CompanyDto';

  import useCompanyInfo from '@/admin/composables/useCompanyInfo';
  import Filter from '@/admin/modules/collaborators/components/filter/Filter.vue';
  import UserActivationModal from '@/admin/modules/collaborators/components/UserActivationModal.vue';
  import useUsers from '@/admin/modules/users/composables/useUsers';
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcAdd from '@/components/icons/IcAdd.vue';
  import IcHide from '@/components/icons/IcHide.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcShow from '@/components/icons/IcShow.vue';
  import IcUpdate from '@/components/icons/IcUpdate.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';

  import { useCollaborators } from '@/admin/modules/collaborators/composables/useCollaborators';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';

  const { t } = i18n.global;
  const { loadData } = useCollaborators();
  const { loadUsers } = useUsers();
  const { loadCompanyInfo } = useCompanyInfo();

  const router = useRouter();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.collaborators');

  const maxCompanyAuthUsers = ref<number>(0);
  const numberActiveUsers = ref<number>(0);
  const numberAdminUsers = ref<number>(0);

  const allUsers = ref<Array<AuthUserDto>>([]);
  const usersList = ref<Array<AuthUserDto>>([]);

  const searchText = ref<string>('');
  const passwordVisible = ref<Map<number, boolean>>(new Map());
  const isLoading = ref<boolean>(true);
  const currentPage = ref<number>(1);
  const currentCollaborator = ref<AuthUserDto>();
  const showActivateModal = ref<boolean>(false);
  const itemsPerPage = 10;

  const activeUsers = computed(() => allUsers.value.map((user) => (user.active ? 'user_' + user.id : '')));

  const filteredCollaborators = computed(() => {
    return usersList.value.filter((collaborator) => {
      return (
        collaborator.name.toLowerCase().includes(searchText.value.toLowerCase()) ||
        collaborator.email.toLowerCase().includes(searchText.value.toLowerCase())
      );
    });
  });

  const pageCount = computed(() => Math.ceil(filteredCollaborators.value.length / itemsPerPage));

  const displayedItems = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredCollaborators.value.length);
    const itmxpage = filteredCollaborators.value.slice(startIndex, endIndex);

    return itmxpage;
  });

  const showModal = function (collaborator: AuthUserDto) {
    currentCollaborator.value = collaborator;
    showActivateModal.value = true;
  };

  const togglePasswordVisibility = (user: AuthUserDto) => {
    const pwdVisibleElement = passwordVisible.value.get(user.id);
    passwordVisible.value.set(user.id, !pwdVisibleElement);
  };

  const newCollaborator = async () => {
    await router.push({ name: '/new-collaborator' });
  };

  const viewCollaborator = async function (collaboratorId: number) {
    await router.push({
      name: '/collaborator',
      params: { collaboratorId: collaboratorId },
    });
  };

  const reachedMaxUsers = () => {
    const maxUser = numberActiveUsers.value - numberAdminUsers.value;
    if (maxUser >= maxCompanyAuthUsers.value) {
      return true;
    }
    return false;
  };

  const update = async () => {
    isLoading.value = true;

    loadCompanyInfo().then((info: CompanyDto | undefined) => {
      if (info && info.configuration) {
        var configuration = JSON.parse(info.configuration);
        maxCompanyAuthUsers.value = configuration.MaxAuthUser;
        numberAdminUsers.value = configuration.NumberAdminAuthUser;
      }
    });

    loadUsers().then((users) => {
      allUsers.value = users;

      numberActiveUsers.value = users.filter((user) => user.active).length;
    });
    const collaborators = await loadData();
    usersList.value = collaborators;
    collaborators.forEach((collab) => {
      passwordVisible.value.set(collab.id, false);
    });
    isLoading.value = false;
  };

  onMounted(async () => await update());
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid grid-cols-[1fr_1fr_1fr_1fr_1fr_100px] grid-flow-col-dense gap-12 py-8 font-light text-neutral-550;
  }

  .headerColumn {
    color: #04a040;
    font-weight: bold;
    word-break: break-word;
    overflow-wrap: break-word;
  }

  .table {
    @apply grid grid-cols-[1fr_1fr_1fr_1fr_1fr_100px] grid-flow-col-dense gap-12 border-t border-neutral-500 py-8;
  }

  .table:hover {
    @apply bg-neutral-200 cursor-pointer;
  }

  .tableColumn {
    @apply flex;
    word-break: break-word;
    overflow-wrap: break-word;
  }

  .emailColumn {
    word-break: break-word;
    overflow-wrap: break-word;
  }

  .fill-success {
    color: #04a040; /* Assuming this is your success color */
  }

  .infoRed {
    color: red; /* Adjust this to your desired info red color */
  }
</style>
