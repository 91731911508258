<template>
  <div class="w-fit mt-32 mx-auto" v-if="isLoading">
    <IcLoading />
  </div>
  <div v-else>
    <TheSubheader class="bg-white">
      <CustomerNoteFilter
        v-model:inputSearch="inputSearch"
        v-model:category="category"
        v-model:dateFilter="dateFilter"
        v-model:selectTypeDateFilter="selectTypeDateFilter"
      />
    </TheSubheader>

    <div v-if="customerNotes.content">
      <div>
        <div class="flex items-center justify-between">
          <AgPaginatorInfo
            :current-page="currentPage"
            :page-size="pageSize"
            :number-items="customerNotes.totalElements"
          />
        </div>
        <!-- PAGINATOR -->
        <AgPaginator v-model="currentPage" :total-pages="customerNotes.totalPages" class="my-24" />
        <!-- CUSTOMER NOTES ITEMS -->
        <div class="overflow-x-auto">
          <!-- BUTTON ADD NOTE -->
          <div class="italic text-neutral-600">*{{ t('customers.deleteNoteInfo') }}</div>
          <div class="flex justify-end">
            <AgButton class="h-48 w-48 rounded-full" @click.stop="openPopupAddNote">
              <template #icon><IcAdd /></template>
            </AgButton>
          </div>
          <div v-if="customerNotes.content.length === 0" class="mt-12">
            <AgAlert variant="info"> {{ t('customers.noNotes') }}</AgAlert>
          </div>
          <div v-else>
            <div class="tableHeader pl-8">
              <div class="cursor-default">
                <div>{{ t('customers.createdBy') }}</div>
                <div>{{ t('customers.agent') }}</div>
              </div>
              <div class="headerColumn" @click.stop.prevent="setOrder(ColumnName.DATE)">
                <span>{{ t('customers.noteDate') }}</span>
                <span v-if="sorting.col === ColumnName.DATE">
                  <span v-if="sorting.order === Order.ASC"><IcChevronDown class="h-12 w-12" /></span>
                  <span v-else-if="sorting.order === Order.DESC"><IcChevronUp class="h-12 w-12" /></span>
                  <span v-else class="h-12 w-12">&nbsp;</span>
                </span>
              </div>
              <div class="headerColumn" @click.stop.prevent="setOrder(ColumnName.MODIFYDATE)">
                <span>{{ t('customers.modifyDate') }}</span>
                <span v-if="sorting.col === ColumnName.MODIFYDATE">
                  <span v-if="sorting.order === Order.ASC"><IcChevronDown class="h-12 w-12" /></span>
                  <span v-else-if="sorting.order === Order.DESC"><IcChevronUp class="h-12 w-12" /></span>
                  <span v-else class="h-12 w-12">&nbsp;</span>
                </span>
              </div>
              <div class="headerColumn" @click.stop.prevent="setOrder(ColumnName.CATEGORY)">
                <span>{{ t('customers.category') }}</span>
                <span v-if="sorting.col === ColumnName.CATEGORY">
                  <span v-if="sorting.order === Order.ASC"><IcChevronDown class="h-12 w-12" /></span>
                  <span v-else-if="sorting.order === Order.DESC"><IcChevronUp class="h-12 w-12" /></span>
                  <span v-else class="h-12 w-12">&nbsp;</span>
                </span>
              </div>
              <div class="headerColumn" @click.stop.prevent="setOrder(ColumnName.SUBJECT)">
                <span>{{ t('customers.subject') }}</span>
                <span v-if="sorting.col === ColumnName.SUBJECT">
                  <span v-if="sorting.order === Order.ASC"><IcChevronDown class="h-12 w-12" /></span>
                  <span v-else-if="sorting.order === Order.DESC"><IcChevronUp class="h-12 w-12" /></span>
                  <span v-else class="h-12 w-12">&nbsp;</span>
                </span>
              </div>
            </div>

            <div v-for="note in customerNotes.content" :key="note.id">
              <AgCollapsible show-icon :collapse="true" class="">
                <template #header>
                  <div class="table">
                    <div class="flex flex-col justify-center break-all">
                      <div>{{ agentMap.get(note.createdByAgentId) }}</div>
                      <div>{{ agentMap.get(note.agentId) }}</div>
                    </div>
                    <div class="tableColumn">
                      {{ formatDateTime(note.dateTimestamp) }}
                    </div>
                    <div class="tableColumn">
                      {{ formatDateTime(note.modifyTimestamp) }}
                    </div>
                    <div class="tableColumn">
                      {{ note.category }}
                    </div>
                    <div class="tableColumn break-all">
                      {{ note.subject }}
                    </div>
                    <AgButton variant="ghost" @click="openPopupEditNote(note)">
                      <template #icon> <IcEdit class="h-24 w-24 hover:text-primary-light" /></template>
                    </AgButton>
                    <!-- DELETE NOTE IF YOU ARE THE AGENT WHO HAVE CREATED THE NOTE -->
                    <AgButton variant="ghost" @click="deleteNote(note.id)" :disabled="note.createdByAgentId !== userId">
                      <template #icon>
                        <IcDelete
                          class="h-24 w-24"
                          :class="{ 'hover:text-primary-light': note.createdByAgentId === userId }"
                        />
                      </template>
                    </AgButton>
                  </div>
                  <div class="flex flex-row tableColumn cursor-pointer">
                    <div class="right text-bold italic text-primary">
                      <span>{{ t('customers.note') }}&nbsp;&nbsp;</span>
                    </div>
                  </div>
                </template>
                <template #default>
                  <div class="collapsedContent">
                    <div class="bold overflow-y-scroll text-neutral-700 break-all max-w-[calc(100vw-35em)]">
                      <span class="text-wrap">{{ note.note }}</span>
                    </div>
                  </div>
                </template>
              </AgCollapsible>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CustomerNotePopup v-model="showModal" :customerId="props.customerId" />
    <CustomerNotePopupEditNote v-if="noteToEdit" v-model="showModalEdit" :customer-note="noteToEdit" />
  </div>
</template>

<script setup lang="ts">
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcAdd from '@/components/icons/IcAdd.vue';
  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';
  import IcDelete from '@/components/icons/IcDelete.vue';
  import IcEdit from '@/components/icons/IcEdit.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgCollapsible from '@/components/library/collapsible/AgCollapsible.vue';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';

  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { onMounted, ref, watch } from 'vue';

  import type { CustomerNotesDto } from '@/domain/CustomerNoteDto';
  import { CustomerNotesFilter } from '@/util/filters/CustomerNotesFilter';
  import { Order } from '@/util/Order';
  import { PageRequest } from '@/util/Paging';
  import { Sorting } from '@/util/Sorting';

  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { useHierarchy } from '@/composables/useHierarchy';
  import { useCustomerNotesStore } from '@/modules/customers/composables/useCustomerNotesStore';
  import { useHierarchyEmployeeStore } from '@/store/useHierarchyEmployeeStore';

  import CustomerNoteFilter from '@/modules/customers/components/customer-notes/CustomerNoteFilter.vue';
  import CustomerNotePopup from '@/modules/customers/components/customer-notes/CustomerNotePopup.vue';
  import CustomerNotePopupEditNote from '@/modules/customers/components/customer-notes/CustomerNotePopupEditNote.vue';

  const { t } = i18n.global;

  const { formatDateTime } = useDateFormatting();

  const { resolveHierarchyUsersWithWorkers } = useHierarchy();

  const store = useCustomerNotesStore();
  const { loadData } = store;

  const hierarchyEmployeeStore = useHierarchyEmployeeStore();
  const { userId } = storeToRefs(hierarchyEmployeeStore);

  const { customerNotes } = storeToRefs(store);

  interface Props {
    customerId: number;
  }
  const props = defineProps<Props>();

  enum ColumnName {
    DATE = 'datetimestamp',
    NOTE = 'note',
    CATEGORY = 'category',
    SUBJECT = 'subject',
    MODIFYDATE = 'modifytimestamp',
  }
  const sorting = ref<Sorting>(new Sorting('datetimestamp', Order.DESC));

  const inputSearch = ref<string>('');
  const category = ref<string>('');
  const dateFilter = ref<string>('');
  const selectTypeDateFilter = ref<number>(0);

  const currentPage = ref(1);
  const pageSize = ref(5);

  const isLoading = ref<boolean>(true);
  const showModal = ref<boolean>(false);
  const showModalEdit = ref<boolean>(false);

  const noteToEdit = ref<CustomerNotesDto>();

  const agentMap = ref<Map<number, string>>(new Map());

  const openPopupAddNote = () => {
    showModal.value = true;
  };

  const openPopupEditNote = (note: CustomerNotesDto) => {
    showModalEdit.value = true;
    noteToEdit.value = note;
  };

  const setOrder = (column: string) => {
    if (sorting.value.col === column) {
      if (sorting.value.order === Order.ASC) {
        sorting.value.order = Order.DESC;
      } else {
        sorting.value.order = Order.ASC;
      }
    } else {
      sorting.value.col = column;
      sorting.value.order = Order.ASC;
    }
  };

  const load = async (filter: CustomerNotesFilter) => {
    await loadData(props.customerId, new PageRequest(pageSize.value, currentPage.value), sorting.value, filter).then(
      () => (isLoading.value = false)
    );
  };

  const deleteNote = async (noteId: number) => {
    await store.deleteCustomerNote(noteId);
    await load(
      new CustomerNotesFilter(inputSearch.value, category.value, dateFilter.value, selectTypeDateFilter.value)
    );
  };

  const loadHierarchyList = async function () {
    const hierarchyUserList = await resolveHierarchyUsersWithWorkers();

    hierarchyUserList.forEach((currentUser) => {
      agentMap.value.set(currentUser.id, currentUser.name);
    });
  };
  watch(
    () => sorting.value,
    async () => {
      const filter = new CustomerNotesFilter(
        inputSearch.value,
        category.value,
        dateFilter.value,
        selectTypeDateFilter.value
      );
      await load(filter);
    },
    { deep: true }
  );

  watch(
    [showModal, showModalEdit, currentPage, inputSearch, category, sorting, dateFilter, selectTypeDateFilter],
    async () => {
      if (selectTypeDateFilter.value === 0 && dateFilter.value) {
        return;
      } else if (selectTypeDateFilter.value === 0 && !dateFilter.value) {
        dateFilter.value = '';
      }
      if (selectTypeDateFilter.value > 0 && selectTypeDateFilter.value < 4 && !dateFilter.value) {
        dateFilter.value = new Date().toISOString().split('T')[0];
      }
      const dateTimestamp = dateFilter.value.length > 0 ? (new Date(dateFilter.value).getTime() / 1000).toString() : '';

      const filter = new CustomerNotesFilter(
        inputSearch.value,
        category.value,
        dateTimestamp,
        selectTypeDateFilter.value
      );
      await load(filter);
    },
    { immediate: true }
  );

  onMounted(async () => await loadHierarchyList());
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid grid-cols-[minmax(103px,250px)_minmax(103px,250px)_minmax(103px,250px)_minmax(103px,200px)_minmax(133px,350px)] gap-8 py-4 font-light text-neutral-550 hover:cursor-pointer;
  }

  .headerColumn {
    @apply flex items-center;
  }

  .table {
    @apply grid grid-cols-[minmax(103px,250px)_minmax(103px,250px)_minmax(103px,250px)_minmax(103px,200px)_minmax(133px,350px)_10px_75px] gap-8 py-4 border-t border-neutral-500;
  }

  .tableColumn {
    @apply flex items-center;
  }

  .table:hover {
    @apply bg-neutral-200;
  }

  .collapsedContent {
    @apply m-24 p-16 border border-neutral-550 rounded-lg shadow-sm bg-neutral-100 cursor-pointer hover:bg-neutral-200;
  }
</style>
