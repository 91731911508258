<template>
  <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
    <IcLoading />
  </div>
  <div v-else-if="levels.length > 0">
    <TheAdminSubHeader @save="saveHierarchy" hasNotDeleteButton />

    <div class="space-y-8 md:mx-[80px]">
      <HierarchyInsertTree :tree="hierarchy" :original-tree="hierarchy" :deept="1" :levels="levels" />
    </div>
  </div>
  <div v-else-if="!isLoading && levels.length === 0" class="md:mx-[80px] my-32">
    <AgAlert variant="info">
      <template #icon>
        <IcAlert />
      </template>
      <span>{{ t('admin.levelsRequired') }}</span>
    </AgAlert>
  </div>
</template>

<script setup lang="ts">
  import type { HierarchyLevelDto } from '@/admin/domain/HierarchyLevelDto';
  import type { InsertHierarchyDto } from '@/admin/domain/InsertHierarchyDto';
  import TheAdminSubHeader from '@/components/framework/admin/the-admin-sub-header/TheAdminSubHeader.vue';
  import IcAlert from '@/components/icons/IcAlert.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import HierarchyInsertTree from '../components/HierarchyInsertTree.vue';
  import { useHierarchies } from '../composables/useHierarchies';

  const { t } = i18n.global;
  const { createHierarchy, loadLevels } = useHierarchies();

  const router = useRouter();
  const theHeader = useTheHeader();

  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.newHierarchy');

  const hierarchy = ref<InsertHierarchyDto>({
    description: '',
    authUserId: 0,
    authUserName: '',
    authUserLevelId: 0,
    parentAuthUserId: null,
    children: null,
  });
  const isLoading = ref<boolean>(false);
  const levels = ref<Array<HierarchyLevelDto>>([]);

  const saveHierarchy = async () => {
    if (hierarchy.value.authUserId) {
      await createHierarchy(hierarchy.value);
    }
    try {
      await router.push({
        path: '/admin/hierarchy/hierarchies',
      });
    } catch (error) {
      console.error('Failed to navigate to hierarrchies:', error);
    }
  };

  onMounted(async () => {
    isLoading.value = true;
    levels.value = await loadLevels();
    isLoading.value = false;
  });
</script>
