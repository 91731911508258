<template>
  <div>
    <div class="py-8">
      <div v-if="!isLoading && fileImages.length > 0" class="gap-4 text-s-13 flex items-center">
        <AgButton variant="ghost" class="cursor-default flex">
          <template #icon>
            <IcInfo class="text-infoBlue h-[20px] w-[20px]" />
          </template>
        </AgButton>
        <span class="italic font-light">{{ t('admin.imgAdvice') }}</span>
      </div>
      <div class="flex flex-row justify-between max-md:flex-col gap-8 border-neutral-550">
        <div v-if="isLoading">
          <IcLoading />
        </div>

        <div v-else-if="!isLoading && fileImages.length > 0" class="flex gap-2">
          <div :class="getPhotoOneClass" class="flex">
            <span v-if="fileImages[0] && fileImages[0].size > 0">
              <img :src="imgToDisplayableImage(0)" alt="Photo one" width="180" height="140" />
            </span>
            <span v-else>{{ t('orders.imageNotFound') }}</span>
            <div class="flex flex-col">
              <AgButton variant="ghost" class="text-success px-8" @click="downloadOpenPhoto(0, true)">
                <IcDownload />
              </AgButton>
              <AgButton variant="ghost" class="text-infoBlue px-8" @click="downloadOpenPhoto(0, false)">
                <IcShow />
              </AgButton>
            </div>
          </div>
          <div v-if="fileImages.length > 1" :class="getPhotoTwoClass" class="flex flex-row">
            <span v-if="fileImages[1] && fileImages[1].size > 0">
              <img :src="imgToDisplayableImage(1)" alt="Photo two" width="180" height="140" />
            </span>
            <span v-else>{{ t('orders.imageNotFound') }}</span>
            <div class="flex flex-col">
              <AgButton variant="ghost" class="text-success px-8" @click="downloadOpenPhoto(1, true)">
                <IcDownload />
              </AgButton>
              <AgButton variant="ghost" class="text-infoBlue px-8" @click="downloadOpenPhoto(1, false)">
                <IcShow />
              </AgButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IcDownload from '@/components/icons/IcDownload.vue';
  import IcInfo from '@/components/icons/IcInfo.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcShow from '@/components/icons/IcShow.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import type { OfferDto } from '@/domain/offerData/OfferDto';
  import type { OrderDto } from '@/domain/orderData/OrderDto';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { usePhotos } from '../composables/usePhotos';

  interface Props {
    orderOffer: OrderDto | OfferDto;
  }

  const props = defineProps<Props>();

  const { openFile, downloadFile } = usePhotos();
  const { t } = i18n.global;

  const fileImages = ref<Array<File>>([]);
  const isLoading = ref(false);

  const calculatedDeliveryAddress = computed(() => {
    if (props.orderOffer.deliveryAddressEqualToBilling) {
      return props.orderOffer.invoiceAddress;
    } else if (props.orderOffer.deliveryAddress) {
      return props.orderOffer.deliveryAddress;
    }
  });

  const getPhotoOneClass = computed(() => {
    if (fileImages.value.length > 0) {
      return 'visible';
    }
    return 'invisible';
  });

  const getPhotoTwoClass = computed(() => {
    let cls = '';
    if (fileImages.value.length > 1) {
      cls += 'visible';
    } else {
      cls += 'invisible';
    }
    return cls;
  });

  const loadPhotos = async function () {
    calculatedDeliveryAddress.value?.photos.forEach(async (name, index) => {
      const blob = await downloadPhoto(index);
      if (blob) {
        fileImages.value[index] = new File([blob], name);
      } else {
        fileImages.value[index] = new File([], name);
      }
    });
    isLoading.value = false;
  };

  const downloadPhoto = async function (index: number) {
    if (
      calculatedDeliveryAddress.value &&
      calculatedDeliveryAddress.value.photos.length > 0 &&
      index < calculatedDeliveryAddress.value.photos.length
    ) {
      const fileName = calculatedDeliveryAddress.value.photos[index];
      return downloadFile(fileName);
    }
  };

  const downloadOpenPhoto = async function (index: number, open: boolean) {
    if (
      calculatedDeliveryAddress.value &&
      calculatedDeliveryAddress.value.photos.length > 0 &&
      index < calculatedDeliveryAddress.value.photos.length
    ) {
      const fileName = calculatedDeliveryAddress.value.photos[index];
      return openFile(fileName, open);
    }
  };

  const imgToDisplayableImage = function (index: number) {
    try {
      if (fileImages.value && fileImages.value.length > 0 && index < fileImages.value.length) {
        const file = fileImages.value[index];
        if (file && file.size > 0) {
          const url = window.URL.createObjectURL(file);
          return url;
        }
      }
    } catch (e) {}
    return '';
  };

  onMounted(() => {
    isLoading.value = true;
    loadPhotos();
  });
</script>
