import { ref } from 'vue';

const headerVisible = ref(false);
const headerEnabled = ref(false);
const hierarchyEnabled = ref(false);

const saveButtonVisible = ref(false);

const primaryTextKey = ref('');
const secondaryTextKey = ref('');

export function useTheHeader() {
  const visible = function (isVisible: boolean) {
    headerVisible.value = isVisible;
  };

  const enabled = function (isEnabled: boolean) {
    headerEnabled.value = isEnabled;
  };

  const enableHierarchy = function (isHierarchyActive: boolean) {
    hierarchyEnabled.value = isHierarchyActive;
  };

  const saveVisible = function (isVisible: boolean) {
    saveButtonVisible.value = isVisible;
  };

  const title = function (primary: string, secondary = '') {
    primaryTextKey.value = primary;
    secondaryTextKey.value = secondary;
  };

  return {
    visible,
    saveVisible,
    enabled,
    title,
    enableHierarchy,
    headerVisible,
    headerEnabled,
    saveButtonVisible,
    primaryTextKey,
    secondaryTextKey,
    hierarchyEnabled,
  };
}
