<template>
  <div v-if="customerIds.length > 0">
    <!-- SUBHEADER SEARCH AREA -->
    <TheSubheader>
      <ByCustomerFilter
        :customerIds="customerIds"
        v-model:customerId="selectedCustomerId"
        v-model:filter="selectedFilter"
      />
    </TheSubheader>

    <div class="mb-32 mt-12 md:mx-[80px] max-md:mx-16">
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <div>
        <!-- TABLE CONTINGENTS BY CUSTOMER -->
        <div class="overflow-x-auto">
          <ByCustomerTable :contingents="selectedCustomerContingentGroup" />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="mb-32 mt-32 md:mx-[80px] max-md:mx-16">
    <AgAlert variant="info">{{ t('contingents.notFound') }}</AgAlert>
  </div>
</template>

<script setup lang="ts">
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import ByCustomerTable from '@/modules/contingents/components/by-customer/ByCustomerTable.vue';
  import ByCustomerFilter from '@/modules/contingents/components/filters/ByCustomerFilter.vue';

  import type { ContingentDto } from '@/domain/masterData/ContingentDto';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { useHierarchyEmployeeStore } from '@/store/useHierarchyEmployeeStore';
  import { storeToRefs } from 'pinia';
  import { onMounted, ref, watch } from 'vue';

  const { t } = i18n.global;

  const header = useTheHeader();
  header.enabled(true);
  header.visible(true);
  header.enableHierarchy(true);
  header.title('contingents.contingents', 'contingents.byCustomer');

  const { tables } = useMasterData();

  const store = useHierarchyEmployeeStore();

  const { agentId } = storeToRefs(store);
  const isLoading = ref<boolean>(true);

  const customerIdContingentGroup = ref<Map<number, Array<ContingentDto>>>(new Map());
  const selectedCustomerContingentGroup = ref<Map<number, Array<ContingentDto>>>(new Map());

  const customerIds = ref<Array<number>>([]);
  const selectedCustomerId = ref<number>(-1);
  const selectedFilter = ref<string>();

  const loadContingents = async function () {
    const contingentsByCustomerId = await tables.contingents.filter((c) => c.customerId !== undefined).toArray();

    contingentsByCustomerId.forEach((c) => {
      if (c.customerId) {
        if (customerIdContingentGroup.value.has(c.customerId)) {
          if (customerIdContingentGroup.value.get(c.customerId)?.some((cc) => cc.id === c.id)) {
            return;
          }
          customerIdContingentGroup.value.get(c.customerId)?.push(c);
        } else {
          customerIdContingentGroup.value.set(c.customerId, [c]);
        }
      }
    });
  };

  const filterContingents = function (agentId: number, filter: string, customerId: number) {
    selectedCustomerContingentGroup.value.clear();

    for (const [key, value] of customerIdContingentGroup.value) {
      const filteredContingents = filterContingentsByCriteria(value, key, agentId, filter, customerId);
      if (filteredContingents.length > 0) {
        selectedCustomerContingentGroup.value.set(key, filteredContingents);
      }
    }
  };

  const filterContingentsByCriteria = (
    contingents: Array<ContingentDto>,
    key: number,
    agentId: number,
    filter: string,
    customerId: number
  ) => {
    return contingents.filter((c) => {
      let res = true;
      if (agentId !== -1) {
        res = res && c.agentId ? c.agentId === agentId : false;
      }
      if (customerId !== -1) {
        res = res && key === customerId;
      }
      if (filter !== 'All') {
        res = res && c.filter ? c.filter === filter : false;
      }
      return res;
    });
  };

  watch([selectedCustomerId, selectedFilter, agentId], () =>
    filterContingents(agentId.value ?? -1, selectedFilter.value ?? 'All', selectedCustomerId.value ?? -1)
  );

  onMounted(async () => {
    loadContingents().then(() => {
      filterContingents(agentId.value ?? -1, selectedFilter.value ?? 'All', selectedCustomerId.value ?? -1);
      customerIds.value = Array.from(customerIdContingentGroup.value.keys());
      isLoading.value = false;
    });
  });
</script>
