<template>
  <div>
    <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
      <IcLoading />
    </div>

    <div v-else-if="currentDeliveryMethod">
      <TheAdminSubHeader @save="saveDeliveryMethod" @delete="deleteDeliveryMethod(currentDeliveryMethod.id)" />

      <div class="md:mx-[80px] py-12">
        <div>
          <div>
            <div class="infoboxTitle">{{ t('admin.code').toUpperCase() }}</div>
            {{ currentDeliveryMethod.code }}
          </div>

          <div class="flex items-center justify-between w-1/2 space-y-12">
            <div>
              <span class="infoboxTitle">{{ t('admin.predefined').toUpperCase() }}</span>
              <AgButton
                variant="ghost"
                class="h-24 w-24 flex items-center border-2"
                :class="{ 'border-success': !defaultError, 'border-infoRed': defaultError }"
                @click="toggleDefault"
              >
                <template #icon>
                  <IcCheck v-if="currentDeliveryMethod.default" class="pr-4" />
                </template>
              </AgButton>
              <AgAlert v-if="defaultError" variant="danger" class="w-256">
                {{ t('admin.deliveryMethodStandardAlreadyChecked') }}
              </AgAlert>
            </div>

            <div>
              <div class="infoboxTitle">{{ t('admin.recieveDateObligatory').toUpperCase() }}</div>
              <IcCheck
                @click="toggleObbligatoryDate"
                class="border-success border-2 cursor-pointer"
                :class="{
                  'fill-black': currentDeliveryMethod.requestedReceiptDateRequired,
                  'fill-transparent': !currentDeliveryMethod.requestedReceiptDateRequired,
                }"
              />
            </div>
          </div>
        </div>

        <div class="table mt-16">
          <span class="text-primary-light">{{ t('admin.languageDescription').toUpperCase() }}</span>
          <div v-for="selectedLang in Object.values(config.company.availableLanguages)" :key="selectedLang">
            <div class="tableColumn">
              <AgFormInput :placeholder="selectedLang" disabled />
              <AgFormInput
                v-model="currentDeliveryMethod.title[selectedLang]"
                :validation-state="hasDescValue(selectedLang)"
                @input="descriptionError = false"
              />
              <IcClose class="cursor-pointer" @click="deleteTranslation(selectedLang)"></IcClose>
            </div>
          </div>
          <AgAlert v-if="descriptionError" variant="danger" dismissible>
            {{ t('admin.descriptionRequired') }}
          </AgAlert>
        </div>
      </div>
    </div>

    <div v-else>
      <AgAlert variant="info">{{ t('admin.deliveryMethodnotFound') }}</AgAlert>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { DeliveryMethodDto } from '@/admin/domain/DeliveryMethodDto';

  import IcCheck from '@/components/icons/IcCheck.vue';
  import IcClose from '@/components/icons/IcClose.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';

  import { useDeliveryMethods } from '@/admin/modules/deliveryMethods/composables/useDeliveryMethods';
  import TheAdminSubHeader from '@/components/framework/admin/the-admin-sub-header/TheAdminSubHeader.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { config } from '@/config/config';
  import { i18n } from '@/plugins/i18n';
  import { onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  const { t } = i18n.global;
  const route = useRoute();
  const router = useRouter();
  const { loadData, deleteData, updateData } = useDeliveryMethods();
  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.deliveryMethodDetails');

  const deliveryMethods = ref<Array<DeliveryMethodDto>>([]);
  const defaultError = ref<boolean>(false);
  const isLoading = ref<boolean>(true);
  const currentDeliveryMethod = ref<DeliveryMethodDto>();
  const descriptionError = ref<boolean>(false);

  const hasDescValue = function (lang: string) {
    if (!currentDeliveryMethod.value) {
      return false;
    }
    if (!descriptionError.value) {
      return true;
    }
    if (!Object.keys(currentDeliveryMethod.value.title).includes(lang)) {
      return false;
    }
    return currentDeliveryMethod.value.title[lang].length > 0;
  };

  const setCurrentItem = () => {
    currentDeliveryMethod.value = deliveryMethods.value.find((method) => method.id.toString() === route.params.codeId);
  };

  const toggleDefault = function () {
    if (currentDeliveryMethod.value) {
      currentDeliveryMethod.value.default = !currentDeliveryMethod.value.default;
    }
    resetValidation();
  };

  const resetValidation = function () {
    defaultError.value = false;
  };

  const saveDeliveryMethod = async () => {
    resetValidation();

    if (currentDeliveryMethod.value?.default) {
      if (deliveryMethods.value.some((method) => method.default && method.id !== currentDeliveryMethod.value?.id)) {
        defaultError.value = true;
        return;
      }
    }

    if (Object.keys(currentDeliveryMethod.value?.title ?? '').length === 0) {
      descriptionError.value = true;
      return;
    }
    if (Object.values(currentDeliveryMethod.value?.title ?? '').every((value) => value.length === 0)) {
      descriptionError.value = true;
      return;
    }

    isLoading.value = true;
    await updateData(currentDeliveryMethod.value);
    try {
      await router.push({
        path: '/admin/delivery-methods',
      });
    } catch (error) {
      console.error('Failed to navigate to delivery method:', error);
    }
    isLoading.value = false;
  };

  const deleteDeliveryMethod = async function (dmId: number) {
    isLoading.value = true;
    await deleteData(dmId);
    try {
      await router.push({
        path: '/admin/delivery-methods',
      });
    } catch (error) {
      console.error('Failed to navigate to delivery method:', error);
    }
    isLoading.value = false;
  };

  const deleteTranslation = function (language: string) {
    if (!currentDeliveryMethod.value) {
      return;
    }
    const titleKeys = Object.keys(currentDeliveryMethod.value.title);
    const indexToRemove = titleKeys.findIndex((keyLang) => keyLang === language);

    if (indexToRemove !== -1) {
      currentDeliveryMethod.value.title[language] = '';
    }
  };
  const toggleObbligatoryDate = () => {
    if (!currentDeliveryMethod.value) {
      return;
    }
    currentDeliveryMethod.value.requestedReceiptDateRequired =
      !currentDeliveryMethod.value.requestedReceiptDateRequired;
  };

  const update = async function () {
    loadData()
      .then((methods) => {
        deliveryMethods.value = methods ?? [];
        if (methods) {
          setCurrentItem();
        }
        isLoading.value = false;
      })
      .catch((error) => {
        console.log('Error loading delivery methods: ', error);
        isLoading.value = false;
      });
  };

  onMounted(async () => update());
</script>

<style scoped lang="scss">
  .infoboxTitle {
    @apply text-primary-light;
  }

  .name {
    @apply text-xl font-bold flex items-center;
  }

  .table {
    @apply grid grid-cols-1 py-16 space-x-0;
  }

  .tableColumn {
    @apply flex items-center min-w-0 py-4 gap-4;
  }
</style>
