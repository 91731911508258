<template>
  <AgModal v-if="internalValue" fit-height fit-width>
    <template #header>
      <span class="text-xl font-bold">{{ t('admin.addAgent') }}</span>
    </template>
    <template #content>
      <div class="h-[calc(100vh-500px)] w-[calc(100vw-1200px)]">
        <div v-if="isLoading" class="flex justify-center items-center h-full">
          <IcLoading />
        </div>
        <div v-else>
          <AgFormGroup :label="t('admin.agent')">
            <AgSearchSelect :options="agentOptions" v-model="agentId" />
          </AgFormGroup>
          <AgFormGroup :label="t('admin.description')" class="py-12">
            <AgFormInput v-model="description" />
          </AgFormGroup>
          <AgFormGroup :label="t('admin.level')">
            <AgFormSelect v-model="levelId" :options="levelOptions" />
          </AgFormGroup>
        </div>
      </div>
    </template>
    <template #footer>
      <AgButton variant="secondary" @click="internalValue = false">
        {{ t('admin.cancel') }}
      </AgButton>
      <AgButton @click="save" :disabled="isDisabled">
        {{ t('admin.save') }}
      </AgButton>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import type { AuthUserDto } from '@/admin/domain/AuthUserDto';
  import type { HierarchyLevelDto } from '@/admin/domain/HierarchyLevelDto';
  import type { InsertHierarchyDto } from '@/admin/domain/InsertHierarchyDto';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';
  import AgModal from '@/components/library/modal/AgModal.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import { i18n } from '@/plugins/i18n';
  import { computed, ref, watch } from 'vue';
  import useUsers from '../../users/composables/useUsers';
  import { useHierarchies } from '../composables/useHierarchies';

  const { t } = i18n.global;

  const { loadUsers } = useUsers();
  const { loadLevels, loadData } = useHierarchies();

  interface Props {
    modelValue: boolean;
    tree: InsertHierarchyDto;
    originalTree: InsertHierarchyDto;
    deept: number;
  }

  const props = defineProps<Props>();
  const emit = defineEmits(['update:modelValue']);

  const agentId = ref<number>();
  const levelId = ref<number>();
  const agents = ref<Array<AuthUserDto>>([]);
  const agentOptions = ref<Array<AgSearchSelectOption>>([]);
  const levelOptions = ref<Array<AgFormSelectOption>>([]);
  const levels = ref<Array<HierarchyLevelDto>>([]);
  const isLoading = ref<boolean>(true);
  const description = ref<string>('');

  const internalValue = computed({
    get: () => props.modelValue,
    set: (value: boolean) => {
      emit('update:modelValue', value);
    },
  });

  const isDisabled = computed(() => !agentId.value || !levelId.value || !description.value);

  const isInHierarchy = function (tree: InsertHierarchyDto, agentId: number) {
    if (tree.authUserId === agentId) {
      return true;
    }

    if (tree.children) {
      for (const child of tree.children) {
        if (isInHierarchy(child, agentId)) {
          return true;
        }
      }
    }

    return false;
  };

  const save = async function () {
    if (!agentId.value || !levelId.value) {
      return;
    }
    if (!props.originalTree.children) {
      props.tree.authUserId = agentId.value;
      props.tree.authUserLevelId = levelId.value;
      props.tree.authUserName = agentOptions.value.find((option) => option.value === agentId.value)?.label || '';
      props.tree.children = [];
      props.tree.description = description.value;
    } else if (props.tree.children) {
      props.tree.children.push({
        authUserId: agentId.value,
        authUserName: agentOptions.value.find((option) => option.value === agentId.value)?.label || '',
        authUserLevelId: levelId.value,
        children: [],
        description: description.value,
        parentAuthUserId: props.tree.authUserId,
      });
    }
    emit('update:modelValue', false);
  };

  watch(internalValue, async (newValue) => {
    if (newValue) {
      isLoading.value = true;
      agents.value = await loadUsers();
      levels.value = await loadLevels();
      const hierarchies = await loadData();
      agentId.value = undefined;
      description.value = '';

      agentOptions.value = agents.value.map((agent) => ({
        label: agent.name,
        value: agent.id,
        searchableString: agent.name,
        disabled:
          isInHierarchy(props.originalTree, agent.id) ||
          hierarchies.some((h) => h.authUser.id === agent.id && !h.parentUser && !props.originalTree.authUserId),
      }));

      levels.value.sort((a, b) => a.grade - b.grade);

      if (!props.originalTree.authUserId) {
        levelId.value = levels.value[0].id;
        levels.value = levels.value.filter((l) => l.grade === 1);
      } else {
        levelId.value = levels.value[props.deept].id;
        levels.value = levels.value.filter((l) => l.grade > props.deept);
      }

      levelOptions.value = levels.value.map((level) => ({
        label: level.description,
        key: level.id,
      }));

      isLoading.value = false;
    }
  });
</script>
