<template>
  <div class="md:mx-[80px] max-md:mx-16 min-w-fit pt-8 max-h-[calc(100dvh-160px)] flex w-full gap-48">
    <AgFormGroup :label="t('admin.search')" class="w-full max-w-384">
      <AgSearchInput v-model="internalSearchText" />
    </AgFormGroup>
    <AgFormGroup :label="t('admin.agent')" class="w-full max-w-256">
      <AgSearchSelect :options="agentOptions" v-model="internalAgent" />
    </AgFormGroup>
  </div>
</template>

<script setup lang="ts">
  import useUsers from '@/admin/modules/users/composables/useUsers';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';

  import AgSearchInput from '@/components/library/search-input/AgSearchInput.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';

  const { t } = i18n.global;
  const { loadUsers } = useUsers();

  interface Props {
    searchText: string;
    agentId: number;
  }

  const props = defineProps<Props>();

  const emit = defineEmits(['update:searchText', 'update:agentId']);

  const agentOptions = ref<Array<AgSearchSelectOption>>([]);

  const internalSearchText = computed({
    get: () => {
      return props.searchText;
    },
    set: (value) => {
      emit('update:searchText', value);
    },
  });

  const internalAgent = computed({
    get: () => {
      return props.agentId;
    },
    set: (value) => {
      emit('update:agentId', value);
    },
  });

  onMounted(async () => {
    const users = await loadUsers();
    agentOptions.value = users.map((u) => ({
      label: u.name,
      value: u.id,
      searchableString: u.name,
    }));
    agentOptions.value.unshift({
      label: t('admin.all'),
      value: -1,
      searchableString: t('admin.all'),
    });
  });
</script>
