<template>
  <AgModal v-if="internalValue" fit-height fit-width>
    <template #header>
      <span class="text-xl font-bold">{{ t('admin.truncateAgent') }}</span>
    </template>
    <template #content>
      <div class="h-384 flex items-center justify-center">
        {{ t('admin.confirmEliminationText') }}
      </div>
    </template>
    <template #footer>
      <AgButton variant="secondary" @click="internalValue = false">
        {{ t('admin.cancel') }}
      </AgButton>
      <AgButton @click="save">
        {{ t('admin.save') }}
      </AgButton>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';
  import type { UserHierarchyDto } from '@/domain/UserHierarchyDto';
  import { i18n } from '@/plugins/i18n';
  import { computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { useHierarchies } from '../composables/useHierarchies';

  const { t } = i18n.global;

  const { truncateAgentHierarchy } = useHierarchies();

  const router = useRouter();

  interface Props {
    modelValue: boolean;
    tree: UserHierarchyDto;
    agentId: number;
    agentName: string;
    deept: number;
  }

  const props = defineProps<Props>();
  const emit = defineEmits(['update:modelValue', 'update:hierarchy']);

  const internalValue = computed({
    get: () => props.modelValue,
    set: (value: boolean) => {
      emit('update:modelValue', value);
    },
  });

  const save = async function () {
    await truncateAgentHierarchy(props.tree.id);
    emit('update:modelValue', false);
    console.log(props.deept);
    if (props.deept === 1) {
      await router.push({ path: '/admin/hierarchy/hierarchies' });
      return;
    }
    emit('update:hierarchy');
  };
</script>
