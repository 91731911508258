<template>
  <div class="flex flex-row items-center justify-between md:mx-[80px] max-md:mx-16 gap-64">
    <AgFormGroup :label="t('contingents.filter')" class="w-1/2">
      <AgSearchSelect :options="filterOptions" v-model="internalFilter" />
    </AgFormGroup>

    <AgFormGroup :label="t('contingents.contingentGroupArticle')" class="w-1/2">
      <AgSearchSelect :options="contGrArticlesByDimension" v-model="internalContGrArticleByDim" />
    </AgFormGroup>
  </div>
</template>

<script setup lang="ts">
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';

  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';

  const { t } = i18n.global;
  const { tables } = useMasterData();

  interface Props {
    filterArtGrDimLabels: Map<string, string>;
    contingentGroupArticleByDim: string | undefined;
    filter: string | undefined;
  }
  const props = withDefaults(defineProps<Props>(), {
    contingentGroupArticleByDim: 'All',
    filter: 'All',
  });

  const emit = defineEmits(['update:filter', 'update:contingentGroupArticleByDim']);

  const filterOptions = ref<Array<AgSearchSelectOption>>([]);
  const contGrArticlesByDimension = ref<Array<AgSearchSelectOption>>([]);

  const internalContGrArticleByDim = computed({
    get: () => {
      return props.contingentGroupArticleByDim;
    },
    set: (value) => {
      if (value) {
        emit('update:contingentGroupArticleByDim', value);
      }
    },
  });

  const internalFilter = computed({
    get: () => {
      return props.filter;
    },
    set: (value) => {
      if (value) {
        emit('update:filter', value);
      }
    },
  });

  const loadContGrArticlesByDimension = function () {
    contGrArticlesByDimension.value.push({
      value: 'All',
      label: 'contingents.allItems',
      searchableString: 'contingents.allItems',
    });
    props.filterArtGrDimLabels.forEach((label: string, key: string) =>
      contGrArticlesByDimension.value.push({
        value: key,
        label: label + ' ',
        searchableString: label,
      })
    );
  };

  const loadFilterContingent = async function () {
    const contingents = await tables.contingents.toArray();
    const contingentsWithFilter = contingents.filter((c) => c.filter);
    const filters = new Set(contingentsWithFilter.map((c) => c.filter ?? ''));

    filterOptions.value.push({ value: 'All', label: 'contingents.allItems', searchableString: 'contingents.allItems' });
    filters.forEach((f) =>
      filterOptions.value.push({
        value: f,
        label: f + ' ',
        searchableString: f,
      })
    );
  };

  onMounted(async () => {
    loadContGrArticlesByDimension();
    loadFilterContingent();
  });
</script>
