<template>
  <div class="text-white mt-12 mb-32 md:mx-[80px] max-md:mx-16">
    <h1 class="text-3xl font-bold">{{ t('welcomeBackDashboard') }} {{ userName }}</h1>

    <div class="flex flex-col justify-between h-[calc(100vh-220px)] mt-24">
      <div class="m-auto"></div>
      <div class="flex max-lg:flex-col gap-24 pt-24">
        <!-- Active Users Box -->
        <div class="pt-0 flex-grow border">
          <div class="justify-center flex-col text-l text-center bg-default">
            <span>{{ t('admin.numberActiveUser') }}</span>
            <p class="leading-10 text-link">{{ activeUsersCount }}</p>
          </div>
        </div>

        <!-- Downloads Box -->
        <div class="pt-0 flex-grow border">
          <div class="justify-center flex-col text-l text-center bg-default">
            <span>{{ t('admin.numberDownloads') }}</span>
            <p class="leading-10 text-link">{{ numberOfdownloads }}</p>
          </div>
        </div>

        <!-- Communication Box -->
        <div class="pt-0 flex-grow border">
          <div class="justify-center flex-col text-l text-center bg-default">
            <span>{{ t('admin.numberCommunication') }}</span>
            <p class="leading-10 text-link">{{ numberOfcommunications }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useDashboard } from '@/admin/composables/useDashboard';
  import type { AuthUserDto } from '@/admin/domain/AuthUserDto';
  import { useCommunication } from '@/admin/modules/communication/composables/useCommunication';
  import { useDownloads } from '@/admin/modules/downloads/composables/useDownloads';
  import { useAuthentication } from '@/composables/auth/useAuthentication';
  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';

  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';

  const { t } = i18n.global;
  const { getUsers } = useDashboard();
  const { loadData: loadDownloadData } = useDownloads();
  const { loadData } = useCommunication();
  const { userName } = useAuthentication();

  const usersList = ref<Array<AuthUserDto>>([]);
  const numberOfdownloads = ref<number>(0);
  const numberOfcommunications = ref<number>(0);

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(false);
  theHeader.enableHierarchy(false);

  const theFooter = useTheFooter();
  theFooter.enabled(true);
  theFooter.visible(true);

  // Compute active users count
  const activeUsersCount = computed(() => {
    return usersList.value.filter((user) => user.active).length;
  });

  onMounted(async () => {
    usersList.value = await getUsers();

    const communications = await loadData();
    numberOfcommunications.value = communications.length;

    const downloads = await loadDownloadData();
    numberOfdownloads.value = downloads.length;
  });

  //const activeDownloadsNumber = () => {};
</script>

<style scoped>
  .border {
    border-width: 1px;
    border-color: gray;
    border-style: solid;
  }
</style>
