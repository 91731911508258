<template>
  <div class="py-[40px] overflow-x-auto">
    <div class="my-24">
      <AgButton @click="exportCSVData()">File CSV</AgButton>
    </div>
    <div class="mx-auto w-fit">
      <!-- TABLE HEADER PERIODS-->
      <StatisticByMonthPeriodsHeader
        :yearOne="props.yearOne"
        :yearTwo="props.yearTwo"
        :startMonth="props.startMonth"
        :endMonth="props.endMonth"
        :agentId="props.agentId"
      />

      <!-- TABLE HEADER -->
      <StatisticTableByMonthHeader :statistics="props.statistics" :agentId="props.agentId" />

      <!-- ROWS -->
      <div v-if="props.statistics && props.statistics.length > 0">
        <div v-for="statistic in statistics" :key="statistic.month">
          <StatisticByMonthTableRow
            :statistic="statistic"
            :yearOne="props.yearOne"
            :yearTwo="props.yearTwo"
            :startMonth="props.startMonth"
            :endMonth="props.endMonth"
            :inputSearch="props.inputSearch"
            :agentId="props.agentId"
          />
        </div>
        <StatisticByMonthTableRow class="font-bold" isSumRow :statistic="sumStatistic" :agentId="props.agentId" />
      </div>
      <span>*{{ t('statistics.distinctCustomers') }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { StatisticByMonthDto } from '@/domain/StatisticByMonthDto';

  import AgButton from '@/components/library/button/AgButton.vue';
  import StatisticByMonthPeriodsHeader from '@/modules/statistics/components/by-month/StatisticByMonthPeriodsHeader.vue';
  import StatisticByMonthTableRow from '@/modules/statistics/components/by-month/StatisticByMonthTableRow.vue';
  import StatisticTableByMonthHeader from '@/modules/statistics/components/by-month/StatisticTableByMonthHeader.vue';

  import { useHierarchy } from '@/composables/useHierarchy';
  import { useStatisticStore } from '@/modules/statistics/store/useStatisticStore';
  import { i18n } from '@/plugins/i18n';
  import { StatisticFilter } from '@/util/StatisticFilter';
  import { Utilities } from '@/util/Utilities';
  import { storeToRefs } from 'pinia';
  import { computed, onMounted, ref } from 'vue';

  const store = useStatisticStore();

  const { agentsByMonth } = storeToRefs(store);
  const { getStatisticsAgentsByMonth } = store;

  const { resolveHierarchyUsers } = useHierarchy();

  const { t } = i18n.global;

  const { csvExport } = Utilities();

  interface Props {
    statistics: Array<StatisticByMonthDto>;
    yearOne: number;
    yearTwo: number;
    startMonth: number;
    endMonth: number;
    inputSearch: string;
    agentId: number | undefined;
  }
  const props = defineProps<Props>();

  const agentNames = ref<{ [key: number]: string }>({});

  const sumStatistic = computed(() => {
    let st: StatisticByMonthDto = {
      totalAmountPeriodOne: 0,
      totalQuantityPeriodOne: 0,
      customerCodesPeriodOne: [],
      totalAmountPeriodTwo: 0,
      totalQuantityPeriodTwo: 0,
      customerCodesPeriodTwo: [],
      month: -1,
    };
    props.statistics.forEach((stat: StatisticByMonthDto) => {
      st.totalQuantityPeriodOne += stat.totalQuantityPeriodOne;
      st.totalAmountPeriodOne += stat.totalAmountPeriodOne;

      stat.customerCodesPeriodOne.forEach((code) => {
        if (!st.customerCodesPeriodOne.includes(code)) {
          st.customerCodesPeriodOne.push(code);
        }
      });

      st.totalQuantityPeriodTwo += stat.totalQuantityPeriodTwo;
      st.totalAmountPeriodTwo += stat.totalAmountPeriodTwo;

      stat.customerCodesPeriodTwo.forEach((code) => {
        if (!st.customerCodesPeriodTwo.includes(code)) {
          st.customerCodesPeriodTwo.push(code);
        }
      });
    });
    return st;
  });

  const exportCSVData = function () {
    const today = props.endMonth == 13 ? true : false;
    const filter = new StatisticFilter(
      undefined,
      undefined,
      props.startMonth,
      props.endMonth,
      props.yearOne,
      props.yearTwo,
      undefined,
      props.inputSearch,
      props.agentId,
      undefined,
      today
    );
    getStatisticsAgentsByMonth(filter).then(() => {
      const periodOne = getSubPeriod(props.yearOne);
      const periodTwo = getSubPeriod(props.yearTwo);

      const csvHeader = [
        'Agent',
        'Month',
        'PeriodTwo',
        'TotalQuantityPeriodTwo',
        'TotalAmountPeriodTwo',
        'TotalCustomerPeriodTwo',
        'PeriodOne',
        'TotalQuantityPeriodTwo',
        'TotalAmountPeriodOne',
        'TotalCustomerPeriodTwo',
      ];
      const csvDataExport: string[] = [];

      agentsByMonth.value?.forEach((statistic) => {
        const row = [
          statistic.agentId ? agentNames.value[statistic.agentId] : '--',
          t(`statistics.month${statistic.month}`),
          periodTwo,
          statistic.totalQuantityPeriodTwo,
          statistic.totalAmountPeriodTwo,
          statistic.customerCodesPeriodTwo.length,
          periodOne,
          statistic.totalQuantityPeriodOne,
          statistic.totalAmountPeriodOne,
          statistic.customerCodesPeriodOne.length,
        ];
        csvDataExport.push(row.join(';'));
      });
      csvExport(csvDataExport, csvHeader);
    });
  };

  const getSubPeriod = function (year: number) {
    let lastMonth = props.endMonth;
    let lastDay = new Date(year, lastMonth, 0).getDate();
    if (props.endMonth === 13) {
      lastDay = new Date().getDate();
      lastMonth = new Date().getMonth() + 1;
    }
    const startMonth = props.startMonth < 10 ? `0${props.startMonth}` : props.startMonth;
    const endMonth = lastMonth < 10 ? `0${lastMonth}` : lastMonth;
    const subPeriodLabel = `01.${startMonth}.${year} - ${lastDay}.${endMonth}.${year}`;

    return subPeriodLabel;
  };

  onMounted(() => {
    resolveHierarchyUsers().then((users) => {
      users.forEach((user) => {
        agentNames.value[user.id] = user.name;
      });
    });
  });
</script>
