<template>
  <div class="md:mx-[80px] max-md:mx-16 pt-8 max-h-[calc(100dvh-160px)] max-w-384 w-full">
    <AgFormGroup label="" class="w-full">
      <AgSearchInput v-model="internalSearchText" />
    </AgFormGroup>
  </div>
</template>

<script setup lang="ts">
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgSearchInput from '@/components/library/search-input/AgSearchInput.vue';
  import { computed } from 'vue';

  interface Props {
    searchText: string;
  }
  const props = withDefaults(defineProps<Props>(), {
    searchText: '',
  });

  const emit = defineEmits(['update:searchText']);

  const internalSearchText = computed({
    get: () => {
      return props.searchText;
    },
    set: (value) => {
      emit('update:searchText', value);
    },
  });
</script>
