import type { RouteRecordRaw } from 'vue-router';

import BusinessCategoriesIndexPage from '@/admin/modules/businessCategories/pages/BusinessCategoriesIndexPage.vue';
import CollaboratorsIndexPage from '@/admin/modules/collaborators/pages/CollaboratorsIndexPage.vue';
import CommunicationIndexPage from '@/admin/modules/communication/pages/CommunicationIndexPage.vue';
import DeliveryMethods from '@/admin/modules/deliveryMethods/pages/DeliveryMethodsIndexPage.vue';
import DeliveryTermIndexPage from '@/admin/modules/deliveryTerms/pages/DeliveryTermIndexPage.vue';
import DownloadCategoriesIndexPage from '@/admin/modules/downloadCategories/pages/DownloadCategoriesIndexPage.vue';
import DownloadsIndexPage from '@/admin/modules/downloads/pages/DownloadsIndexPage.vue';
import PaymentMethodsIndexPage from '@/admin/modules/paymentMethods/pages/PaymentMethodsIndexPage.vue';
import PaymentTermIndexPage from '@/admin/modules/paymentTerms/pages/PaymentTermIndexPage.vue';
import UsersIndexPage from '@/admin/modules/users/pages/UsersIndexPage.vue';
import authAdminRequired from '@/middleware/authAdminRequired';
import ActivityIndexPage from './modules/activities/pages/ActivityIndexPage.vue';
import CampaignsIndexPage from './modules/campaigns/pages/CampaignsIndexPage.vue';
import CustomerIndexPage from './modules/customers/pages/CustomerIndexPage.vue';
import HierarchyIndexPage from './modules/hierarchies/pages/HierarchyIndexPage.vue';
import HierarchyLevelsIndexPage from './modules/hierarchies/pages/HierarchyLevelsIndexPage.vue';
import OrderOfferPhotosIndexPage from './modules/orderOfferPhotos/pages/OrderOfferPhotosIndexPage.vue';
import PermissionGroupsIndexPage from './modules/permissionGroups/pages/PermissionGroupsIndexPage.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/users',
    component: UsersIndexPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/delivery-methods',
    component: DeliveryMethods,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/delivery-terms',
    component: DeliveryTermIndexPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/payment-terms',
    component: PaymentTermIndexPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/payment-methods',
    component: PaymentMethodsIndexPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/business-categories',
    component: BusinessCategoriesIndexPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/download-categories',
    component: DownloadCategoriesIndexPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/downloads',
    component: DownloadsIndexPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/communication',
    component: CommunicationIndexPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/collaborators',
    component: CollaboratorsIndexPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/permissionGroups',
    component: PermissionGroupsIndexPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/checklists/campaigns',
    component: CampaignsIndexPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/photos',
    component: OrderOfferPhotosIndexPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/checklists/activities',
    component: ActivityIndexPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/hierarchy/levels',
    component: HierarchyLevelsIndexPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/hierarchy/hierarchies',
    component: HierarchyIndexPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/customers',
    component: CustomerIndexPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
];

export default routes;
