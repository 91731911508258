<template>
  <svg id="ic_chevron_back" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path
      id="Pfad_2415"
      data-name="Pfad 2415"
      d="M16.59,8.59,12,13.17,7.41,8.59,6,10l6,6,6-6Z"
      transform="translate(22 -2) rotate(90)"
      fill="currentColor"
    />
    <path id="Pfad_2416" data-name="Pfad 2416" d="M0,0H20V20H0Z" transform="translate(20) rotate(90)" fill="none" />
  </svg>
</template>
