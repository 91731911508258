<template>
  <div class="py-12 md:mx-[80px] max-md:mx-16 flex flex-row gap-12">
    <AgFormGroup class="w-full" :label="t('checklists.category')">
      <AgFormSelect :options="categoryOptions" v-model="internalCategory" />
    </AgFormGroup>
  </div>
</template>

<script setup lang="ts">
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed, onMounted, ref } from 'vue';
  import { useCheckListStore } from '../store/useCheckListStore';

  const { t } = i18n.global;

  const store = useCheckListStore();
  const { getCategories } = store;
  const { categories } = storeToRefs(store);

  interface Props {
    category: number | undefined;
  }

  const props = defineProps<Props>();

  const emit = defineEmits(['update:category']);

  const categoryOptions = ref<Array<AgFormSelectOption>>([]);

  const internalCategory = computed({
    get: () => props.category,
    set: (value) => {
      if (value === -1) {
        emit('update:category', undefined);
      } else {
        emit('update:category', value);
      }
    },
  });

  const loadCategoryList = async function () {
    await getCategories();
    categoryOptions.value.push({ label: t('checklists.all'), key: -1 });

    categories.value.forEach((category) => {
      categoryOptions.value.push({ label: category.name, key: category.id });
    });
  };

  onMounted(async () => await loadCategoryList());
</script>
