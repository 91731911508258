<template>
  <div>
    <TheAdminSubHeader @save="createNewPaymentTerm" hasNotDeleteButton />
    <div class="md:mx-[80px]">
      <div class="flex gap-32">
        <div class="infoBox">
          <div class="infoboxTitle">{{ t('admin.code').toUpperCase() }}</div>
          <div>
            <AgFormInput
              v-model="paymentTerm.code"
              class="w-256"
              @input="resetValidation"
              :validation-state="!codeError"
              :error-message="codeErrorText"
            />
          </div>
        </div>

        <div class="infoBox">
          <span class="infoboxTitle">{{ t('admin.predefined').toUpperCase() }}</span>
          <div>
            <AgButton
              variant="ghost"
              class="h-24 w-24 flex items-center border-2"
              :class="{ 'border-success': !defaultError, 'border-infoRed': defaultError }"
              @click="toggleDefault"
            >
              <template #icon>
                <IcCheck v-if="paymentTerm.default" class="pr-4" />
              </template>
            </AgButton>
            <AgAlert v-if="defaultError" variant="danger" class="w-256">
              {{ t('admin.paymentTermStandardAlreadyChecked') }}
            </AgAlert>
          </div>
        </div>
      </div>

      <div class="table">
        <span class="text-primary-light">{{ t('admin.languageDescription').toUpperCase() }}</span>
        <div v-for="selectedLang in Object.values(config.company.availableLanguages)" :key="selectedLang">
          <div class="tableColumn">
            <AgFormInput :placeholder="selectedLang" disabled />
            <AgFormInput
              v-model="paymentTerm.title[selectedLang]"
              :validation-state="hasDescValue(selectedLang)"
              @input="descriptionError = false"
            />
          </div>
        </div>
        <AgAlert v-if="descriptionError" variant="danger" dismissible>
          {{ t('admin.descriptionRequired') }}
        </AgAlert>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { PaymentTermDto } from '@/admin/domain/PaymentTermDto';

  import IcCheck from '@/components/icons/IcCheck.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';

  import { usePaymentTerms } from '@/admin/modules/paymentTerms/composables/usePaymentTerms';
  import TheAdminSubHeader from '@/components/framework/admin/the-admin-sub-header/TheAdminSubHeader.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { config } from '@/config/config';
  import { i18n } from '@/plugins/i18n';
  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';

  const { t } = i18n.global;
  const theHeader = useTheHeader();
  const { createData, loadData } = usePaymentTerms();
  const router = useRouter();

  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.newPaymentTerm');

  const defaultError = ref<boolean>(false);
  const codeError = ref<boolean>(false);
  const descriptionError = ref<boolean>(false);
  const codeErrorText = ref<string>('');

  const paymentTerm = ref<PaymentTermDto>({
    code: '',
    title: {},
    default: false,
    id: -1,
  });
  const paymentTerms = ref<Array<PaymentTermDto>>([]);

  const hasDescValue = function (lang: string) {
    if (!paymentTerm.value) {
      return false;
    }
    if (!descriptionError.value) {
      return true;
    }
    if (!Object.keys(paymentTerm.value.title).includes(lang)) {
      return false;
    }
    return paymentTerm.value.title[lang].length > 0;
  };

  const toggleDefault = function () {
    resetValidation();
    if (paymentTerm.value) {
      paymentTerm.value.default = !paymentTerm.value.default;
    }
  };

  const resetValidation = function () {
    codeError.value = false;
    codeErrorText.value = '';
    defaultError.value = false;
  };

  const createNewPaymentTerm = async function () {
    resetValidation();

    if (paymentTerm.value?.default) {
      if (paymentTerms.value.some((term) => term.default && term.id !== paymentTerm.value?.id)) {
        defaultError.value = true;
      }
    }

    if (paymentTerm.value?.code.length == 0) {
      codeError.value = true;
      codeErrorText.value = t('admin.codeRequired');
    }

    if (
      paymentTerms.value.some(
        (term) => term.code.toLowerCase() === paymentTerm.value?.code.toLowerCase() && term.id !== paymentTerm.value?.id
      )
    ) {
      codeError.value = true;
      codeErrorText.value = t('admin.codeAlreadyExists');
    }

    if (Object.keys(paymentTerm.value?.title ?? '').length === 0) {
      descriptionError.value = true;
    }
    if (Object.values(paymentTerm.value?.title ?? '').every((value) => value.length === 0)) {
      descriptionError.value = true;
    }

    if (!defaultError.value && !codeError.value && !descriptionError.value) {
      sendRequestForCreate();
    }
  };

  const sendRequestForCreate = async function () {
    await createData(paymentTerm.value);

    try {
      await router.push({
        path: '/admin/payment-terms',
      });
    } catch (error) {
      console.error('Failed to navigate to payment terms:', error);
    }
  };

  onMounted(async () => {
    paymentTerms.value = await loadData();
  });
</script>

<style scoped lang="scss">
  .infoboxTitle {
    @apply text-primary-light;
  }
  .infoBox {
    @apply w-1/2 justify-center my-12 max-w-768;
  }

  .table {
    @apply grid grid-cols-1 py-16 space-x-0;
  }

  .tableColumn {
    @apply flex items-center min-w-0 py-4 gap-4;
  }
</style>
