<template>
  <div>
    <div class="tableHeader">
      <div class="headerColumn">{{ t('admin.supervisor').toUpperCase() }}</div>
      <div class="headerColumn">{{ t('admin.description').toUpperCase() }}</div>
      <div class="headerColumn">{{ t('admin.numberOfChildren').toUpperCase() }}</div>
      <div class="headerColumn"></div>
    </div>
    <div
      v-for="hierarchy in props.hierarchies"
      :key="hierarchy.id"
      class="tableRow"
      @click="viewHierarchy(hierarchy.id)"
    >
      <div class="tableColumn">{{ hierarchy.authUser.name }}</div>
      <div class="tableColumn">{{ hierarchy.description }}</div>
      <div class="tableColumn">{{ childrenNumber[hierarchy.id] }}</div>
      <AgButton variant="ghost" class="flex justify-end" @click.stop="openModalCopyAgent(hierarchy)">
        <IcCopy class="h-24 w-24" />
      </AgButton>
    </div>
    <CopyHierarchyModal
      v-if="selectedHierarchy"
      v-model="showModal"
      :hierarchy="selectedHierarchy"
      :hierarchies="props.hierarchies"
      @update:hierarchy="emit('update:hierarchies')"
    />
  </div>
</template>

<script setup lang="ts">
  import IcCopy from '@/components/icons/IcCopy.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import type { UserHierarchyDto } from '@/domain/UserHierarchyDto';

  import { i18n } from '@/plugins/i18n';
  import { ref, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import CopyHierarchyModal from './CopyHierarchyModal.vue';

  interface Props {
    hierarchies: Array<UserHierarchyDto>;
  }

  const props = defineProps<Props>();

  const emit = defineEmits(['update:hierarchies']);

  const router = useRouter();
  const { t } = i18n.global;

  const childrenNumber = ref<{ [key: number]: number }>({});
  const showModal = ref<boolean>(false);
  const selectedHierarchy = ref<UserHierarchyDto>();

  const numberOfChildren = function (hierarchy: UserHierarchyDto, hierarcyBaseId: number) {
    countChildren(hierarchy, hierarcyBaseId);
  };

  const countChildren = function (node: UserHierarchyDto, hierachyBaseId: number) {
    if (!childrenNumber.value[hierachyBaseId]) {
      childrenNumber.value[hierachyBaseId] = 0;
    }
    childrenNumber.value[hierachyBaseId] += node.children.length;
    node.children.forEach((child) => countChildren(child, hierachyBaseId));
  };

  const viewHierarchy = async function (hierarchyId: number) {
    await router.push({
      name: 'hierarchy',
      params: { hierarchyId: hierarchyId },
    });
  };

  const openModalCopyAgent = function (hierarchy: UserHierarchyDto) {
    selectedHierarchy.value = hierarchy;
    showModal.value = true;
  };

  watch(
    () => props.hierarchies,
    (newValue) => {
      childrenNumber.value = {};
      newValue.forEach((hierarchy) => {
        numberOfChildren(hierarchy, hierarchy.id);
      });
    },
    { immediate: true }
  );
</script>

<style scoped>
  .tableHeader {
    @apply grid grid-cols-[1fr_1fr_1fr_100px] gap-12 py-8 font-light text-neutral-550;
  }

  .headerColumn {
    @apply flex items-center;
    color: #04a040;
    font-weight: bold;
  }

  .tableRow {
    @apply grid grid-cols-[1fr_1fr_1fr_100px] gap-12 border-t border-neutral-300 py-8 hover:bg-neutral-200 hover:cursor-pointer;
  }

  .tableColumn {
    @apply flex items-center;
  }
</style>
