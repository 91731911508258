<template>
  <div class="md:mx-[80px] max-md:mx-16 min-w-fit pt-8 max-h-[calc(100dvh-160px)] flex w-full gap-48">
    <AgFormGroup :label="t('admin.agent')" class="w-full">
      <AgSearchSelect :options="agentOptions" v-model="internalAgentId" />
    </AgFormGroup>
    <AgFormGroup :label="t('admin.salesStatus')" class="w-full">
      <AgFormSelect :options="salesOptions" v-model="internalStatus" />
    </AgFormGroup>
    <AgFormGroup :label="t('admin.search')" class="max-w-384 w-full">
      <AgSearchInput v-model="internalSearchText" />
    </AgFormGroup>
  </div>
</template>

<script setup lang="ts">
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgSearchInput from '@/components/library/search-input/AgSearchInput.vue';

  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useCustomers } from '../composables/useCustomers';

  const { t } = i18n.global;
  const { loadAgents } = useCustomers();

  interface Props {
    searchText: string;
    agentId: number;
    status: number;
  }

  const props = defineProps<Props>();

  const emit = defineEmits(['update:searchText', 'update:agentId', 'update:status']);

  const agentOptions = ref<Array<AgSearchSelectOption>>([]);

  const salesOptions = computed(() => [
    { key: -1, label: t('admin.all') },
    { key: 0, label: t('admin.free') },
    { key: 1, label: t('admin.blocked') },
  ]);

  const internalSearchText = computed({
    get: () => props.searchText,
    set: (value) => emit('update:searchText', value),
  });

  const internalAgentId = computed({
    get: () => props.agentId,
    set: (value) => emit('update:agentId', value),
  });

  const internalStatus = computed({
    get: () => props.status,
    set: (value) => emit('update:status', value),
  });

  onMounted(async () => {
    const agents = await loadAgents();
    agentOptions.value = agents.map((agent) => ({
      label: `${agent.code}/${agent.name}`,
      value: agent.id,
      searchableString: `${agent.code}/${agent.name}`,
    }));
    agentOptions.value.unshift({ label: t('admin.all'), value: -1, searchableString: t('admin.all') });
  });
</script>
