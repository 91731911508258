import { useHierarchy } from '@/composables/useHierarchy';
import type { AuthUserDto } from '@/domain/masterData/AuthUserDto';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useHierarchyEmployeeStore = defineStore('hierarchyEmployeeStore', () => {
  const { resolveHierarchyUsers } = useHierarchy();

  const agent = ref<AuthUserDto>();
  const agentId = ref<number | null>();
  const userId = ref<number>();
  const isEmployee = ref<boolean>();
  const hasChildren = ref<boolean>(false);

  const setAgentId = async function (id: number) {
    if (id <= 0) {
      agentId.value = null;
      return;
    } else {
      const hierarchyUserList: Array<AuthUserDto> = await resolveHierarchyUsers();
      agent.value = hierarchyUserList.find((user) => user.id === id);
      agentId.value = id;
    }
  };

  const setUserId = function (id: number) {
    userId.value = id;
  };

  const setIsEmployee = function (employee: boolean) {
    isEmployee.value = employee;
  };

  const setHasChildren = function (hc: boolean) {
    hasChildren.value = hc;
  };

  return {
    setAgentId,
    setUserId,
    setIsEmployee,
    setHasChildren,
    agent,
    agentId,
    userId,
    isEmployee,
    hasChildren,
  };
});
