<template>
  <div class="node-container">
    <div class="node">
      <div class="flex items-center">
        <div class="node-content">
          <div>{{ props.tree.authUser.name }}</div>
          <div>({{ props.tree.description }})</div>
        </div>

        <AgButton variant="ghost" @click="showModalDelete = true">
          <template #icon>
            <IcDelete class="h-24 w-24 text-danger" />
          </template>
        </AgButton>
        <AgButton
          v-if="props.deept < 3 && props.deept < levels.length"
          variant="ghost"
          @click="showModal = true"
          class="px-12"
        >
          <template #icon>
            <IcAdd class="h-24 w-24 text-primary" />
          </template>
        </AgButton>
      </div>
    </div>
    <div class="children" v-if="props.tree.children && tree.children.length > 0">
      <div class="children-nodes">
        <HierarchyTree
          v-for="(child, index) in props.tree.children"
          :key="index"
          :tree="child"
          :original-tree="props.originalTree"
          :deept="props.deept + 1"
          :levels="props.levels"
          @update:hierarchy="propagateToParent"
        />
      </div>
    </div>
  </div>
  <ModalAddAgent
    :tree="props.tree"
    :original-tree="props.originalTree"
    v-model="showModal"
    :deept="props.deept"
    @update:hierarchy="propagateToParent"
  />
  <ModalTruncateAgentTree
    v-model="showModalDelete"
    :tree="props.tree"
    :agentId="props.tree.authUser.id"
    :agentName="props.tree.authUser.name"
    :deept="props.deept"
    @update:hierarchy="propagateToParent"
  />
</template>

<script setup lang="ts">
  import type { HierarchyLevelDto } from '@/admin/domain/HierarchyLevelDto';
  import IcAdd from '@/components/icons/IcAdd.vue';
  import IcDelete from '@/components/icons/IcDelete.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import type { UserHierarchyDto } from '@/domain/UserHierarchyDto';
  import { ref } from 'vue';
  import ModalAddAgent from './ModalAddAgent.vue';
  import ModalTruncateAgentTree from './ModalTruncateAgentTree.vue';

  interface Props {
    tree: UserHierarchyDto;
    originalTree: UserHierarchyDto;
    deept: number;
    levels: Array<HierarchyLevelDto>;
  }

  const props = defineProps<Props>();

  const emit = defineEmits(['update:hierarchy']);

  const showModal = ref<boolean>(false);
  const showModalDelete = ref<boolean>(false);

  const propagateToParent = () => {
    emit('update:hierarchy');
  };
</script>

<style scoped>
  .node-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .node {
    position: relative;
    margin: 20px;
    padding: 10px;
    border-radius: 50%;
    background-color: #f0f0f0;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .node-content {
    font-weight: bold;
  }

  .children {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .children-nodes {
    display: flex;
    justify-content: center;
    position: relative;
  }
</style>
