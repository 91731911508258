<template>
  <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
    <IcLoading />
  </div>
  <div v-else-if="level">
    <TheAdminSubHeader @save="saveLevel" @delete="deleteCurrentLevel" />
    <div class="md:mx-[80px]">
      <div class="space-y-8">
        <div class="font-bold text-primary">{{ t('admin.name').toUpperCase() }}</div>
        <AgFormInput
          class="max-w-256"
          v-model="level.description"
          :validation-state="!descriptionError"
          :error-message="descriptionErrorText"
          @update:model-value="resetValidation"
        />
        <div class="flex">
          <span class="font-bold">{{ t('admin.grade') }}:&nbsp;</span>
          <span>{{ level.grade }}</span>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <AgAlert variant="info"> {{ t('admin.notFound') }} </AgAlert>
  </div>
</template>

<script setup lang="ts">
  import type { HierarchyLevelDto } from '@/admin/domain/HierarchyLevelDto';
  import TheAdminSubHeader from '@/components/framework/admin/the-admin-sub-header/TheAdminSubHeader.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useHierarchies } from '../composables/useHierarchies';

  const { t } = i18n.global;
  const route = useRoute();
  const router = useRouter();
  const { loadLevels, updateLevel, deleteLevel } = useHierarchies();
  const theHeader = useTheHeader();

  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.levelDetail');

  const level = ref<HierarchyLevelDto | null>(null);
  const isLoading = ref<boolean>(true);
  const descriptionError = ref<boolean>(false);
  const descriptionErrorText = ref<string>('');

  const levelId = computed(() => {
    const lvlId = route.params.levelId;
    if (Array.isArray(lvlId)) {
      return parseInt(lvlId[0]);
    }
    return parseInt(lvlId);
  });

  const resetValidation = () => {
    descriptionError.value = false;
    descriptionErrorText.value = '';
  };

  const saveLevel = async () => {
    if (!level.value || !level.value.description || level.value.description.length === 0) {
      console.log('descriptionError');
      descriptionError.value = true;
      descriptionErrorText.value = t('admin.descriptionRequired');
      return;
    }
    if (level.value && !descriptionError.value) {
      isLoading.value = true;
      await updateLevel(level.value);
      try {
        await router.push({
          path: '/admin/hierarchy/levels',
        });
      } catch (error) {
        console.error('Failed to navigate to hierarchy levels:', error);
      }
      isLoading.value = false;
    }
  };

  const deleteCurrentLevel = async () => {
    if (level.value) {
      await deleteLevel(level.value.id);
      try {
        await router.push({
          path: '/admin/hierarchy/levels',
        });
      } catch (error) {
        console.error('Failed to navigate to hierarchy levels:', error);
      }
    }
  };

  const update = async function () {
    isLoading.value = true;
    const levels = await loadLevels();
    if (levels) {
      level.value = levels.find((l) => l.id === levelId.value) || null;
    }
    isLoading.value = false;
  };

  onMounted(async () => update());
</script>
