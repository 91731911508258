<template>
  <div>
    <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
      <IcLoading />
    </div>

    <div v-else-if="currentGroup">
      <TheAdminSubHeader
        @save="savePermissionGroup"
        @delete="deletePermissionGroup(currentGroup.id)"
        :disable-delete="currentGroup.downloadsNumber > 0 || currentGroup.communicationsNumber > 0"
      />
      <div class="md:mx-[80px]">
        <div>
          <span class="text-primary font-bold">{{ t('admin.code').toUpperCase() }}</span>
          <AgFormInput
            v-model="currentGroup.code"
            class="max-w-256"
            :validation-state="!codeError"
            :error-message="codeErrorText"
          />
        </div>
        <div class="py-12">
          <span class="text-primary font-bold">{{ t('admin.downloads').toUpperCase() }}:&nbsp;</span>
          <span>{{ currentGroup.downloadsNumber }}</span>
        </div>
        <div>
          <span class="text-primary font-bold">{{ t('admin.communications').toUpperCase() }}:&nbsp;</span>
          <span>{{ currentGroup.communicationsNumber }}</span>
        </div>
        <div class="flex justify-between gap-x-32 h-[calc(100dvh-250px)] py-12">
          <div class="w-full">
            <span class="text-xl font-bold text-primary">{{ t('admin.insert').toUpperCase() }}</span>
            <AgBox class="w-full h-[calc(100dvh-300px)] overflow-y-auto border-0 p-0" variant="strong-accent">
              <AgSearchInput v-model="insertFilter" class="w-full" />
              <div v-for="(agent, index) in filteredInsertAgents" :key="agent.id">
                <div
                  class="px-12 py-8 border-neutral-550 cursor-pointer hover:bg-neutral-500 italic"
                  :class="{ 'border-t': index !== 0 }"
                  @click="select(agent.id)"
                >
                  <span class="font-bold">
                    {{ agent.name }}
                  </span>
                  <span> &nbsp;({{ agent.email }}) </span>
                </div>
              </div>
            </AgBox>
          </div>

          <div class="w-full">
            <span class="text-xl font-bold text-primary">{{ t('admin.remove').toUpperCase() }}</span>
            <AgBox class="w-full h-[calc(100dvh-300px)] overflow-y-auto border-0 p-0" variant="strong-accent">
              <AgSearchInput v-model="removeFilter" class="w-full" />
              <div v-for="(agent, index) in filteredRemoveAgents" :key="agent.id">
                <div
                  class="px-12 py-8 border-neutral-550 cursor-pointer hover:bg-neutral-500 italic"
                  :class="{ 'border-t': index !== 0 }"
                  @click="unselect(agent.id)"
                >
                  <span class="font-bold">
                    {{ agent.name }}
                  </span>
                  <span> &nbsp;({{ agent.email }}) </span>
                </div>
              </div>
            </AgBox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { computed, onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  import IcLoading from '@/components/icons/IcLoading.vue';

  import type { AuthUserDto } from '@/admin/domain/AuthUserDto';
  import type { PermissionGroupDto } from '@/admin/domain/PermissionGroupDto';
  import TheAdminSubHeader from '@/components/framework/admin/the-admin-sub-header/TheAdminSubHeader.vue';
  import AgBox from '@/components/library/box/AgBox.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgSearchInput from '@/components/library/search-input/AgSearchInput.vue';
  import { i18n } from '@/plugins/i18n';
  import { usePermissiongroups } from '../composables/usePermissionGroups';

  const { t } = i18n.global;
  const route = useRoute();
  const router = useRouter();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('admin.permissionGroupDetails');

  const { loadData, loadAgents, updateData, deleteData } = usePermissiongroups();

  const permissinGroups = ref<Array<PermissionGroupDto>>([]);
  const isLoading = ref<boolean>(true);
  const currentGroup = ref<PermissionGroupDto>();
  const agents = ref<Array<AuthUserDto>>([]);
  const insertFilter = ref<string>('');
  const removeFilter = ref<string>('');
  const codeError = ref<boolean>(false);
  const codeErrorText = ref<string>('');

  const filteredInsertAgents = computed(() => {
    if (insertFilter.value === '') {
      return agents.value;
    }
    return agents.value.filter((agent) => {
      return (
        agent.name.toLowerCase().includes(insertFilter.value.toLowerCase()) ||
        agent.email.toLowerCase().includes(insertFilter.value.toLowerCase())
      );
    });
  });

  const filteredRemoveAgents = computed(() => {
    if (removeFilter.value === '') {
      return currentGroup.value?.agents;
    }
    return currentGroup.value?.agents.filter((agent) => {
      return (
        agent.name.toLowerCase().includes(removeFilter.value.toLowerCase()) ||
        agent.email.toLowerCase().includes(removeFilter.value.toLowerCase())
      );
    });
  });

  const setCurrentItem = () => {
    currentGroup.value = permissinGroups.value.find((group) => group.id.toString() === route.params.groupId);
  };

  const resetValidation = function () {
    codeError.value = false;
    codeErrorText.value = '';
  };

  const savePermissionGroup = async () => {
    resetValidation();

    if (!currentGroup.value || currentGroup.value.code.length === 0) {
      codeError.value = true;
      codeErrorText.value = t('admin.codeRequired');
    }

    const codeAlreadyExists = permissinGroups.value.find(
      (pg) => pg.code === currentGroup.value?.code && pg.id !== currentGroup.value.id
    );

    if (codeAlreadyExists) {
      codeError.value = true;
      codeErrorText.value = t('admin.codeAlreadyExists');
    }

    if (codeError.value) {
      return;
    }

    isLoading.value = true;
    await updateData(currentGroup.value);
    try {
      await router.push({
        path: '/admin/permissionGroups',
      });
    } catch (error) {
      console.error('Failed to navigate to permisssionGroup:', error);
    }
    isLoading.value = false;
  };

  const deletePermissionGroup = async function (id: number) {
    isLoading.value = true;
    await deleteData(id);
    try {
      await router.push({
        path: '/admin/permissionGroups',
      });
    } catch (error) {
      console.error('Failed to navigate to permissionGroup:', error);
    }
    isLoading.value = false;
  };

  const update = async function () {
    loadData()
      .then((groups) => {
        permissinGroups.value = groups ?? [];
        if (groups) {
          setCurrentItem();
        }
        isLoading.value = false;
      })
      .catch((error) => {
        console.log('Error loading permissionGroups: ', error);
        isLoading.value = false;
      });
  };

  const select = (id: number) => {
    insertFilter.value = '';
    const agent = agents.value.find((agent) => agent.id === id);
    if (agent) {
      currentGroup.value?.agents.push(agent);
      agents.value = agents.value.filter((agent) => agent.id !== id);
    }
  };

  const unselect = (id: number) => {
    removeFilter.value = '';
    const agent = currentGroup.value?.agents.find((agent) => agent.id === id);
    if (agent && currentGroup.value) {
      agents.value.push(agent);
      currentGroup.value.agents = currentGroup.value.agents.filter((agent) => agent.id !== id);
    }
  };

  onMounted(async () => {
    await update();
    agents.value = await loadAgents();

    agents.value = agents.value.filter((agent) => {
      return !currentGroup.value?.agents.some((groupAgent) => groupAgent.id === agent.id);
    });
  });
</script>
