<template>
  <div>
    <div v-if="orderOfferSelection === 0">
      <TheSubheader class="flex items-center justify-between">
        <Filter v-model:searchText="searchText" v-model:orderOfferSelection="orderOfferSelection" />
        <div class="flex items-center justify-end space-x-64 md:mx-[80px] max-md:mx-16">
          <AgButton variant="ghost" iconBeforeText @click="update">
            <template #icon>
              <IcUpdate />
            </template>
            <div class="text-xl">{{ t('admin.update') }}</div>
          </AgButton>
        </div>
      </TheSubheader>

      <div class="overflow-y-auto">
        <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
          <IcLoading />
        </div>
        <div v-else class="md:mx-[80px] max-md:mx-16 min-w-fit pt-8 max-h-[calc(100dvh-160px)]">
          <div class="my-24">
            <AgPaginatorInfo :current-page="currentPage" :page-size="itemsPerPage" :number-items="orders.length" />
            <AgPaginator v-model="currentPage" :total-pages="pageCountOrder" />
          </div>
          <OrderTable :orders="displayedOrderItems" />
        </div>
      </div>
    </div>

    <div v-else="orderOfferSelection === 1">
      <TheSubheader class="flex items-center justify-between">
        <Filter v-model:searchText="searchText" v-model:orderOfferSelection="orderOfferSelection" />
        <div class="flex items-center justify-end space-x-64 md:mx-[80px] max-md:mx-16">
          <AgButton variant="ghost" iconBeforeText @click="update">
            <template #icon>
              <IcUpdate />
            </template>
            <div class="text-xl">{{ t('admin.update') }}</div>
          </AgButton>
        </div>
      </TheSubheader>

      <div class="overflow-y-auto">
        <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
          <IcLoading />
        </div>
        <div v-else class="md:mx-[80px] max-md:mx-16 min-w-fit pt-8 max-h-[calc(100dvh-160px)]">
          <div class="my-24">
            <AgPaginatorInfo :current-page="currentPage" :page-size="itemsPerPage" :number-items="orders.length" />
            <AgPaginator v-model="currentPage" :total-pages="pageCountOffer" />
          </div>
          <OfferTable :offers="displayedOfferItems" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcUpdate from '@/components/icons/IcUpdate.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import type { OfferDto } from '@/domain/offerData/OfferDto';
  import type { OrderDto } from '@/domain/orderData/OrderDto';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import Filter from '../components/filter/Filter.vue';

  import OfferTable from '../components/OfferTable.vue';
  import OrderTable from '../components/OrderTable.vue';
  import { usePhotos } from '../composables/usePhotos';

  const { t } = i18n.global;
  const { loadOffers, loadOrders } = usePhotos();
  const theHeader = useTheHeader();

  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.photos');

  const orders = ref<Array<OrderDto>>([]);
  const offers = ref<Array<OfferDto>>([]);

  const isLoading = ref<boolean>(true);
  const currentPage = ref<number>(1);
  const itemsPerPage = 10;
  const searchText = ref<string>('');
  const orderOfferSelection = ref<number>(0);

  const filteredOrders = computed(() => {
    if (!searchText.value) {
      return orders.value;
    }

    return orders.value.filter(
      (o) =>
        o.orderNumber.toLowerCase().includes(searchText.value.toLowerCase()) ||
        o.gid.toLowerCase().includes(searchText.value.toLowerCase()) ||
        o.customer.name.toLowerCase().includes(searchText.value.toLowerCase()) ||
        o.userName?.toString().toLowerCase().includes(searchText.value.toLowerCase()) ||
        o.agentName?.toString().toLowerCase().includes(searchText.value.toLowerCase())
    );
  });

  const filteredOffers = computed(() => {
    if (!searchText.value) {
      return offers.value;
    }

    return offers.value.filter(
      (o) =>
        o.offerNumber.toLowerCase().includes(searchText.value.toLowerCase()) ||
        o.gid.toLowerCase().includes(searchText.value.toLowerCase()) ||
        o.customer.name.toLowerCase().includes(searchText.value.toLowerCase()) ||
        o.userName?.toString().toLowerCase().includes(searchText.value.toLowerCase()) ||
        o.agentName?.toString().toLowerCase().includes(searchText.value.toLowerCase())
    );
  });

  const pageCountOrder = computed(() => Math.ceil(filteredOrders.value.length / itemsPerPage));

  const pageCountOffer = computed(() => Math.ceil(filteredOffers.value.length / itemsPerPage));

  const displayedOrderItems = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredOrders.value.length);
    const itmxpage = filteredOrders.value.slice(startIndex, endIndex);

    return itmxpage;
  });

  const displayedOfferItems = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredOffers.value.length);
    const itmxpage = filteredOffers.value.slice(startIndex, endIndex);

    return itmxpage;
  });

  const update = async () => {
    isLoading.value = true;
    orders.value = await loadOrders();
    offers.value = await loadOffers();
    isLoading.value = false;
  };

  onMounted(async () => {
    await update();
  });
</script>
