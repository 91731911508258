<template>
  <div>
    <TheSubheader class="flex items-center justify-between">
      <Filter v-model:searchText="searchText" />
      <div class="flex items-center justify-end space-x-64 md:mx-[80px] max-md:mx-16">
        <AgButton variant="ghost" iconBeforeText @click="update">
          <template #icon>
            <IcUpdate />
          </template>
          <div class="text-xl">{{ t('admin.update') }}</div>
        </AgButton>

        <AgButton variant="ghost" iconBeforeText @click="newBusinessCategory">
          <template #icon>
            <IcAdd />
          </template>
          <div class="text-xl">{{ t('admin.create') }}</div>
        </AgButton>
      </div>
    </TheSubheader>

    <div class="overflow-y-auto">
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <div v-else class="md:mx-[80px] max-md:mx-16 min-w-fit pt-8 max-h-[calc(100dvh-160px)]">
        <div class="my-24">
          <AgPaginatorInfo :current-page="currentPage" :page-size="itemsPerPage" :number-items="categories.length" />
          <AgPaginator v-model="currentPage" :total-pages="pageCount" />
        </div>
        <BusinessCategoriesTable :categories="displayedItems" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { BusinessCategoryDto } from '@/admin/domain/BusinessCategoryDto';

  import BusinessCategoriesTable from '@/admin/modules/businessCategories/components/BusinessCategoriesTable.vue';
  import Filter from '@/admin/modules/businessCategories/components/filter/Filter.vue';
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcAdd from '@/components/icons/IcAdd.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcUpdate from '@/components/icons/IcUpdate.vue';
  import AgButton from '@/components/library/button/AgButton.vue';

  import { useBusinnessCategories } from '@/admin/modules/businessCategories/composables/useBusinnessCategories';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';

  const { t, locale } = i18n.global;

  const { loadData } = useBusinnessCategories();

  const router = useRouter();
  const theHeader = useTheHeader();

  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.businessCategories');

  const categories = ref<Array<BusinessCategoryDto>>([]);
  const isLoading = ref<boolean>(true);
  const searchText = ref<string>('');
  const currentPage = ref<number>(1);
  const itemsPerPage = 10;

  const filteredBusinessCategories = computed(() => {
    if (!searchText.value) {
      return categories.value;
    }

    return categories.value.filter(
      (category) =>
        category.code.toLowerCase().includes(searchText.value.toLowerCase()) ||
        category.title[locale.value]?.toString().toLowerCase().includes(searchText.value.toLowerCase())
    );
  });

  const pageCount = computed(() => Math.ceil(filteredBusinessCategories.value.length / itemsPerPage));

  const displayedItems = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredBusinessCategories.value.length);
    const itmxpage = filteredBusinessCategories.value.slice(startIndex, endIndex);

    return itmxpage;
  });

  const newBusinessCategory = async () => {
    try {
      await router.push({
        name: '/new-business-category',
      });
    } catch (error) {
      console.error('Failed to navigate to businness category:', error);
    }
  };

  const update = async () => {
    isLoading.value = true;
    const cat = await loadData();
    categories.value = cat;
    isLoading.value = false;
  };

  onMounted(() => update());
</script>
