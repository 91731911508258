<template>
  <div class="flex flex-row items-center justify-between md:mx-[80px] max-md:mx-16 gap-64">
    <AgFormGroup :label="t('contingents.filter')" class="w-1/2">
      <AgSearchSelect :options="filterOptions" v-model="internalFilter" />
    </AgFormGroup>

    <AgFormGroup :label="t('contingents.customer')" class="w-1/2">
      <AgSearchSelect :options="customerOptions" v-model="internalCustomer" />
    </AgFormGroup>
  </div>
</template>

<script setup lang="ts">
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import type { CustomerDto } from '@/domain/masterData/CustomerDto';

  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { i18n } from '@/plugins/i18n';
  import { useHierarchyEmployeeStore } from '@/store/useHierarchyEmployeeStore';
  import { storeToRefs } from 'pinia';
  import { computed, onMounted, ref } from 'vue';

  const { t } = i18n.global;
  const { tables } = useMasterData();

  const store = useHierarchyEmployeeStore();
  const { agentId } = storeToRefs(store);

  interface Props {
    customerId: number;
    filter: string | undefined;
    customerIds: Array<number>;
  }
  const props = withDefaults(defineProps<Props>(), {
    customerId: -1,
    filter: 'All',
  });

  const emit = defineEmits(['update:filter', 'update:customerId']);

  const filterOptions = ref<Array<AgSearchSelectOption>>([]);
  const customers = ref<Array<CustomerDto>>([]);

  const internalCustomer = computed({
    get: () => {
      return props.customerId;
    },
    set: (value) => {
      emit('update:customerId', value);
    },
  });

  const internalFilter = computed({
    get: () => {
      return props.filter;
    },
    set: (value) => {
      if (value) {
        emit('update:filter', value);
      }
    },
  });

  const contingentCustomers = computed(() => {
    const tmp = [];
    for (const id of props.customerIds) {
      const customer = customers.value.find((c) => c.id === id);
      if (customer) {
        if (agentId.value && agentId.value === customer.agentId) {
          tmp.push(customer);
        } else if (!agentId.value) {
          tmp.push(customer);
        }
      }
    }
    return tmp;
  });

  const customerOptions = computed(() => {
    const tmp = [];
    tmp.push({ value: -1, label: 'contingents.allItems', searchableString: 'All' });
    contingentCustomers.value.forEach((customer: CustomerDto) => {
      tmp.push({
        value: customer.id,
        label: `${customer.code} / ${customer.name} `,
        searchableString: `${customer.code} / ${customer.name}`,
      });
    });
    return tmp;
  });

  const loadFilterContingent = async function () {
    const contingents = await tables.contingents.toArray();
    const contingentsWithFilter = contingents.filter((c) => c.filter);
    const filters = new Set(contingentsWithFilter.map((c) => c.filter ?? ''));

    filterOptions.value.push({ value: 'All', label: 'contingents.allItems', searchableString: 'contingents.allItems' });
    filters.forEach((f) =>
      filterOptions.value.push({
        value: f,
        label: f + ' ',
        searchableString: f,
      })
    );
  };

  onMounted(async () => {
    customers.value = await tables.customers.toArray();
    loadFilterContingent();
  });
</script>
