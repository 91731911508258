import type { ArticleInfoDto } from '@/domain/ArticleInfoDto';
import type { CustomerInfoDto } from '@/domain/CustomerInfoDto';
import type { StatisticByArticleDto } from '@/domain/StatisticByArticleDto';
import type { StatisticByCustomerDto } from '@/domain/StatisticByCustomerDto';
import type { StatisticByMonthDto } from '@/domain/StatisticByMonthDto';
import type { StatisticGlobalDto } from '@/domain/StatisticGlobalDto';
import type { StatisticGroupedDto } from '@/domain/StatisticGroupedDto';
import type { ContainerDto } from '@/domain/data/ContainerDto';
import type { Sorting } from '@/util/Sorting';

import { useApi } from '@/composables/api/useApi';
import { config } from '@/config/config';
import { PageImpl, PageRequest, type Page } from '@/util/Paging';
import { StatisticFilter } from '@/util/StatisticFilter';
import { useError } from '@/util/useError';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useStatisticStore = defineStore('statistic-store', () => {
  const { handleCatchLocalError, handleCatchServerError } = useError();

  const api = useApi();

  const globalStatistics = ref<Array<StatisticGlobalDto>>([]);

  const byMonth = ref<Array<StatisticByMonthDto>>([]);
  const agentsByMonth = ref<Array<StatisticByMonthDto>>([]);
  const byArticle = ref<Page<StatisticByArticleDto>>(new PageImpl([], new PageRequest(10, 1), 0));
  const byCustomer = ref<Page<StatisticByCustomerDto>>(new PageImpl([], new PageRequest(10, 1), 0));
  const articlesByCustomer = ref<Array<StatisticGlobalDto>>([]);
  const customSearch = ref<Page<StatisticGroupedDto>>(new PageImpl([], new PageRequest(10, 1), 0));

  const articles = ref<Array<ArticleInfoDto>>([]);
  const customers = ref<Array<CustomerInfoDto>>([]);
  const filters = ref<Array<string>>([]);

  async function getStatistics(filter: StatisticFilter) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
        'filters.fromDate': `${filter.fromDate}`,
        'filters.toDate': `${filter.toDate}`,
        'filters.search': `${filter.search}`,
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/statistics?${searchParams}`;

      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.statistics) {
        globalStatistics.value = container.data.statistics;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  async function getStatisticsByMonth(filter: StatisticFilter) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
        'filters.startMonth': `${filter.startMonth}`,
        'filters.endMonth': `${filter.endMonth}`,
        'filters.yearOne': `${filter.yearOne}`,
        'filters.yearTwo': `${filter.yearTwo}`,
        'filters.search': `${filter.search}`,
        'filters.today': `${filter.today}`,
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/statistics/months?${searchParams}`;
      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.statisticByMonth) {
        byMonth.value = container.data.statisticByMonth;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  async function getStatisticsAgentsByMonth(filter: StatisticFilter) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
        'filters.startMonth': `${filter.startMonth}`,
        'filters.endMonth': `${filter.endMonth}`,
        'filters.yearOne': `${filter.yearOne}`,
        'filters.yearTwo': `${filter.yearTwo}`,
        'filters.search': `${filter.search}`,
        'filters.today': `${filter.today}`,
        ...(filter.agentId ? { 'filters.agentId': `${filter.agentId}` } : ''),
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/statistics/month/agent?${searchParams}`;
      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.statisticByMonth) {
        agentsByMonth.value = container.data.statisticByMonth;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  async function getStatisticsByArticle(pageRequest: PageRequest, sorting: Sorting, filter: StatisticFilter) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
        'pagination.page': `${pageRequest.number}`,
        'pagination.records': `${pageRequest.size}`,
        'sorting.col': `${sorting.col}`,
        'sorting.order': `${sorting.order}`,
        'filters.startMonth': `${filter.startMonth}`,
        'filters.endMonth': `${filter.endMonth}`,
        'filters.yearOne': `${filter.yearOne}`,
        'filters.yearTwo': `${filter.yearTwo}`,
        'filters.search': `${filter.search}`,
        'filters.articleName': `${filter.articleName}`,
        'filters.today': `${filter.today}`,
        ...(filter.agentId ? { 'filters.agentId': `${filter.agentId}` } : ''),
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/statistics/articles?${searchParams}`;
      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.statisticByArticle) {
        byArticle.value = api.getResultPage(container, container.data.statisticByArticle);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  async function getCSVByArticle(filter: StatisticFilter) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
        'filters.startMonth': `${filter.startMonth}`,
        'filters.endMonth': `${filter.endMonth}`,
        'filters.yearOne': `${filter.yearOne}`,
        'filters.yearTwo': `${filter.yearTwo}`,
        'filters.search': `${filter.search}`,
        'filters.articleName': `${filter.articleName}`,
        'filters.today': `${filter.today}`,
        ...(filter.agentId ? { 'filters.agentId': `${filter.agentId}` } : ''),
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/statistics/csv/articles?${searchParams}`;

      const url: string = config.backend.url + requestUri;
      const blob = await api.download(url);

      return blob;
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  async function getStatisticsByCustomer(pageRequest: PageRequest, sorting: Sorting, filter: StatisticFilter) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
        'pagination.page': `${pageRequest.number}`,
        'pagination.records': `${pageRequest.size}`,
        'sorting.col': sorting.col,
        'sorting.order': sorting.order,
        'filters.search': `${filter.search}`,
        'filters.startMonth': `${filter.startMonth}`,
        'filters.endMonth': `${filter.endMonth}`,
        'filters.yearOne': `${filter.yearOne}`,
        'filters.yearTwo': `${filter.yearTwo}`,
        'filters.customerCode': `${filter.customerCode}`,
        'filters.today': `${filter.today}`,
        ...(filter.agentId ? { 'filters.agentId': `${filter.agentId}` } : ''),
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/statistics/customers?${searchParams}`;

      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.statisticByCustomer) {
        byCustomer.value = api.getResultPage(container, container.data.statisticByCustomer);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  async function getCSVByCustomer(filter: StatisticFilter) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
        'filters.search': `${filter.search}`,
        'filters.startMonth': `${filter.startMonth}`,
        'filters.endMonth': `${filter.endMonth}`,
        'filters.yearOne': `${filter.yearOne}`,
        'filters.yearTwo': `${filter.yearTwo}`,
        'filters.customerCode': `${filter.customerCode}`,
        'filters.today': `${filter.today}`,
        ...(filter.agentId ? { 'filters.agentId': `${filter.agentId}` } : ''),
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/statistics/csv/customers?${searchParams}`;

      const url: string = config.backend.url + requestUri;
      const blob = await api.download(url);

      return blob;
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  async function getStatisticsArticlesByCustomer(filter: StatisticFilter) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
        'filters.search': `${filter.search}`,
        'filters.startMonth': `${filter.startMonth}`,
        'filters.endMonth': `${filter.endMonth}`,
        'filters.yearOne': `${filter.yearOne}`,
        'filters.yearTwo': `${filter.yearTwo}`,
        'filters.customerCode': `${filter.customerCode}`,
        'filters.today': `${filter.today}`,
        ...(filter.agentId ? { 'filters.agentId': `${filter.agentId}` } : ''),
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/statistics/customer?${searchParams}`;

      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.statistics) {
        articlesByCustomer.value = container.data.statistics;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  async function getCustomSearch(sorting: Sorting, pageRequest: PageRequest, filter: StatisticFilter) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
        'pagination.page': `${pageRequest.number}`,
        'pagination.records': `${pageRequest.size}`,
        'sorting.col': sorting.col,
        'sorting.order': sorting.order,
        'filters.articleName': `${filter.articleName}`,
        'filters.customerCode': `${filter.customerCode}`,
        'filters.search': `${filter.search}`,
        'filters.startMonth': `${filter.startMonth}`,
        'filters.endMonth': `${filter.endMonth}`,
        'filters.yearOne': `${filter.yearOne}`,
        'filters.yearTwo': `${filter.yearTwo}`,
        'filters.today': `${filter.today}`,
        ...(filter.agentId ? { 'filters.agentId': `${filter.agentId}` } : ''),
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/statistics/byCustomerArticle?${searchParams}`;

      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.statisticGrouped) {
        customSearch.value = api.getResultPage(container, container.data.statisticGrouped);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  async function getCSVCustomSearch(filter: StatisticFilter) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
        'filters.articleName': `${filter.articleName}`,
        'filters.customerCode': `${filter.customerCode}`,
        'filters.search': `${filter.search}`,
        'filters.startMonth': `${filter.startMonth}`,
        'filters.endMonth': `${filter.endMonth}`,
        'filters.yearOne': `${filter.yearOne}`,
        'filters.yearTwo': `${filter.yearTwo}`,
        'filters.today': `${filter.today}`,
        ...(filter.agentId ? { 'filters.agentId': `${filter.agentId}` } : ''),
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/statistics/csv/byCustomerArticle?${searchParams}`;

      const url: string = config.backend.url + requestUri;
      const blob = await api.download(url);

      return blob;
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  async function getArticleList() {
    try {
      const requestUri = '/statistic/articleList';

      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.articlesInfo) {
        articles.value = container.data.articlesInfo;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  async function getCustomerList() {
    try {
      const requestUri = '/statistic/customerList';

      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.customersInfo) {
        customers.value = container.data.customersInfo;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  async function getFilterList() {
    try {
      const requestUri = '/statistic/filterList';

      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.statisticFilters) {
        filters.value = container.data.statisticFilters;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  return {
    getStatistics,
    getStatisticsByMonth,
    getStatisticsAgentsByMonth,
    getCSVByArticle,
    getStatisticsByArticle,
    getStatisticsByCustomer,
    getCSVByCustomer,
    getStatisticsArticlesByCustomer,
    getCustomSearch,
    getCSVCustomSearch,
    getArticleList,
    getCustomerList,
    getFilterList,
    globalStatistics,
    byMonth,
    agentsByMonth,
    byArticle,
    byCustomer,
    articlesByCustomer,
    customSearch,
    articles,
    customers,
    filters,
  };
});
