<template>
  <div>
    <TheSubheader>
      <StatisticsByArticleFilter
        v-model:startMonth="startMonth"
        v-model:endMonth="endMonth"
        v-model:yearOne="yearOne"
        v-model:yearTwo="yearTwo"
        v-model:articleName="articleName"
        v-model:inputSearch="inputSearch"
        @newSearch="newSearch"
      />
    </TheSubheader>

    <div class="mb-32 mt-12 max-md:mx-16 md:px-[80px]">
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <div v-else-if="byArticle.content.length > 0">
        <!-- PAGINATOR -->
        <AgPaginatorInfo :current-page="currentPage" :page-size="pageSize" :number-items="byArticle.totalElements" />
        <AgPaginator v-model="currentPage" :total-pages="byArticle.totalPages" class="my-24" />

        <div class="my-24">
          <AgButton @click="exportCSVData()">File CSV</AgButton>
        </div>

        <!-- TABLE STATISTICS BY ARTICLE -->
        <StatisticsByArticleTable
          v-model:sorting="sorting"
          :statistics="byArticle.content"
          :periodOne="yearOne"
          :periodTwo="yearTwo"
          :startMonth="startMonth"
          :endMonth="endMonth"
        />
      </div>
      <div v-else>
        <AgAlert variant="info">{{ t('statistics.noData') }}</AgAlert>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import StatisticsByArticleTable from '@/modules/statistics/components/by-article/StatisticsByArticleTable.vue';
  import StatisticsByArticleFilter from '@/modules/statistics/components/filters/StatisticsByArticleFilter.vue';

  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useStatisticStore } from '@/modules/statistics/store/useStatisticStore';
  import { i18n } from '@/plugins/i18n';
  import { useHierarchyEmployeeStore } from '@/store/useHierarchyEmployeeStore';
  import { Order } from '@/util/Order';
  import { PageRequest } from '@/util/Paging';
  import { Sorting } from '@/util/Sorting';
  import { StatisticFilter } from '@/util/StatisticFilter';
  import { storeToRefs } from 'pinia';
  import { onMounted, ref, watch } from 'vue';

  const { t } = i18n.global;

  const store = useStatisticStore();
  const { byArticle } = storeToRefs(store);
  const { getStatisticsByArticle, getCSVByArticle } = useStatisticStore();

  const hierarchyEmployeeStore = useHierarchyEmployeeStore();
  const { agentId } = storeToRefs(hierarchyEmployeeStore);

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(true);
  theHeader.title('statistics.statistics', 'statistics.byArticle');

  const theFooter = useTheFooter();
  theFooter.enabled(true);
  theFooter.visible(true);

  const currentPage = ref<number>(1);
  const pageSize = ref<number>(10);

  const isLoading = ref<boolean>(true);

  const sorting = ref<Sorting>(new Sorting('articlename', Order.ASC));

  const articleName = ref<string>('');
  const inputSearch = ref<string>('');

  const date: Date = new Date();
  const startMonth = ref<number>(1);
  const endMonth = ref<number>(13);
  const yearOne = ref<number>(date.getFullYear() - 1);
  const yearTwo = ref<number>(date.getFullYear());

  const newSearch = function () {
    const today = endMonth.value == 13 ? true : false;
    const eMonth = today ? new Date().getMonth() + 1 : endMonth.value;
    const filter = new StatisticFilter(
      undefined,
      undefined,
      startMonth.value,
      eMonth,
      yearOne.value,
      yearTwo.value,
      articleName.value,
      inputSearch.value,
      agentId.value ?? undefined,
      undefined,
      today
    );
    getStatisticsByArticle(new PageRequest(pageSize.value, currentPage.value), sorting.value, filter)
      .then(() => (isLoading.value = false))
      .catch((error) => console.log(error));
  };

  const exportCSVData = function () {
    const today = endMonth.value == 13 ? true : false;
    const eMonth = today ? new Date().getMonth() + 1 : endMonth.value;
    const filter = new StatisticFilter(
      undefined,
      undefined,
      startMonth.value,
      eMonth,
      yearOne.value,
      yearTwo.value,
      articleName.value,
      inputSearch.value,
      agentId.value ?? undefined,
      undefined,
      today
    );
    getCSVByArticle(filter)
      .then((blob) => {
        if (blob) {
          const url = window.URL.createObjectURL(blob);

          const today = Date.now();

          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.setAttribute('download', `agent2go_export_${today}.csv`);
          link.click();

          window.URL.revokeObjectURL(url);
        }
      })
      .catch((error) => console.log(error));
  };

  watch([currentPage, sorting, articleName, inputSearch, agentId], () => newSearch(), { immediate: false, deep: true });

  onMounted(async () => {
    isLoading.value = true;
    newSearch();
  });
</script>
