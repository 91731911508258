import authAdminRequired from '@/middleware/authAdminRequired';
import type { RouteRecordRaw } from 'vue-router';
import HierarchyDetailPage from './pages/HierarchyDetailPage.vue';
import HierarchyLevelDetailPage from './pages/HierarchyLevelDetailPage.vue';
import NewHierarchyPage from './pages/NewHierarchyPage.vue';
import NewLevelIndexPage from './pages/NewLevelIndexPage.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/:levelId/',
    name: 'level',
    component: HierarchyLevelDetailPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/:hierarchyId/',
    name: 'hierarchy',
    component: HierarchyDetailPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/new-level',
    name: '/new-level',
    component: NewLevelIndexPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/new-hierarchy',
    name: '/new-hierarchy',
    component: NewHierarchyPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
];

export default routes;
