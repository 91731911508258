<template>
  <div>
    <TheSubheader class="flex items-center justify-between">
      <DownloadFilter v-model:searchText="searchText" />
      <div class="flex items-center justify-end space-x-64 md:mx-[80px] max-md:mx-16">
        <AgButton variant="ghost" iconBeforeText @click="update">
          <template #icon>
            <IcUpdate />
          </template>
          <div class="text-xl">{{ t('admin.update') }}</div>
        </AgButton>

        <AgButton variant="ghost" iconBeforeText @click="newDownload">
          <template #icon>
            <IcAdd />
          </template>
          <div class="text-xl">{{ t('admin.create') }}</div>
        </AgButton>
      </div>
    </TheSubheader>

    <div class="overflow-y-auto">
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <div v-else class="md:mx-[80px] max-md:mx-16 min-w-fit pt-8 max-h-[calc(100dvh-160px)]">
        <div class="my-24">
          <AgPaginatorInfo :current-page="currentPage" :page-size="itemsPerPage" :number-items="downloads.length" />
          <AgPaginator v-model="currentPage" :total-pages="pageCount" />
        </div>
        <DownloadTable :downloads="displayedItems" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { DownloadDto } from '@/admin/domain/DownloadDto';

  import DownloadTable from '@/admin/modules/downloads/components/DownloadTable.vue';
  import DownloadFilter from '@/admin/modules/downloads/components/filter/DownloadFilter.vue';
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcAdd from '@/components/icons/IcAdd.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcUpdate from '@/components/icons/IcUpdate.vue';
  import AgButton from '@/components/library/button/AgButton.vue';

  import { useDownloads } from '@/admin/modules/downloads/composables/useDownloads';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';

  const { t } = i18n.global;
  const { loadData } = useDownloads();
  const { getTranslatedText } = useTranslatedText();
  const router = useRouter();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.downloads');

  const downloads = ref<Array<DownloadDto>>([]);
  const searchText = ref<string>('');
  const isLoading = ref<boolean>(true);
  const currentPage = ref<number>(1);
  const itemsPerPage = 10;

  const filteredDownloads = computed(() => {
    if (!searchText.value) {
      return downloads.value;
    }

    return downloads.value.filter(
      (download) =>
        download.title.toLowerCase().includes(searchText.value.toLowerCase()) ||
        getTranslatedText(download.category.title).toString().toLowerCase().includes(searchText.value.toLowerCase()) ||
        download.language.toLowerCase().includes(searchText.value.toLowerCase())
    );
  });

  const pageCount = computed(() => Math.ceil(filteredDownloads.value.length / itemsPerPage));

  const displayedItems = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredDownloads.value.length);
    const itmxpage = filteredDownloads.value.slice(startIndex, endIndex);

    return itmxpage;
  });

  const newDownload = async () => {
    try {
      await router.push({
        name: '/new-download',
      });
    } catch (error) {
      console.error('Failed to navigate to download:', error);
    }
  };

  const update = async () => {
    isLoading.value = true;
    downloads.value = await loadData();
    isLoading.value = false;
  };

  onMounted(async () => update());
</script>
