<template>
  <div>
    <!-- SUBHEADER SEARCH AREA -->
    <TheSubheader>
      <OpenItemsByAgentFilter v-model="inputSearch" />
    </TheSubheader>
    <div class="mt-12 mb-32 md:mx-[80px] max-md:mx-16">
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <!-- CONTENT AREA -->
      <div v-else class="no-scrollbar overflow-x-auto">
        <div class="my-12 min-w-fit">
          <div
            v-if="openItemAgents.content && openItemAgents.content.length > 0"
            class="my-16 flex items-center justify-between"
          >
            <AgPaginatorInfo
              :current-page="currentPage"
              :page-size="pageSize"
              :number-items="openItemAgents.totalElements"
            />
          </div>

          <!-- PAGINATOR -->
          <AgPaginator
            v-if="openItemAgents.content && openItemAgents.content.length > 0"
            v-model="currentPage"
            :total-pages="openItemAgents.totalPages"
            class="my-24"
          />
          <!-- TABLE OPEN ITEMS BY AGENT -->
          <OpenItemsByAgentTable :openItems="openItemAgents.content" v-model="sorting" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import OpenItemsByAgentFilter from '@/modules/open-items/components/by-agent/OpenItemsByAgentFilter.vue';
  import OpenItemsByAgentTable from '@/modules/open-items/components/by-agent/OpenItemsByAgentTable.vue';

  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useByAgentStore } from '@/modules/open-items/store/useByAgentStore';
  import { OutstandingFilter } from '@/util/filters/OutstandingFilter';
  import { Order } from '@/util/Order';
  import { PageRequest } from '@/util/Paging';
  import { Sorting } from '@/util/Sorting';
  import { storeToRefs } from 'pinia';
  import { ref, watch } from 'vue';

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('openItems.openItemsByAgent');

  const theFooter = useTheFooter();
  theFooter.enabled(true);
  theFooter.visible(true);

  const currentPage = ref(1);
  const pageSize = ref(10);
  const sorting = ref<Sorting>(new Sorting('agentName', Order.ASC));
  const inputSearch = ref<string>('');
  const isLoading = ref<boolean>(true);

  const store = useByAgentStore();
  const { openItemAgents } = storeToRefs(store);
  const { loadData } = store;

  watch(
    [inputSearch, sorting, currentPage],
    () => {
      loadData(
        new PageRequest(pageSize.value, currentPage.value),
        new OutstandingFilter(inputSearch.value),
        sorting.value
      )
        .then(() => (isLoading.value = false))
        .catch((error) => console.log(error));
    },
    { immediate: true }
  );
</script>
