import type { AuthUserDto } from '@/domain/masterData/AuthUserDto';
import type { UserHierarchyDto } from '@/domain/UserHierarchyDto';
import { useUserData } from './data/useUserData';

export const useHierarchy = () => {
  const userData = useUserData();

  const getAllHierarchy = async function () {
    return (await userData.tables.hierarchy.toArray()) || [];
  };

  /**
   * ATTENTION THIS METHOD RETURN THE LIST OF THE AUTHUSER LOGGEND AND ONLY THE CHILDS OF LOGGED USERS
   * THE PARENT USERS ARE COMPLETELY IGNORED!
   * @returns the list of the authuser loggend and only the childs of logged users
   *
   */
  const resolveHierarchyUsers = async function (): Promise<AuthUserDto[]> {
    const userHierarchy = await getAllHierarchy();
    const authUsers: AuthUserDto[] = [];
    const uniqueAuthUsers = new Set<number>();

    traverseHierarchy(userHierarchy, uniqueAuthUsers, authUsers);
    return authUsers;
  };

  function traverseHierarchy(hierarchy: UserHierarchyDto[], uniqueAuthUsers: Set<number>, authUsers: AuthUserDto[]) {
    for (const user of hierarchy) {
      if (user && user.authUser && !uniqueAuthUsers.has(user.authUser.id)) {
        authUsers.push(user.authUser);
        uniqueAuthUsers.add(user.authUser.id);
      }
      if (user.children) {
        traverseHierarchy(user.children, uniqueAuthUsers, authUsers);
      }
    }
  }

  const resolveHierarchyUsersWithWorkers = async function (): Promise<AuthUserDto[]> {
    const userHierarchy = await getAllHierarchy();
    const authUsers: AuthUserDto[] = [];
    const uniqueAuthUsers = new Set<number>();

    traverseHierarchyWithWorkers(userHierarchy, uniqueAuthUsers, authUsers);
    return authUsers;
  };

  function traverseHierarchyWithWorkers(
    hierarchy: UserHierarchyDto[],
    uniqueAuthUsers: Set<number>,
    authUsers: AuthUserDto[]
  ) {
    for (const user of hierarchy) {
      if (user && user.authUser && !uniqueAuthUsers.has(user.authUser.id)) {
        authUsers.push(user.authUser);
        uniqueAuthUsers.add(user.authUser.id);
      }
      if (user && user.workerAuthUser && !uniqueAuthUsers.has(user.workerAuthUser.id)) {
        authUsers.push(user.workerAuthUser);
        uniqueAuthUsers.add(user.workerAuthUser.id);
      }
      if (user.children) {
        traverseHierarchy(user.children, uniqueAuthUsers, authUsers);
      }
    }
  }

  return {
    getAllHierarchy,
    resolveHierarchyUsers,
    resolveHierarchyUsersWithWorkers,
  };
};
