import authAdminRequired from '@/middleware/authAdminRequired';
import type { RouteRecordRaw } from 'vue-router';
import NewUserPage from './pages/NewUserPage.vue';
import User from './pages/User.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/user/:userId/',
    name: 'user',
    component: User,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/newUser',
    name: '/newUser',
    component: NewUserPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
];

export default routes;
