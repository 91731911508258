<template>
  <div>
    <TheAdminSubHeader @save="createNewDownloadCategory" hasNotDeleteButton />
    <div class="md:mx-[80px]">
      <div class="flex gap-32">
        <div class="infoBox">
          <div class="infoboxTitle">{{ t('admin.code').toUpperCase() }}</div>
          <AgFormInput
            v-model="downloadCategory.code"
            class="w-256"
            @input="codeError = false"
            :validation-state="!codeError"
            :error-message="codeErrorText"
          />
        </div>
      </div>

      <div class="table">
        <span class="text-primary-light">{{ 'admin.languageDescription'.toUpperCase() }}</span>
        <div v-for="selectedLang in Object.values(config.company.availableLanguages)" :key="selectedLang">
          <div class="tableColumn">
            <AgFormInput :placeholder="selectedLang" disabled />
            <AgFormInput
              v-model="downloadCategory.title[selectedLang]"
              :validation-state="hasDescValue(selectedLang)"
              @input="descriptionError = false"
            />
          </div>
        </div>
        <AgAlert v-if="descriptionError" variant="danger" dismissible>
          {{ t('admin.descriptionRequired') }}
        </AgAlert>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useDownloadCategories } from '@/admin/modules/downloadCategories/composables/useDownloadCategories';
  import TheAdminSubHeader from '@/components/framework/admin/the-admin-sub-header/TheAdminSubHeader.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { config } from '@/config/config';
  import type { DownloadCategoryDto } from '@/domain/DownloadCategoryDto';
  import { i18n } from '@/plugins/i18n';
  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';

  const { t } = i18n.global;
  const { createDownloadCategory, getDownloadCategoryList } = useDownloadCategories();
  const router = useRouter();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.newDownloadCategory');

  const codeError = ref<boolean>(false);
  const codeErrorText = ref<string>('');
  const descriptionError = ref<boolean>(false);

  const downloadCategories = ref<DownloadCategoryDto[]>([]);
  const downloadCategory = ref<DownloadCategoryDto>({
    code: '',
    title: {},
    id: -1,
    downloadsNumber: 0,
  });

  const hasDescValue = function (lang: string) {
    if (!descriptionError.value) {
      return true;
    }
    if (!Object.keys(downloadCategory.value.title).includes(lang)) {
      return false;
    }
    return downloadCategory.value.title[lang].length > 0;
  };

  const createNewDownloadCategory = async function () {
    codeError.value = false;
    codeErrorText.value = '';

    if (downloadCategory.value.code.length === 0) {
      codeError.value = true;
      codeErrorText.value = t('admin.codeRequired');
    }
    const codeAlreadyExists = downloadCategories.value.some(
      (category) => category.code === downloadCategory.value.code && category.id !== downloadCategory.value.id
    );

    if (Object.keys(downloadCategory.value.title).length === 0) {
      descriptionError.value = true;
    }
    if (Object.values(downloadCategory.value.title).every((value) => value.length === 0)) {
      descriptionError.value = true;
    }

    if (codeAlreadyExists) {
      codeError.value = true;
      codeErrorText.value = t('admin.codeAlreadyExists');
    }

    if (!codeError.value && !descriptionError.value) {
      await createDownloadCategory(downloadCategory.value);
      try {
        await router.push({
          path: '/admin/download-categories',
        });
      } catch (error) {
        console.error('Failed to navigate to download categories:', error);
      }
    }
  };

  onMounted(async () => {
    downloadCategories.value = await getDownloadCategoryList();
  });
</script>

<style scoped lang="scss">
  .infoboxTitle {
    @apply text-primary-light;
  }
  .infoBox {
    @apply w-1/2 justify-center my-12 max-w-768;
  }

  .table {
    @apply grid grid-cols-1 py-16 space-x-0;
  }

  .tableColumn {
    @apply flex items-center min-w-0 py-4 gap-4;
  }
</style>
