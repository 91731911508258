<template>
  <div v-if="!props.originalTree.children" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
    <AgButton variant="ghost" @click="showModal = true" class="px-12 rounded-full bg-primary text-white h-48 w-48">
      <template #icon>
        <IcAdd class="h-24 w-24" />
      </template>
    </AgButton>
  </div>
  <div class="node-container">
    <div v-if="props.originalTree.children" class="node">
      <div class="flex items-center">
        <div class="node-content">
          <div>{{ props.tree.authUserName }}</div>
          <div>({{ props.tree.description }})</div>
        </div>
        <AgButton variant="ghost" @click="truncateHierarchy()">
          <template #icon>
            <IcDelete class="h-24 w-24 text-danger" />
          </template>
        </AgButton>
        <AgButton
          v-if="deept !== undefined && deept < 3 && props.deept < levels.length"
          variant="ghost"
          @click="showModal = true"
        >
          <template #icon>
            <IcAdd class="h-24 w-24 text-success" />
          </template>
        </AgButton>
      </div>
    </div>
    <div class="children" v-if="props.tree.children && props.tree.children.length > 0">
      <div class="children-nodes">
        <HierarchyInsertTree
          v-for="(child, index) in props.tree.children"
          :key="index"
          :tree="child"
          :original-tree="props.originalTree"
          :deept="props.deept + 1"
          :levels="props.levels"
        />
      </div>
    </div>
    <ModalAddAgentNewHierarchy
      v-model="showModal"
      :tree="props.tree"
      :original-tree="props.originalTree"
      :deept="props.deept"
    />
  </div>
</template>

<script setup lang="ts">
  import type { HierarchyLevelDto } from '@/admin/domain/HierarchyLevelDto';
  import type { InsertHierarchyDto } from '@/admin/domain/InsertHierarchyDto';
  import IcAdd from '@/components/icons/IcAdd.vue';
  import IcDelete from '@/components/icons/IcDelete.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import { ref } from 'vue';
  import ModalAddAgentNewHierarchy from './ModalAddAgentNewHierarchy.vue';

  interface Props {
    tree: InsertHierarchyDto;
    originalTree: InsertHierarchyDto;
    deept: number;
    levels: Array<HierarchyLevelDto>;
  }

  const props = defineProps<Props>();

  const showModal = ref<boolean>(false);

  const truncateHierarchy = function () {
    if (props.tree.parentAuthUserId === null) {
      props.originalTree.authUserId = 0;
      props.originalTree.authUserName = '';
      props.originalTree.authUserLevelId = 0;
      props.originalTree.parentAuthUserId = null;
      props.originalTree.children = null;
      return;
    }
    if (!props.tree.children || props.tree.children.length === 0) {
      const parent = props.originalTree.children?.find((child) => child.authUserId === props.tree.parentAuthUserId);
      parent?.children?.splice(
        parent.children.findIndex((child) => child.authUserId === props.tree.authUserId),
        1
      );
      return;
    }
    const treeIndex = props.originalTree.children?.findIndex((child) => child.authUserId === props.tree.authUserId);
    console.log(treeIndex);
    if (treeIndex === undefined || treeIndex === -1) {
      return;
    }
    if (props.originalTree.children) {
      props.originalTree.children.splice(treeIndex, 1);
    } else {
      props.originalTree.children = null;
      props.originalTree.authUserId = 0;
      props.originalTree.authUserName = '';
    }
  };

  const calculateTreeDepth = function (tree: InsertHierarchyDto): number {
    if (!tree.authUserId) {
      return 0;
    }
    if (!tree.children || tree.children.length === 0) {
      return 1;
    }
    const depths = tree.children.map(calculateTreeDepth);
    return 1 + Math.max(...depths);
  };
</script>

<style scoped>
  .node-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .node {
    position: relative;
    margin: 20px;
    padding: 10px;
    border-radius: 50%;
    background-color: #f0f0f0;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .node-content {
    font-weight: bold;
  }

  .children {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .children-nodes {
    display: flex;
    justify-content: center;
    position: relative;
  }
</style>
