<template>
  <div class="md:px-[80px] max-md:px-16 py-12 flex w-full items-center justify-between gap-32">
    <AgFormGroup :label="t('offers.search')" class="w-full">
      <AgSearchInput v-model="internalOfferFilter" />
    </AgFormGroup>
    <AgFormGroup :label="t('offers.status')" class="w-full">
      <AgFormSelect v-model="internalOfferStatus" :options="options" />
    </AgFormGroup>
    <AgFormGroup :label="t('offers.customer')" class="w-full">
      <AgSearchSelect v-model="internalCustomerId" :options="customerOptions" />
    </AgFormGroup>
  </div>
</template>

<script setup lang="ts">
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';

  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import AgSearchInput from '@/components/library/search-input/AgSearchInput.vue';

  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import { useMasterData } from '@/composables/data/useMasterData';
  import { OfferStatus } from '@/domain/enumeration/OfferStatus';
  import type { CustomerDto } from '@/domain/masterData/CustomerDto';
  import { i18n } from '@/plugins/i18n';
  import { useHierarchyEmployeeStore } from '@/store/useHierarchyEmployeeStore';
  import { storeToRefs } from 'pinia';
  import { computed, onBeforeMount, ref } from 'vue';

  const { t } = i18n.global;

  const store = useHierarchyEmployeeStore();
  const { tables } = useMasterData();

  interface Props {
    offerFilter: string;
    offerStatus: OfferStatus;
    customerId: number;
  }
  const props = withDefaults(defineProps<Props>(), {
    offerFilter: '',
    offerStatus: OfferStatus.DRAFT,
  });

  const emit = defineEmits(['update:offerFilter', 'update:offerStatus', 'update:customerId']);

  const { agentId } = storeToRefs(store);
  const customers = ref<Array<CustomerDto>>([]);
  const options = ref<Array<AgFormSelectOption>>([
    { key: OfferStatus.DRAFT, label: 'offers.draft' },
    { key: OfferStatus.COMPLETED, label: 'offers.completed' },
    { key: OfferStatus.ALL, label: 'offers.all' },
  ]);

  const customerOptions = computed((): Array<AgSearchSelectOption> => {
    let customersFiltered = customers.value;
    if (agentId.value && agentId.value !== -1) {
      customersFiltered = customers.value.filter((c) => c.agentId === agentId.value);
    }
    const opts = customersFiltered.map((c) => {
      const label: string[] = [c.code];
      if (c.searchName) {
        label.push(c.searchName);
      }
      label.push(c.name);

      return {
        value: c.id,
        label: label.join(' / '),
        searchableString: c.code + c.name + c.searchName,
      };
    });
    opts.unshift({ value: -1, label: t('offers.all'), searchableString: t('offers.all') });
    return opts;
  });

  const internalOfferFilter = computed({
    get: () => props.offerFilter,
    set: (value) => emit('update:offerFilter', value),
  });

  const internalOfferStatus = computed({
    get: () => props.offerStatus,
    set: (value) => emit('update:offerStatus', value),
  });

  const internalCustomerId = computed({
    get: () => props.customerId,
    set: (value) => emit('update:customerId', value),
  });

  onBeforeMount(async () => {
    customers.value = await tables.customers.toArray();
  });
</script>
