<template>
  <div>
    <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
      <IcLoading />
    </div>
    <TheAdminSubHeader @save="saveCampaign" hasNotDeleteButton />
    <div class="md:mx-[80px]">
      <div class="space-y-8">
        <div class="font-bold text-primary">{{ t('admin.name').toUpperCase() }}</div>
        <AgFormInput
          class="max-w-256"
          v-model="level.description"
          :validation-state="!nameError"
          :error-message="nameErrorText"
          @update:model-value="resetValidation"
        />
        <div class="flex">
          <span class="font-bold">{{ t('admin.grade') }}:&nbsp;</span>
          <span>{{ levels.length + 1 }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IcLoading from '@/components/icons/IcLoading.vue';

  import type { HierarchyLevelDto } from '@/admin/domain/HierarchyLevelDto';
  import TheAdminSubHeader from '@/components/framework/admin/the-admin-sub-header/TheAdminSubHeader.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useHierarchies } from '../composables/useHierarchies';

  const { t } = i18n.global;
  const { loadLevels, createLevel } = useHierarchies();

  const router = useRouter();
  const theHeader = useTheHeader();

  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.newLevel');

  const levels = ref<Array<HierarchyLevelDto>>([]);
  const nameError = ref<boolean>(false);
  const nameErrorText = ref<string>('');
  const isLoading = ref<boolean>(false);
  const level = ref<HierarchyLevelDto>({
    id: 0,
    grade: 0,
    description: '',
  });

  const resetValidation = () => {
    nameError.value = false;
    nameErrorText.value = '';
  };

  const saveCampaign = async () => {
    if (!level.value.description || level.value.description.length === 0) {
      nameError.value = true;
      nameErrorText.value = t('admin.nameRequired');
      return;
    } else if (levels.value.some((l) => l.description === level.value.description)) {
      nameError.value = true;
      nameErrorText.value = t('admin.nameAlreadyExists');
      return;
    }
    if (level.value && !nameError.value) {
      await createLevel(level.value);
      try {
        await router.push({
          path: '/admin/hierarchy/levels',
        });
      } catch (error) {
        console.error('Failed to navigate to hierarchy levels:', error);
      }
    }
  };

  onMounted(async () => {
    levels.value = await loadLevels();
    level.value.grade = levels.value.length + 1;
  });
</script>
