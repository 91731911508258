<template>
  <div>
    <TheSubheader class="flex items-center justify-between">
      <Filter v-model:searchText="searchText" />
      <div class="flex items-center justify-end space-x-64 md:mx-[80px] max-md:mx-16">
        <AgButton variant="ghost" iconBeforeText @click="update">
          <template #icon>
            <IcUpdate />
          </template>
          <div class="text-xl">{{ t('admin.update') }}</div>
        </AgButton>

        <AgButton variant="ghost" iconBeforeText @click="newDeliveryMethod">
          <template #icon>
            <IcAdd />
          </template>
          <div class="text-xl">{{ t('admin.create') }}</div>
        </AgButton>
      </div>
    </TheSubheader>

    <div class="overflow-y-auto">
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <div v-else class="md:mx-[80px] max-md:mx-16 min-w-fit pt-8 max-h-[calc(100dvh-160px)]">
        <div class="my-24">
          <AgPaginatorInfo
            :current-page="currentPage"
            :page-size="itemsPerPage"
            :number-items="communications.length"
          />
          <AgPaginator v-model="currentPage" :total-pages="pageCount" />
        </div>
        <CommunicationTable :communications="displayedItems" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { CommunicationDto } from '@/admin/domain/CommunicationDto';

  import CommunicationTable from '@/admin/modules/communication/components/CommunicationTable.vue';
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcAdd from '@/components/icons/IcAdd.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcUpdate from '@/components/icons/IcUpdate.vue';
  import AgButton from '@/components/library/button/AgButton.vue';

  import { useCommunication } from '@/admin/modules/communication/composables/useCommunication';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import Filter from '../../businessCategories/components/filter/Filter.vue';

  const { t } = i18n.global;

  const { loadData } = useCommunication();

  const router = useRouter();
  const theHeader = useTheHeader();

  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.communications');

  const communications = ref<Array<CommunicationDto>>([]);
  const isLoading = ref<boolean>(true);
  const searchText = ref<string>('');
  const currentPage = ref<number>(1);
  const itemsPerPage = 10;

  const filteredCommunications = computed(() => {
    return communications.value.filter(
      (c) =>
        c.title.toLowerCase().includes(searchText.value.toLowerCase()) ||
        (c.subtitle ?? '').toString().toLowerCase().includes(searchText.value.toLowerCase())
    );
  });

  const pageCount = computed(() => Math.ceil(filteredCommunications.value.length / itemsPerPage));

  const displayedItems = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredCommunications.value.length);
    const itmxpage = filteredCommunications.value.slice(startIndex, endIndex);

    return itmxpage;
  });

  const newDeliveryMethod = async () => {
    try {
      await router.push({
        name: '/new-communication',
      });
    } catch (error) {
      console.error('Failed to navigate to communication:', error);
    }
  };

  const update = async () => {
    isLoading.value = true;
    const cm = await loadData();
    communications.value = cm;
    isLoading.value = false;
  };

  onMounted(() => update());
</script>
