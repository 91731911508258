import { ref } from 'vue';

const errorEnabled = ref(false);
const message = ref<string>('');
const code = ref<string | number | undefined>('');

export function useTheError() {
  const enabled = function (isEnabled: boolean, errorMessage: string, codeMessage: string | number | undefined) {
    message.value = errorMessage;
    errorEnabled.value = isEnabled;
    code.value = codeMessage;
  };

  return {
    enabled,
    errorEnabled,
    message,
    code,
  };
}
