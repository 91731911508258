<template>
  <div class="flex h-[calc(100dvh)] w-full flex-col">
    <div v-if="theHeader.headerEnabled && theHeader.headerEnabled.value === true" class="flex h-[80px] justify-between">
      <TheHeader />
      <div
        v-if="!navigationOpen && isAuthenticated"
        class="navigationOpen"
        :class="{ 'bg-neutral-250': '/dashboard' !== route.path }"
        @click="navigationOpen = true"
      >
        <IcMenu class="hover:opacity-50" :class="classes" />
      </div>
    </div>

    <div id="the-content">
      <TheSidebar v-show="navigationOpen" @close="navigationOpen = false" :navigation-open="navigationOpen" />
      <RouterView class="mb-8" />
      <TheError v-model="errorEnabled" :message="message" :code="code" />
    </div>

    <div class="footer">
      <TheFooter />
    </div>

    <span id="overlayOpenMenu" @click="navigationOpen = false">&nbsp;</span>
    <div v-if="errorEnabled" class="overlay"></div>
  </div>
</template>

<script setup lang="ts">
  import TheError from '@/components/framework/the-error/TheError.vue';
  import TheFooter from '@/components/framework/the-footer/TheFooter.vue';
  import TheHeader from '@/components/framework/the-header/TheHeader.vue';
  import TheSidebar from '@/components/framework/the-sidebar/TheSidebar.vue';
  import IcMenu from '@/components/icons/IcMenu.vue';

  import { useAuthentication } from '@/composables/auth/useAuthentication';
  import { useTheError } from '@/composables/framework/useTheError';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { computed, ref, watch } from 'vue';
  import { RouterView, useRoute } from 'vue-router';

  const { isAuthenticated } = useAuthentication();
  const { errorEnabled, message, code } = useTheError();

  const route = useRoute();

  const navigationOpen = ref<boolean>(false);
  const theHeader = useTheHeader();

  watch(
    navigationOpen,
    (value) => {
      const overlayOpenMenu = document.getElementById('overlayOpenMenu');
      if (overlayOpenMenu) {
        overlayOpenMenu.style.display = value ? 'block' : 'none';
      }
    },
    { immediate: true }
  );

  const classes = computed(() => ({
    'text-white bg-transparent': '/dashboard' === route.path,
  }));
</script>

<style lang="scss">
  @import '@/assets/styles/styles';

  body {
    /* Hide arrows on input type number*/
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
      appearance: textfield;
    }

    #the-content {
      flex: 1 1 auto;
      position: relative;

      @apply no-scrollbar mb-48 max-h-screen overflow-y-auto overflow-x-hidden;
    }

    a {
      @apply text-link;
    }
  }

  :root {
    color-scheme: only light;
  }

  .navigationOpen {
    @apply flex items-center px-24 lg:hidden;
  }

  .footer {
    @apply fixed bottom-0 left-0 right-0 max-lg:w-full lg:w-[calc(100%-20.8125em)];
  }

  #overlayOpenMenu {
    @apply fixed top-0 z-30 hidden h-[calc(100dvh)] w-screen bg-[#111111] bg-opacity-50;
  }

  .overlay {
    @apply fixed top-0 left-0 right-0 bottom-0 z-[9999] bg-black bg-opacity-50;
  }
</style>
