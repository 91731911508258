import { ref } from 'vue';

const isDisabled = ref<boolean>(false);
export function useOfferWizardNavigation() {
  const disableTabsExceptShoppingCart = function () {
    isDisabled.value = true;
  };

  const enableAllTabs = function () {
    isDisabled.value = false;
  };
  return {
    disableTabsExceptShoppingCart,
    enableAllTabs,
    isDisabled,
  };
}
