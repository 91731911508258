<template>
  <div>
    <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
      <IcLoading />
    </div>
    <div v-else>
      <TheAdminSubHeader @save="createNewCollaborator" hasNotDeleteButton />
      <div class="md:mx-[80px] gap-32 py-24">
        <div>
          <span class="infoboxTitle">{{ t('admin.name').toUpperCase() }}</span>
          <AgFormInput v-model="collaborator.name" class="w-256" @input="resetValidation" />
        </div>
        <div>
          <span class="infoboxTitle">{{ t('admin.code').toUpperCase() }}</span>
          <AgFormInput
            v-model="collaborator.code"
            class="w-256"
            @input="resetValidation"
            :validation-state="!codeError"
            :error-message="codeErrorText"
          />
        </div>
        <div>
          <span class="infoboxTitle">{{ t('admin.email').toUpperCase() }}</span>
          <AgFormInput
            v-model="collaborator.email"
            class="w-256"
            @input="resetValidation"
            :validation-state="!emailError"
            :error-message="emailErrorText"
          />
        </div>
        <div class="max-w-384">
          <span class="infoboxTitle">{{ t('admin.workingForAgent').toUpperCase() }}</span>
          <AgSearchSelect :options="userOptions" v-model="collaborator.workingForAgentId" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { AuthUserDto } from '@/admin/domain/AuthUserDto';
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';

  import IcCheck from '@/components/icons/IcCheck.vue';
  import IcClose from '@/components/icons/IcClose.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';

  import TheAdminSubHeader from '@/components/framework/admin/the-admin-sub-header/TheAdminSubHeader.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useCollaborators } from '../composables/useCollaborators';

  const { createData, loadData, loadUsers } = useCollaborators();

  const { t } = i18n.global;
  const router = useRouter();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.newCollaborator');

  const codeError = ref<boolean>(false);
  const codeErrorText = ref<string>('');
  const emailError = ref<boolean>(false);
  const emailErrorText = ref<string>('');
  const isLoading = ref<boolean>(false);
  const collaborators = ref<Array<AuthUserDto>>([]);
  const userOptions = ref<Array<AgSearchSelectOption>>([]);
  const users = ref<Array<AuthUserDto>>([]);

  const collaborator = ref<AuthUserDto>({
    id: -1,
    code: '',
    active: false,
    email: '',
    endOfPassword: '',
    name: '',
    surname: '',
    username: '',
    isEmployee: false,
    workingForAgentId: null,
    modifyTimeStamp: 0,
  });

  const emailRegexp = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const resetValidation = function () {
    codeError.value = false;
    codeErrorText.value = '';
    emailError.value = false;
    emailErrorText.value = '';
  };

  const createNewCollaborator = async function () {
    resetValidation();

    if (!collaborator.value.code) {
      codeErrorText.value = t('admin.codeRequired');
      codeError.value = true;
    }

    if (!collaborator.value.email || collaborator.value.email.length === 0) {
      emailError.value = true;
      emailErrorText.value = t('admin.emailRequired');
    } else if (!emailRegexp.test(collaborator.value.email ?? '')) {
      emailError.value = true;
      emailErrorText.value = t('admin.invalidEmail');
    }

    const emailAlreadyExists =
      users.value.some((user) => user.email === collaborator.value.email && user.id !== collaborator.value.id) ||
      collaborators.value.some(
        (collab) => collab.email === collaborator.value.email && collab.id !== collaborator.value.id
      );

    if (emailAlreadyExists) {
      emailError.value = true;
      emailErrorText.value = t('admin.emailAlreadyExists');
    }

    const codeAlreadyExists = collaborators.value.some(
      (collab) =>
        (collab.code ?? '').toLowerCase() === collaborator.value.code.toLowerCase() &&
        collab.id !== collaborator.value.id
    );

    if (codeAlreadyExists) {
      codeErrorText.value = t('admin.codeAlreadyExists');
      codeError.value = true;
    }

    if (!codeError.value && !emailError.value) {
      await sendRequestForCreate();
    }
  };

  const sendRequestForCreate = async function () {
    isLoading.value = true;
    await createData(collaborator.value);
    try {
      await router.push({
        path: '/admin/collaborators',
      });
    } catch (error) {
      console.error('Failed to navigate to collaborators:', error);
    }
    isLoading.value = false;
  };

  const activeIcon = computed(() => {
    return collaborator.value.active ? IcCheck : IcClose;
  });

  onMounted(async () => {
    collaborators.value = await loadData();
    users.value = await loadUsers();
    userOptions.value = users.value.map((user) => ({
      value: user.id,
      id: user.id,
      searchableString: `${user.name} / ${user.email}`,
      label: `${user.name} / ${user.email}`,
    }));
    collaborator.value.workingForAgentId = users.value[0].id;
  });
</script>

<style scoped lang="scss">
  .infoboxTitle {
    @apply text-primary-light;
  }
  .infoBox {
    @apply w-1/2 justify-center my-12 max-w-768;
  }

  .table {
    @apply grid grid-cols-1 py-16 space-x-0;
  }

  .tableColumn {
    @apply flex items-center min-w-0 py-4 gap-4;
  }

  .userbox {
    @apply w-1/2 justify-center my-12;
  }
</style>
