<template>
  <div class="bg-black text-neutral-600 p-16">
    <div class="flex justify-between items-center">
      <IcArrowBack class="h-16 w-16 m-4" @click="move(-1)" :class="iconBackClasses" />

      <div
        id="container"
        class="gap-x-8 grid grid-flow-col place-items-center overflow-x-auto overflow-y-hidden w-full scroll-smooth snap-x snap-mandatory"
      >
        <div
          v-for="(item, index) in props.steps"
          :key="item.id"
          @click="moveItem(item.id)"
          class="snap-always snap-start max-h-min"
          :class="{
            'bg-primary rounded-full px-24 py-8': props.modelValue === item.id,
            'text-white ': index <= currentPosition && (!props.disableStepsExpectCart || item.id === 'shopping-cart'),
            'fade-in-text': index === currentPosition + 1,
            'hover:cursor-pointer': !props.disableStepsExpectCart,
            'cursor-not-allowed': props.disableStepsExpectCart && item.id !== 'shopping-cart',
          }"
        >
          <div v-if="props.modelValue === item.id" class="flex items-center">
            <Popper
              class="agPopper"
              openDelay="1000"
              closeDelay="3333"
              placement="top"
              offsetDistance="15"
              arrow
              :show="showPopper"
              @open:popper="showPopper = false"
            >
              <div class="flex">
                <div v-if="!props.validationStates[index]" class=""><IcAlertRed /></div>
                <div class="pl-4">{{ t(item.labelKey) }}</div>
              </div>

              <template #content v-if="isShow(index)">
                <div class="p-16 text-s-20">{{ t('validationWarning') }}</div>
              </template>
            </Popper>
          </div>

          <div v-else class="flex items-center">
            <div v-if="!props.validationStates[index]"><IcAlertRed /></div>
            <div class="pl-4">{{ t(item.labelKey) }}</div>
          </div>
        </div>
      </div>

      <IcArrowForward class="h-16 w-16 m-4" @click="move(1)" :class="iconForwardClasses" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { WizardNavigationItem } from '@/components/library/wizard-navigation/WizardNavigationItem';

  import IcAlertRed from '@/components/icons/IcAlertRed.vue';
  import IcArrowBack from '@/components/icons/IcArrowBack.vue';
  import IcArrowForward from '@/components/icons/IcArrowForward.vue';
  import Popper from 'vue3-popper';

  import { i18n } from '@/plugins/i18n';
  import { computed, ref } from 'vue';

  const { t } = i18n.global;

  interface Props {
    steps: Array<WizardNavigationItem>;
    validationStates: Array<boolean>;
    modelValue: string | number;
    disableStepsExpectCart?: boolean;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:modelValue']);

  const showPopper = ref(false);

  const isShow = function (index: number) {
    if (!props.validationStates[index]) {
      showPopper.value = true;
      return true;
    } else {
      showPopper.value = false;
      return false;
    }
  };

  const currentPosition = computed(() => {
    return props.steps.map((step) => step.id).indexOf(props.modelValue);
  });

  const iconBackClasses = computed(() => ({
    'text-white': currentPosition.value > 0 && !props.disableStepsExpectCart,
    'hover:cursor-pointer': !props.disableStepsExpectCart,
    'hover:cursor-not-allowed ': props.disableStepsExpectCart,
  }));

  const iconForwardClasses = computed(() => ({
    'text-white': currentPosition.value < props.steps.length - 1 && !props.disableStepsExpectCart,
    'hover:cursor-pointer': !props.disableStepsExpectCart,
    'hover:cursor-not-allowed ': props.disableStepsExpectCart,
  }));

  function moveItem(itemId: number | string) {
    if (props.disableStepsExpectCart && props.disableStepsExpectCart && itemId !== 'shopping-cart') {
      return;
    }
    emit('update:modelValue', itemId);
    const container = document.getElementById('container');
    const nextPosition = props.steps.map((step) => step.id).indexOf(itemId);
    if (currentPosition.value > nextPosition && container) {
      container.scrollLeft -= 65;
    } else if (currentPosition.value < nextPosition && container) {
      container.scrollLeft += 65;
    }
  }

  function move(direction: number) {
    if (props.disableStepsExpectCart) {
      return;
    }
    const container = document.getElementById('container');
    if (direction > 0) {
      if (container) {
        container.scrollLeft += 65;
      }
      if (currentPosition.value < props.steps.length - 1) {
        emit('update:modelValue', props.steps[currentPosition.value + 1].id);
      }
    } else {
      if (container) {
        container.scrollLeft -= 65;
      }
      if (currentPosition.value > 0) {
        emit('update:modelValue', props.steps[currentPosition.value - 1].id);
      }
    }
  }
</script>

<style scoped lang="scss">
  .agPopper {
    --popper-theme-background-color: #006fff;
    --popper-theme-background-color-hover: #006fff;
    --popper-theme-text-color: #ffffff;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }
  .fade-in-text {
    animation: fadeIn 3s;
    @apply rounded-full border-primary border-2 px-24 py-8;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
</style>
