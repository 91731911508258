<template>
  <div class="py-12 md:mx-[80px] max-md:mx-16 flex items-center justify-between">
    <AgFormGroup :label="t('customers.search')" class="w-full">
      <AgSearchSelect :options="customerOptions" :placeholder="t('customers.hintSearch')" v-model="internalValue" />
    </AgFormGroup>
  </div>
</template>

<script setup lang="ts">
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import type { CustomerDto } from '@/domain/masterData/CustomerDto';

  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { CustomerAddressType } from '@/domain/enumeration/CustomerAddressType';
  import { i18n } from '@/plugins/i18n';
  import { useHierarchyEmployeeStore } from '@/store/useHierarchyEmployeeStore';
  import { storeToRefs } from 'pinia';
  import { computed, ref, watch } from 'vue';

  const { t } = i18n.global;
  const { tables, isFullReloading, isLoading } = useMasterData();

  const store = useHierarchyEmployeeStore();
  const { agentId } = storeToRefs(store);

  interface Props {
    modelValue: number;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:modelValue']);

  const internalValue = computed({
    get: () => {
      return props.modelValue;
    },
    set: (value) => {
      emit('update:modelValue', value);
    },
  });

  const customers = ref<Array<CustomerDto>>([]);

  const loadCustomers = async function () {
    customers.value = await tables.customers.toArray();
  };

  const customerOptions = computed((): Array<AgSearchSelectOption> => {
    return customers.value
      .filter((c) => (agentId.value ? c.agentId === agentId.value : true))
      .map((c) => {
        const label: string[] = [c.code];
        if (c.searchName) {
          label.push(c.searchName);
        }
        label.push(c.name);

        let address: string = '';
        if (c.addresses && c.addresses.length > 0) {
          c.addresses
            .filter((a) => a.type.includes(CustomerAddressType.BILLING))
            .map((a) => {
              address = a.street + a.city;
              label.push(a.street);
              label.push(a.city);
              if (a.district) {
                address += a.district;
                label.push(a.district);
              }
            });
        }
        return {
          value: c.id,
          label: label.join(' / '),
          searchableString: c.name + c.code + c.fiscalCode + c.vatCode + c.searchName + address,
          disabled: !c.vatCode,
          class: c.salesBlocked ? 'italic text-warning' : '',
        };
      });
  });

  watch(
    [isFullReloading, isLoading],
    async ([fullReload, reload]) => {
      if (!fullReload && !reload) {
        await loadCustomers();
      }
    },
    { immediate: true }
  );
</script>
