import { useMsal } from '@/composables/auth/useMsal';
import { config } from '@/config/config';
import { defineStore } from 'pinia';

export const useDBNameStore = defineStore('dbname-store', () => {
  const { account } = useMsal();

  const prefix = config.company.code;
  const postfix = account.value ? account.value.username : 'noUser';

  const mastedDataName = 'a2gMasterData';
  const userDataName = 'a2gUserData';

  function getMasterDataName() {
    return `${prefix}_${mastedDataName}_${postfix}`;
  }

  function getuserDataName() {
    return `${prefix}_${userDataName}_${postfix}`;
  }

  return {
    getMasterDataName,
    getuserDataName,
  };
});
