<template>
  <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
    <IcLoading />
  </div>
  <div v-else-if="download">
    <TheAdminSubHeader @save="saveDownload" @delete="deleteCurrentDonwload" />

    <div class="md:mx-[80px]">
      <div class="infoBox">
        <div>
          <span class="infoboxTitle">{{ t('admin.title').toUpperCase() }}</span>
          <AgFormInput
            v-model="download.title"
            class="w-256"
            :validation-state="!titleError"
            :error-message="titleErrorText"
            @input="resetValidation"
          />
        </div>

        <div class="pt-8">
          <span class="infoboxTitle">{{ t('admin.communications').toUpperCase() }}:&nbsp;</span>
          {{ download.numberOfCommunications }}
        </div>
      </div>

      <div class="infoBox max-w-384">
        <span class="infoboxTitle">{{ t('admin.category').toUpperCase() }}</span>
        <AgSearchSelect :options="categoryOptions" v-model="download.category.id" />
      </div>

      <div class="infoBox max-w-384">
        <span class="infoboxTitle">{{ t('admin.language').toUpperCase() }}</span>
        <AgFormSelect :options="languageSelectOptions" v-model="download.language" />
      </div>

      <div class="infoBox max-w-384">
        <span class="infoboxTitle">{{ t('admin.permission').toUpperCase() }}</span>
        <AgSearchSelect :options="permissionGroupOptions" v-model="download.permissionGroupId" />
      </div>

      <div class="infoBox max-w-384">
        <span class="infoboxTitle">{{ t('admin.article').toUpperCase() }}</span>
        <AgSearchSelect
          :options="articleOptions"
          v-model="download.articleId"
          :disabled="download.articleGroupId !== null && download.articleGroupId !== -1"
        />
      </div>

      <div class="infoBox max-w-384">
        <span class="infoboxTitle">{{ t('admin.articleGroup').toUpperCase() }}</span>
        <AgSearchSelect
          :options="articleGroupOptions"
          v-model="download.articleGroupId"
          :disabled="download.articleId !== null && download.articleId !== -1"
        />
      </div>

      <div class="infoBox">
        <span class="infoboxTitle">{{ t('admin.fileName').toUpperCase() }}</span>
        <div class="flex items-center gap-4">
          <AgFormInput
            v-model="download.fileName"
            :validation-state="!fileNameError"
            :error-message="fileNameErrorText"
            @input="resetValidation"
            class="w-1/2"
          />

          <IcShow
            v-if="(fileInput && fileInput.value) || loadedFile"
            class="h-24 w-24 cursor-pointer text-infoBlue"
            @click="openFile"
          />
        </div>
      </div>
      <AgAlert v-if="!isLoadingFile && !loadedFile" variant="danger" class="my-12">
        <template #icon>
          <IcAlert />
        </template>
        {{ t('admin.fileNotFound') }}
      </AgAlert>

      <div>
        <div class="infoboxTitle">{{ t('admin.upload').toUpperCase() }}</div>
        <input ref="fileInput" type="file" class="hidden" accept="application/pdf" @change="handleFileChange" />
        <AgButton @click="openFileDialog" variant="ghost">
          <template #icon>
            <IcDownload class="h-24 w-24 text-infoBlue" />
          </template>
        </AgButton>
      </div>
    </div>
  </div>
  <div v-else>
    <AgAlert variant="info"> {{ t('admin.notFound') }} </AgAlert>
  </div>
</template>

<script setup lang="ts">
  import type { DownloadDto } from '@/admin/domain/DownloadDto';
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';

  import IcDownload from '@/components/icons/IcDownload.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcShow from '@/components/icons/IcShow.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';

  import { useDownloads } from '@/admin/modules/downloads/composables/useDownloads';
  import TheAdminSubHeader from '@/components/framework/admin/the-admin-sub-header/TheAdminSubHeader.vue';
  import IcAlert from '@/components/icons/IcAlert.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { config } from '@/config/config';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  const { t } = i18n.global;
  const {
    loadFile,
    loadData,
    deleteData,
    updateData,
    getDownloadCategoryList,
    getPermissionGroups,
    getArticles,
    getArticleGroups,
  } = useDownloads();
  const { getTranslatedText } = useTranslatedText();

  const route = useRoute();
  const router = useRouter();
  const theHeader = useTheHeader();

  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);

  const download = ref<DownloadDto | null>(null);
  const isLoading = ref<boolean>(true);
  const languageSelectOptions = ref<Array<AgFormSelectOption>>([]);
  const categoryOptions = ref<Array<AgSearchSelectOption>>([]);
  const fileInput = ref<HTMLInputElement | null>(null);
  const loadedFile = ref<Blob | null>(null);
  const isLoadingFile = ref<boolean>(true);
  const permissionGroupOptions = ref<Array<AgSearchSelectOption>>([]);
  const articleOptions = ref<Array<AgSearchSelectOption>>([]);
  const articleGroupOptions = ref<Array<AgSearchSelectOption>>([]);

  const titleError = ref<boolean>(false);
  const titleErrorText = ref<string>('');
  const fileNameError = ref<boolean>(false);
  const fileNameErrorText = ref<string>('');

  const openFileDialog = () => {
    resetValidation();
    fileInput.value?.click();
  };

  const handleFileChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const files = target.files;

    if (files && files.length > 0 && download.value) {
      const file = files[0];
      download.value.fileName = file.name;
    }
  };

  const resetValidation = function () {
    titleError.value = false;
    titleErrorText.value = '';
    fileNameError.value = false;
    fileNameErrorText.value = '';
  };

  const downloadId = computed(() => {
    const dwnlId = route.params.downloadId;
    if (Array.isArray(dwnlId)) {
      return parseInt(dwnlId[0]);
    }
    return parseInt(dwnlId);
  });

  const saveDownload = async () => {
    resetValidation();
    if (!download.value || !download.value.title || download.value.title.length === 0) {
      titleError.value = true;
      titleErrorText.value = t('admin.titleRequired');
    }
    if (!download.value || !download.value.fileName || download.value.fileName.length === 0) {
      fileNameError.value = true;
      fileNameErrorText.value = t('admin.fileNameRequired');
    }
    if (!download.value || !download.value.fileName.includes('.pdf')) {
      fileNameError.value = true;
      fileNameErrorText.value = t('admin.fileNotSupported');
    }

    if (download.value && !titleError.value && !fileNameError.value) {
      download.value.fileName = download.value.fileName.replace(/\s/g, '');
      console.log('FILE NAME: ', download.value.fileName);
      fileInput.value?.files?.[0]?.name.replace(/\s/g, '');
      await updateData(download.value, fileInput.value?.files?.[0] ?? null);
      try {
        await router.push({
          path: '/admin/downloads',
        });
      } catch (error) {
        console.error('Failed to navigate to downloads:', error);
      }
    }
  };

  const openFile = function () {
    if (fileInput.value && fileInput.value.files && fileInput.value.files[0]) {
      const fileURL = URL.createObjectURL(fileInput.value.files[0]);
      window.open(fileURL, '_blank');
    } else if (loadedFile.value) {
      const fileURL = URL.createObjectURL(loadedFile.value);
      window.open(fileURL, '_blank');
    }
  };

  const deleteCurrentDonwload = async () => {
    if (download.value) {
      await deleteData(download.value.id);
      try {
        await router.push({
          path: '/admin/downloads',
        });
      } catch (error) {
        console.error('Failed to navigate to downloads:', error);
      }
    }
  };

  const chargeFile = async function () {
    const blob = await loadFile(download.value?.fileName ?? '');
    if (blob) {
      loadedFile.value = blob;
    }
    isLoadingFile.value = false;
  };

  const update = async function () {
    loadData()
      .then(async (downloads) => {
        download.value = downloads.find((d) => d.id === downloadId.value) || null;
        isLoading.value = false;
        chargeFile();
      })
      .catch((error) => {
        console.log('Error loading download: ', error);
        isLoading.value = false;
      });

    const options: Array<AgFormSelectOption> = [];
    Object.values(config.company.availableLanguages).forEach((lang) => {
      options.push({
        key: lang,
        label: lang,
      });
    });

    getDownloadCategoryList().then((categories) => {
      categories.forEach((category) => {
        categoryOptions.value.push({
          label: getTranslatedText(category.title),
          value: category.id,
          searchableString: getTranslatedText(category.title),
        });
      });
    });
    languageSelectOptions.value = options;
  };

  onMounted(async () => {
    update();
    const permissionGroupsData = await getPermissionGroups();

    permissionGroupOptions.value = permissionGroupsData.map((group) => ({
      label: group.code,
      value: group.id,
      searchableString: group.code,
    }));
    permissionGroupOptions.value.unshift({ label: 'admin.noChoice', value: -1, searchableString: 'admin.noChoice' });

    const articlesData = await getArticles();

    articleOptions.value = articlesData.map((article) => ({
      label: `${article.code} / ${getTranslatedText(article.title)}`,
      value: article.id,
      searchableString: `${article.code} / ${getTranslatedText(article.title)}`,
    }));
    articleOptions.value.unshift({ label: 'admin.noChoice', value: -1, searchableString: 'admin.noChoice' });

    const articleGroupsData = await getArticleGroups();

    articleGroupOptions.value = articleGroupsData.map((group) => ({
      label: `${group.code} / ${getTranslatedText(group.title)}`,
      value: group.id,
      searchableString: `${group.code} / ${getTranslatedText(group.title)}`,
    }));
    articleGroupOptions.value.unshift({ label: 'admin.noChoice', value: -1, searchableString: 'admin.noChoice' });
  });
</script>

<style scoped lang="scss">
  .infoboxTitle {
    @apply text-primary-light;
  }
  .infoBox {
    @apply my-12;
  }

  .table {
    @apply grid grid-cols-1 py-16 space-x-0;
  }

  .tableColumn {
    @apply flex items-center min-w-0 py-4 gap-4;
  }

  .content {
    margin-top: 16px;
  }

  .contentDownloads {
    margin-top: 16px;
  }
</style>
