import { MasterData } from '@/composables/data/local-database/tables/masterData';
import { UserDatabase } from '@/composables/data/local-database/tables/userData';

export function useLocalDatabase() {
  const db = {
    masterData: new MasterData(),
    userData: new UserDatabase(),
  };

  const clearUserData = async function () {
    const dbName = db.userData.name;

    try {
      await db.userData.delete();
      console.log(`Database ${dbName} successfully deleted!!!`);

      await db.userData.open();
      console.log(`Database ${dbName} successfully opened!!!`);
    } catch (error) {
      console.error('Could not delete/open database', error);
    }
  };

  const clearMasterData = async function () {
    const dbName = db.masterData.name;

    try {
      await db.masterData.delete();
      console.log(`Database ${dbName} successfully deleted!!!`);

      await db.masterData.open();
      console.log(`Database ${dbName} successfully opened!!!`);
    } catch (error) {
      console.error(`Could not delete/open database ${dbName}`, error);
    }
  };

  return {
    clearUserData,
    clearMasterData,
    masterData: db.masterData,
    userData: db.userData,
  };
}
