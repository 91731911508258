import { useApi } from '@/composables/api/useApi';
import type { ContainerDto } from '@/domain/data/ContainerDto';
import { useError } from '@/util/useError';
import { ref } from 'vue';

export function useMessageCount() {
  const { handleCatchLocalError, handleCatchServerError } = useError();
  const api = useApi();

  // /communication/countNewCommunications?app_version=2023.1.4512813617
  const getCountNewMessages = async function () {
    const newCommunicationsCount = ref<number>(0);
    try {
      const params = {
        app_version: '2023.1.4512813617',
      };
      const requestUri = 'countNewCommunications?app_version=2023.1.4512813617';
      const container = await api.fetch<ContainerDto>(requestUri, 'GET');

      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return null;
      }

      if (container.data.communicationNumber) {
        newCommunicationsCount.value = container.data.communicationNumber.newCommunications;
      }
    } catch (error) {
      handleCatchLocalError(error);
      return null;
    }
    return newCommunicationsCount.value;
  };

  return {
    getCountNewMessages,
  };
}
