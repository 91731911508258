<template>
  <div>
    <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
      <IcLoading />
    </div>
    <div v-else>
      <TheAdminSubHeader @save="createNewUser" hasNotDeleteButton />

      <div class="gap-32 md:mx-[80px]">
        <div>
          <span class="infoboxTitle">{{ t('admin.name').toUpperCase() }}</span>
          <AgFormInput v-model="user.name" class="w-256" @input="resetValidation" />
        </div>
        <div>
          <span class="infoboxTitle">{{ t('admin.code').toUpperCase() }}</span>
          <AgFormInput
            v-model="user.code"
            class="w-256"
            @input="resetValidation"
            :validation-state="!codeError"
            :error-message="codeErrorText"
          />
        </div>
        <div>
          <span class="infoboxTitle">{{ t('admin.email').toUpperCase() }}</span>
          <AgFormInput
            v-model="user.email"
            class="w-256"
            @input="resetValidation"
            :validation-state="!emailError"
            :error-message="emailErrorText"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { AuthUserDto } from '@/admin/domain/AuthUserDto';

  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';

  import TheAdminSubHeader from '@/components/framework/admin/the-admin-sub-header/TheAdminSubHeader.vue';
  import IcCheck from '@/components/icons/IcCheck.vue';
  import IcClose from '@/components/icons/IcClose.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import useUsers from '../composables/useUsers';

  const { loadUsers, createData } = useUsers();

  const { t } = i18n.global;
  const router = useRouter();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.newUser');

  const codeError = ref<boolean>(false);
  const codeErrorText = ref<string>('');
  const emailError = ref<boolean>(false);
  const emailErrorText = ref<string>('');
  const isLoading = ref<boolean>(false);
  const users = ref<Array<AuthUserDto>>([]);

  const user = ref<AuthUserDto>({
    id: -1,
    code: '',
    active: false,
    email: '',
    endOfPassword: '',
    name: '',
    surname: '',
    username: '',
    isEmployee: false,
    workingForAgentId: null,
    modifyTimeStamp: 0,
  });

  const activeIcon = computed(() => {
    return user.value.active ? IcCheck : IcClose;
  });

  const emailRegexp = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const resetValidation = function () {
    codeError.value = false;
    codeErrorText.value = '';
    emailError.value = false;
    emailErrorText.value = '';
  };

  const createNewUser = async function () {
    resetValidation();

    if (!user.value.code) {
      codeErrorText.value = t('admin.codeRequired');
      codeError.value = true;
    }

    if (!user.value.email || user.value.email.length === 0) {
      emailError.value = true;
      emailErrorText.value = t('admin.emailRequired');
    } else if (!emailRegexp.test(user.value.email ?? '')) {
      emailError.value = true;
      emailErrorText.value = t('admin.invalidEmail');
    }

    const emailAlreadyExists =
      users.value.some((u) => user.value.email === u.email && user.value.id !== u.id) ||
      users.value.some((u) => u.email === user.value.email && u.id !== user.value.id);

    if (emailAlreadyExists) {
      emailError.value = true;
      emailErrorText.value = t('admin.emailAlreadyExists');
    }

    const codeAlreadyExists = users.value.some(
      (u) => (u.code ?? '').toLowerCase() === user.value.code.toLowerCase() && u.id !== user.value.id
    );

    if (codeAlreadyExists) {
      codeErrorText.value = t('admin.codeAlreadyExists');
      codeError.value = true;
    }

    if (!codeError.value && !emailError.value) {
      await sendRequestForCreate();
    }
  };

  const sendRequestForCreate = async function () {
    isLoading.value = true;
    await createData(user.value);
    try {
      await router.push({
        path: '/admin/users',
      });
    } catch (error) {
      console.error('Failed to navigate to users:', error);
    }
    isLoading.value = false;
  };

  onMounted(async () => {
    users.value = await loadUsers();
  });
</script>

<style scoped lang="scss">
  .infoboxTitle {
    @apply text-primary-light;
  }
  .infoBox {
    @apply w-1/2 justify-center my-12 max-w-768;
  }

  .table {
    @apply grid grid-cols-1 py-16 space-x-0;
  }

  .tableColumn {
    @apply flex items-center min-w-0 py-4 gap-4;
  }

  .userbox {
    @apply w-1/2 justify-center my-12;
  }
</style>
