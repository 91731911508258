<template>
  <div class="border-b border-b-neutral-200 bg-neutral-200 pb-8 flex justify-between md:px-[80px]">
    <AgButton variant="ghost" @click="router.back">
      <template #icon>
        <IcChevronBack class="h-32 w-32" />
      </template>
    </AgButton>
    <div class="flex gap-x-32">
      <AgButton v-if="!props.hasNotSaveButton" variant="ghost" @click="emit('save')">
        <template #icon>
          <IcSave />
        </template>
        <span class="text-xl"> {{ t('admin.save') }}</span>
      </AgButton>
      <AgButton
        v-if="!props.hasNotDeleteButton"
        class="pl-12"
        variant="ghost"
        @click="emit('delete')"
        :disabled="props.disableDelete"
        :class="{ 'text-neutral-550': props.disableDelete }"
      >
        <template #icon>
          <IcDelete class="text-xl" />
        </template>
        <span class="text-xl">{{ t('admin.delete') }}</span>
      </AgButton>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IcChevronBack from '@/components/icons/IcChevronBack.vue';
  import IcDelete from '@/components/icons/IcDelete.vue';
  import IcSave from '@/components/icons/IcSave.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import { i18n } from '@/plugins/i18n';
  import { router } from '@/plugins/router';

  interface Props {
    hasNotSaveButton?: boolean;
    hasNotDeleteButton?: boolean;
    disableDelete?: boolean;
  }

  const props = defineProps<Props>();

  const emit = defineEmits(['save', 'delete']);

  const { t } = i18n.global;
</script>
