<template>
  <WizardNavigation
    v-model="internalValue"
    :steps="steps"
    :validation-states="cpValidationStates"
    :disableStepsExpectCart="isDisabled"
  />
</template>

<script setup lang="ts">
  import WizardNavigation from '@/components/library/wizard-navigation/WizardNavigation.vue';
  import { useOrderData } from '@/composables/data/useOrderData';

  import { useOrderWizardStore } from '@/modules/orders/stores/useOrderWizardStore';
  import { storeToRefs } from 'pinia';
  import { computed, ref, watchEffect } from 'vue';
  import { useOrderWizardNavigation } from './useOrderWizardNavigation';

  const orderStore = useOrderWizardStore();
  const { syncOrders } = useOrderData();
  const { isDisabled } = useOrderWizardNavigation();

  const { validationStates } = storeToRefs(orderStore);

  interface Props {
    modelValue: string;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:modelValue']);

  const cpValidationStates = ref<Array<boolean>>([]);

  const steps = ref([
    {
      id: 'customer',
      position: 0,
      labelKey: `orders.customerWizard`,
    },
    {
      id: 'address',
      position: 1,
      labelKey: `orders.addressWizard`,
    },
    {
      id: 'shopping-cart',
      position: 2,
      labelKey: `orders.shoppingCartWizard`,
    },
    {
      id: 'order-data',
      position: 3,
      labelKey: `orders.orderDetailsWizard`,
    },
    {
      id: 'notes',
      position: 4,
      labelKey: `orders.notesWizard`,
    },
    {
      id: 'review',
      position: 5,
      labelKey: `orders.reviewWizard`,
    },
  ]);

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => {
      syncOrders();
      emit('update:modelValue', newValue);
    },
  });

  watchEffect(() => (cpValidationStates.value = validationStates.value));
</script>
