<template>
  <div>
    <TheAdminSubHeader @save="createNewPaymentMethod" hasNotDeleteButton />
    <div class="md:mx-[80px]">
      <div class="flex gap-32">
        <div class="infoBox">
          <div class="infoboxTitle">{{ t('admin.code').toUpperCase() }}</div>
          <div>
            <AgFormInput
              v-model="paymentMethod.code"
              class="w-256"
              @input="resetValidation"
              :validation-state="!codeError"
              :error-message="codeErrorText"
            />
          </div>
        </div>

        <div class="infoBox">
          <span class="infoboxTitle">{{ t('admin.predefined').toUpperCase() }}</span>
          <div>
            <AgButton
              variant="ghost"
              class="h-24 w-24 flex items-center border-2"
              :class="{ 'border-success': !defaultError, 'border-infoRed': defaultError }"
              @click="toggleDefault"
            >
              <template #icon>
                <IcCheck v-if="paymentMethod.default" class="pr-4" />
              </template>
            </AgButton>
            <AgAlert v-if="defaultError" variant="danger" class="w-256">
              {{ t('admin.paymentMethodStandardAlreadyChecked') }}
            </AgAlert>
          </div>
        </div>

        <div class="infoBox">
          <div class="infoboxTitle">{{ t('admin.ibanRequired').toUpperCase() }}</div>
          <AgButton
            variant="ghost"
            class="h-24 w-24 flex items-center border-2 border-success"
            @click="toggleReceiptDateRequired"
          >
            <template #icon>
              <IcCheck v-if="paymentMethod.ibanRequired" class="pr-4" />
            </template>
          </AgButton>
        </div>
      </div>

      <div class="table">
        <span class="text-primary-light">{{ t('admin.languageDescription').toUpperCase() }}</span>
        <div v-for="selectedLang in Object.values(config.company.availableLanguages)" :key="selectedLang">
          <div class="tableColumn">
            <AgFormInput :placeholder="selectedLang" disabled />
            <AgFormInput
              v-model="paymentMethod.title[selectedLang]"
              :validation-state="hasDescValue(selectedLang)"
              @input="descriptionError = false"
            />
          </div>
        </div>
        <AgAlert v-if="descriptionError" variant="danger" dismissible>
          {{ t('admin.descriptionRequired') }}
        </AgAlert>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { PaymentMethodDto } from '@/admin/domain/PaymentMethodDto';
  import IcCheck from '@/components/icons/IcCheck.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { config } from '@/config/config';

  import TheAdminSubHeader from '@/components/framework/admin/the-admin-sub-header/TheAdminSubHeader.vue';
  import { i18n } from '@/plugins/i18n';
  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { usePaymentMethods } from '../composables/usePaymentMethods';

  const { createData, loadData } = usePaymentMethods();

  const { t } = i18n.global;
  const router = useRouter();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.newPaymentMethod');

  const defaultError = ref<boolean>(false);
  const codeError = ref<boolean>(false);
  const codeErrorText = ref<string>('');
  const isLoading = ref<boolean>(false);
  const paymentMethods = ref<Array<PaymentMethodDto>>([]);
  const descriptionError = ref<boolean>(false);

  const paymentMethod = ref<PaymentMethodDto>({
    code: '',
    default: false,
    ibanRequired: false,
    title: {},
    id: -1,
  });

  const hasDescValue = function (lang: string) {
    if (!paymentMethod.value) {
      return false;
    }
    if (!descriptionError.value) {
      return true;
    }
    if (!Object.keys(paymentMethod.value.title).includes(lang)) {
      return false;
    }
    return paymentMethod.value.title[lang].length > 0;
  };

  const toggleDefault = function () {
    resetValidation();
    if (paymentMethod.value) {
      paymentMethod.value.default = !paymentMethod.value.default;
    }
  };

  const toggleReceiptDateRequired = function () {
    if (paymentMethod.value) {
      paymentMethod.value.ibanRequired = !paymentMethod.value.ibanRequired;
    }
  };

  const resetValidation = function () {
    codeError.value = false;
    codeErrorText.value = '';
    defaultError.value = false;
  };

  const createNewPaymentMethod = async function () {
    resetValidation();

    if (paymentMethod.value?.default) {
      if (paymentMethods.value.some((method) => method.default && method.id !== paymentMethod.value?.id)) {
        defaultError.value = true;
      }
    }

    if (paymentMethod.value?.code.length == 0) {
      codeError.value = true;
      codeErrorText.value = t('admin.codeRequired');
    }

    if (
      paymentMethods.value.some(
        (method) =>
          method.code.toLowerCase() === paymentMethod.value?.code.toLowerCase() && method.id !== paymentMethod.value?.id
      )
    ) {
      codeError.value = true;
      codeErrorText.value = t('admin.codeAlreadyExists');
    }

    if (Object.keys(paymentMethod.value?.title ?? '').length === 0) {
      descriptionError.value = true;
    }
    if (Object.values(paymentMethod.value?.title ?? '').every((value) => value.length === 0)) {
      descriptionError.value = true;
    }

    if (!defaultError.value && !codeError.value && !descriptionError.value) {
      sendRequestForCreate();
    }
  };

  const sendRequestForCreate = async function () {
    isLoading.value = true;
    await createData(paymentMethod.value);
    try {
      await router.push({
        path: '/admin/payment-methods',
      });
    } catch (error) {
      console.error('Failed to navigate to payment method:', error);
    }
    isLoading.value = false;
  };

  onMounted(async () => {
    paymentMethods.value = await loadData();
  });
</script>

<style scoped lang="scss">
  .infoboxTitle {
    @apply text-primary-light;
  }
  .infoBox {
    @apply w-1/2 justify-center my-12 max-w-768;
  }

  .table {
    @apply grid grid-cols-1 py-16 space-x-0;
  }

  .tableColumn {
    @apply flex items-center min-w-0 py-4 gap-4;
  }
</style>
