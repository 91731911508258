<template>
  <div class="md:px-[80px] max-md:px-16 py-12">
    <div class="flex w-full items-center justify-between gap-32">
      <AgFormGroup :label="t('orders.search')" class="w-full">
        <AgSearchInput v-model="internalOrderFilter" />
      </AgFormGroup>
      <AgFormGroup :label="t('orders.status')" class="w-full">
        <AgFormSelect v-model="internalOrderStatus" :options="options" />
      </AgFormGroup>
      <AgFormGroup :label="t('orders.customer')" class="w-full">
        <AgSearchSelect v-model="internalCustomerId" :options="customerOptions" />
      </AgFormGroup>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';

  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import AgSearchInput from '@/components/library/search-input/AgSearchInput.vue';

  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import { useMasterData } from '@/composables/data/useMasterData';
  import { OrderStatus } from '@/domain/enumeration/OrderStatus';
  import type { CustomerDto } from '@/domain/masterData/CustomerDto';
  import { i18n } from '@/plugins/i18n';
  import { useHierarchyEmployeeStore } from '@/store/useHierarchyEmployeeStore';
  import { storeToRefs } from 'pinia';
  import { computed, onBeforeMount, ref } from 'vue';

  const { t } = i18n.global;
  const store = useHierarchyEmployeeStore();
  const { tables } = useMasterData();

  interface Props {
    orderFilter: string;
    orderStatus: OrderStatus;
    customerId: number;
  }
  const props = withDefaults(defineProps<Props>(), {
    orderFilter: '',
    orderStatus: OrderStatus.DRAFT,
  });

  const emit = defineEmits(['update:orderFilter', 'update:orderStatus', 'update:customerId']);

  const { agentId } = storeToRefs(store);
  const customers = ref<Array<CustomerDto>>([]);
  const options = ref<Array<AgFormSelectOption>>([
    { key: OrderStatus.DRAFT, label: 'orders.draft' },
    { key: OrderStatus.COMPLETED, label: 'orders.completed' },
    { key: OrderStatus.ALL, label: 'orders.all' },
  ]);

  const customerOptions = computed((): Array<AgSearchSelectOption> => {
    let customersFiltered = customers.value;
    if (agentId.value && agentId.value !== -1) {
      customersFiltered = customers.value.filter((c) => c.agentId === agentId.value);
    }
    const opts = customersFiltered.map((c) => {
      const label: string[] = [c.code];
      if (c.searchName) {
        label.push(c.searchName);
      }
      label.push(c.name);

      return {
        value: c.id,
        label: label.join(' / '),
        searchableString: c.code + c.name + c.searchName,
      };
    });
    opts.unshift({ value: -1, label: t('orders.all'), searchableString: t('orders.all') });
    return opts;
  });

  const internalOrderFilter = computed({
    get: () => props.orderFilter,
    set: (value) => emit('update:orderFilter', value),
  });

  const internalOrderStatus = computed({
    get: () => props.orderStatus,
    set: (value) => emit('update:orderStatus', value),
  });

  const internalCustomerId = computed({
    get: () => props.customerId,
    set: (value) => emit('update:customerId', value),
  });

  onBeforeMount(async () => {
    customers.value = await tables.customers.toArray();
  });
</script>
