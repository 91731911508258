<template>
  <TheAdminSubHeader hasNotSaveButton hasNotDeleteButton />
  <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
    <IcLoading />
  </div>
  <div v-else-if="currentOffer" class="md:mx-[80px]">
    <div>
      <div class="py-12">
        <div class="text-primary text-xl font-bold">{{ t('admin.agent').toUpperCase() }}</div>
        <div class="font-bold">{{ currentOffer.agentName }}</div>
      </div>
      <div class="py-12">
        <div class="text-primary font-bold">{{ t('admin.offerNumber').toUpperCase() }}</div>
        <div class="name">{{ offerNumber(currentOffer) }}</div>
      </div>
      <div class="py-12">
        <div class="text-primary font-bold">{{ t('admin.offerDate').toUpperCase() }}</div>
        <div class="name">{{ offerDate(currentOffer) }}</div>
      </div>
      <div class="py-12">
        <div class="text-primary font-bold">{{ t('admin.customerName').toUpperCase() }}</div>
        <div class="name">{{ currentOffer.customer.name }}</div>
      </div>
      <div class="py-12">
        <div class="text-primary font-bold">{{ t('admin.status').toUpperCase() }}</div>
        <div class="name">{{ offerstatus(currentOffer.status) }}</div>
      </div>
      <PhotoVisualizer :orderOffer="currentOffer" />
    </div>
  </div>
  <div v-else>
    <AgAlert variant="info">{{ t('admin.offerNotFound') }}</AgAlert>
  </div>
</template>

<script setup lang="ts">
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';

  import TheAdminSubHeader from '@/components/framework/admin/the-admin-sub-header/TheAdminSubHeader.vue';
  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { OfferStatus } from '@/domain/enumeration/OfferStatus';
  import type { OfferDto } from '@/domain/offerData/OfferDto';
  import { i18n } from '@/plugins/i18n';
  import { onMounted, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import PhotoVisualizer from '../components/PhotoVisualizer.vue';
  import { usePhotos } from '../composables/usePhotos';

  const { t } = i18n.global;
  const route = useRoute();
  const { loadOffers } = usePhotos();
  const { formatDate } = useDateFormatting();
  const theHeader = useTheHeader();

  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.offerDetails');

  const offers = ref<Array<OfferDto>>([]);
  const isLoading = ref<boolean>(true);
  const currentOffer = ref<OfferDto>();

  const setCurrentItem = () => {
    currentOffer.value = offers.value.find((o) => o.gid === route.params.gid);
  };

  const offerNumber = function (offer: OfferDto) {
    if (offer.status === OfferStatus.COMPLETED) {
      return offer.offerNumber;
    }
    return offer.gid;
  };

  const offerDate = function (offer: OfferDto) {
    if (offer.status === OfferStatus.COMPLETED) {
      return formatDate(offer.offerDate);
    }
    return formatDate(offer.createdAt);
  };

  const offerstatus = function (status: number) {
    if (status === OfferStatus.COMPLETED) {
      return t('admin.completed');
    }
    return t('admin.draft');
  };

  onMounted(async () => {
    offers.value = await loadOffers();
    setCurrentItem();
    isLoading.value = false;
  });
</script>
