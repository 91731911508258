export function Utilities() {
  const escapedRegExp = function (str: string) {
    return new RegExp(str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi');
  };

  enum SerchType {
    NORMAL = 0,
    OR = 1,
    AND = 2,
  }

  const escapedRegExpTwo = function (pattern: string) {
    const pt = pattern.trim();
    const words: Array<string> = [];

    let sType: SerchType = SerchType.NORMAL;

    if (pt.split(/\|\|/).length === 2) {
      sType = SerchType.OR;
      words.push(...pt.split(/\|\|/).filter((word) => word));
    } else if (pt.split(/\&\&/).length === 2) {
      sType = SerchType.AND;
      words.push(...pt.split(/\&\&/).filter((word) => word));
    } else {
      sType = SerchType.NORMAL;
      words.push(pt);
    }
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].trim().replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }
    if (sType === 2 && words.length === 2) {
      const pattern = '.*' + words.join('.*') + '|' + words.reverse().join('.*');
      return new RegExp(pattern, 'gi');
    }
    return new RegExp(words.join('|'), 'gi');
  };

  const escapedRegExpThree = function (pattern: string) {
    const pt = pattern.trim();
    const words: Array<string> = [];

    let sType: SerchType = SerchType.NORMAL;

    if (pt.split(/\|\|/).length === 2) {
      sType = SerchType.OR;
      words.push(...pt.split(/\|\|/).filter((word) => word));
    } else if (pt.split(/\&\&/).length === 2) {
      sType = SerchType.AND;
      words.push(...pt.split(/\&\&/).filter((word) => word));
    } else {
      sType = SerchType.NORMAL;
      words.push(pt);
    }
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].trim().replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }
    return new RegExp(words.join('|'), 'gi');
  };

  const timeToDate = function (time: string | null, locale: string) {
    if (time) {
      const date = new Date();
      const parts = time.match(/(\d+)\:(\d+) (\w+)/) || time.match(/(\d+)\:(\d+)/);

      if (parts) {
        let hours = parseInt(parts[1]);
        if (parts.length === 4) {
          hours = /am/i.test(parts[3]) ? parseInt(parts[1]) : parseInt(parts[1]) + 12;
        }
        const minutes = parseInt(parts[2]);

        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(0);

        return date.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' });
      }
    }
    return null;
  };

  const csvExport = function (data: Array<string>, header: Array<string>, fileName?: string) {
    const csv = `sep=;\n${header.join(';')}\n${data.join('\n')}`;

    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const today = Date.now();

    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName ? fileName : `agent2go_export_${today}.csv`);
    link.click();

    window.URL.revokeObjectURL(url);
  };

  return {
    escapedRegExp,
    escapedRegExpTwo,
    escapedRegExpThree,
    timeToDate,
    csvExport,
  };
}
