import authAdminRequired from '@/middleware/authAdminRequired';
import type { RouteRecordRaw } from 'vue-router';
import CustomerDetailPage from './pages/CustomerDetailPage.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: ':customerId/',
    name: '/customer',
    component: CustomerDetailPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
];
export default routes;
