import type { ContainerDto } from '@/admin/domain/data/ContainerDto';
import type { HierarchyLevelDto } from '@/admin/domain/HierarchyLevelDto';
import type { InsertHierarchyDto } from '@/admin/domain/InsertHierarchyDto';
import { useApi } from '@/composables/api/useApi';
import type { UserHierarchyDto } from '@/domain/UserHierarchyDto';
import { useError } from '@/util/useError';
import { cloneDeep } from 'lodash';

export function useHierarchies() {
  const api = useApi();
  const { handleCatchServerError, handleCatchLocalError } = useError();

  const loadData = async function (): Promise<Array<UserHierarchyDto>> {
    try {
      const requestUri = '/adminapi/hierarchy';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.hierarchies) {
        return container.data.hierarchies;
      }
      return [];
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  const loadLevels = async function (): Promise<Array<HierarchyLevelDto>> {
    try {
      const requestUri = '/adminapi/hierarchy/levels';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.hierarchyLevels) {
        return container.data.hierarchyLevels;
      }
      return [];
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  const createLevel = async function (level: HierarchyLevelDto) {
    try {
      const requestUri = '/adminapi/hierarchy/level';

      const container = await api.fetch<ContainerDto>(requestUri, 'POST', level, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const addAgentToHierarchy = async function (
    hierarchyId: number,
    userId: number,
    levelId: number,
    description: string
  ) {
    try {
      const requestUri = '/adminapi/hierarchy/addAgent';

      const formData = new FormData();
      formData.append('hierarchyId', hierarchyId.toString());
      formData.append('agentId', userId.toString());
      formData.append('levelId', levelId.toString());
      formData.append('description', description.toString());

      const container = await api.fetch<ContainerDto>(requestUri, 'POST', formData, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const updateLevel = async function (level: HierarchyLevelDto) {
    try {
      const requestUri = '/adminapi/hierarchy/level';

      const container = await api.fetch<ContainerDto>(requestUri, 'PATCH', level, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const truncateAgentHierarchy = async function (hierarchyId: number) {
    try {
      const requestUri = `/adminapi/hierarchy/${hierarchyId}`;

      const container = await api.fetch<ContainerDto>(requestUri, 'DELETE', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const createHierarchy = async function (hierarchy: InsertHierarchyDto) {
    try {
      const requestUri = '/adminapi/hierarchy/createHierarchy';

      const container = await api.fetch<ContainerDto>(requestUri, 'POST', hierarchy, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const deleteLevel = async function (levelId: number) {
    try {
      const requestUri = `/adminapi/hierarchy/deleteLevel/${levelId}`;

      const container = await api.fetch<ContainerDto>(requestUri, 'DELETE', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const fromHierarchyToInsertHierarchy = function (hierarchy: UserHierarchyDto): InsertHierarchyDto {
    const newHierarchy: InsertHierarchyDto = {
      authUserId: hierarchy.authUser.id,
      authUserName: hierarchy.authUser.name,
      authUserLevelId: hierarchy.authUserLevel.id,
      parentAuthUserId: hierarchy.parentUser ? hierarchy.parentUser.authUser.id : null,
      description: hierarchy.description,
      children: cloneDeep(hierarchy.children).map((child) => fromHierarchyToInsertHierarchy(child)) || null,
    };
    return newHierarchy;
  };

  return {
    loadData,
    loadLevels,
    createLevel,
    updateLevel,
    addAgentToHierarchy,
    truncateAgentHierarchy,
    createHierarchy,
    deleteLevel,
    fromHierarchyToInsertHierarchy,
  };
}
