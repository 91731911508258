import type { UserHierarchyDto } from '@/domain/UserHierarchyDto';
import type { ContainerDto } from '@/domain/data/ContainerDto';

import { useApi } from '@/composables/api/useApi';
import { useLocalDatabase } from '@/composables/data/local-database/useLocalDatabase';
import { useError } from '@/util/useError';

export function useUserData() {
  const db = useLocalDatabase();
  const api = useApi();

  const { handleCatchLocalError, handleCatchServerError } = useError();

  const syncUserHierarchy = async function () {
    try {
      const responseContainer = await api.fetch<ContainerDto>('/userhierarchy');
      if (responseContainer.errors && responseContainer.errors.length > 0) {
        handleCatchServerError(responseContainer.errors);
        return;
      }
      if (responseContainer.data.userHierarchy) {
        await updateUserHierarchy(responseContainer.data.userHierarchy);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const updateUserHierarchy = async function (data: Array<UserHierarchyDto>) {
    await db.userData.userHierarchy.clear();
    await db.userData.userHierarchy.bulkPut(data);
  };

  return {
    syncUserHierarchy,
    tables: { hierarchy: db.userData.userHierarchy },
  };
}
