import type { OfferDto } from '@/domain/offerData/OfferDto';

import { useMasterData } from '@/composables/data/useMasterData';
import { useCommon } from '@/composables/useCommon';
import { CustomerType } from '@/domain/enumeration/CustomerType';
import { OfferStatus } from '@/domain/enumeration/OfferStatus';
import type { DiscountDto } from '@/domain/masterData/DiscountDto';
import { useOffers } from '@/modules/offers/composables/useOffers';
import type { Optional } from '@/util/Optional';
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useOfferWizardNavigation } from '../components/offer-wizard/navigation/useOfferWizardNavigation';
import { useOfferFactory } from '../composables/useOfferFactory';

export const useOfferWizardStore = defineStore('offer-wizard', () => {
  const { getTradeAgreementPriceAndDisconts, getCustomer, getFinalPrice, getUnitPrice } = useOfferFactory();
  const { disableTabsExceptShoppingCart } = useOfferWizardNavigation();
  const common = useCommon();

  const theOffer = ref<OfferDto | null>(null);
  const theNewOffer = ref<OfferDto | null>(null);
  const hasOfferBeenChanged = ref<boolean>(false);

  const isCustomerBlocked = ref<boolean>(false);
  const validationStates = ref<Array<boolean>>([true, true, true, true, true, true]);
  const wizzardAddressOrrigin = ref<{ [key: string]: string }>({});

  const masterData = useMasterData();
  const offers = useOffers();

  watch(
    theOffer,
    async (newValue, oldValue) => {
      if (newValue && oldValue) {
        const copy = cloneDeep(newValue);
        copy.synced = 0;

        await offers.saveOffer(copy);

        isCustomerBlocked.value = await isBlocked();
      }
    },
    { immediate: true, deep: true }
  );

  const reset = function () {
    theOffer.value = null;
    isCustomerBlocked.value = false;
    theNewOffer.value = null;
    validationStates;
  };

  const loadOfferByGid = async function (gid: string, isCopy: boolean = false) {
    const maybeOffer = await offers.getOfferByGid(gid);
    if (maybeOffer.isPresent()) {
      theOffer.value = maybeOffer.get();
      hasOfferBeenChanged.value = false;
      if (theOffer.value.status !== OfferStatus.COMPLETED || isCopy) {
        theNewOffer.value = cloneDeep(theOffer.value);
        await Promise.all(
          // guarantee that all promises are resolved, wait until all promises are resolved(all await have awaited)
          theNewOffer.value.items.map(async (item) => {
            const oldOrderItem = theOffer.value?.items.find(
              (i) => `${i.article.id}${item.packaging.id}` === `${item.article.id}${item.packaging.id}`
            );
            const dimensions = item.article.dimensions ?? [];
            const dimValConfId = dimensions && dimensions.length > 0 ? dimensions[0].value.id : undefined;

            const dbArticle = await masterData.tables.articles.where('id').equals(item.article.id).first();

            const taDiscountLists = await getTradeAgreementPriceAndDisconts(
              theNewOffer.value!,
              item.article.id,
              dbArticle?.priceGroupIds ?? [],
              item.quantity ?? 0,
              dimValConfId
            );

            const tradeAgreement = taDiscountLists.tradeAgreement;
            const discounts = taDiscountLists.discounts;
            const price = taDiscountLists.tradeAgreement ? taDiscountLists.price : 0;
            const isPromotional =
              tradeAgreement && tradeAgreement.customerType === CustomerType.PROMOTION ? true : false;

            if (tradeAgreement) {
              item.tradeAgreement = {
                id: tradeAgreement.id,
                price: price,
                isPromotional: isPromotional,
              };
            } else {
              item.tradeAgreement = {
                id: -1,
                price: 0,
                isPromotional: isPromotional,
              };
            }

            item.finalPrice = getFinalPrice(price, discounts, item.packaging.size, item.quantity ?? 0);
            item.unitPrice = getUnitPrice(price, discounts);

            let recalculateDiscounts = true;
            if (oldOrderItem?.tradeAgreement.price === item.tradeAgreement.price) {
              item.tradeAgreement.editedPrice = oldOrderItem?.tradeAgreement?.editedPrice ?? undefined;
              item.isTradeAgreementPriceEdited = oldOrderItem?.isTradeAgreementPriceEdited ?? false;
              if (oldOrderItem?.isTradeAgreementPriceEdited) {
                item.isSomeDiscountEdited = true;
                item.discount1 = oldOrderItem?.discount1 ?? null;
                item.discount2 = oldOrderItem?.discount2 ?? null;
                item.discount3 = oldOrderItem?.discount3 ?? null;
                item.discount4 = oldOrderItem?.discount4 ?? null;
                item.discount5 = oldOrderItem?.discount5 ?? null;
                item.discount6 = oldOrderItem?.discount6 ?? null;
                recalculateDiscounts = false;
              }
            } else {
              hasOfferBeenChanged.value = true;
            }

            if (recalculateDiscounts) {
              item.discount1 = discounts.length > 0 ? discounts[0] : null;
              item.discount2 = discounts.length > 1 ? discounts[1] : null;
              item.discount3 = discounts.length > 2 ? discounts[2] : null;
              item.discount4 = discounts.length > 3 ? discounts[3] : null;
              item.discount5 = discounts.length > 4 ? discounts[4] : null;
              item.discount6 = discounts.length > 5 ? discounts[5] : null;
            }

            if (
              item.discount1 !== (oldOrderItem?.discount1 ?? null) ||
              item.discount2 !== (oldOrderItem?.discount2 ?? null) ||
              item.discount3 !== (oldOrderItem?.discount3 ?? null) ||
              item.discount4 !== (oldOrderItem?.discount4 ?? null) ||
              item.discount5 !== (oldOrderItem?.discount5 ?? null) ||
              item.discount6 !== (oldOrderItem?.discount6 ?? null)
            ) {
              hasOfferBeenChanged.value = true;
            }
          })
        );

        if (theNewOffer.value.customer) {
          const customer = await getCustomer(theNewOffer.value.customer.code);
          if (customer) {
            const discount: Optional<DiscountDto> = await common.getHeadDiscount(customer.id);
            theNewOffer.value.discount = 0;
            if (discount.isPresent() && discount.get().discount1) {
              theNewOffer.value.discount = discount.get().discount1 || 0;
            }
            if (theNewOffer.value.discount !== theOffer.value.discount) {
              hasOfferBeenChanged.value = true;
            }
          }
        }

        if (hasOfferBeenChanged.value) {
          disableTabsExceptShoppingCart();
        }
      }

      isCustomerBlocked.value = await isBlocked();
    } else {
      theOffer.value = null;
      isCustomerBlocked.value = false;
    }
  };

  const isLoaded = computed(() => {
    return theOffer.value !== null;
  });

  const isBlocked = async function () {
    if (!theOffer.value || !theOffer.value.customer.id) {
      return false;
    }
    const id = theOffer.value.customer.id;
    const customer = await masterData.tables.customers.where('id').equals(id).first();
    if (!customer) {
      return true;
    }
    if (!customer.blocked) {
      return customer.salesBlocked;
    } else {
      return customer.blocked;
    }
  };

  return {
    reset,
    validationStates: validationStates,
    offer: theOffer,
    newOffer: theNewOffer,
    hasOfferBeenChanged,
    loadOfferByGid,
    isLoaded,
    isCustomerBlocked,
    wizzardAddressOrrigin,
  };
});
