import { ref } from 'vue';

const refreshNewMessages = ref(false);

export function useNewMessages() {
  return {
    refreshing: (refresh: boolean) => (refreshNewMessages.value = refresh),
    refreshNewMessages,
  };
}
