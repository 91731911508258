<template>
  <div class="py-[40px] overflow-x-auto">
    <div class="mx-auto w-fit">
      <!-- TABLE HEADER PERIODS-->
      <StatisticsByArticleTablePeriodsHeader
        :periodOne="props.periodOne"
        :periodTwo="props.periodTwo"
        :startMonth="props.startMonth"
        :endMonth="props.endMonth"
      />

      <!-- TABLE HEADER -->
      <StatisticsByArticleTableHeader @update:sorting="emitNewValue($event)" />

      <!-- ROWS -->
      <div v-if="props.statistics && props.statistics.length > 0">
        <div v-for="statistic in statistics" :key="statistic.index">
          <StatisticsByArticleTableRow :statistic="statistic" />
        </div>
        <StatisticsByArticleTableRow :statistic="sumStatistic" isSumRow class="bg-neutral-100 font-bold" />
      </div>
      <span>*{{ t('statistics.distinctCustomers') }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { StatisticByArticleDto } from '@/domain/StatisticByArticleDto';
  import type { Sorting } from '@/util/Sorting';

  import StatisticsByArticleTableHeader from '@/modules/statistics/components/by-article/StatisticsByArticleTableHeader.vue';
  import StatisticsByArticleTablePeriodsHeader from '@/modules/statistics/components/by-article/StatisticsByArticleTablePeriodsHeader.vue';
  import StatisticsByArticleTableRow from '@/modules/statistics/components/by-article/StatisticsByArticleTableRow.vue';

  import { i18n } from '@/plugins/i18n';
  import { computed } from 'vue';

  const { t } = i18n.global;

  interface Props {
    statistics: Array<StatisticByArticleDto>;
    periodOne: number;
    periodTwo: number;
    startMonth: number;
    endMonth: number;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:sorting']);

  const sumStatistic = computed(() => {
    const st: StatisticByArticleDto = {
      totalAmountPeriodOne: 0,
      totalQuantityPeriodOne: 0,
      customerCodesPeriodOne: [],
      totalAmountPeriodTwo: 0,
      totalQuantityPeriodTwo: 0,
      customerCodesPeriodTwo: [],
      articleCode: '',
      articleName: '',
      index: 0,
    };
    props.statistics.forEach((stat: StatisticByArticleDto) => {
      st.totalQuantityPeriodOne += stat.totalQuantityPeriodOne;
      st.totalAmountPeriodOne += stat.totalAmountPeriodOne;

      stat.customerCodesPeriodOne.forEach((code) => {
        if (!st.customerCodesPeriodOne.includes(code)) {
          st.customerCodesPeriodOne.push(code);
        }
      });

      st.totalQuantityPeriodTwo += stat.totalQuantityPeriodTwo;
      st.totalAmountPeriodTwo += stat.totalAmountPeriodTwo;
      stat.customerCodesPeriodTwo.forEach((code) => {
        if (!st.customerCodesPeriodTwo.includes(code)) {
          st.customerCodesPeriodTwo.push(code);
        }
      });
    });
    return st;
  });

  const emitNewValue = (value: Sorting) => {
    emit('update:sorting', value);
  };
</script>
