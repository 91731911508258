<template>
  <slot v-if="isEnabled"></slot>
</template>

<script setup lang="ts">
  import { useFeatureFlags } from '@/composables/framework/useFeatureFlags';
  import { EModules } from '@/config/EModules';
  import { computed } from 'vue';

  interface Props {
    featureId: EModules | EModules[];
  }
  const props = defineProps<Props>();

  const { featureEnabled } = useFeatureFlags();

  const isEnabled = computed(() => {
    if (props.featureId instanceof Array) {
      return props.featureId.some((fId) => {
        return featureEnabled(fId);
      });
    }
    return featureEnabled(props.featureId);
  });
</script>
