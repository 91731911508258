<template>
  <div>
    <MessageRow
      v-for="message in props.messages"
      :key="message.id"
      :message="message"
      :class="{ 'font-bold': !message.viewed }"
      @click="markAsViewed(message)"
    />
  </div>
</template>

<script setup lang="ts">
  import { useNewMessages } from '@/composables/useNewMessages';
  import type { CommunicationDto } from '@/domain/CommunicationDto';
  import type { CommunicationNotificationRequest } from '@/domain/internal/CommunicationNotificationRequest';

  import MessageRow from '@/modules/messages/components/MessageRow.vue';

  import { useMessageCount } from '@/modules/messages/composables/useMessageCount';
  import { useMessageStore } from '@/modules/messages/stores/useMessageStore';
  import { ref, watch } from 'vue';

  const store = useMessageStore();
  const { viewedCommunicationNotification } = store;
  const { getCountNewMessages } = useMessageCount();

  const props = defineProps<Props>();
  const { refreshing } = useNewMessages();

  interface Props {
    messages: Array<CommunicationDto>;
  }

  const refreshCount = ref<boolean>(false);
  const newMessagesNumber = ref<number>(0);
  const emit = defineEmits(['update:reload-messages']);

  const markAsViewed = function (message: CommunicationDto) {
    if (!message.viewed) {
      const request: CommunicationNotificationRequest = {
        communicationId: message.id,
      };
      viewedCommunicationNotification(request);
      message.viewed = true;
      refreshing(true);
    }
  };

  const loadNewMessages = async () => {
    newMessagesNumber.value = (await getCountNewMessages()) ?? 0;
    refreshing(true);
  };

  watch(refreshCount, async (newValue) => {
    if (newValue) {
      emit('update:reload-messages');
      await loadNewMessages();
      refreshCount.value = false;
    }
  });
</script>
