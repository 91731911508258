<template>
  <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
    <IcLoading />
  </div>
  <div v-else-if="campaign">
    <TheAdminSubHeader
      @save="saveCampaign"
      @delete="deleteCurrentCampaign"
      :disable-delete="campaign.activitiesNumber > 0"
    />
    <div class="md:mx-[80px]">
      <div class="space-y-8">
        <div class="font-bold text-primary">{{ t('admin.name').toUpperCase() }}</div>
        <AgFormInput
          v-model="campaign.name"
          :validation-state="!nameError"
          :error-message="nameErrorText"
          @update:model-value="resetValidation"
        />
      </div>

      <div class="py-12">
        <span class="font-bold text-primary">{{ t('admin.activities').toUpperCase() }}:&nbsp;</span>
        <span>{{ campaign.activitiesNumber }}</span>
      </div>

      <div class="flex w-full justify-end">
        <AgButton class="text-infoBlue" variant="ghost" @click="goToActivities(campaignId)">
          {{ t('admin.resume') }}
          <template #icon>
            <IcShowDocument />
          </template>
        </AgButton>
      </div>
    </div>
  </div>
  <div v-else>
    <AgAlert variant="info"> {{ t('admin.campaignCategoryNotFound') }} </AgAlert>
  </div>
</template>

<script setup lang="ts">
  import type { CampaignDto } from '@/admin/domain/CampaignDto';

  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgButton from '@/components/library/button/AgButton.vue';

  import { useCampaigns } from '@/admin/modules/campaigns/composables/useCampaigns';
  import TheAdminSubHeader from '@/components/framework/admin/the-admin-sub-header/TheAdminSubHeader.vue';
  import IcShowDocument from '@/components/icons/IcShowDocument.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  const { t } = i18n.global;
  const route = useRoute();
  const router = useRouter();
  const { loadData, updateData, deleteData } = useCampaigns();
  const theHeader = useTheHeader();

  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.campaignDetail');

  const campaign = ref<CampaignDto | null>(null);
  const isLoading = ref<boolean>(true);
  const nameError = ref<boolean>(false);
  const nameErrorText = ref<string>('');

  const campaignId = computed(() => {
    const camId = route.params.campaignId;
    if (Array.isArray(camId)) {
      return parseInt(camId[0]);
    }
    return parseInt(camId);
  });

  const resetValidation = () => {
    nameError.value = false;
    nameErrorText.value = '';
  };

  const saveCampaign = async () => {
    if (!campaign.value || !campaign.value.name || campaign.value.name.length === 0) {
      nameError.value = true;
      nameErrorText.value = t('admin.nameRequired');
      return;
    }
    if (campaign.value && !nameError.value) {
      await updateData(campaign.value);
      try {
        await router.push({
          path: '/admin/checklists/campaigns',
        });
      } catch (error) {
        console.error('Failed to navigate to campaigns:', error);
      }
    }
  };

  const deleteCurrentCampaign = async () => {
    if (campaign.value) {
      await deleteData(campaign.value.id);
      try {
        await router.push({
          path: '/admin/checklists/campaigns',
        });
      } catch (error) {
        console.error('Failed to navigate to campaigns:', error);
      }
    }
  };

  const goToActivities = async (campaignId: number) => {
    try {
      await router.push({
        name: '/campaign/activities',
        params: { campaignId: campaignId },
      });
    } catch (error) {
      console.error('Failed to navigate to campaign activities:', error);
    }
  };

  const update = async function () {
    loadData()
      .then((campaigns) => {
        campaign.value = campaigns.find((cam) => cam.id === campaignId.value) || null;
        isLoading.value = false;
      })
      .catch((error) => {
        console.log('Error loading campaigns: ', error);
        isLoading.value = false;
      });
  };

  onMounted(async () => update());
</script>
