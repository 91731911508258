<template>
  <div>
    <div class="overflow-y-auto">
      <TheSubheader class="flex items-center justify-between">
        <Filter v-model:searchText="searchText" v-model:agentId="agentId" v-model:status="status" />
        <div class="flex items-center justify-end space-x-64 md:mx-[80px] max-md:mx-16">
          <AgButton variant="ghost" iconBeforeText @click="reload">
            <template #icon>
              <IcUpdate />
            </template>
            <div class="text-xl">{{ t('admin.update') }}</div>
          </AgButton>
        </div>
      </TheSubheader>
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <div v-else class="md:mx-[80px] max-md:mx-16 min-w-fit pt-8 h-[calc(100dvh-150px)]">
        <div class="h-full">
          <div class="my-24">
            <AgPaginatorInfo
              :current-page="currentPage"
              :page-size="itemsPerPage"
              :number-items="filteredCustomers.length"
            />
            <AgPaginator v-model="currentPage" :total-pages="pageCount" />
          </div>
          <div class="tableHeader">
            <div class="headerColumn">{{ t('admin.name').toUpperCase() }}</div>
            <div class="headerColumn">{{ t('admin.searchName').toUpperCase() }}</div>
            <div class="headerColumn">{{ t('admin.code').toUpperCase() }}</div>
            <div class="headerColumn">{{ t('admin.agent').toUpperCase() }}</div>
            <div class="headerColumn justify-end">{{ t('admin.salesStatus').toUpperCase() }}</div>
          </div>
          <div v-for="customer in displayedItems" :key="customer.id">
            <div class="table justify-start items-center" @click="viewCustomer(customer.id)">
              <div class="tableColumn">{{ customer.name }}</div>
              <div class="tableColumn">{{ customer.searchName }}</div>
              <div class="tableColumn">{{ customer.code }}</div>
              <div class="tableColumn">{{ customer.agentName }}</div>
              <div class="tableColumn justify-end">
                <span v-if="customer.salesBlocked"> {{ t('admin.blocked') }}</span>
                <span v-else> {{ t('admin.free') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import Filter from '@/admin/modules/customers/components/Filter.vue';
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcUpdate from '@/components/icons/IcUpdate.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';

  import type { CustomerDto } from '@/admin/domain/CustomerDto';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useCustomers } from '../composables/useCustomers';

  const { t } = i18n.global;
  const { loadCustomers } = useCustomers();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.customers');

  const isLoading = ref<boolean>(true);
  const router = useRouter();
  const currentPage = ref<number>(1);
  const itemsPerPage = 10;
  const customers = ref<CustomerDto[]>([]);
  const searchText = ref<string>('');
  const agentId = ref<number>(-1);
  const status = ref<number>(-1);

  const filteredCustomers = computed(() => {
    let filteredCustomers = customers.value;
    if (agentId.value !== -1) {
      filteredCustomers = filteredCustomers.filter((c) => c.agentId === agentId.value);
    }

    if (status.value >= 0) {
      const boolStatusBlocked = status.value === 0 ? false : true;
      filteredCustomers = filteredCustomers.filter((c) => c.salesBlocked === boolStatusBlocked);
    }

    if (searchText.value !== '') {
      filteredCustomers = filteredCustomers.filter(
        (c) =>
          c.name.toLowerCase().includes(searchText.value.toLowerCase()) ||
          c.searchName.toLowerCase().includes(searchText.value.toLowerCase()) ||
          c.code.toLowerCase().includes(searchText.value.toLowerCase())
      );
    }
    return filteredCustomers;
  });

  const pageCount = computed(() => Math.ceil(filteredCustomers.value.length / itemsPerPage));

  const displayedItems = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredCustomers.value.length);
    const itmxpage = filteredCustomers.value.slice(startIndex, endIndex);

    return itmxpage;
  });

  const viewCustomer = async function (customerId: number) {
    await router.push({
      name: '/customer',
      params: { customerId: customerId },
    });
  };

  const reload = async function () {
    isLoading.value = true;
    customers.value = await loadCustomers();
    isLoading.value = false;
  };

  onMounted(async () => await reload());
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid grid-cols-[1fr_1fr_1fr_1fr_200px] grid-flow-col-dense gap-12 py-8 font-light text-neutral-550;
  }

  .headerColumn {
    @apply flex items-center;
    color: #04a040;
    font-weight: bold;
    word-break: break-word;
    overflow-wrap: break-word;
  }

  .table {
    @apply grid grid-cols-[1fr_1fr_1fr_1fr_200px] grid-flow-col-dense gap-12 border-t border-neutral-500 py-8;
  }

  .table:hover {
    @apply bg-neutral-200 cursor-pointer;
  }

  .tableColumn {
    @apply flex items-center;
    word-break: break-word;
    overflow-wrap: break-word;
  }

  @media (max-width: 1024px) {
    .tableHeader,
    .table {
      grid-template-columns: repeat(6, 1fr); /* Adjust grid layout for smaller screens */
      gap: 8px; /* Reduce gap for smaller screens */
    }

    .tableColumn {
      min-width: 0; /* Allow columns to shrink */
    }

    .emailColumn {
      min-width: 0; /* Allow email column to shrink */
    }
  }
</style>
