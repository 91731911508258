<template>
  <div>
    <TheSubheader class="flex items-center justify-between">
      <Filter v-model:searchText="searchText" v-model:agent-id="agentId" />
      <div class="flex items-center justify-end space-x-64 md:mx-[80px] max-md:mx-16">
        <AgButton variant="ghost" iconBeforeText @click="update">
          <template #icon>
            <IcUpdate />
          </template>
          <div class="text-xl">{{ t('admin.update') }}</div>
        </AgButton>

        <AgButton variant="ghost" iconBeforeText @click="newHierarchy">
          <template #icon>
            <IcAdd />
          </template>
          <div class="text-xl">{{ t('admin.create') }}</div>
        </AgButton>
      </div>
    </TheSubheader>

    <div class="overflow-y-auto">
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <div v-else class="md:mx-[80px] max-md:mx-16 min-w-fit pt-8 max-h-[calc(100dvh-160px)]">
        <div class="my-24">
          <AgPaginatorInfo :current-page="currentPage" :page-size="itemsPerPage" :number-items="hierarchies.length" />
          <AgPaginator v-model="currentPage" :total-pages="pageCount" />
        </div>
        <HierarchiesTable :hierarchies="displayedItems" @update:hierarchies="update" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import Filter from '@/admin/modules/hierarchies/components/filter/Filter.vue';
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcAdd from '@/components/icons/IcAdd.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcUpdate from '@/components/icons/IcUpdate.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import type { UserHierarchyDto } from '@/domain/UserHierarchyDto';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import HierarchiesTable from '../components/HierarchiesTable.vue';
  import { useHierarchies } from '../composables/useHierarchies';

  const { t } = i18n.global;
  const { loadData } = useHierarchies();

  const router = useRouter();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.hierarchies');

  const isLoading = ref<boolean>(false);
  const hierarchies = ref<Array<UserHierarchyDto>>([]);
  const searchText = ref<string>('');
  const currentPage = ref<number>(1);
  const itemsPerPage = 10;
  const agentId = ref<number>(-1);
  const filteredHierarchies = ref<Array<UserHierarchyDto>>([]);

  const filterHierarchies = function () {
    if (!searchText.value && agentId.value === -1) {
      filteredHierarchies.value = hierarchies.value;
      return;
    }

    if (agentId.value === -1) {
      filteredHierarchies.value = hierarchies.value.filter((h) =>
        h.description.toLowerCase().includes(searchText.value.toLowerCase())
      );
      return;
    }
    filteredHierarchies.value = hierarchies.value.filter((h) => {
      const matchDesc = h.description.toLowerCase().includes(searchText.value.toLowerCase());
      const matchAgent = isInHierarchy(h, agentId.value);
      return matchDesc && matchAgent;
    });
  };

  const isInHierarchy = function (hierarchy: UserHierarchyDto, agentId: number): boolean {
    if (hierarchy.authUser.id === agentId) {
      return true;
    }
    if (hierarchy.children) {
      return hierarchy.children.some((child) => isInHierarchy(child, agentId));
    }
    return false;
  };

  const pageCount = computed(() => Math.ceil(filteredHierarchies.value.length / itemsPerPage));

  const displayedItems = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredHierarchies.value.length);
    const itmxpage = filteredHierarchies.value.slice(startIndex, endIndex);

    return itmxpage;
  });

  const newHierarchy = async () => {
    try {
      await router.push({
        name: '/new-hierarchy',
      });
    } catch (error) {
      console.error('Failed to navigate to hierarchies:', error);
    }
  };

  const update = async () => {
    isLoading.value = true;
    hierarchies.value = (await loadData()).filter((h) => !h.parentUser);
    filterHierarchies();
    isLoading.value = false;
  };

  watch([agentId, searchText], async () => filterHierarchies(), { immediate: false });

  onMounted(async () => {
    await update();
    filterHierarchies();
  });
</script>
