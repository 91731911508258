<template>
  <div class="py-12 md:mx-[80px] max-md:mx-16">
    <div class="flex flex-row items-center gap-12">
      <AgFormGroup :label="t('processedData.search')" class="w-full">
        <AgSearchInput v-model="internalSearch" />
      </AgFormGroup>

      <AgFormGroup :label="t('processedData.state')" class="w-full">
        <AgFormSelect :options="stateOptions" v-model="internalState" />
      </AgFormGroup>

      <AgFormGroup :label="t('processedData.customer')" class="w-full">
        <AgSearchSelect :options="customerOptions" v-model="internalCustomer" />
      </AgFormGroup>

      <AgFormGroup :label="t('processedData.article')" class="w-full">
        <AgSearchSelect :options="articleOptions" v-model="internalArticle" />
      </AgFormGroup>
    </div>

    <hr class="mt-24 mb-16 text-neutral-500" />

    <div class="flex items-center gap-12">
      <AgFormGroup class="w-full" :label="t('processedData.month')">
        <AgFormSelect :options="months" v-model="internalMonth" />
      </AgFormGroup>

      <AgFormGroup class="w-full" :label="t('processedData.year')">
        <AgFormSelect :options="years" v-model="internalYear" />
      </AgFormGroup>

      <AgButton class="mt-[29px] h-[40px]" @click="newSearch()">
        {{ t('processedData.search') }}
      </AgButton>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';

  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';

  import AgSearchInput from '@/components/library/search-input/AgSearchInput.vue';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed, onMounted, ref } from 'vue';
  import { useProcessedDataStore } from '../store/useProcessedDataStore';

  const { t } = i18n.global;

  interface Props {
    selectState: number;
    inputSearch: string;
    articleCode: string;
    customerId: number;
    month: number;
    year: number;
  }
  const props = defineProps<Props>();

  const store = useProcessedDataStore();
  const { getArticleList, getCustomerList } = useProcessedDataStore();

  const { customers, articles } = storeToRefs(store);

  const internalMonth = ref<number>(props.month);
  const internalYear = ref<number>(props.year);

  const articleOptions = ref<Array<AgSearchSelectOption>>([]);
  const customerOptions = ref<Array<AgSearchSelectOption>>([]);

  const months = ref<Array<AgFormSelectOption>>([]);
  const years = ref<Array<AgFormSelectOption>>([]);

  const emit = defineEmits([
    'update:selectState',
    'update:inputSearch',
    'update:customerId',
    'update:articleCode',
    'update:month',
    'update:year',
    'newSearch',
  ]);

  const stateOptions = ref<Array<AgFormSelectOption>>([
    {
      key: 0,
      label: 'processedData.all',
    },
    {
      key: 1,
      label: 'processedData.createOrder',
    },
    {
      key: 2,
      label: 'processedData.createOffer',
    },
    {
      key: 4,
      label: 'processedData.partiallyEvaded',
    },
    {
      key: 5,
      label: 'processedData.evaded',
    },
    {
      key: 6,
      label: 'processedData.invoiced',
    },
  ]);

  const internalState = computed({
    get: () => props.selectState,
    set: (value) => emit('update:selectState', value),
  });

  const internalSearch = computed({
    get: () => props.inputSearch,
    set: (value) => emit('update:inputSearch', value),
  });

  const internalCustomer = computed({
    get: () => props.customerId,
    set: (value) => emit('update:customerId', value),
  });

  const internalArticle = computed({
    get: () => props.articleCode,
    set: (value) => emit('update:articleCode', value),
  });

  const newSearch = function () {
    emit('update:month', internalMonth.value);
    emit('update:year', internalYear.value);
  };

  const loadFilterCustomers = async function () {
    await getCustomerList();
    customerOptions.value.push({ value: 0, label: 'processedData.all', searchableString: 'processedData.all' });
    customers.value.forEach((customer) => {
      customerOptions.value.push({
        label: `${customer.customerName} (${customer.customerCode})`,
        value: customer.customerId ?? 0,
        searchableString: `${customer.customerName}${customer.customerCode}`,
      });
    });
  };

  const loadFilterArticles = async function () {
    await getArticleList();

    articleOptions.value.push({ value: '', label: 'processedData.all', searchableString: 'processedData.all' });
    articles.value.forEach((article) => {
      articleOptions.value.push({
        label: `${article.articleName} (${article.articleCode})`,
        value: article.articleCode,
        searchableString: `${article.articleName}${article.articleCode}`,
      });
    });
  };

  const loadFilterMonthsAndYears = function () {
    const currentYear = new Date().getFullYear();
    const end = currentYear - 5;

    months.value.push({ label: 'processedData.all', key: 0 });
    for (let i = 1; i <= 12; i++) {
      months.value.push({ label: `processedData.month${i}`, key: i });
    }
    years.value.push({ label: 'processedData.all', key: 0 });
    for (let i = currentYear; i >= end; i--) {
      years.value.push({ label: i.toString(), key: i });
    }
  };

  onMounted(async () => {
    loadFilterCustomers();
    loadFilterArticles();
    loadFilterMonthsAndYears();
  });
</script>
