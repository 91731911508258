<template>
  <div class="flex w-full items-center justify-center gap-4">
    <AgButton variant="ghost" :disabled="isFirst" @click="goToPreviousPage">
      <template #icon>
        <IcFastback class="w-12" />
      </template>
    </AgButton>
    <div v-if="totalPages < 8">
      <AgButton
        :variant="getVariant(page)"
        @click.stop="goToPage(page)"
        v-for="page in totalPages"
        :key="page"
        rounded
        class="buttonPage"
      >
        {{ page }}
      </AgButton>
    </div>
    <div v-else>
      <div v-if="modelValue < 4">
        <AgButton
          :variant="getVariant(page)"
          @click.stop="goToPage(page)"
          v-for="page in 4"
          :key="page"
          rounded
          class="buttonPage"
        >
          {{ page }}
        </AgButton>
        <AgButton variant="ghost" class="buttonPage">...</AgButton>
        <AgButton variant="ghost" @click.stop="goToPage(totalPages)" rounded class="buttonPage">
          {{ totalPages }}
        </AgButton>
      </div>
      <div v-else-if="modelValue < totalPages - 2">
        <AgButton variant="ghost" @click.stop="goToPage(1)" rounded class="buttonPage">1</AgButton>
        <AgButton variant="ghost" class="buttonPage">...</AgButton>
        <AgButton variant="ghost" @click.stop="goToPage(modelValue - 1)" rounded class="buttonPage">
          {{ modelValue - 1 }}
        </AgButton>
        <AgButton variant="primary" @click.stop="goToPage(modelValue)" rounded class="buttonPage">
          {{ modelValue }}
        </AgButton>
        <AgButton variant="ghost" @click.stop="goToPage(modelValue + 1)" rounded class="buttonPage">
          {{ modelValue + 1 }}
        </AgButton>
        <AgButton variant="ghost" class="buttonPage">...</AgButton>
        <AgButton variant="ghost" @click.stop="goToPage(totalPages)" rounded class="buttonPage">
          {{ totalPages }}
        </AgButton>
      </div>
      <div v-else>
        <AgButton variant="ghost" @click.stop="goToPage(1)" rounded class="buttonPage">1</AgButton>
        <AgButton variant="ghost" class="buttonPage">...</AgButton>
        <AgButton
          :variant="getVariant(totalPages - 3)"
          @click.stop="goToPage(totalPages - 3)"
          rounded
          class="buttonPage"
        >
          {{ totalPages - 3 }}
        </AgButton>
        <AgButton
          :variant="getVariant(totalPages - 2)"
          @click.stop="goToPage(totalPages - 2)"
          rounded
          class="buttonPage"
        >
          {{ totalPages - 2 }}
        </AgButton>
        <AgButton
          :variant="getVariant(totalPages - 1)"
          @click.stop="goToPage(totalPages - 1)"
          rounded
          class="buttonPage"
        >
          {{ totalPages - 1 }}
        </AgButton>
        <AgButton :variant="getVariant(totalPages)" @click.stop="goToPage(totalPages)" rounded class="buttonPage">
          {{ totalPages }}
        </AgButton>
      </div>
    </div>
    <AgButton variant="ghost" @click.stop="goToNextPage" :disabled="isLast">
      <template #icon>
        <IcFastforward class="w-12" />
      </template>
    </AgButton>
  </div>
</template>

<script setup lang="ts">
  import IcFastback from '@/components/icons/IcFastback.vue';
  import IcFastforward from '@/components/icons/IcFastforward.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import { watch } from 'vue';

  import { computed } from 'vue';

  interface Props {
    totalPages: number;
    modelValue: number;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:modelValue']);

  const isFirst = computed(() => {
    return props.modelValue === 1;
  });

  const isLast = computed(() => {
    return props.modelValue === props.totalPages || props.totalPages === 0;
  });

  const goToNextPage = () => {
    emit('update:modelValue', props.modelValue + 1);
  };

  const goToPreviousPage = () => {
    emit('update:modelValue', props.modelValue - 1);
  };

  const goToPage = (pageNum: number) => {
    emit('update:modelValue', pageNum);
  };

  const getVariant = (page: number) => {
    if (props.modelValue === page) {
      return 'primary';
    }
    return 'ghost';
  };

  watch(
    () => props.totalPages,
    () => {
      emit('update:modelValue', 1); //WHEN THE NUMBER OF PAGES CHANGED, GO TO THE FIRST PAGE
    }
  );
</script>

<style scoped lang="scss">
  .buttonPage {
    @apply p-4 font-mono w-32;
  }
</style>
