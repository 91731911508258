import type { AuthUserDto } from '@/admin/domain/AuthUserDto';
import type { CustomerDto } from '@/admin/domain/CustomerDto';
import type { CustomerNoteDto } from '@/admin/domain/CustomerNoteDto';
import type { ContainerDto } from '@/admin/domain/data/ContainerDto';
import { useApi } from '@/composables/api/useApi';
import { useError } from '@/util/useError';

export function useCustomers() {
  const api = useApi();
  const { handleCatchServerError, handleCatchLocalError } = useError();

  const loadCustomers = async function () {
    let customers: Array<CustomerDto> = [];
    try {
      const requestUri = '/adminapi/customer';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }

      if (container.data.customers) {
        customers = container.data.customers;
      }
      return customers;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  const loadCustomerNotes = async function (customerId: number) {
    let customerNotes: Array<CustomerNoteDto> = [];
    try {
      const requestUri = `/adminapi/customer/customerNotes/${customerId}`;

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }

      if (container.data.customerNotes) {
        customerNotes = container.data.customerNotes;
      }
      return customerNotes;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  const updateCustomer = async function (customer: CustomerDto) {
    try {
      const requestUri = '/adminapi/customer';

      const container = await api.fetch<ContainerDto>(requestUri, 'PATCH', customer, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const loadAgents = async function () {
    let agents: Array<AuthUserDto> = [];
    try {
      const requestUri = '/adminapi/users';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.authUsers) {
        agents = container.data.authUsers;
      }
      return agents;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  return {
    loadCustomers,
    updateCustomer,
    loadCustomerNotes,
    loadAgents,
  };
}
