<template>
  <AgCollapsible class="row" showBorder showIcon :collapse="props.message.id != openId">
    <template #header>
      <div class="py-12">
        <div>
          {{ props.message.title }}
        </div>
        <div class="font-light">{{ props.message.subtitle }}</div>
        <div>
          {{ d(props.message.date * 1000) }}
        </div>
      </div>
    </template>
    <template #default>
      <div class="p-12">
        <div class="mt-24" v-if="props.message.text">
          {{ props.message.text }}
        </div>
        <div v-if="props.message.download">
          <span class="pr-4">File:</span>
          <a
            :href="props.message.download.fileUrl"
            @click.prevent="openFile(props.message.download.fileUrl)"
            target="_blank"
          >
            {{ props.message.download.fileName }}
          </a>
        </div>
      </div>
    </template>
  </AgCollapsible>
</template>

<script setup lang="ts">
  import type { CommunicationDto } from '@/domain/CommunicationDto';

  import AgCollapsible from '@/components/library/collapsible/AgCollapsible.vue';

  import { useDownloadStore } from '@/modules/downloads/stores/useDownloadStore';
  import { i18n } from '@/plugins/i18n';
  import { parseInt } from 'lodash';
  import { ref, watch } from 'vue';
  import { useRoute } from 'vue-router';

  const { d } = i18n.global;

  const store = useDownloadStore();
  const { openFile } = store;

  interface Props {
    message: CommunicationDto;
  }
  const props = defineProps<Props>();

  const openId = ref<number>(-1);
  const route = useRoute();

  watch(
    () => route.params.id,
    (newId) => {
      if (newId) {
        openId.value = parseInt(newId as string);
      } else {
        openId.value = -1;
      }
    },
    { immediate: true }
  );
</script>

<style scoped lang="scss">
  .row:hover {
    @apply bg-neutral-200;
  }
</style>
