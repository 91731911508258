<template>
  <AgModal v-if="internalValue">
    <template #header>
      <span class="text-xl font-bold">{{ t('admin.addAgent') }}</span>
    </template>
    <template #content>
      <div v-if="isLoading" class="flex justify-center items-center h-full">
        <IcLoading />
      </div>
      <div v-else>
        <AgFormGroup :label="t('admin.agent')">
          <AgSearchSelect :options="agentOptions" v-model="agentId" />
        </AgFormGroup>
        <AgFormGroup :label="t('admin.description')">
          <AgFormInput v-model="description" />
        </AgFormGroup>
        <AgFormGroup :label="t('admin.level')">
          <AgFormSelect v-model="levelId" :options="levelOptions" />
        </AgFormGroup>
      </div>
    </template>
    <template #footer>
      <AgButton variant="secondary" @click="internalValue = false">
        {{ t('admin.cancel') }}
      </AgButton>
      <AgButton @click="save" :disabled="isDisabled">
        {{ t('admin.save') }}
      </AgButton>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import type { AuthUserDto } from '@/admin/domain/AuthUserDto';
  import type { HierarchyLevelDto } from '@/admin/domain/HierarchyLevelDto';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';
  import AgModal from '@/components/library/modal/AgModal.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import type { UserHierarchyDto } from '@/domain/UserHierarchyDto';
  import { i18n } from '@/plugins/i18n';
  import { computed, ref, watch } from 'vue';
  import useUsers from '../../users/composables/useUsers';
  import { useHierarchies } from '../composables/useHierarchies';

  const { t } = i18n.global;

  const { loadUsers } = useUsers();
  const { loadLevels, addAgentToHierarchy } = useHierarchies();

  interface Props {
    modelValue: boolean;
    tree: UserHierarchyDto;
    originalTree: UserHierarchyDto;
    deept: number;
  }

  const props = defineProps<Props>();
  const emit = defineEmits(['update:modelValue', 'update:hierarchy']);

  const agentId = ref<number>();
  const levelId = ref<number>();
  const agents = ref<Array<AuthUserDto>>([]);
  const agentOptions = ref<Array<AgSearchSelectOption>>([]);
  const levelOptions = ref<Array<AgFormSelectOption>>([]);
  const levels = ref<Array<HierarchyLevelDto>>([]);
  const isLoading = ref<boolean>(true);
  const description = ref<string>('');

  const internalValue = computed({
    get: () => props.modelValue,
    set: (value: boolean) => {
      emit('update:modelValue', value);
    },
  });

  const isDisabled = computed(() => !agentId.value || !levelId.value || !description.value);

  const isInHierarchy = function (tree: UserHierarchyDto, agentId: number) {
    if (tree.authUser.id === agentId) {
      return true;
    }

    if (tree.children) {
      for (const child of tree.children) {
        if (isInHierarchy(child, agentId)) {
          return true;
        }
      }
    }

    return false;
  };

  const save = async function () {
    if (!agentId.value || !levelId.value) {
      return;
    }
    await addAgentToHierarchy(props.tree.id, agentId.value, levelId.value, description.value);
    emit('update:hierarchy');
    emit('update:modelValue', false);
  };

  watch(internalValue, async (newValue) => {
    if (newValue) {
      isLoading.value = true;
      agents.value = await loadUsers();
      levels.value = await loadLevels();
      description.value = '';

      levels.value.sort((a, b) => a.grade - b.grade);
      levelId.value = levels.value[props.deept].id;

      agentOptions.value = agents.value.map((agent) => ({
        label: agent.name,
        value: agent.id,
        searchableString: agent.name,
        disabled: isInHierarchy(props.originalTree, agent.id),
      }));
      levelOptions.value = levels.value
        .filter((l) => l.grade > props.deept)
        .map((level) => ({
          label: level.description,
          key: level.id,
        }));

      isLoading.value = false;
    }
  });
</script>
