<template>
  <AgModal v-if="internalValue">
    <template #header>
      <span class="text-xl font-bold">{{ t('admin.copyHierarchy') }}</span>
    </template>
    <template #content>
      <div v-if="isLoading" class="flex justify-center items-center h-full">
        <IcLoading />
      </div>
      <div v-else-if="newHierarchy">
        <AgFormGroup :label="t('admin.agent')">
          <AgSearchSelect :options="agentOptions" v-model="agentId" />
        </AgFormGroup>
        <AgFormGroup :label="t('admin.description')">
          <AgFormInput v-model="newHierarchy.description" />
        </AgFormGroup>
      </div>
    </template>
    <template #footer>
      <AgButton variant="secondary" @click="internalValue = false">
        {{ t('admin.cancel') }}
      </AgButton>
      <AgButton @click="save" :disabled="isDisabled">
        {{ t('admin.save') }}
      </AgButton>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import type { AuthUserDto } from '@/admin/domain/AuthUserDto';
  import type { InsertHierarchyDto } from '@/admin/domain/InsertHierarchyDto';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import type { UserHierarchyDto } from '@/domain/UserHierarchyDto';
  import { i18n } from '@/plugins/i18n';
  import { computed, ref, watch } from 'vue';
  import useUsers from '../../users/composables/useUsers';
  import { useHierarchies } from '../composables/useHierarchies';

  const { t } = i18n.global;

  const { loadUsers } = useUsers();
  const { createHierarchy, fromHierarchyToInsertHierarchy } = useHierarchies();

  interface Props {
    modelValue: boolean;
    hierarchy: UserHierarchyDto;
    hierarchies: Array<UserHierarchyDto>;
  }

  const props = defineProps<Props>();
  const emit = defineEmits(['update:modelValue', 'update:hierarchy']);

  const isLoading = ref<boolean>(true);
  const agentId = ref<number>();
  const newHierarchy = ref<InsertHierarchyDto>();
  const agents = ref<Array<AuthUserDto>>([]);
  const agentOptions = ref<Array<AgSearchSelectOption>>([]);

  const internalValue = computed({
    get: () => props.modelValue,
    set: (value: boolean) => {
      emit('update:modelValue', value);
    },
  });

  const isDisabled = computed(() => !agentId.value);

  const save = async function () {
    if (!newHierarchy.value || !agentId.value) {
      return;
    }
    newHierarchy.value.authUserId = agentId.value;
    console.log('CREATE HIERARCHY');
    await createHierarchy(newHierarchy.value);
    emit('update:hierarchy');
    emit('update:modelValue', false);
  };

  const isInHierarchy = function (tree: UserHierarchyDto, agentId: number) {
    if (tree.authUser?.id === agentId) {
      return true;
    }

    if (tree.children) {
      for (const child of tree.children) {
        if (isInHierarchy(child, agentId)) {
          return true;
        }
      }
    }

    return false;
  };

  watch(
    internalValue,
    async (newValue) => {
      if (newValue) {
        isLoading.value = true;
        agents.value = await loadUsers();

        newHierarchy.value = fromHierarchyToInsertHierarchy(props.hierarchy);

        agentOptions.value = agents.value.map((ag) => ({
          label: ag.name,
          value: ag.id,
          searchableString: ag.name,
          disabled: isInHierarchy(props.hierarchy, ag.id) || props.hierarchies.some((h) => h.authUser.id === ag.id),
        }));

        isLoading.value = false;
      }
    },
    { immediate: true }
  );
</script>
