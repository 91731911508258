<template>
  <div>
    <div class="tableHeader">
      <div class="headerColumn">{{ t('admin.name').toUpperCase() }}</div>
      <div class="headerColumn">{{ t('admin.grade').toUpperCase() }}</div>
    </div>
    <div v-for="level in props.levels" :key="level.id" class="tableRow" @click="viewLevel(level.id)">
      <div class="tableColumn">{{ level.description }}</div>
      <div class="tableColumn">{{ level.grade }}</div>
      <div class="tableColumn iconColumn"><IcChevronForward /></div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { HierarchyLevelDto } from '@/admin/domain/HierarchyLevelDto';

  import IcChevronForward from '@/components/icons/IcChevronForward.vue';

  import { i18n } from '@/plugins/i18n';
  import { useRouter } from 'vue-router';

  interface Props {
    levels: Array<HierarchyLevelDto>;
  }

  const props = defineProps<Props>();

  const router = useRouter();
  const { t } = i18n.global;

  const viewLevel = async function (levelId: number) {
    await router.push({
      name: 'level',
      params: { levelId: levelId },
    });
  };
</script>

<style scoped>
  .tableHeader {
    @apply grid grid-cols-[2fr_3fr_auto] gap-12 py-8 font-light text-neutral-550;
  }

  .headerColumn {
    @apply flex items-center;
    color: #04a040;
    font-weight: bold;
  }

  .tableRow {
    @apply grid grid-cols-[2fr_3fr_auto] gap-12 border-t border-neutral-300 py-8 hover:bg-neutral-200 hover:cursor-pointer;
  }

  .tableColumn {
    @apply flex items-center;
  }

  .iconColumn {
    @apply col-start-3 col-end-4 justify-end;
  }
</style>
