<template>
  <div>
    <!-- SUBHEADER SEARCH AREA -->
    <TheSubheader>
      <ByCustomerFilter v-model="inputSearch" />
    </TheSubheader>

    <div class="mb-32 mt-12 md:mx-[80px] max-md:mx-16">
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <!-- PAGINATED CONTENT -->
      <div v-else class="no-scrollbar overflow-x-auto">
        <div class="my-12 min-w-fit">
          <div
            v-if="openItemCustomers.content && openItemCustomers.content.length > 0"
            class="my-16 flex items-center justify-between"
          >
            <AgPaginatorInfo
              :current-page="currentPage"
              :page-size="pageSize"
              :number-items="openItemCustomers.totalElements"
            />
          </div>

          <!-- PAGINATOR -->
          <AgPaginator
            v-if="openItemCustomers.content && openItemCustomers.content.length > 0"
            v-model="currentPage"
            :total-pages="openItemCustomers.totalPages"
            class="my-24"
          />

          <div v-if="config.company.sendEmailPaidInvoices" class="flex flex-row justify-between py-12 font-bold">
            <div>
              <input class="hover:cursor-pointer" type="checkbox" name="selectItemsToPay" v-model="isSelectionActive" />
              <label
                class="ml-8 hover:cursor-pointer"
                for="selectItemsToPay"
                @click="isSelectionActive = !isSelectionActive"
              >
                {{ t('openItems.selectActive') }}
              </label>
            </div>

            <div v-if="isSelectionActive">
              <AgButton @click.stop="openModalPayment" :disabled="selectedInvoices.length === 0">
                <span>{{ t('openItems.notifyPayment') }} ({{ selectedInvoices.length }})</span>
              </AgButton>
            </div>
          </div>

          <!-- TABLE OPEN ITEMS BY CUSTOMER -->
          <div class="overflow-x-auto">
            <ByCustomerTable
              :isSelectionActive="isSelectionActive"
              :openItems="openItemCustomers.content"
              v-model="sorting"
            />
          </div>
        </div>
      </div>
    </div>
    <PaymentModal v-if="config.company.sendEmailPaidInvoices" v-model="showModalPayment" />
  </div>
</template>

<script setup lang="ts">
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import ByCustomerFilter from '@/modules/open-items/components/by-customer/OpenItemsByCustomerFilter.vue';
  import ByCustomerTable from '@/modules/open-items/components/by-customer/OpenItemsByCustomerTable.vue';
  import PaymentModal from '@/modules/open-items/components/by-customer/paymentModal/PaymentModal.vue';

  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { config } from '@/config/config';
  import { useByCustomerStore } from '@/modules/open-items/store/useByCustomerStore';
  import { i18n } from '@/plugins/i18n';
  import { OutstandingFilter } from '@/util/filters/OutstandingFilter';
  import { Order } from '@/util/Order';
  import { PageRequest } from '@/util/Paging';
  import { Sorting } from '@/util/Sorting';
  import { storeToRefs } from 'pinia';
  import { ref, watch } from 'vue';

  const { t } = i18n.global;

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('openItems.openItemsByCustomer');

  const theFooter = useTheFooter();
  theFooter.enabled(true);
  theFooter.visible(true);

  const currentPage = ref(1);
  const pageSize = ref(10);
  const sorting = ref<Sorting>(new Sorting('customerName', Order.ASC));
  const inputSearch = ref<string>('');
  const isLoading = ref<boolean>(true);
  const isSelectionActive = ref<boolean>(false);
  const showModalPayment = ref<boolean>(false);

  const store = useByCustomerStore();
  const { openItemCustomers, selectedInvoices } = storeToRefs(store);
  const { loadData } = store;

  const openModalPayment = () => {
    showModalPayment.value = true;
  };

  watch(
    [inputSearch, sorting, currentPage],
    () => {
      loadData(
        new PageRequest(pageSize.value, currentPage.value),
        new OutstandingFilter(inputSearch.value),
        sorting.value
      )
        .then(() => (isLoading.value = false))
        .catch((error) => console.log(error));
    },
    { immediate: true }
  );

  watch(isSelectionActive, () => {
    selectedInvoices.value = [];
  });
</script>
