<template>
  <div>
    <div class="my-24 flex items-center justify-between">
      <AgPaginatorInfo :current-page="currentPage" :page-size="pageSize" :number-items="props.orders.length" />
      <div class="flex gap-24">
        <AgButton variant="ghost" class="text-link" @click="csvExport(csvDataExport, csvHeader, fileName)">
          Export to CSV
          <template #icon><IcDownload /></template>
        </AgButton>

        <AgButton class="sm:min-w-[359px]" @click="onNew">{{ t('orders.newOrder') }}</AgButton>
      </div>
    </div>
    <div>
      <AgPaginator v-model="currentPage" :total-pages="totalPages" />
      <div class="tableHeader grid-cols-[minmax(150px,1fr)_150px_130px_200px_180px]">
        <div>{{ t('orders.customerName') }}</div>
        <div class="flex flex-col">
          <span>{{ t('orders.agent') }}</span>
          <span>{{ t('orders.user') }}</span>
        </div>
        <span>{{ t('orders.customerStatus') }}</span>
        <span>{{ t('orders.date') }}</span>
        <span class="flex justify-end">{{ t('orders.orderNumber') }}</span>
      </div>
      <div v-for="order in filteredOrders" :key="order.gid">
        <OrderTableRowCompleted
          :customer-status="isCustomerBlocked(order.customer.id)"
          :order="order"
          class="cursor-pointer"
          @click="openOrder(order)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { DimensionItemDto } from '@/domain/DimensionItemDto';
  import type { CustomerDto } from '@/domain/masterData/CustomerDto';
  import type { DayOfRestDto } from '@/domain/masterData/DayOfRestDto';
  import type { UnloadingTimeDto } from '@/domain/masterData/UnloadingTimeDto';
  import type { OrderAddressDto } from '@/domain/orderData/OrderAddressDto';
  import type { OrderDto } from '@/domain/orderData/OrderDto';
  import type { OrderItemDto } from '@/domain/orderData/OrderItemDto';

  import IcDownload from '@/components/icons/IcDownload.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import OrderTableRowCompleted from '@/modules/orders/components/order-table/order-table-row/OrderTableRowCompleted.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { config } from '@/config/config';
  import { i18n } from '@/plugins/i18n';
  import { Utilities } from '@/util/Utilities';
  import { computed, ref, watch } from 'vue';

  const { t, locale } = i18n.global;

  const { tables, isFullReloading, isLoading } = useMasterData();

  const { getTranslatedText } = useTranslatedText();
  const { csvExport, timeToDate } = Utilities();
  const { formatDate } = useDateFormatting();

  interface Props {
    orders: Array<OrderDto>;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['new-entry', 'open-order']);

  const customers = ref<Array<CustomerDto>>([]);
  const currentPage = ref(1);
  const pageSize = ref(10);

  const today = new Date();
  const fileName = `${config.company.code}_orders_${today.toISOString()}.csv`;

  const csvHeader = ref<string[]>([
    'Agent name',
    'Agent code',
    'Order date',
    'Order gid',
    'Order number',
    'VAT code',
    'Fiscal code',
    'Customer name',
    'Customer code',
    'Customer Email',
    'Customer address',
    'Customer phone',
    'Delivery address',
    'Delivery phone',
    'Delivery unloading times',
    'Delivery days of rest',
    'Delivery term',
    'Delivery method',
    'Payment term',
    'payment method',
    'Depot',
    'IBAN',
    'External note',
    'Internal note',
    'Article code',
    'Article extCode',
    'Article name',
    'Article type',
    'Article group',
    'Article note',
    'Article dimension',
    'Article dimension',
    'Article dimension',
    'Packaging',
    'Quantity',
    'Free quatity',
    'Price',
    'Discount 1',
    'Discount 2',
    'Discount 3',
    'Discount 4',
    'Discount 5',
    'Discount 6',
    'Head discount',
    'VAT',
  ]);

  const csvDataExport = computed(() => {
    const data: string[] = [];

    for (const order of props.orders) {
      const invoiceAddress = getAddress(order.invoiceAddress);
      const invoicePhone = order.invoiceAddress.phoneNumber;

      const deliveryAddress = order.deliveryAddressEqualToBilling ? invoiceAddress : getAddress(order.deliveryAddress);
      const deliveryPhone = order.deliveryAddressEqualToBilling ? invoicePhone : order.deliveryAddress?.phoneNumber;
      const deliveryUnloadingTimes = order.deliveryAddressEqualToBilling
        ? getUnloadingTimes(order.invoiceAddress.unloadingTimes)
        : getUnloadingTimes(order.deliveryAddress?.unloadingTimes);
      const deliveryRestDays = order.deliveryAddressEqualToBilling
        ? getDaysOfRest(order.invoiceAddress.daysOfRest)
        : getDaysOfRest(order.deliveryAddress?.daysOfRest);

      const deliveryTerm = order.deliveryTerm ? getTranslatedText(order.deliveryTerm.title) : '';
      const deliveryMethod = order.deliveryMethod ? getTranslatedText(order.deliveryMethod.title) : '';

      const depot = order.depot ? getTranslatedText(order.depot.title) : '';
      const paymentTerm = order.paymentTerm ? getTranslatedText(order.paymentTerm.title) : '';
      const paymentMethod = order.paymentMethod ? getTranslatedText(order.paymentMethod.title) : '';
      const headDiscount = `${order.discount}`;

      const orderData = [
        order.agentName,
        order.agentCode,
        formatDate(order.orderDate),
        order.gid,
        order.orderNumber,
        order.customer.vatCode,
        order.customer.fiscalCode,
        order.customer.name,
        order.customer.code,
        order.invoiceAddress.email,
        invoiceAddress,
        invoicePhone,
        deliveryAddress,
        deliveryPhone,
        deliveryUnloadingTimes,
        deliveryRestDays,
        deliveryTerm,
        deliveryMethod,
        paymentTerm,
        paymentMethod,
        depot,
        order.customer.iban,
        order.internalNote,
        order.orderNote,
      ];

      order.items.forEach((item) => {
        const packaging = item.packaging.unit.title[locale.value] + `( ${item.packaging.size})`;
        const title = getTranslatedText(item.article.title);
        const articleType = getTranslatedText(item.article.articleType);
        const articleGroup = getTranslatedText(item.article.articleGroup);
        const quantity = item.quantity ? `${item.quantity}` : '';
        const freeQuantity = item.freeQuantity ? `${item.freeQuantity}` : '';
        const price = item.tradeAgreement.editedPrice
          ? `${item.tradeAgreement.editedPrice}`
          : `${item.tradeAgreement.price}`;
        const tax = item.tax ? `${item.tax}` : '';

        const itemData = [
          item.article.code,
          item.article.externalCode,
          title,
          articleType,
          articleGroup,
          item.note,
          getDimension(item.article.dimensions, 0),
          getDimension(item.article.dimensions, 1),
          getDimension(item.article.dimensions, 2),
          packaging,
          quantity,
          freeQuantity,
          price,
          getDiscount(item, 1),
          getDiscount(item, 2),
          getDiscount(item, 3),
          getDiscount(item, 4),
          getDiscount(item, 5),
          getDiscount(item, 6),
          headDiscount,
          tax,
        ];
        data.push(orderData.concat(itemData).join(';'));
      });
    }
    return data;
  });

  const getAddress = function (address: OrderAddressDto | null) {
    if (address) {
      return `${address.street} ${address.city} (${address.district}) ${address.zip} ${address.region} ${address.nation}`;
    }
    return '';
  };

  const getDaysOfRest = function (daysOfRest: Array<DayOfRestDto> | undefined) {
    if (daysOfRest && daysOfRest.length > 0) {
      let restd = '';
      for (const dayOfRest of daysOfRest) {
        restd =
          restd +
          `${t('orders.day' + dayOfRest.day)} ${timeToDate(dayOfRest.timeStart, locale.value)} - ${timeToDate(dayOfRest.timeEnd, locale.value)}\n`;
      }
    }
    return '';
  };

  const getUnloadingTimes = function (unloadingTimes: Array<UnloadingTimeDto> | undefined) {
    if (unloadingTimes && unloadingTimes.length > 0) {
      let unloading = '';
      for (const unloadingTime of unloadingTimes) {
        unloading =
          unloading +
          `${timeToDate(unloadingTime.timeStart, locale.value)} - ${timeToDate(unloadingTime.timeEnd, locale.value)}\n`;
      }
    }
    return '';
  };

  const getDimension = function (dimension: Array<DimensionItemDto> | undefined, position: number) {
    if (dimension && dimension.length >= position + 1) {
      return dimension[position].title[locale.value];
    }
    return '';
  };

  const getDiscount = function (item: OrderItemDto, position: number) {
    if (item.quantity && item.quantity > 0) {
      if (position <= config.company.maxItemDiscounts) {
        const key = `discount${position}` as keyof typeof item;
        if (key) {
          return (item[key] as string) ?? '';
        }
      }
    }
    return '';
  };

  const filteredOrders = computed(() => {
    const startIndex = (currentPage.value - 1) * pageSize.value;
    const endIndex = Math.min(startIndex + pageSize.value, props.orders.length);

    return props.orders.slice(startIndex, endIndex);
  });

  const totalPages = computed(() => Math.ceil(props.orders.length / pageSize.value));

  const onNew = function () {
    emit('new-entry');
  };

  const openOrder = function (order: OrderDto) {
    emit('open-order', order);
  };

  const isCustomerBlocked = function (customerId?: number | null) {
    if (!customerId) {
      return false;
    }
    const customer = customers.value.find((entry) => {
      return entry.id === customerId;
    });
    if (!customer) {
      return true;
    }
    return customer.blocked;
  };

  const loadCustomers = async function () {
    customers.value = await tables.customers.toArray();
  };

  watch(
    [isFullReloading, isLoading],
    async ([fullReload, reload]) => {
      if (!fullReload && !reload) {
        await loadCustomers();
      }
    },
    { immediate: true }
  );
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid items-center font-light text-neutral-550 gap-4 py-8;
  }
</style>
