<template>
  <span>
    <teleport to="body">
      <aside v-if="isAuthenticated" class="sidebar no-scrollbar" :class="getClasses">
        <div class="flex h-full w-full flex-col">
          <div class="mb-12">
            <div class="flex justify-between items-center p-8 h-32">
              <div class="text-danger font-bold" v-if="config.enviroment !== 'production'">
                <span>{{ config.enviroment.toUpperCase() }}</span>
              </div>
              <AgButton variant="ghost" @click="$emit('close')" class="lg:hidden">
                <template #icon><IcClose /></template>
              </AgButton>
            </div>
            <div class="mt-12 mb-12 flex justify-center">
              <component v-if="logo" :is="logo" />
            </div>
          </div>
          <SidebarLogout :username="accountName" @logout="userLogout" />
          <SidebarMenu style="flex: 1 1 auto" @click.stop="menuOnClick" />
          <SidebarStatusInfo
            :online="isOnline"
            :lastDataSync="lastMasterDataSync"
            :lastOrderSync="lastOrdersDataSync"
            :lastOfferSync="lastOffersDataSync"
          />
          <SidebarSyncMenu
            :is-loading-master-data="isLoadingMasterData"
            :is-syncing="isSyncing"
            @reload-data="syncMasterData"
            @sync-now="syncData"
          />
          <SidebarAppInfo
            :memory-total-bytes="estimatedStorageQuota.quota"
            :memory-used-bytes="estimatedStorageQuota.usage"
            :storage-persisted="storagePersisted"
          />
        </div>
      </aside>
    </teleport>
  </span>
</template>

<script setup lang="ts">
  import SidebarAppInfo from '@/components/framework/the-sidebar/app-info/SidebarAppInfo.vue';
  import SidebarLogout from '@/components/framework/the-sidebar/logout/SidebarLogout.vue';
  import SidebarMenu from '@/components/framework/the-sidebar/menu/SidebarMenu.vue';
  import SidebarStatusInfo from '@/components/framework/the-sidebar/status-info/SidebarStatusInfo.vue';
  import SidebarSyncMenu from '@/components/framework/the-sidebar/sync-menu/SidebarSyncMenu.vue';
  import IcClose from '@/components/icons/IcClose.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import LogoA2G from '@/components/logo/LogoA2G.vue';

  import { useAuthentication } from '@/composables/auth/useAuthentication';
  import { useMasterData } from '@/composables/data/useMasterData';
  import { useOfferData } from '@/composables/data/useOfferData';
  import { useOrderData } from '@/composables/data/useOrderData';
  import { useSyncData } from '@/composables/data/useSyncData';
  import { config } from '@/config/config';
  import { useDataTimestampStore } from '@/store/useDataTimestampStore';
  import { useNetwork } from '@vueuse/core';
  import { storeToRefs } from 'pinia';
  import { computed, defineAsyncComponent, ref, watch } from 'vue';

  const { logout, accountName, isAuthenticated } = useAuthentication();

  const store = useDataTimestampStore();
  const { lastOrdersDataSync, lastOffersDataSync, lastMasterDataSync } = storeToRefs(store);

  const { isOnline } = useNetwork();

  const estimatedStorageQuota = ref<StorageEstimate>({ quota: 0, usage: 0 });
  const storagePersisted = ref(false);

  const masterData = useMasterData();
  const orderData = useOrderData();
  const offerData = useOfferData();
  const useSync = useSyncData();

  interface Props {
    navigationOpen: boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    navigationOpen: false,
  });

  const syncData = function () {
    useSync.syncData();
  };

  const syncMasterData = function () {
    masterData.reloadMasterData();
  };

  const userLogout = async function () {
    useSync.clearSyncData();
    useSync.clearOneShotSyncData();
    store.clearLastDataSync();

    logout();
    if (!isOnline.value) {
      window.location.href = '/#/login';
    }
  };

  const isLoadingMasterData = computed(() => {
    return masterData.isFullReloading.value;
  });

  const isSyncing = computed(() => {
    return orderData.isLoading.value || offerData.isLoading.value || masterData.isLoading.value;
  });

  const getClasses = computed(() => ({
    'fixed flex': props.navigationOpen,
  }));

  const emit = defineEmits(['close']);
  window.addEventListener('resize', () => {
    if (window.innerWidth > 1180) {
      emit('close');
    }
  });

  const menuOnClick = function () {
    emit('close');
  };

  const logo = computed(() => {
    if (importedLogo) {
      return importedLogo;
    }
    return LogoA2G;
  });

  const importedLogo = defineAsyncComponent({
    loader: () =>
      import(`../../logo/${config.company.code}.vue`).catch((_error) => {
        return LogoA2G;
      }),
    errorComponent: LogoA2G,
  });

  watch(
    () => isAuthenticated.value,
    (newValue) => {
      const bodyElement = document.getElementsByTagName('body')[0];
      if (newValue) {
        bodyElement.classList.add('content');

        // After authentication schedule check sync data
        useSync.oneShotSyncAllData();

        masterData.setIntervalSyncMasterData();
        if (config.company.modules.ORDER) {
          orderData.setIntervalSyncOrders();
        }
        if (config.company.modules.OFFER) {
          offerData.setIntervalSyncOffers();
        }
        // After authentication schedule check last data sync
        store.setIntervalLastDataSync();
      } else {
        bodyElement.classList.remove('content');
      }
    },
    { immediate: true }
  );

  watch(isOnline, (value, oldValue) => {
    if (value && !oldValue) {
      useSync.syncData();
    }
  });
</script>

<style scopend lang="scss">
  .content {
    @apply overflow-hidden;

    #the-content {
      @apply max-lg:w-full lg:w-[calc(100%-20.8125em)];
    }
  }

  .sidebar {
    z-index: 9998;
    @apply right-0 top-0 w-[20.8125em] border-l border-l-neutral-500 bg-white;
    @apply fixed h-full max-lg:hidden;
    @apply overflow-y-auto overflow-x-hidden;
  }
</style>
