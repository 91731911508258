<template>
  <header
    v-show="headerEnabled"
    id="the-header"
    class="flex h-[80px] max-lg:w-full lg:w-[calc(100%-20.8em)] items-center bg-neutral-250 justify-between"
    :class="{ invisible: headerVisible === false }"
  >
    <div class="flex items-center w-full header">
      <div class="flex flex-row text-xl w-fit">
        <div class="font-bold" v-if="primaryTextKey">{{ t(primaryTextKey) }}</div>
        <template v-if="hasSecondaryText">
          <span class="font-light">&nbsp;|&nbsp;</span>
          <h2 class="font-light" v-if="secondaryTextKey">{{ t(secondaryTextKey) }}</h2>
        </template>
      </div>
    </div>
    <div v-if="hierarchyEnabled && agentOptions.length > 2" class="flex header items-center w-1/2 justify-end">
      <div class="max-w-256 w-full">
        <AgFormSelect :options="agentOptions" v-model="internalAgentId" />
      </div>
    </div>
    <div class="flex flex-row header" v-if="isOfferWizard">
      <AgButton variant="ghost" class="text-link" @click="offerSaveClose" :disabled="!isOnline">
        <span>{{ t('save&close') }}</span>
        <template #icon><IcSave /></template>
      </AgButton>
    </div>
    <div class="flex flex-row header" v-if="isOrderWizard">
      <AgButton variant="ghost" class="text-link" @click="orderSaveClose" :disabled="!isOnline">
        <span>{{ t('save&close') }}</span>
        <template #icon><IcSave /></template>
      </AgButton>
    </div>
  </header>
</template>

<script setup lang="ts">
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';

  import IcSave from '@/components/icons/IcSave.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useOfferData } from '@/composables/data/useOfferData';
  import { useOrderData } from '@/composables/data/useOrderData';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useHierarchy } from '@/composables/useHierarchy';
  import { i18n } from '@/plugins/i18n';
  import { router } from '@/plugins/router';
  import { useHierarchyEmployeeStore } from '@/store/useHierarchyEmployeeStore';
  import { notify } from '@kyvg/vue3-notification';
  import { useNetwork } from '@vueuse/core';
  import { storeToRefs } from 'pinia';
  import { computed, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';

  const { t } = i18n.global;

  const route = useRoute();

  const { isOnline } = useNetwork();
  const { syncOffers } = useOfferData();
  const { syncOrders } = useOrderData();
  const { headerEnabled, headerVisible, saveButtonVisible, primaryTextKey, secondaryTextKey, hierarchyEnabled } =
    useTheHeader();
  const { resolveHierarchyUsers, getAllHierarchy } = useHierarchy();
  const { setAgentId, setIsEmployee, setUserId, setHasChildren } = useHierarchyEmployeeStore();
  const store = useHierarchyEmployeeStore();
  const { isFullReloading, isLoading } = useMasterData();
  const { agentId } = storeToRefs(store);

  const internalAgentId = ref<number>();
  const agentOptions = ref<Array<AgFormSelectOption>>([]);

  const hasSecondaryText = computed(() => {
    return secondaryTextKey.value !== '';
  });

  const isOfferWizard = computed(() => {
    const name = route.name;
    if (name === 'offer-wizard' && saveButtonVisible.value) {
      return true;
    }
    return false;
  });

  const isOrderWizard = computed(() => {
    const name = route.name;
    if (name === 'order-wizard' && saveButtonVisible.value) {
      return true;
    }
    return false;
  });

  const offerSaveClose = () => {
    syncOffers().then(async () => {
      notify({
        type: 'success',
        title: t('offerSaved'),
      });
      await router.push({
        path: '/offers',
      });
    });
  };

  const orderSaveClose = () => {
    syncOrders().then(async () => {
      notify({
        type: 'success',
        title: t('orderSaved'),
      });
      await router.push({
        path: '/orders',
      });
    });
  };

  const loadHierarchyList = async function () {
    const hierarchyUserList = await resolveHierarchyUsers();

    agentOptions.value = [];
    if (hierarchyUserList.length > 1) {
      agentOptions.value.push({ label: t('all'), key: -1 });

      hierarchyUserList.forEach((currentUser) => {
        agentOptions.value.push({ label: currentUser.name, key: currentUser.id });
      });
    }
  };

  const updateValues = async function (updateAgent: boolean) {
    const hierarchyList = await getAllHierarchy();

    if (hierarchyList.length > 0) {
      await loadHierarchyList();
      if (hierarchyList[0].workerAuthUser && hierarchyList[0].authUser) {
        setIsEmployee(true);
        setUserId(hierarchyList[0].workerAuthUser.id);
        if (updateAgent) {
          internalAgentId.value = hierarchyList[0].authUser.id;
          setAgentId(hierarchyList[0].authUser.id);
        }
        setHasChildren(hierarchyList[0].children && hierarchyList[0].children.length > 0);
      } else if (hierarchyList[0].authUser) {
        setIsEmployee(false);
        setUserId(hierarchyList[0].authUser.id);
        if (updateAgent) {
          internalAgentId.value = hierarchyList[0].authUser.id;
          setAgentId(hierarchyList[0].authUser.id);
        }
        setHasChildren(hierarchyList[0].children && hierarchyList[0].children.length > 0);
      }
    }
  };

  watch(internalAgentId, (newValue) => {
    if (newValue) {
      internalAgentId.value = newValue;
      setAgentId(newValue);
    }
  });

  watch(
    [isFullReloading, isLoading],
    async ([fullReload, reload]) => {
      if (!fullReload && !reload) {
        if (agentId.value !== undefined) {
          internalAgentId.value = agentId.value ?? -1;
          await updateValues(false);
        } else {
          await updateValues(true);
        }
      }
    },
    { immediate: true }
  );
</script>

<style scoped lang="scss">
  /* Vertical orientation of device */
  .header {
    @media screen and (orientation: portrait) {
      @apply md:lg:flex items-center mx-16 md:mx-[80px];
    }
    /* Horizontal orientation of device */
    @media screen and (orientation: landscape) {
      @apply md:lg:flex items-center mx-16 md:mx-[80px];
    }
  }

  .headerWithBack {
    @media screen and (orientation: portrait) {
      @apply md:lg:flex items-center mx-16;
    }
    /* Horizontal orientation of device */
    @media screen and (orientation: landscape) {
      @apply md:lg:flex items-center mx-16;
    }
  }
</style>
