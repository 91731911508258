<template>
  <div class="py-8" :class="{ 'bg-white': isDashboardOrLoginOrAdmin === false }">
    <footer
      v-show="footerEnabled"
      id="the-footer"
      :class="{
        'text-white': isDashboardOrLoginOrAdmin,
        invisible: footerVisible === false,
      }"
    >
      <KonvertoImprintLine />
    </footer>
  </div>
</template>

<script setup lang="ts">
  import KonvertoImprintLine from '@/components/framework/the-footer/KonvertoImprintLine.vue';
  import { useMsal } from '@/composables/auth/useMsal';

  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { computed } from 'vue';
  import { useRoute } from 'vue-router';

  const route = useRoute();
  const { footerEnabled, footerVisible } = useTheFooter();

  const isDashboardOrLoginOrAdmin = computed(() => {
    return '/dashboard' === route.path || '/login' === route.path || '/admin/dashboard' === route.path;
  });

  window.addEventListener('storage', function (event) {
    console.log('Logged out from another tab!', event.key);
    if (event.key == 'signOut-event') {
      useMsal().signOut();
    }
  });
</script>
