<template>
  <div>
    <TheAdminSubHeader @save="createCommunication" hasNotDeleteButton />
    <div class="md:mx-[80px]">
      <div class="infoBox">
        <div class="infoboxTitle">{{ t('admin.title').toUpperCase() }}</div>
        <AgFormInput
          v-model="communication.title"
          :validation-state="titleValidation"
          :error-message="t('admin.titleRequired')"
          @input="resetValidation"
        />
      </div>
      <div class="infoBox">
        <div class="infoboxTitle">{{ t('admin.subtitle').toUpperCase() }}</div>
        <AgFormInput v-model="communication.subtitle" />
      </div>
      <div class="infoBox">
        <div class="infoboxTitle">{{ t('admin.text').toUpperCase() }}</div>
        <AgFormTextarea
          v-model="communication.text"
          rows="7"
          :validation-state="textValidation"
          :error-message="t('admin.textRequired')"
          @update:modelValue="resetValidation"
        />
      </div>
      <div class="infoBox">
        <div class="infoboxTitle">{{ t('admin.language').toUpperCase() }}</div>
        <AgFormSelect :options="langOptions" v-model="communication.language" />
      </div>
      <div class="infoBox w-full" v-if="communication.download">
        <div class="infoboxTitle">{{ t('admin.download').toUpperCase() }}</div>
        <div class="w-1/2">
          <AgSearchSelect :options="downloadOptions" v-model="communication.download.id" />
        </div>
      </div>
      <div class="infoBox w-full" v-if="communication.permissionGroup">
        <div class="infoboxTitle">{{ t('admin.permission').toUpperCase() }}</div>
        <div class="w-1/2">
          <AgSearchSelect :options="permissionGroupOptions" v-model="communication.permissionGroup.id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { CommunicationDto } from '@/admin/domain/CommunicationDto';
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';

  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import AgFormTextarea from '@/components/library/form-textarea/AgFormTextarea.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';

  import { useCommunication } from '@/admin/modules/communication/composables/useCommunication';
  import TheAdminSubHeader from '@/components/framework/admin/the-admin-sub-header/TheAdminSubHeader.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { config } from '@/config/config';
  import { i18n } from '@/plugins/i18n';
  import { onBeforeMount, ref } from 'vue';
  import { useRouter } from 'vue-router';

  const { t, locale } = i18n.global;
  const { createData, getDownloads, getPermissionGroups } = useCommunication();
  const router = useRouter();

  const theHeader = useTheHeader();

  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.newCommunication');

  const downloadOptions = ref<Array<AgSearchSelectOption>>([]);
  const permissionGroupOptions = ref<Array<AgSearchSelectOption>>([]);
  const textValidation = ref<boolean>(true);
  const titleValidation = ref<boolean>(true);

  const langOptions = ref<Array<AgFormSelectOption>>(
    Object.values(config.company.availableLanguages).map((lang) => ({
      key: lang,
      label: `downloads.language.${lang}`,
    }))
  );
  const communication = ref<CommunicationDto>({
    id: -1,
    download: {
      id: -1,
      category: {
        code: '',
        id: -1,
        downloadsNumber: 0,
        title: {},
      },
      fileName: '',
      title: '',
      language: locale.value,
      articleGroupId: null,
      articleId: null,
      permissionGroupId: null,
      numberOfCommunications: 0,
    },
    language: locale.value,
    permissionGroup: {
      code: '',
      id: -1,
      agents: [],
      communicationsNumber: 0,
      downloadsNumber: 0,
    },
    subtitle: '',
    text: '',
    title: '',
  });

  const resetValidation = () => {
    textValidation.value = true;
    titleValidation.value = true;
  };

  const createCommunication = async () => {
    if (!communication.value.title || communication.value.title.length === 0) {
      titleValidation.value = false;
    }
    if (!communication.value.text || communication.value.text.length === 0) {
      textValidation.value = false;
    }
    if (!titleValidation.value || !textValidation.value) {
      return;
    }
    createData(communication.value).then(() => {
      router.push({ path: '/admin/communication' });
    });
  };

  onBeforeMount(async () => {
    downloadOptions.value.push({ label: 'admin.noChoice', value: -1, searchableString: 'admin.noChoice' });
    permissionGroupOptions.value.push({ label: 'admin.noChoice', value: -1, searchableString: 'admin.noChoice' });

    const downloads = await getDownloads();
    downloadOptions.value.push(
      ...downloads.map((download) => ({
        label: download.fileName,
        value: download.id,
        searchableString: download.fileName,
      }))
    );

    const permissionGroupsData = await getPermissionGroups();
    permissionGroupOptions.value.push(
      ...permissionGroupsData.map((group) => ({
        label: group.code,
        value: group.id,
        searchableString: group.code,
      }))
    );
  });
</script>
<style scoped lang="scss">
  .infoboxTitle {
    @apply text-primary-light;
  }

  .name {
    @apply text-xl font-bold flex items-center;
  }

  .infoBox {
    @apply w-full justify-center my-12;
  }

  .table {
    @apply grid grid-cols-1 py-16 ml-32 space-x-0;
  }

  .tableColumn {
    @apply flex items-center min-w-0 ml-48 w-1/2 py-4 space-x-12;
  }
</style>
