<template>
  <div class="tableHeader">
    <div class="border-r border-neutral-500">&nbsp;</div>
    <div class="tableColumn">
      <div class="tableColumnEntry">
        <span class="title">{{ props.periodTwo }}</span>
        <span class="subTitle">{{ getSubPeriod(props.periodTwo) }}</span>
      </div>
    </div>
    <div class="tableColumn">
      <div class="tableColumnEntry">
        <span class="title">{{ props.periodOne }}</span>
        <span class="subTitle">{{ getSubPeriod(props.periodOne) }}</span>
      </div>
    </div>
    <div class="tableColumn">
      <div class="px-24 text-black text-l font-bold w-full">
        {{ t('statistics.difference') }}&nbsp;({{ props.periodTwo }} - {{ props.periodOne }})
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { i18n } from '@/plugins/i18n';

  const { t } = i18n.global;

  interface Props {
    periodOne: number;
    periodTwo: number;
    startMonth: number;
    endMonth: number;
  }
  const props = defineProps<Props>();

  const getSubPeriod = function (year: number) {
    let lastMonth = props.endMonth;
    let lastDay = new Date(year, lastMonth, 0).getDate();
    if (props.endMonth === 13) {
      lastDay = new Date().getDate();
      lastMonth = new Date().getMonth() + 1;
    }
    const startMonth = props.startMonth < 10 ? `0${props.startMonth}` : props.startMonth;
    const endMonth = lastMonth < 10 ? `0${lastMonth}` : lastMonth;
    const subPeriodLabel = `01.${startMonth}.${year} - ${lastDay}.${endMonth}.${year}`;

    return subPeriodLabel;
  };
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid grid-cols-[minmax(233px,433px)_300px_300px_350px];
    @apply font-light text-neutral-550;
  }

  .tableColumn {
    @apply flex items-center justify-center;
  }

  .tableColumnEntry {
    @apply flex flex-col w-full border-r border-neutral-500 px-12;
  }

  .title {
    @apply text-black text-l font-bold;
  }

  .subTitle {
    @apply text-black font-normal;
  }
</style>
