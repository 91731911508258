<template>
  <AgModal v-if="internalValue" fit-height fit-width>
    <template #header>
      <span class="text-l font-bold">{{ t('offers.articleAttachments') }}&nbsp;</span>
      <span class="text-l italic font-light">{{ article.title[locale] }}</span>
      <AgButton variant="ghost" @click="internalValue = false" class="flex justify-end">
        <template #icon><IcClose class="h-24 w-24" /></template>
      </AgButton>
    </template>
    <template #content>
      <div class="min-h-384">
        <div v-if="isLoading" class="flex items-center justify-center min-h-384">
          <IcLoading />
        </div>
        <div v-else-if="downloads.length > 0">
          <div v-for="(download, index) in downloads" :key="index" class="flex items-center gap-4 p-4">
            <div class="flex w-full">
              <span class="font-light italic"> {{ download.title }}&nbsp;({{ download.fileName }}) </span>
            </div>
            <div class="flex gap-4">
              <a
                :href="download.fileUrl"
                @click.prevent="openFile(download.fileUrl, true)"
                target="_blank"
                class="flex items-center gap-1 text-blue-500 hover:text-blue-700"
              >
                <IcDownload /><span class="hidden">Download</span>
              </a>
              <a
                :href="download.fileUrl"
                @click.prevent="openFile(download.fileUrl, false)"
                target="_blank"
                class="flex items-center gap-1 text-green-500 hover:text-green-700"
              >
                <IcShow /><span class="hidden">View</span>
              </a>
            </div>
          </div>
        </div>
        <div v-else class="flex items-center justify-center min-h-384">{{ t('offers.noAttachment') }}</div>
      </div>
    </template>
    <template #footer>
      <div class="flex w-full justify-end">
        <AgButton @click="closeModal">{{ t('offers.close') }}</AgButton>
      </div>
    </template>
  </AgModal>
</template>
<script setup lang="ts">
  import IcClose from '@/components/icons/IcClose.vue';
  import IcDownload from '@/components/icons/IcDownload.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcShow from '@/components/icons/IcShow.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';
  import type { DownloadDto } from '@/domain/DownloadDto';
  import type { ShoppingCartArticleDto } from '@/domain/masterData/ShoppingCartArticleDto';
  import { useOfferFactory } from '@/modules/offers/composables/useOfferFactory';
  import { i18n } from '@/plugins/i18n';
  import { DownloadArticleFilters } from '@/util/filters/DownloadArticleFilters';
  import { computed, ref, watch } from 'vue';

  interface Props {
    article: ShoppingCartArticleDto;
    modelValue: boolean;
  }
  const props = defineProps<Props>();

  const { locale, t } = i18n.global;
  const { getArticleDownloads, openFile } = useOfferFactory();

  const emit = defineEmits(['update:modelValue']);

  const downloads = ref<Array<DownloadDto>>([]);
  const isLoading = ref<boolean>(true);

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const closeModal = function () {
    internalValue.value = false;
  };

  watch([internalValue, locale], (value) => {
    isLoading.value = true;
    if (value) {
      const filter = new DownloadArticleFilters(locale.value, props.article.id, props.article.articleGroup?.id);

      getArticleDownloads(filter).then((data) => {
        downloads.value = data;
        isLoading.value = false;
      });
    }
  });
</script>
