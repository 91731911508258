<template>
  <div class="my-12">
    <div v-if="isHeadDiscountsLoding"><IcLoading /></div>

    <div v-else>
      <div v-if="customerDiscount">
        <div class="m-8 font-bold">{{ t('customers.customer') }}</div>
        <div class="m-8 grid grid-flow-col gap-4 grid-cols-[233px_333px]">
          <div class="tableColumn">{{ t('customers.cartDiscount') }}</div>
          <div class="tableColumn">{{ formatNumberToPercentage(customerDiscount) }}</div>
        </div>
      </div>
      <div v-else>
        <AgAlert variant="info">{{ t('customers.noCustomerDiscount') }}</AgAlert>
      </div>
    </div>

    <hr class="mx-12 my-24" />

    <div class="my-32">
      <AgSearchSelect :options="articleOptions" :placeholder="t('customers.search')" v-model="articleId" />
    </div>

    <div class="z-10">
      <div v-if="articleTradeAgreements.length === 0">
        <AgAlert variant="info">{{ t('customers.noTradeAgreements') }}</AgAlert>
      </div>

      <div v-else>
        <div class="my-8 font-bold">{{ t('customers.article') }}</div>
        <div class="my-8 font-bold">
          <span class="mr-4 bg-danger px-4">&nbsp;</span>
          <span>{{ t('customers.articleOnPromotion') }}</span>
        </div>

        <!-- Trade Agreement Table HEADER -->
        <div class="tableHeader grid-cols-[8px_133px_minmax(133px,433px)_93px_103px_minmax(233px,1fr)_23px]">
          <div class="headerColumn">&nbsp;</div>
          <div class="headerColumn">{{ t('customers.articleCode') }}</div>
          <div class="headerColumn">{{ t('customers.description') }}</div>
          <div class="headerColumn">{{ t('customers.fromQuantity') }}</div>
          <div class="headerColumn">{{ t('customers.price') }}</div>
          <div class="headerColumn justify-between pl-8">
            <span>({{ t('customers.fromQuantity') }}) {{ t('customers.discount') }}</span>
            <span>{{ t('customers.netPrice') }}</span>
          </div>
          <div class="headerColumn right">&nbsp;</div>
        </div>
        <div class="max-h-512 overflow-y-auto">
          <!-- Trade Agreement Table BODY -->
          <div v-for="cta in articleTradeAgreements" :key="cta.tradeAgreement.id">
            <div class="table grid-cols-[8px_133px_minmax(133px,433px)_93px_103px_minmax(233px,1fr)_23px]">
              <div class="tableColumn">
                <span class="w-8 h-[1em]" :class="{ 'bg-danger': cta.tradeAgreement.customerType === 5 }">&nbsp;</span>
              </div>
              <div class="tableColumn">
                <div class="flex-col">
                  <div>{{ cta.article.code }}</div>
                  <div :class="{ hidden: isDebugActive === false }" class="text-s-13">[id: {{ cta.article.id }}]</div>
                </div>
              </div>
              <div class="tableColumn">
                <div class="flex-col">
                  <div>{{ getTranslatedText(cta.article.title) }}</div>
                  <div v-if="cta.tradeAgreement.articleDimensionValueConfigId" class="text-s-13">
                    {{ getDimension(cta.tradeAgreement.articleDimensionValueConfigId, locale) }}
                  </div>
                  <div :class="{ hidden: isDebugActive === false }" class="text-s-13">
                    <span>[customerType: {{ cta.tradeAgreement.customerType }}],</span>
                    <span>
                      [priceGroupId: {{ cta.tradeAgreement.priceGroupId ? cta.tradeAgreement.priceGroupId : '-' }}],
                    </span>
                    <span>
                      [priceListId: {{ cta.tradeAgreement.priceListId ? cta.tradeAgreement.priceListId : '-' }}]
                    </span>
                  </div>
                </div>
              </div>
              <div class="tableColumn">
                <div class="flex-col">
                  <div>{{ cta.tradeAgreement.fromQuantity }}</div>
                  <div :class="{ hidden: isDebugActive === false }" class="text-s-13">&nbsp;</div>
                </div>
              </div>
              <div class="tableColumn right">
                <div class="flex flex-col">
                  <div :class="{ 'text-danger': cta.tradeAgreement.customerType === 5 }">
                    {{ formatNumberToEuro(getPrice(cta.tradeAgreement, cta.customer.segment)) }} /
                    {{ cta.article.unit }}
                  </div>
                  <div :class="{ hidden: isDebugActive === false }" class="text-s-13">&nbsp;</div>
                </div>
              </div>
              <div class="flex flex-col justify-center mx-4" v-if="cta.discounts.length > 0">
                <div v-for="discount in cta.discounts" :key="discount.id">
                  <div class="flex justify-between gap-4">
                    <div class="min-w-[33px] flex items-center">({{ discount.fromQuantity }})</div>
                    <div class="w-full flex justify-start">{{ getDiscounts(discount) }}</div>
                    <div class="min-w-[83px] flex justify-end">
                      {{ formatNumberToEuro(getNetPrice(cta.tradeAgreement, cta.customer.segment, discount)) }}
                    </div>
                  </div>
                  <div v-if="discount.articleDimensionValueConfigId" class="text-s-13">
                    {{ getDimension(discount.articleDimensionValueConfigId, locale) }}
                  </div>
                  <div class="text-s-13" :class="{ hidden: isDebugActive === false }">
                    <span>[customerType: {{ discount.customerType }}],</span>
                    <span>[priceGroupId: {{ discount.priceGroupId ? discount.priceGroupId : '-' }}],</span>
                    <span>[priceListId: {{ discount.priceListId ? discount.priceListId : '-' }}]</span>
                    <span>[discountListId: {{ discount.discountListId ? discount.discountListId : '-' }}]</span>
                  </div>
                </div>
              </div>
              <div v-else class="tableColumn right justify-center">
                <div class="flex-col">
                  <div>
                    {{ formatNumberToEuro(getPrice(cta.tradeAgreement, cta.customer.segment)) }}
                  </div>
                  <div :class="{ hidden: isDebugActive === false }" class="text-s-13">&nbsp;</div>
                </div>
              </div>
              <div class="tableColumn right justify-center">
                <AgButton variant="ghost" @click.stop="removeTradeAgreement(cta)">
                  <template #icon><IcDelete /></template>
                </AgButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import type { CustomerTradeAgreement } from '@/domain/internal/CustomerTradeAgreement';
  import type { ArticleDimensionValueConfigsDto } from '@/domain/masterData/ArticleDimensionValueConfigsDto';
  import type { CustomerDto } from '@/domain/masterData/CustomerDto';
  import type { DiscountDto } from '@/domain/masterData/DiscountDto';
  import type { ShoppingCartArticleDto } from '@/domain/masterData/ShoppingCartArticleDto';
  import type { TradeAgreementDto } from '@/domain/masterData/TradeAgreementDto';

  import IcDelete from '@/components/icons/IcDelete.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useCommon } from '@/composables/useCommon';
  import { useNordwal } from '@/composables/useNordwal';
  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { config } from '@/config/config';
  import { CustomerType } from '@/domain/enumeration/CustomerType';
  import { SegmentType } from '@/domain/enumeration/SegmentType';
  import { i18n } from '@/plugins/i18n';
  import { notify } from '@kyvg/vue3-notification';
  import { computed, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';

  const { t, locale } = i18n.global;

  const { isFullReloading, isLoading, tables } = useMasterData();

  const { formatNumberToEuro } = useNumberFormatting();
  const { formatNumberToPercentage } = useNumberFormatting();
  const { getTranslatedText } = useTranslatedText();

  const route = useRoute();
  const common = useCommon();

  interface Props {
    customer: CustomerDto | null;
  }
  const props = withDefaults(defineProps<Props>(), {
    customer: null,
  });

  const articleId = ref<number>();
  const customerDiscount = ref<number>();
  const articles = ref<Array<ShoppingCartArticleDto>>([]);
  const articleTradeAgreements = ref<Array<CustomerTradeAgreement>>([]);
  const articleDimensionValueConfigs = ref<Array<ArticleDimensionValueConfigsDto>>([]);

  const isHeadDiscountsLoding = ref<boolean>(true);

  const loadArticleDimensionValueConfigs = async function () {
    articleDimensionValueConfigs.value = await tables.articleDimensionValueConfigs.toArray();
  };

  const getDimension = function (articleDimensionValueConfigId: number, locale: string) {
    const advc = articleDimensionValueConfigs.value.find((d) => d.id === articleDimensionValueConfigId);
    if (advc) {
      return `${advc.dimensionConfig.title[locale]}: ${advc.title[locale]}`;
    }
    return '';
  };

  const loadHeadDiscount = async function () {
    if (props.customer) {
      const customerId = props.customer.id;

      const headDiscount = await common.getHeadDiscount(customerId);

      isHeadDiscountsLoding.value = false;
      customerDiscount.value = undefined;
      if (headDiscount.isPresent() && headDiscount.get().discount1) {
        customerDiscount.value = headDiscount.get().discount1 || 0;
      }
    } else {
      customerDiscount.value = undefined;
      isHeadDiscountsLoding.value = false;
    }
  };

  const filterBySegment = function (ta: TradeAgreementDto, segment: string | null) {
    if (!segment || (ta.salesPrice === 0 && ta.industrialPrice === 0)) {
      return true;
    }
    if (segment === SegmentType.INDUSTRIAL) {
      return ta.industrialPrice > 0;
    }
    return ta.salesPrice > 0;
  };

  const compareFn = function (a?: number | null, b?: number | null) {
    return (a || 0) - (b || 0);
  };

  const loadArticleTradeAgreements = async function () {
    const article = articles.value.find((a) => a.id === articleId.value);
    if (article && props.customer) {
      const customerId = props.customer.id;
      const priceListId = props.customer.priceListId;
      const discountListId = props.customer.discountListId;

      const segment = ref<string | null>(null);
      if (props.customer.segment) {
        segment.value = props.customer.segment;
      } else if (config.company.code === 'NORDWAL') {
        segment.value = SegmentType.SALES;
      }
      const contains = articleTradeAgreements.value.find((cta) => cta.article.id === article.id);
      if (!contains) {
        const allTradeAgreement = await common.getTradeAgreements(
          article.id,
          article.priceGroupIds,
          customerId,
          priceListId
        );
        const tradeAgreements = filterTradeAgreements(allTradeAgreement, segment.value);
        tradeAgreements
          .sort((t1, t2) => t1.fromQuantity - t2.fromQuantity)
          .sort((t1, t2) => compareFn(t1.articleDimensionValueConfigId, t2.articleDimensionValueConfigId));

        for (const [i, tradeAgreement] of tradeAgreements.entries()) {
          const isPromotional = tradeAgreement.price > 0 && tradeAgreement.customerType === CustomerType.PROMOTION;

          const discounts: DiscountDto[] = [];
          // At the moment, only NORDWAL has promotions and you don't have to look for discounts
          if (!isPromotional) {
            let nextTa = undefined;
            if (i >= 0 && i < tradeAgreements.length - 1) {
              const tan = tradeAgreements[i + 1];
              if (
                tradeAgreement.fromQuantity < tan.fromQuantity &&
                tradeAgreement.articleDimensionValueConfigId === tan.articleDimensionValueConfigId
              ) {
                nextTa = tan;
              }
            }
            if (config.company.code === 'NORDWAL') {
              const nordwal = useNordwal();

              const customerType = tradeAgreement.customerType;
              const fromQt = tradeAgreement.fromQuantity;
              const toQt = nextTa ? nextTa.fromQuantity : undefined;

              const positions = await nordwal.getPositionDiscounts(
                article.id,
                article.priceGroupIds,
                customerId,
                priceListId,
                customerType,
                undefined,
                fromQt,
                toQt
              );
              if (positions.length > 0) {
                discounts.push(...positions);
              }
              if (
                positions.length > 0 &&
                tradeAgreement.customerType !== CustomerType.ALL &&
                tradeAgreement.price === 0
              ) {
                const baseTa = await nordwal.getBaseTradeAgreements(article.id, segment.value);
                if (baseTa) {
                  tradeAgreement.price = baseTa.salesPrice;
                  if (segment.value && segment.value === SegmentType.INDUSTRIAL) {
                    tradeAgreement.price = baseTa.industrialPrice;
                  }
                }
              }
            } else {
              const fromQt = nextTa ? nextTa.fromQuantity : undefined;

              const positions = await common.getPositionDiscounts(
                article.id,
                article.priceGroupIds,
                customerId,
                priceListId,
                discountListId,
                fromQt
              );
              if (positions.length > 0) {
                const finalDiscounts = getFinalDiscounts(tradeAgreement, positions);
                discounts.push(...finalDiscounts);
              }
            }
          }
          articleTradeAgreements.value.push({
            article: article,
            customer: props.customer,
            tradeAgreement: tradeAgreement,
            discounts: discounts,
          });
          articleTradeAgreements.value.sort((a, b) => a.article.code.localeCompare(b.article.code));
        }
      } else {
        notify({
          type: 'error',
          title: t('customers.isAlreadyLoaded'),
          text: t('customers.isAlreadyLoadedDesc'),
        });
      }
    } else {
      articleTradeAgreements.value = [];
    }
  };

  const filterTradeAgreements = function (tas: TradeAgreementDto[], segment: string | null) {
    if (tas.length === 1) {
      return tas;
    }
    const groupBy = tas.reduce((group: { [key: string]: TradeAgreementDto[] }, ta: TradeAgreementDto) => {
      const byFromQuantity = ta.fromQuantity;
      const byDimension = ta.articleDimensionValueConfigId;

      const index = byFromQuantity + '_' + byDimension;

      group[index] = group[index] ?? [];
      group[index].push(ta);

      return group;
    }, {});
    const finaltas: TradeAgreementDto[] = [];

    Object.values(groupBy).forEach((value) => {
      if (value.length > 1) {
        const taPromotion = value.filter((ta) => ta.customerType === CustomerType.PROMOTION);
        const taCustomerArticle = value.filter((ta) => ta.customerType === CustomerType.CUSTOMER_ARTICLE);
        const taCustomerPriceGroup = value.filter((ta) => ta.customerType === CustomerType.CUSTOMER_PRICEGROUP);
        const taPriceListArticle = value.filter((ta) => ta.customerType === CustomerType.PRICELIST_ARTICLE);
        const taPriceListPriceGroup = value.filter((ta) => ta.customerType === CustomerType.PRICELIST_PRICEGROUP);
        const taAll = value.filter((ta) => ta.customerType === CustomerType.ALL && filterBySegment(ta, segment));

        if (taPromotion.length > 0) {
          finaltas.push(...taPromotion);
        } else if (taCustomerArticle.length > 0) {
          finaltas.push(...taCustomerArticle);
        } else if (taCustomerPriceGroup.length > 0) {
          finaltas.push(...taCustomerPriceGroup);
        } else if (taPriceListArticle.length > 0) {
          finaltas.push(...taPriceListArticle);
        } else if (taPriceListPriceGroup.length > 0) {
          finaltas.push(...taPriceListPriceGroup);
        } else if (taAll.length > 0) {
          finaltas.push(...taAll);
        }
      } else {
        finaltas.push(...value);
      }
    });
    return finaltas;
  };

  const getFinalDiscounts = function (ta: TradeAgreementDto, dis: DiscountDto[], nextTa?: TradeAgreementDto) {
    if (dis.length === 1) {
      return dis.filter((d) => (nextTa ? d.fromQuantity < nextTa.fromQuantity : d.fromQuantity >= 0));
    }
    const ds: DiscountDto[] = dis.sort((d1, d2) => d1.fromQuantity - d2.fromQuantity);

    const groupBy = ds.reduce((group: { [key: string]: DiscountDto[] }, discount: DiscountDto) => {
      const byFromQuantity = discount.fromQuantity;
      const byDimension = discount.articleDimensionValueConfigId;

      const index = byFromQuantity + '_' + byDimension;

      group[index] = group[index] ?? [];
      group[index].push(discount);

      return group;
    }, {});
    const discounts: DiscountDto[] = [];

    Object.values(groupBy).forEach((value) => {
      if (value.length > 1) {
        const dsPromotion = value.filter((d) => d.customerType === CustomerType.PROMOTION);
        const dsCustomerArticle = value.filter((d) => d.customerType === CustomerType.CUSTOMER_ARTICLE);
        const dsCustomerPriceGroup = value.filter((d) => d.customerType === CustomerType.CUSTOMER_PRICEGROUP);
        const dsCustomer = value.filter((d) => d.customerType === CustomerType.CUSTOMER);
        const dsDiscountListArticle = value.filter((d) => d.customerType === CustomerType.DISCOUNTLIST_ARTICLE);
        const dsDiscountListPriceGroup = value.filter((d) => d.customerType === CustomerType.DISCOUNTLIST_PRICEGROUP);
        const dsDiscountList = value.filter((d) => d.customerType === CustomerType.DISCOUNTLIST);
        const dsPriceListArticle = value.filter((d) => d.customerType === CustomerType.PRICELIST_ARTICLE);
        const dsPriceListPriceGroup = value.filter((d) => d.customerType === CustomerType.PRICELIST_PRICEGROUP);
        const dsPriceList = value.filter((d) => d.customerType === CustomerType.PRICELIST);
        const dsAll = value.filter((d) => d.customerType === CustomerType.ALL);
        const dsPriceGroup = value.filter((d) => d.customerType === CustomerType.PRICEGROUP);

        if (dsPromotion.length > 0) {
          discounts.push(dsPromotion[0]);
        } else if (dsCustomerArticle.length > 0) {
          discounts.push(dsCustomerArticle[0]);
        } else if (dsCustomerPriceGroup.length > 0) {
          discounts.push(dsCustomerPriceGroup[0]);
        } else if (dsCustomer.length > 0) {
          discounts.push(dsCustomer[0]);
        } else if (dsDiscountListArticle.length > 0) {
          discounts.push(dsDiscountListArticle[0]);
        } else if (dsDiscountListPriceGroup.length > 0) {
          discounts.push(dsDiscountListPriceGroup[0]);
        } else if (dsDiscountList.length > 0) {
          discounts.push(dsDiscountList[0]);
        } else if (dsPriceListArticle.length > 0) {
          discounts.push(dsPriceListArticle[0]);
        } else if (dsPriceListPriceGroup.length > 0) {
          discounts.push(dsPriceListPriceGroup[0]);
        } else if (dsPriceList.length > 0) {
          discounts.push(dsPriceList[0]);
        } else if (dsAll.length > 0) {
          discounts.push(dsAll[0]);
        } else if (dsPriceGroup.length > 0) {
          discounts.push(dsPriceGroup[0]);
        }
      } else {
        discounts.push(...value);
      }
    });
    let finalDiscounts = discounts.filter(
      (d) => !d.articleDimensionValueConfigId && (nextTa ? d.fromQuantity < nextTa.fromQuantity : d.fromQuantity >= 0)
    );
    if (ta.articleDimensionValueConfigId) {
      const finalDiscountsWithDimension = discounts.filter(
        (d) =>
          d.articleDimensionValueConfigId === ta.articleDimensionValueConfigId &&
          (nextTa ? d.fromQuantity < nextTa.fromQuantity : d.fromQuantity >= 0)
      );
      if (finalDiscountsWithDimension.length > 0) {
        finalDiscounts = finalDiscountsWithDimension;
      }
      if (finalDiscounts.length > 0 && finalDiscounts[0].fromQuantity > ta.fromQuantity) {
        const tfds = discounts.filter(
          (d) =>
            !d.articleDimensionValueConfigId && d.fromQuantity > 0 && d.fromQuantity < finalDiscounts[0].fromQuantity
        );
        if (tfds.length > 0) {
          const fdsg = tfds.filter((d) => d.fromQuantity > ta.fromQuantity);
          const fdsm = tfds.filter((d) => d.fromQuantity <= ta.fromQuantity);
          fdsg.unshift(fdsm[fdsm.length - 1]);

          finalDiscounts.unshift(...fdsg);
        }
      }
    }
    if (nextTa || ta.fromQuantity === 0) {
      return finalDiscounts;
    }
    const fdsCount = finalDiscounts.filter((d) => d.fromQuantity >= ta.fromQuantity).length;
    if (fdsCount === 0) {
      return finalDiscounts.filter((d) => Math.max(d.fromQuantity));
    }
    return finalDiscounts;
  };

  const getDiscounts = function (discount: DiscountDto) {
    let discounts = '';

    const maxItemDiscounts = config.company.maxItemDiscounts;
    if (discount && maxItemDiscounts > 0) {
      for (let i = 1; i <= maxItemDiscounts; i++) {
        type ObjectKey = keyof typeof discount;

        const key = `discount${i}` as ObjectKey;
        if (discount[key] && typeof discount[key] === 'number') {
          const val = discount[key] as number;
          const value = '-' + formatNumberToPercentage(val);
          if (i === 1) {
            discounts = value;
          } else {
            discounts = discounts + ' | ' + value;
          }
        }
      }
    }
    return discounts;
  };

  const getPrice = function (ta: TradeAgreementDto, segment: string | null) {
    if (ta.customerType === CustomerType.ALL) {
      if (ta.salesPrice === 0 && ta.industrialPrice === 0) {
        return ta.price;
      }
      if (segment && segment.length > 0 && segment === SegmentType.INDUSTRIAL) {
        return ta.industrialPrice;
      }
      return ta.salesPrice;
    }
    return ta.price;
  };

  const getNetPrice = function (tradeAgreement: TradeAgreementDto, segment: string | null, discount?: DiscountDto) {
    let price = getPrice(tradeAgreement, segment);

    const maxItemDiscounts = config.company.maxItemDiscounts;
    if (discount && maxItemDiscounts > 0) {
      for (let i = 1; i <= maxItemDiscounts; i++) {
        type ObjectKey = keyof typeof discount;

        const key = `discount${i}` as ObjectKey;
        if (discount[key]) {
          const dValue = discount[key] as number;
          price = price * (1 - dValue / 100);
        }
      }
    }
    return price;
  };

  const loadArticles = async function () {
    articles.value = [];
    if (props.customer) {
      articles.value = await common.getArticlesForCustomer(props.customer.id, props.customer.priceListId);
    }
  };

  const removeTradeAgreement = function (cta: CustomerTradeAgreement) {
    const index = articleTradeAgreements.value.indexOf(cta);
    if (index > -1) {
      articleTradeAgreements.value.splice(index, 1);
      articleId.value = undefined;
    }
  };

  const articleOptions = computed((): Array<AgSearchSelectOption> => {
    return articles.value.map((a) => {
      const label: string[] = [a.code];

      const articleType = getTranslatedText(a.articleType?.title);
      if (articleType.length > 0) {
        label.push(articleType);
      }
      const title = getTranslatedText(a.title);
      if (title.length > 0) {
        label.push(title);
      }
      if (a.searchName) {
        label.push(`(${a.searchName})`);
      }
      return {
        value: a.id,
        label: label.join(' - '),
        searchableString: a.code + (articleType ?? '') + title + a.searchName,
        disabled: !a.code,
      };
    });
  });

  const isDebugActive = computed((): boolean => {
    if (route.query.debug && route.query.debug === 'true') {
      return true;
    }
    return false;
  });

  watch(
    [isFullReloading, isLoading],
    async ([fullReload, loading]) => {
      if (!fullReload && !loading) {
        await loadArticleDimensionValueConfigs();
        await loadHeadDiscount();
        await loadArticles();
      }
    },
    { immediate: true }
  );

  watch(
    articleId,
    async (newArticleId, oldArticleId) => {
      if (newArticleId && newArticleId !== oldArticleId) {
        await loadArticleTradeAgreements();
      }
    },
    { immediate: false }
  );
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid gap-4 py-8 text-neutral-550;
  }

  .headerColumn {
    @apply flex font-light items-center;
  }

  .table {
    @apply grid gap-4 py-8 border-t border-neutral-500;
  }

  .table:hover {
    @apply bg-neutral-200;
  }

  .tableColumn {
    @apply flex items-center;
  }

  .right {
    @apply justify-end;
  }

  .specialPrice {
    font-weight: bold;
    color: #dc3545;
  }
</style>
