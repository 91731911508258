<template>
  <div v-if="order" class="md:mx-[80px] max-md:mx-16 mt-12 mb-32 space-y-8">
    <OrderInfo />

    <div class="flex justify-end py-16 gap-12" v-if="config.company.sendEmailOrder">
      <!-- EXPORT ORDER -->
      <AgButton
        variant="ghost"
        class="text-link"
        @click="csvExport(csvDataExport, csvHeader, fileName)"
        :disabled="!order.gid"
      >
        Export to CSV
        <template #icon><IcDownload /></template>
      </AgButton>

      <!-- SEND ORDER EMAIL -->
      <AgButton
        variant="ghost"
        class="text-link"
        @click="openPopupSendMail"
        :disabled="!order.gid || isCustomerBlocked || !isOnline"
      >
        {{ t('orders.sendByMail') }}
        <template #icon><IcMailClose /></template>
      </AgButton>
    </div>

    <!-- IF ORDER IS COMPLETED -->
    <div class="py-16" v-if="order.status === OrderStatus.COMPLETED">
      <AgBox variant="alternative">
        <div class="pb-12 font-bold" v-if="order.synced === 1">{{ t('orders.orderCompletedAndTransmit') }}</div>
        <div class="pb-12 font-bold" v-else>{{ t('orders.orderCompletedAndNotTransmit') }}</div>
        <div class="pb-12">
          <span class="text-lg font-bold">{{ t('orders.orderNumber') }}:&nbsp;</span>
          <span v-if="order.orderNumber">{{ order.orderNumber }}</span>
          <span v-else>{{ t('orders.orderNumberNotAvailable') }}</span>
        </div>
        <div>
          <span class="font-bold">{{ t('orders.orderDate') }}:&nbsp;</span>
          <span>{{ formatDate(order.orderDate) }}</span>
        </div>
      </AgBox>
    </div>

    <!-- ORDER ARTICLE INFO -->
    <div class="py-16">
      <AgBox v-if="items.length > 0" variant="accent" class="max-h-[333px] overflow-scroll">
        <!-- ARTICLES -->
        <div v-for="item in items" :key="item.article.id">
          <div class="flex-col border-b-2 border-y-neutral-200 w-full py-12">
            <div
              class="flex gap-12 text-s-13 font-light w-fit text-neutral-550 italic"
              v-if="item.article.articleGroup || item.article.articleType"
            >
              <div v-if="item.article.articleGroup">
                {{ getTranslatedText(item.article.articleGroup) }}
              </div>
              <div v-if="item.article.articleType">
                {{ getTranslatedText(item.article.articleType) }}
              </div>
            </div>
            <div class="flex flex-row items-center gap-4">
              <span class="font-bold text-l">{{ getTranslatedText(item.article.title) }}</span>
              <div v-if="item.note">
                <Popper class="agPopper" arrow @click.stop>
                  <AgButton variant="ghost">
                    <template #icon>
                      <IcInfo class="text-link" />
                    </template>
                  </AgButton>
                  <template #content>
                    <div class="p-8 text-s-20 whitespace-pre-wrap w-[350px] h-[230px] overflow-scroll">
                      {{ item.note }}
                    </div>
                  </template>
                </Popper>
              </div>
            </div>
            <div>
              <span class="text-neutral-550 font-light">{{ t('orders.articleCode') }}:&nbsp;</span>
              <span>{{ item.article.code }}</span>
            </div>
            <div v-if="item.article.externalCode">
              <span class="text-neutral-550 font-light">{{ t('orders.exCode') }}:&nbsp;</span>
              <span>{{ item.article.externalCode }}</span>
            </div>
            <div class="font-bold mt-12">
              <span class="font-bold">{{ t('orders.packaging') }}:&nbsp;</span>
              {{ item.packaging.unit.title[locale] }}
            </div>

            <div class="flex items-start">
              <div class="flex flex-col basis-6/12">
                <div v-if="item.quantity && item.quantity > 0">
                  <span class="justify-end text-neutral-550 font-light">{{ t('orders.quantity') }}:&nbsp;</span>
                  <span class="font-bold">{{ item.quantity }}&nbsp;</span>
                  <span>(x {{ item.packaging.size }} = {{ (item.packaging.size * item.quantity).toFixed(2) }})</span>
                </div>
                <div v-if="config.company.enableFreeQuantity && item.freeQuantity && item.freeQuantity > 0">
                  <span class="justify-end font-light text-neutral-550">{{ t('orders.freeQta') }}:&nbsp;</span>
                  <span class="font-bold">{{ item.freeQuantity }}</span>
                  <span>
                    (x {{ item.packaging.size }} = {{ (item.packaging.size * item.freeQuantity).toFixed(2) }})
                  </span>
                </div>
                <div v-if="item.article.dimensions && item.article.dimensions.length > 0" class="flex flex-row">
                  <span class="text-neutral-550 font-light">{{ t('orders.optionals') }}:&nbsp;</span>
                  <span>{{ getSortDimensions(item, locale) }}</span>
                </div>
                <div class="sm:pb-8 text-primary-light" v-if="item.requestedPartialDelivery">
                  <span>{{ t('orders.requestedPartialDelivery') }}</span>
                </div>
                <div class="break-all" v-if="item.article.productionDescription">
                  <span class="text-neutral-550">{{ t('orders.productDescription') }}: &nbsp;</span>
                  <span>{{ item.article.productionDescription }}</span>
                </div>
                <div v-if="config.company.enableTaxCalculator && !hasArticleTax(item.article)">
                  <span class="text-infoRed">*{{ t('orders.taxNotFound') }}</span>
                </div>
              </div>

              <div class="flex flex-col basis-6/12 items-end">
                <div>
                  <span class="font-light text-neutral-550"> {{ t('orders.unitPrice') }}:&nbsp;</span>
                  {{ formatNumberToEuro(tradeAgreementPrice(item)) }}
                </div>
                <div v-if="item.quantity && item.quantity > 0">
                  ({{ tradeAgreementPrice(item) }}*{{ item.packaging.size }}*{{ item.quantity }})&nbsp;=&nbsp;
                  <span>{{ formatNumberToEuro(getFinalPrice(item)) }}</span>
                </div>
                <span v-if="getDiscountsString(item)" class="pl-4 text-neutral-550 font-light">
                  <span v-if="getDiscountedAmount(item) > 0">
                    - {{ formatNumberToEuro(getDiscountedAmount(item)) }}&nbsp;
                  </span>
                  <span>({{ getDiscountsString(item) }})</span>
                </span>
                <span class="font-bold">
                  {{ formatNumberToEuro(getFinalDiscountedPrice(item)) }}
                </span>
                <div
                  v-if="item.quantity && item.quantity > 0 && item.tradeAgreement.isPromotional"
                  class="flex flex-row w-full justify-end"
                >
                  <span class="text-infoRed font-bold">{{ t('offers.promotion') }}</span>
                </div>
                <span
                  class="text-link font-bold"
                  v-if="item.article.articleTypeCode === config.company.productionArticleTypeCode"
                >
                  {{ t('offers.production') }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <OrderWizardShoppingCartSummary editDiscount:false v-model:article-taxes="articleTaxes" />
      </AgBox>
      <AgAlert v-else variant="danger">
        {{ t('orders.cartEmpty') }}
        <template #icon><IcAlert /></template>
      </AgAlert>
    </div>

    <!-- CUSTOMER  -->
    <!-- CUSTOMER INFO -->
    <div class="pb-16">
      <div class="text-sm font-bold">{{ t('orders.customer') }}</div>
      <AgBox class="flex flex-row">
        <IcClientDetails class="mr-32" />

        <div class="sm:h-5/6 row w-full">
          <div>
            <div>
              <span class="text-sm text-neutral-550 font-light">{{ t('orders.customerCode') }}:&nbsp;</span
              ><span>{{ order.customer.code ?? '--' }}</span>
            </div>
            <div>
              <span class="text-sm text-neutral-550 font-light">{{ t('orders.customer') }}:&nbsp;</span>
              <span>{{ order.customer.name ?? '--' }}</span>
            </div>
            <div>
              <span class="text-sm text-neutral-550 font-light">{{ t(`orders.alternativeName`) }}:&nbsp;</span>
              <span>{{ order.customer.alternativeName ?? '--' }}</span>
            </div>
            <div>
              <span class="text-sm text-neutral-550 font-light">{{ t(`orders.website`) }}:&nbsp;</span>
              <span>{{ order.customer.webSiteUrl ?? '--' }}</span>
            </div>
            <div>
              <span class="text-sm text-neutral-550 font-light">{{ t('orders.addressWizard') }}:&nbsp;</span>
              <span v-if="order.invoiceAddress"> {{ getCompleteInvoiceAddress() ?? '--' }}</span>
              <span v-else> </span>
            </div>
            <div>
              <span class="text-sm text-neutral-550 font-light">{{ t('orders.mail') }}:&nbsp;</span>
              {{ order.invoiceAddress.email ?? '--' }}
            </div>
            <div>
              <span class="text-sm text-neutral-550 font-light">{{ t('orders.phone') }}:&nbsp;</span>
              {{ order.invoiceAddress.phoneNumber ?? '--' }}
            </div>
          </div>
          <div>
            <div>
              <span class="text-sm text-neutral-550 font-light">{{ t('orders.fiscalCode') }}:&nbsp;</span>
              {{ order.customer.fiscalCode ?? '--' }}
            </div>
            <div>
              <span class="text-sm text-neutral-550 font-light">{{ t('orders.vatCode') }}:&nbsp;</span>
              {{ order.customer.vatCode ?? '--' }}
            </div>
            <div>
              <span class="text-sm text-neutral-550 font-light">{{ t('orders.languageCustomer') }}:&nbsp;</span>
              {{ order.customer.language ?? '--' }}
            </div>
            <div>
              <span class="text-sm text-neutral-550 font-light">{{ t('orders.section') }}:&nbsp;</span>
              {{ getTranslatedText(order.invoiceAddress.businessCategory?.title) }}
            </div>
            <div>
              <span class="text-sm text-neutral-550 font-light">{{ t('orders.sdiCode') }}:&nbsp;</span>
              {{ order.customer.sdiCode ?? '--' }}
            </div>
            <div>
              <span class="text-sm text-neutral-550 font-light">{{ 'IBAN' }}:&nbsp;</span>
              {{ order.customer.iban ?? '--' }}
            </div>
          </div>
        </div>
      </AgBox>
    </div>
    <!-- DELIVERY INFO -->
    <div class="pb-16">
      <div class="text-sm font-bold">{{ t('orders.deliveryAddress') }}</div>
      <AgBox class="sm:h-5/6 max-h-[350px] flex flex-row">
        <IcDeliveryAddress class="mr-32" />

        <div>
          <div v-if="order.deliveryAddressEqualToBilling" class="font-bold">
            {{ t('orders.deliveryAddressEqualToBilling') }}
          </div>
          <div>
            <span class="text-sm text-neutral-550 font-light"> {{ t('orders.addressCode') }}:&nbsp; </span>
            <span>{{ calculatedDeliveryAddress?.code }}</span>
          </div>
          <div>
            <span class="text-sm text-neutral-550 font-light"> {{ t('orders.addressWizard') }}:&nbsp; </span>
            {{ getCompleteDeliveryAddress() ?? '--' }}
          </div>
          <div>
            <span class="text-sm text-neutral-550 font-light">{{ t('orders.mail') }}:&nbsp;</span>
            {{ calculatedDeliveryAddress?.email ?? '--' }}
          </div>
          <div>
            <span class="text-sm text-neutral-550 font-light">{{ t('orders.phone') }}:&nbsp;</span>
            {{ calculatedDeliveryAddress?.phoneNumber ?? '--' }}
          </div>
          <div v-if="calculatedDeliveryAddress?.deliveryPhoneNotification">
            <span class="text-sm text-neutral-550 font-light">{{ t('orders.deliveryPhoneNumber') }}:&nbsp;</span>
            {{ calculatedDeliveryAddress?.deliveryPhoneNumber ?? '--' }}
          </div>
          <div>
            <span class="text-sm text-neutral-550 font-light">{{ t('orders.latitude') }}:&nbsp;</span>
            {{ calculatedDeliveryAddress?.latitude ?? '--' }}
          </div>
          <div>
            <span class="text-sm text-neutral-550 font-light">{{ t('orders.longitude') }}:&nbsp;</span>
            {{ calculatedDeliveryAddress?.longitude ?? '--' }}
          </div>
          <div>
            <span class="text-sm text-neutral-550 font-light">{{ t('orders.section') }}:&nbsp;</span>
            {{ getTranslatedText(calculatedDeliveryAddress?.businessCategory?.title) ?? '--' }}
          </div>
          <div>
            <span class="text-sm text-neutral-550 font-light">{{ t('orders.unloadingTimes') }}:&nbsp;</span>
            <span v-if="calculatedDeliveryAddress">
              <span v-for="(unloadingTime, index) in calculatedDeliveryAddress.unloadingTimes" :key="index" class="p-4">
                <span>
                  {{ timeToDate(unloadingTime.timeStart, locale) }} - {{ timeToDate(unloadingTime.timeEnd, locale) }}
                </span>
                <span v-if="index < calculatedDeliveryAddress?.unloadingTimes.length - 1">;</span>
              </span>
            </span>
          </div>
          <div>
            <span class="text-sm text-neutral-550 font-light">{{ t('orders.daysOfRest') }}:&nbsp;</span>
            <span v-if="calculatedDeliveryAddress">
              <span v-for="(dayOfRest, index) in calculatedDeliveryAddress.daysOfRest" :key="index" class="p-4">
                <span class="text-sm">{{ t(`orders.day${dayOfRest.day}`) }}:&nbsp;</span>
                <span>
                  {{ timeToDate(dayOfRest.timeStart, locale) }} - {{ timeToDate(dayOfRest.timeEnd, locale) }}
                </span>
                <span v-if="index < calculatedDeliveryAddress.daysOfRest.length - 1">;</span>
              </span>
            </span>
          </div>
        </div>
      </AgBox>
    </div>

    <!-- DELIVERY PHOTOS -->
    <div class="pb-16" v-if="calculatedDeliveryAddress?.photos && calculatedDeliveryAddress?.photos.length > 0">
      <div class="text-sm font-bold">{{ t('orders.deliveryAddressPictures') }}</div>
      <AgBox class="sm:h-5/6 max-h-[350px]">
        <!-- PHOTOS -->
        <PhotoVisualizer :order="order" :editable="false" />
      </AgBox>
    </div>

    <!-- DELIVERY METHOD -->
    <div class="flex justify-between gap-12 pb-16 max-md:flex-col">
      <div class="basis-1/2">
        <div class="text-sm font-bold">{{ t('orders.deliveryDetails') }}</div>
        <AgBox class="sm:h-5/6 flex flex-row items-center">
          <IcDeliveryDetails class="mr-32" />
          <div>
            <div>
              <span class="text-sm text-neutral-550 font-light">{{ t('orders.deliveryTerm') }}:&nbsp;</span>
              <span> {{ getTranslatedText(order.deliveryTerm?.title) ?? '--' }}</span>
            </div>
            <div>
              <span class="text-sm text-neutral-550 font-light">{{ t('orders.deliveryMethod') }}:&nbsp;</span>
              <span>{{ getTranslatedText(order.deliveryMethod?.title) ?? '--' }}&nbsp;</span>
              <span v-if="order.deliveryMethod?.requestedReceiptDateRequired && order.deliveryDateRequest">
                {{ formatDate(order.deliveryDateRequest) ?? '--' }}
              </span>
            </div>
            <div v-if="depots.length > 0">
              <span class="text-sm text-neutral-550 font-light">{{ t('orders.depot') }}:&nbsp;</span>
              <span>{{ getTranslatedText(order.depot?.title) ?? '--' }}&nbsp;</span>
            </div>
            <div v-if="order.shippingDateRequest">
              <span class="text-sm text-neutral-550 font-light">{{ t(`orders.shippingRequestDate`) }}:&nbsp;</span>
              <span>{{ formatDate(order.shippingDateRequest) ?? '--' }}</span>
            </div>
          </div>
        </AgBox>
      </div>

      <!-- PAYMENT METHOD -->
      <div class="basis-1/2">
        <div class="text-sm font-bold">{{ t('orders.paymentDetails') }}</div>
        <AgBox class="sm:h-5/6 flex flex-row gap-12 items-center">
          <IcPaymentInfo class="mr-32" />
          <div>
            <div>
              <span class="text-sm text-neutral-550 font-light">{{ t('orders.paymentTerm') }}:&nbsp;</span>
              <span>{{ getTranslatedText(order.paymentTerm?.title) ?? '--' }}</span>
            </div>
            <div>
              <span class="text-sm text-neutral-550 font-light">{{ t('orders.paymenthMethod') }}:&nbsp;</span>
              <span>{{ getTranslatedText(order.paymentMethod?.title) ?? '--' }}</span>
            </div>
            <div v-if="order.paymentMethod && order.paymentMethod.ibanRequired">
              <div>
                <span class="text-sm text-neutral-550 font-light">IBAN:&nbsp;</span>
                <span>{{ order.customer.iban ?? '--' }} </span>
              </div>
            </div>
          </div>
        </AgBox>
      </div>
    </div>

    <div class="flex max-md:flex-col gap-12 pb-16">
      <div class="basis-1/2">
        <div class="text-sm font-bold">{{ t('orders.externalNote') }}</div>
        <AgBox class="whitespace-pre-wrap max-h-[333px] overflow-y-scroll">{{ getNote(order.orderNote) }}</AgBox>
      </div>
      <div class="basis-1/2">
        <div class="text-sm font-bold">{{ t('orders.internalNote') }}</div>
        <AgBox class="whitespace-pre-wrap max-h-[333px] overflow-y-scroll">{{ getNote(order.internalNote) }}</AgBox>
      </div>
    </div>

    <!-- ALERT INFO -->
    <div v-if="isCustomerBlocked" class="py-12">
      <AgAlert variant="danger">
        {{ t('orders.userBlocked') }}
        <template #icon><IcAlert /></template>
      </AgAlert>
    </div>

    <div v-if="order.status === OrderStatus.DRAFT" class="pb-16">
      <AgAlert v-if="isError" variant="danger">
        {{ t('orders.notAllDataCorrect') }}
        <template #icon><IcAlert /></template>
      </AgAlert>
      <AgAlert v-else-if="!isCustomerBlocked" variant="success">
        {{ t('orders.allDataCorrect') }}
        <template #icon><IcAlert /></template>
      </AgAlert>
    </div>

    <div class="flex justify-end pt-16" v-if="order.status === OrderStatus.DRAFT">
      <AgButton
        variant="primary"
        class="sm:min-w-[359px]"
        :disabled="isError || isCustomerBlocked"
        @click="openPopupCompleteOrder"
      >
        {{ t('orders.completeOrder') }}
      </AgButton>
    </div>

    <OrderWizardSendMail v-model="showPopupEmail" :customerLanguage="order.customer.language" />
    <OrderWizardComplete v-model="showPopupComplete" />
  </div>
</template>

<script setup lang="ts">
  import type { DimensionItemDto } from '@/domain/DimensionItemDto';
  import type { DepotDto } from '@/domain/masterData/DepotDto';
  import type { OrderArticleDto } from '@/domain/orderData/OrderArticleDto';
  import type { OrderItemDto } from '@/domain/orderData/OrderItemDto';

  import IcAlert from '@/components/icons/IcAlert.vue';
  import IcClientDetails from '@/components/icons/IcClientDetails.vue';
  import IcDeliveryAddress from '@/components/icons/IcDeliveryAddress.vue';
  import IcDeliveryDetails from '@/components/icons/IcDeliveryDetails.vue';
  import IcDownload from '@/components/icons/IcDownload.vue';
  import IcInfo from '@/components/icons/IcInfo.vue';
  import IcMailClose from '@/components/icons/IcMailClose.vue';
  import IcPaymentInfo from '@/components/icons/IcPaymentInfo.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgBox from '@/components/library/box/AgBox.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import OrderInfo from '@/modules/orders/components/order-wizard/steps/OrderInfo.vue';
  import PhotoVisualizer from '@/modules/orders/components/order-wizard/steps/PhotoVisualizer.vue';
  import OrderWizardComplete from '@/modules/orders/components/order-wizard/steps/review/OrderWizardComplete.vue';
  import OrderWizardSendMail from '@/modules/orders/components/order-wizard/steps/review/OrderWizardSendMail.vue';
  import OrderWizardShoppingCartSummary from '@/modules/orders/components/order-wizard/steps/shopping-cart/shopping-cart-summary/OrderWizardShoppingCartSummary.vue';
  import Popper from 'vue3-popper';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { config } from '@/config/config';
  import { OrderStatus } from '@/domain/enumeration/OrderStatus';
  import { useOrderWizardStore } from '@/modules/orders/stores/useOrderWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { Utilities } from '@/util/Utilities';
  import { useNetwork } from '@vueuse/core';
  import { storeToRefs } from 'pinia';
  import { computed, onMounted, ref } from 'vue';

  const { locale, t } = i18n.global;

  const { csvExport, timeToDate } = Utilities();
  const { formatDate } = useDateFormatting();
  const { getTranslatedText } = useTranslatedText();
  const { formatNumberToEuro } = useNumberFormatting();
  const { formatNumberToPercentage } = useNumberFormatting();

  const { isOnline } = useNetwork();
  const { tables } = useMasterData();

  const orderStore = useOrderWizardStore();
  const { order, isCustomerBlocked, validationStates } = storeToRefs(orderStore);

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('orders.order', 'orders.summary');

  const theFooter = useTheFooter();
  theFooter.enabled(order.value?.status === 1);
  theFooter.visible(order.value?.status === 1);

  const showPopupEmail = ref<boolean>(false);
  const showPopupComplete = ref<boolean>(false);

  const articleTaxes = ref<Map<number, number>>(new Map<number, number>());
  const depots = ref<Array<DepotDto>>([]);

  const items = computed((): Array<OrderItemDto> => order.value?.items ?? []);
  const isError = computed((): boolean => validationStates.value?.includes(false) ?? true);

  const today = new Date();
  const fileName = `${config.company.code}_order_${today.toISOString()}.csv`;

  const csvHeader = ref<string[]>([
    'Agent name',
    'Agent code',
    'Order date',
    'Order gid',
    'Order number',
    'VAT code',
    'Fiscal code',
    'Customer name',
    'Customer code',
    'Customer Email',
    'Customer address',
    'Customer phone',
    'Delivery address',
    'Delivery phone',
    'Delivery unloading times',
    'Delivery days of rest',
    'Delivery term',
    'Delivery method',
    'Payment term',
    'payment method',
    'Depot',
    'IBAN',
    'External note',
    'Internal note',
    'Article code',
    'Article extCode',
    'Article name',
    'Article type',
    'Article group',
    'Article note',
    'Article dimension',
    'Article dimension',
    'Article dimension',
    'Packaging',
    'Quantity',
    'Free quatity',
    'Price',
    'Discount 1',
    'Discount 2',
    'Discount 3',
    'Discount 4',
    'Discount 5',
    'Discount 6',
    'Head discount',
    'VAT',
  ]);

  const csvDataExport = computed(() => {
    const data: string[] = [];

    if (order.value) {
      const invoiceAddress = getCompleteInvoiceAddress() ?? '';
      const invoicePhone = order.value.invoiceAddress.phoneNumber;

      const deliveryAddress = getCompleteDeliveryAddress() ?? '';
      const deliveryPhone = calculatedDeliveryAddress.value?.phoneNumber ?? '';
      const deliveryUnloadingTimes = '';
      const deliveryRestDays = '';
      const deliveryTerm = order.value.deliveryTerm ? getTranslatedText(order.value.deliveryTerm.title) : '';
      const deliveryMethod = order.value.deliveryMethod ? getTranslatedText(order.value.deliveryMethod.title) : '';

      const depot = order.value.depot ? getTranslatedText(order.value.depot.title) : '';
      const paymentTerm = order.value.paymentTerm ? getTranslatedText(order.value.paymentTerm.title) : '';
      const paymentMethod = order.value.paymentMethod ? getTranslatedText(order.value.paymentMethod.title) : '';

      const headDiscount = `${order.value.discount}`;

      const orderData = [
        order.value.agentName,
        order.value.agentCode,
        formatDate(order.value.orderDate),
        order.value.gid,
        order.value.orderNumber,
        order.value.customer.vatCode,
        order.value.customer.fiscalCode,
        order.value.customer.name,
        order.value.customer.code,
        order.value.invoiceAddress.email,
        invoiceAddress,
        invoicePhone,
        deliveryAddress,
        deliveryPhone,
        deliveryUnloadingTimes,
        deliveryRestDays,
        deliveryTerm,
        deliveryMethod,
        paymentTerm,
        paymentMethod,
        depot,
        order.value.customer.iban,
        order.value.internalNote,
        order.value.orderNote,
      ];
      if (order.value.items && order.value.items.length > 0) {
        order.value.items.forEach((item) => {
          const packaging = item.packaging.unit.title[locale.value] + `( ${item.packaging.size})`;
          const title = getTranslatedText(item.article.title);
          const articleType = getTranslatedText(item.article.articleType);
          const articleGroup = getTranslatedText(item.article.articleGroup);
          const quantity = item.quantity ? `${item.quantity}` : '';
          const freeQuantity = item.freeQuantity ? `${item.freeQuantity}` : '';
          const price = item.tradeAgreement.editedPrice
            ? `${item.tradeAgreement.editedPrice}`
            : `${item.tradeAgreement.price}`;
          const tax = item.tax ? `${item.tax}` : '';
          const discount1 = item.discount1 ? `${item.discount1}` : '';
          const discount2 = item.discount2 ? `${item.discount2}` : '';
          const discount3 = item.discount3 ? `${item.discount3}` : '';
          const discount4 = item.discount4 ? `${item.discount4}` : '';
          const discount5 = item.discount5 ? `${item.discount5}` : '';
          const discount6 = item.discount6 ? `${item.discount6}` : '';

          const itemData = [
            item.article.code,
            item.article.externalCode,
            title,
            articleType,
            articleGroup,
            item.note,
            getDimension(item.article.dimensions, 0),
            getDimension(item.article.dimensions, 1),
            getDimension(item.article.dimensions, 2),
            packaging,
            quantity,
            freeQuantity,
            price,
            discount1,
            discount2,
            discount3,
            discount4,
            discount5,
            discount6,
            headDiscount,
            tax,
          ];
          data.push(orderData.concat(itemData).join(';'));
        });
      } else {
        data.push(orderData.join(';'));
      }
    }
    return data;
  });

  const getDimension = function (dimension: Array<DimensionItemDto> | undefined, position: number) {
    if (dimension && dimension.length >= position + 1) {
      return dimension[position].title[locale.value];
    }
    return '';
  };

  const tradeAgreementPrice = function (item: OrderItemDto) {
    if (item && item.quantity && item.quantity > 0) {
      if (item.isTradeAgreementPriceEdited) {
        return item.tradeAgreement.editedPrice ?? 0;
      }
      return item.tradeAgreement.price;
    }
    return 0;
  };

  const getSortDimensions = function (item: OrderItemDto, locale: string) {
    const dimensions: Array<string> = [];

    if (item.article.dimensions && item.article.dimensions.length > 0) {
      const sortDim = item.article.dimensions.sort((a, b) => {
        if (!a.title[locale]) {
          console.warn('Warning: founded a dimension without title in locale: ' + locale);
          return 1;
        }
        if (!b.title[locale]) {
          console.warn('Warning: founded a dimension without title in locale: ' + locale);
          return -1;
        }
        return a.title[locale].localeCompare(b.title[locale]);
      });
      dimensions.push(...sortDim.map((d) => getTranslatedText(d.value.title)));
    }
    return dimensions.join(', ');
  };

  const getCompleteInvoiceAddress = function () {
    if (order.value && order.value.invoiceAddress && order.value.invoiceAddress.street) {
      const adr = order.value.invoiceAddress;
      return [adr.street, adr.city + ` (${adr.district})`, adr.region, adr.zip, adr.nation].join(', ');
    }
    return '';
  };

  const getCompleteDeliveryAddress = function () {
    if (order.value && calculatedDeliveryAddress.value && calculatedDeliveryAddress.value.street) {
      const adr = calculatedDeliveryAddress.value;
      return [adr.street, adr.city + ` (${adr.district})`, adr.region, adr.zip, adr.nation].join(', ');
    }
    return '';
  };

  const calculatedDeliveryAddress = computed(() => {
    if (order.value?.deliveryAddressEqualToBilling) {
      return order.value.invoiceAddress;
    } else if (order.value?.deliveryAddress) {
      return order.value.deliveryAddress;
    }
  });

  const getFinalPrice = function (item: OrderItemDto) {
    if (item.quantity && item.quantity > 0) {
      return tradeAgreementPrice(item) * item.quantity * item.packaging.size;
    }
    return 0;
  };

  const getFinalDiscountedPrice = function (item: OrderItemDto) {
    let price = tradeAgreementPrice(item);
    for (let i = 1; i <= config.company.maxItemDiscounts; i++) {
      if ((item as never)[`discount${i}`]) {
        price = price * (1 - (item as never)[`discount${i}`] / 100);
      }
    }
    if (item.quantity && item.quantity > 0) {
      return price * item.quantity * item.packaging.size;
    }
    return 0;
  };

  const hasArticleTax = function (article: OrderArticleDto) {
    const hasCustomerTax: boolean = order.value?.customer.customerTaxCode ? true : false;
    if (!hasCustomerTax) {
      return true;
    }
    return articleTaxes.value.has(article.id);
  };

  const getDiscountedAmount = function (item: OrderItemDto) {
    return getFinalPrice(item) - getFinalDiscountedPrice(item);
  };

  const getDiscountsString = function (item: OrderItemDto) {
    if (item.quantity && item.quantity > 0) {
      type ObjectKey = keyof typeof item & number;
      let discounts: Array<string> = [];
      for (let i = 1; i <= config.company.maxItemDiscounts; i++) {
        const key = `discount${i}` as ObjectKey;
        if (!item[key]) {
          continue;
        }
        discounts.push(formatNumberToPercentage(item[key]));
      }
      return discounts.join(', ');
    }
    return '';
  };

  const getNote = function (note: string) {
    if (note && note.length > 0) {
      return note;
    } else {
      return i18n.global.t('orders.noNote');
    }
  };

  const openPopupCompleteOrder = function () {
    showPopupComplete.value = true;
    showPopupEmail.value = false;
  };

  const openPopupSendMail = function () {
    showPopupComplete.value = false;
    showPopupEmail.value = true;
  };

  onMounted(async () => {
    depots.value = await tables.depots.toArray();
  });
</script>

<style scoped lang="scss">
  .row {
    @apply flex flex-wrap justify-between;
  }
  .agPopper {
    --popper-theme-background-color: #006fff;
    --popper-theme-background-color-hover: #006fff;
    --popper-theme-text-color: #ffffff;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }
</style>
