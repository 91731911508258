<template>
  <div>
    <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
      <IcLoading />
    </div>

    <div v-else-if="currentPaymentMethod">
      <TheAdminSubHeader @save="savePaymentMethod" @delete="deletePaymentMethod(currentPaymentMethod.id)" />
      <div class="md:mx-[80px]">
        <div class="infoBox font-bold">
          <span class="infoboxTitle">{{ t('admin.code').toUpperCase() }}</span>
          <div>{{ currentPaymentMethod.code }}</div>
        </div>

        <div class="flex">
          <div class="infoBox">
            <span class="infoboxTitle">{{ t('admin.predefined').toUpperCase() }}</span>
            <div>
              <AgButton
                variant="ghost"
                class="h-24 w-24 flex items-center border-2"
                :class="{ 'border-success': !defaultError, 'border-infoRed': defaultError }"
                @click="toggleDefault"
              >
                <template #icon>
                  <IcCheck v-if="currentPaymentMethod.default" class="pr-4" />
                </template>
              </AgButton>
              <AgAlert v-if="defaultError" variant="danger" class="w-256">
                {{ t('admin.paymentMethodStandardAlreadyChecked') }}
              </AgAlert>
            </div>
          </div>

          <div class="infoBox">
            <span class="infoboxTitle">{{ t('admin.ibanRequired').toUpperCase() }}</span>
            <div>
              <AgButton
                variant="ghost"
                class="h-24 w-24 flex items-center border-2 border-success"
                @click="toggleIbanRequired"
              >
                <template #icon>
                  <IcCheck v-if="currentPaymentMethod.ibanRequired" class="pr-4" />
                </template>
              </AgButton>
            </div>
          </div>
        </div>

        <div class="table mt-16">
          <span class="text-primary-light">{{ t('admin.languageDescription').toUpperCase() }}</span>
          <div v-for="selectedLang in Object.values(config.company.availableLanguages)" :key="selectedLang">
            <div class="tableColumn">
              <AgFormInput :placeholder="selectedLang" disabled />
              <AgFormInput
                v-model="currentPaymentMethod.title[selectedLang]"
                :validation-state="hasDescValue(selectedLang)"
                @input="descriptionError = false"
              />
              <IcClose class="cursor-pointer" @click="deleteTranslation(selectedLang)"></IcClose>
            </div>
          </div>
          <AgAlert v-if="descriptionError" variant="danger" dismissible>
            {{ t('admin.descriptionRequired') }}
          </AgAlert>
        </div>
      </div>
    </div>

    <div v-else>
      <AgAlert variant="info">{{ t('admin.paymentMethodnotFound') }}</AgAlert>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { PaymentMethodDto } from '@/admin/domain/PaymentMethodDto';

  import IcCheck from '@/components/icons/IcCheck.vue';
  import IcClose from '@/components/icons/IcClose.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';

  import TheAdminSubHeader from '@/components/framework/admin/the-admin-sub-header/TheAdminSubHeader.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { config } from '@/config/config';
  import { i18n } from '@/plugins/i18n';
  import { onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { usePaymentMethods } from '../composables/usePaymentMethods';

  const { t } = i18n.global;
  const route = useRoute();
  const router = useRouter();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.paymentMethodDetails');

  const { loadData, deleteData, updateData } = usePaymentMethods();

  const paymentMethods = ref<Array<PaymentMethodDto>>([]);
  const defaultError = ref<boolean>(false);
  const isLoading = ref<boolean>(true);
  const descriptionError = ref<boolean>(false);
  const currentPaymentMethod = ref<PaymentMethodDto>();

  const hasDescValue = function (lang: string) {
    if (!currentPaymentMethod.value) {
      return false;
    }
    if (!descriptionError.value) {
      return true;
    }
    if (!Object.keys(currentPaymentMethod.value.title).includes(lang)) {
      return false;
    }
    return currentPaymentMethod.value.title[lang].length > 0;
  };

  const setCurrentItem = () => {
    currentPaymentMethod.value = paymentMethods.value.find(
      (method) => method.id.toString() === route.params.methodId.toString()
    );
  };

  const savePaymentMethod = async () => {
    defaultError.value = false;

    if (currentPaymentMethod.value?.default) {
      if (paymentMethods.value.some((method) => method.default && method.id !== currentPaymentMethod.value?.id)) {
        defaultError.value = true;
        return;
      }
    }

    if (Object.keys(currentPaymentMethod.value?.title ?? '').length === 0) {
      descriptionError.value = true;
      return;
    }
    if (Object.values(currentPaymentMethod.value?.title ?? '').every((value) => value.length === 0)) {
      descriptionError.value = true;
      return;
    }

    isLoading.value = true;
    await updateData(currentPaymentMethod.value);
    try {
      await router.push({
        path: '/admin/payment-methods',
      });
    } catch (error) {
      console.error('Failed to navigate to payment method:', error);
    }
    isLoading.value = false;
  };

  const deletePaymentMethod = async function (dmId: number) {
    isLoading.value = true;
    await deleteData(dmId);
    try {
      await router.push({
        path: '/admin/payment-methods',
      });
    } catch (error) {
      console.error('Failed to navigate to payment method:', error);
    }
    isLoading.value = false;
  };

  const deleteTranslation = function (language: string) {
    if (!currentPaymentMethod.value) {
      return;
    }
    const titleKeys = Object.keys(currentPaymentMethod.value.title);
    const indexToRemove = titleKeys.findIndex((keyLang) => keyLang === language);

    if (indexToRemove !== -1) {
      currentPaymentMethod.value.title[language] = '';
    }
  };
  const toggleIbanRequired = () => {
    if (!currentPaymentMethod.value) {
      return;
    }
    currentPaymentMethod.value.ibanRequired = !currentPaymentMethod.value.ibanRequired;
  };

  const toggleDefault = () => {
    defaultError.value = false;
    if (!currentPaymentMethod.value) {
      return;
    }
    currentPaymentMethod.value.default = !currentPaymentMethod.value?.default;
  };

  const update = async function () {
    loadData()
      .then((methods) => {
        paymentMethods.value = methods ?? [];
        if (methods) {
          setCurrentItem();
        }
        isLoading.value = false;
      })
      .catch((error) => {
        console.log('Error loading payment methods: ', error);
        isLoading.value = false;
      });
  };

  onMounted(async () => update());
</script>

<style scoped lang="scss">
  .infoboxTitle {
    @apply text-primary-light;
  }

  .name {
    @apply text-xl font-bold flex items-center;
  }

  .infoBox {
    @apply w-1/2 justify-center my-12;
  }

  .table {
    @apply grid grid-cols-1 py-16 space-x-0;
  }

  .tableColumn {
    @apply flex items-center min-w-0 py-4 gap-4;
  }
</style>
