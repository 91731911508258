<template>
  <router-link
    :to="props.to"
    class="sidebar-item block text-l font-light text-default"
    :class="{ 'router-link-active': isActive }"
    exact
  >
    <div v-if="newMessagesNumber > 0" class="flex place-items-center gap-12">
      <div
        v-if="!isLoading"
        class="rotate notification-badge top-4 right-4 flex items-center justify-center rounded-full bg-infoRed text-white w-32 h-32 shadow-lg"
      >
        {{ newMessagesNumber }}
      </div>
    </div>
  </router-link>
</template>

<script setup lang="ts">
  import { useNewMessages } from '@/composables/useNewMessages';
  import { useMessageCount } from '@/modules/messages/composables/useMessageCount';
  import { router } from '@/plugins/router';
  import { onMounted, ref, watch } from 'vue';

  const { getCountNewMessages } = useMessageCount();
  const { refreshing, refreshNewMessages } = useNewMessages();

  const props = defineProps<Props>();

  interface Props {
    to: string;
  }
  const isLoading = ref<boolean>(true);
  const isActive = ref<boolean>(false);
  const newMessagesNumber = ref<number>(0);

  const loadNewMessages = async () => {
    isLoading.value = true;
    newMessagesNumber.value = (await getCountNewMessages()) ?? 0;
    isLoading.value = false;
    refreshing(false);
  };

  setInterval(
    async () => {
      await loadNewMessages();
    },
    30 * 60 * 1000
  );

  router.beforeEach((to) => {
    if (to.fullPath && to.fullPath.startsWith(props.to)) {
      isActive.value = true;
    } else {
      isActive.value = false;
    }
  });

  watch(
    () => refreshNewMessages.value,
    async (newValue) => {
      if (newValue === true) {
        await loadNewMessages();
        refreshing(false);
      }
    }
  );

  onMounted(async () => {
    await loadNewMessages();
    refreshing(false);
  });
</script>

<style scoped lang="scss">
  .rotate {
    margin: 0px 0px;
    width: 32px;
    height: 32px;
    background: #676470;
    color: #fff;
    font-family: Tahoma;
    font-size: 2em;
    text-align: center;
    animation: rotateZ 3s linear;
  }

  @keyframes rotateZ {
    to {
      transform: rotateZ(-1080deg);
    }
  }
  .notification-badge {
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 1;
    border: 2px solid white;
    transition: transform 0.2s ease-in-out;
  }

  .notification-badge:hover {
    transform: scale(1.1);
  }

  .bg-infoRed {
    background-color: #ff4d4f;
  }

  .sidebar-item.router-link-active {
    @apply font-bold text-primary;
  }
</style>
