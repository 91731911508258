<template>
  <div>
    <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
      <IcLoading />
    </div>

    <div v-else class="space-y-8">
      <TheAdminSubHeader hasNotSaveButton hasNotDeleteButton />
      <div class="md:mx-[80px]">
        <AgFormGroup :label="t('admin.campaign')">
          <AgSearchSelect v-model="selectedCampaignId" :options="campaignOptions"> </AgSearchSelect>
        </AgFormGroup>
        <AgFormGroup :label="t('admin.activity')">
          <AgSearchSelect v-model="selectedActivityId" :options="activityOptions"> </AgSearchSelect>
        </AgFormGroup>
        <AgFormGroup :label="t('admin.dueDate')">
          <span>
            {{ formatDate(selectedActivity?.dueDate) }}
          </span>
        </AgFormGroup>

        <div class="flex justify-between gap-x-32 h-[calc(100dvh-370px)] py-12">
          <div class="w-full">
            <span class="text-xl font-bold text-primary">{{ t('admin.insert').toUpperCase() }}</span>
            <AgBox class="border-0 p-0 h-[calc(100dvh-420px)]" variant="strong-accent">
              <AgSearchInput v-model="agentFilter" class="w-full" />
              <div class="w-full overflow-y-auto h-[calc(100dvh-500px)]">
                <AgCollapsible
                  :showIcon="!agent.customers || agent.customers.length === 0"
                  v-for="(agent, index) in filteredAgents"
                  :key="agent.agent.id"
                  class="border-neutral-550 cursor-pointer"
                  :class="{
                    'border-b': index !== filteredAgents?.length,
                    'px-12': (agent.customers?.length ?? 0) > 0,
                  }"
                >
                  <template #header>
                    <div
                      class="flex items-center py-8 gap-x-12 italic hover:bg-neutral-500"
                      @click="selectAgent(agent.agent.id)"
                    >
                      <component
                        :is="isActivityCompleted(agent) ? IcCheck : IcClose"
                        class="fill-success text-infoRed"
                      />
                      <div>
                        <div>
                          <span class="font-bold">
                            {{ agent.agent.name }}
                          </span>
                          <span> &nbsp;({{ agent.agent.email }}) </span>
                          <span>[{{ forHowManyCustomers(agent.agent.id) }} / {{ agent.customers?.length ?? 0 }}]</span>
                        </div>
                        <div>
                          {{ formatDate(agent.modifyTimeStamp) }}
                        </div>
                      </div>
                    </div>
                  </template>

                  <template v-if="!agent.customers || agent.customers.length === 0" #default>
                    <div class="px-12 py-8">
                      <AgFormTextarea v-model="agent.comment" disabled />
                    </div>
                  </template>
                </AgCollapsible>
              </div>
            </AgBox>
            <div>
              <span class="font-bold">{{ t('admin.number') }}:&nbsp;</span>
              <span>{{ filteredAgents?.length ?? 0 }}</span>
            </div>
          </div>

          <div class="w-full">
            <span class="text-xl font-bold text-primary">{{ t('admin.remove').toUpperCase() }}</span>
            <AgBox class="w-full h-[calc(100dvh-420px)] border-0 p-0" variant="strong-accent">
              <AgSearchInput v-model="customerFilter" class="w-full" />
              <div class="w-full overflow-y-auto h-[calc(100dvh-500px)]">
                <div
                  v-for="(customer, index) in filteredCustomers"
                  class="px-12 py-8 border-neutral-550"
                  :class="{ 'border-t': index !== 0 }"
                  :key="customer.id"
                >
                  <div class="flex items-center w-full cursor-pointer hover:bg-neutral-500 italic gap-12">
                    <div class="cursor flex items-center gap-4">
                      <component :is="customer.completed ? IcCheck : IcClose" class="fill-success text-infoRed" />
                      <div>
                        <div class="font-bold">
                          {{ customer.name }}
                        </div>
                        <div class="font-bold">
                          {{ formatDate(customer.modifyTimeStamp) }}
                        </div>
                        <div>
                          {{ customer.comment }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </AgBox>
            <div>
              <span class="font-bold">{{ t('admin.number') }}:&nbsp;</span>
              <span>{{ filteredCustomers?.length ?? 0 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { ActivityDto } from '@/admin/domain/ActivityDto';
  import type { AgentCustomerActivityDto } from '@/admin/domain/AgentCustomerActivityDto';
  import type { CampaignDto } from '@/admin/domain/CampaignDto';
  import TheAdminSubHeader from '@/components/framework/admin/the-admin-sub-header/TheAdminSubHeader.vue';
  import IcCheck from '@/components/icons/IcCheck.vue';
  import IcClose from '@/components/icons/IcClose.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgBox from '@/components/library/box/AgBox.vue';
  import AgCollapsible from '@/components/library/collapsible/AgCollapsible.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormTextarea from '@/components/library/form-textarea/AgFormTextarea.vue';
  import AgSearchInput from '@/components/library/search-input/AgSearchInput.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { useActivities } from '../../activities/composables/useActivities';
  import { useCampaigns } from '../composables/useCampaigns';

  const { t } = i18n.global;
  const { loadData } = useActivities();
  const { loadData: loadCampaigns } = useCampaigns();
  const { formatDate } = useDateFormatting();

  const route = useRoute();

  const theHeader = useTheHeader();

  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);

  const agentFilter = ref<string>('');
  const customerFilter = ref<string>('');
  const isLoading = ref<boolean>(true);
  const campaigns = ref<Array<CampaignDto>>([]);
  const campaignOptions = ref<Array<AgSearchSelectOption>>([]);
  const activities = ref<Array<ActivityDto>>([]);
  const selectedCampaignId = ref<number>();
  const selectedActivityId = ref<number>();
  const selectedAgentActivity = ref<AgentCustomerActivityDto>();

  const selectedActivity = computed(() => {
    return activities.value.find((a) => a.id === selectedActivityId.value);
  });

  const campgaignId = computed(() => {
    const camId = route.params.campaignId;
    if (Array.isArray(camId)) {
      return parseInt(camId[0]);
    }
    return parseInt(camId);
  });

  const activityOptions = computed(() => {
    return activities.value
      .filter((a) => a.campaign.id === selectedCampaignId.value)
      .map((activity) => {
        return {
          label: activity.name,
          value: activity.id,
          searchableString: activity.name,
        };
      });
  });

  const filteredAgents = computed(() => {
    return selectedActivity.value?.agents.filter((agent) =>
      agent.agent.name.toLowerCase().includes(agentFilter.value.toLowerCase())
    );
  });

  const filteredCustomers = computed(() => {
    return selectedAgentActivity.value?.customers?.filter((customer) =>
      customer.name.toLowerCase().includes(customerFilter.value.toLowerCase())
    );
  });

  const selectAgent = (agentId: number) => {
    if (agentId === selectedAgentActivity.value?.agent.id) {
      selectedAgentActivity.value = undefined;
      return;
    }
    selectedAgentActivity.value = selectedActivity.value?.agents.find((a) => a.agent.id === agentId);
  };

  const isActivityCompleted = function (agentActivity: AgentCustomerActivityDto) {
    if (agentActivity.customers) {
      return agentActivity.customers.every((aca) => aca.completed);
    } else {
      return agentActivity.completed;
    }
  };

  const forHowManyCustomers = function (agentId: number) {
    const agentActivity = selectedActivity.value?.agents.find((agent) => agent.agent.id === agentId);
    if (agentActivity?.customers) {
      return agentActivity.customers.filter((aca) => aca.completed).length;
    }
    return 0;
  };

  onMounted(async () => {
    campaigns.value = await loadCampaigns();
    activities.value = await loadData();
    selectedCampaignId.value = campgaignId.value;

    campaignOptions.value = campaigns.value.map((campaign) => {
      return {
        label: campaign.name,
        value: campaign.id,
        searchableString: campaign.name,
      };
    });
    isLoading.value = false;
  });

  watch(selectedCampaignId, async () => {
    selectedActivityId.value = undefined;
    selectedAgentActivity.value = undefined;
  });

  watch(selectedActivityId, async () => {
    selectedAgentActivity.value = undefined;
  });
</script>
