<template>
  <div v-if="contingentsGroup.size > 0">
    <!-- SUBHEADER SEARCH AREA -->
    <TheSubheader>
      <ByContingentGroupArticleFilter
        :filterArtGrDimLabels="filterArtGrDimLabels"
        v-model:contingentGroupArticleByDim="selectedContingentGroupArticleByDim"
        v-model:filter="selectedFilter"
      />
    </TheSubheader>

    <div class="mb-32 mt-12 md:mx-[80px] max-md:mx-16">
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <!-- TABLE CONTINGENTS BY CONTINGENT GROUP ARTICLE -->
      <div class="overflow-x-auto">
        <ByContingentGroupArticleTable :contingents="selectedGrArtDimensionContingentGroup" />
      </div>
    </div>
  </div>
  <div v-else class="mb-32 mt-32 md:mx-[80px] max-md:mx-16">
    <AgAlert variant="info">{{ t('contingents.notFound') }}</AgAlert>
  </div>
</template>

<script setup lang="ts">
  import type { ArticleDimensionValueConfigsDto } from '@/domain/masterData/ArticleDimensionValueConfigsDto';
  import type { ContingentDto } from '@/domain/masterData/ContingentDto';
  import type { ContingentGroupArticleDto } from '@/domain/masterData/ContingentGroupArticleDto';

  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import ByContingentGroupArticleTable from '@/modules/contingents/components/by-group-article/ByContingentGroupArticleTable.vue';
  import ByContingentGroupArticleFilter from '@/modules/contingents/components/filters/ByContingentGroupArticleFilter.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { useHierarchyEmployeeStore } from '@/store/useHierarchyEmployeeStore';
  import { storeToRefs } from 'pinia';
  import { onMounted, ref, watch } from 'vue';

  const header = useTheHeader();
  header.enabled(true);
  header.visible(true);
  header.enableHierarchy(true);
  header.title('contingents.contingents', 'contingents.byContingentGroupArticle');

  const { locale, t } = i18n.global;
  const { tables } = useMasterData();

  const store = useHierarchyEmployeeStore();

  const isLoading = ref<boolean>(true);
  const { agentId } = storeToRefs(store);

  const contingentsGroup = ref<Map<string, Array<ContingentDto>>>(new Map());
  const selectedGrArtDimensionContingentGroup = ref<Map<string, Array<ContingentDto>>>(new Map());
  const filterArtGrDimLabels = ref<Map<string, string>>(new Map());

  const contingentGroupArticles = ref<Array<ContingentGroupArticleDto>>([]);
  const articleDimensionValueConfigs = ref<Array<ArticleDimensionValueConfigsDto>>([]);

  const selectedContingentGroupArticleByDim = ref<string>();
  const selectedFilter = ref<string>();

  const loadContingents = async function () {
    const contingents = await tables.contingents.toArray();

    contingents.forEach((c) => {
      if (c.contingentGroupArticleId && c.articleDimensionValueConfigId) {
        if (contingentsGroup.value.has(`${c.contingentGroupArticleId}_${c.articleDimensionValueConfigId}`)) {
          if (
            contingentsGroup.value
              .get(`${c.contingentGroupArticleId}_${c.articleDimensionValueConfigId}`)
              ?.some((cc) => cc.id === c.id)
          ) {
            return;
          }
          contingentsGroup.value.get(`${c.contingentGroupArticleId}_${c.articleDimensionValueConfigId}`)?.push(c);
        } else {
          contingentsGroup.value.set(`${c.contingentGroupArticleId}_${c.articleDimensionValueConfigId}`, [c]);

          const contGrArticle = contingentGroupArticles.value.find((cga) => cga.id === c.contingentGroupArticleId);
          const artDimValueConfig = articleDimensionValueConfigs.value.find(
            (ad) => ad.id === c.articleDimensionValueConfigId
          );
          if (contGrArticle && artDimValueConfig) {
            filterArtGrDimLabels.value.set(
              `${c.contingentGroupArticleId}_${c.articleDimensionValueConfigId}`,
              `${contGrArticle.code} ${artDimValueConfig.title[locale.value]}
              / ${contGrArticle.title[locale.value]} / ${artDimValueConfig.title[locale.value]}`
            );
          }
        }
      }
    });
  };

  const filterContingents = function (agentId: number, filter: string, selectedContingentGroupArticleByDim: string) {
    selectedGrArtDimensionContingentGroup.value.clear();

    for (const [key, value] of contingentsGroup.value) {
      const filteredContingents = filterContingentsByCriteria(
        value,
        key,
        agentId,
        filter,
        selectedContingentGroupArticleByDim
      );
      if (filteredContingents.length > 0) {
        selectedGrArtDimensionContingentGroup.value.set(key, filteredContingents);
      }
    }
  };

  const filterContingentsByCriteria = (
    contingents: Array<ContingentDto>,
    key: string,
    agentId: number,
    filter: string,
    selectedContingentGroupArticleByDim: string
  ) => {
    return contingents.filter((c) => {
      let res = true;
      if (agentId !== -1) {
        res = res && c.agentId ? c.agentId === agentId : false;
      }
      if (filter !== 'All') {
        res = res && c.filter ? c.filter === filter : false;
      }
      if (selectedContingentGroupArticleByDim !== 'All') {
        res = res && selectedContingentGroupArticleByDim === key;
      }
      return res;
    });
  };

  watch([selectedContingentGroupArticleByDim, selectedFilter, agentId], () => {
    filterContingents(
      agentId.value ?? -1,
      selectedFilter.value ?? 'All',
      selectedContingentGroupArticleByDim.value ?? 'All'
    );
  });

  onMounted(async () => {
    contingentGroupArticles.value = await tables.contingentGroupArticles.toArray();
    articleDimensionValueConfigs.value = await tables.articleDimensionValueConfigs.toArray();
    loadContingents().then(() => {
      filterContingents(
        agentId.value ?? -1,
        selectedFilter.value ?? 'All',
        selectedContingentGroupArticleByDim.value ?? 'All'
      );
      isLoading.value = false;
    });
  });
</script>
