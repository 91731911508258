<template>
  <div>
    <div class="md:mx-[80px] max-md:mx-16">
      <div class="min-w-fit">
        <div class="py-12 text-xl text-primary font-bold">{{ t('offers.existingCustomer') }}</div>
        <div class="font-bold">{{ t('offers.customer') }}</div>
        <div class="flex items-center gap-32 max-md:flex-col">
          <AgSearchSelect
            :options="customerOptions"
            :placeholder="t('offers.clickToSearch')"
            v-model="selectedCustomerId"
          />
          <AgButton
            variant="secondary"
            @click="createOfferForExistingCustomer"
            :disabled="isDisabledCustomerButton"
            class="basis-1/5"
          >
            {{ t('offers.newOffer') }}
          </AgButton>
        </div>
      </div>
      <div class="min-w-fit" v-if="config.company.code !== 'NORDWAL'">
        <div class="py-12 text-xl text-primary font-bold">{{ t('offers.newCustomer') }}</div>
        <div class="font-bold">{{ t('offers.priceList') }}</div>
        <div class="flex items-center gap-32 max-md:flex-col">
          <AgSearchSelect
            :options="priceListOptions"
            v-model="selectedPriceListId"
            :placeholder="t('offers.clickToSearch')"
            @update:model-value="handlePriceListChange"
          />
          <AgButton
            variant="secondary"
            @click="createOfferForNewCustomerUsingPriceList"
            :disabled="isDisabledPriceListsButton"
            class="basis-1/5"
          >
            {{ t('offers.newOffer') }}
          </AgButton>
        </div>
      </div>
      <AgAlert v-if="showAgentAlert" variant="info" class="my-32">
        {{ t('offers.selectAgent') }}
      </AgAlert>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import type { CustomerDto } from '@/domain/masterData/CustomerDto';
  import type { PriceListDto } from '@/domain/masterData/PriceListDto';

  import AgButton from '@/components/library/button/AgButton.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';

  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import { useMasterData } from '@/composables/data/useMasterData';
  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { config } from '@/config/config';
  import { CustomerAddressType } from '@/domain/enumeration/CustomerAddressType';
  import { useOfferFactory } from '@/modules/offers/composables/useOfferFactory';
  import { useOffers } from '@/modules/offers/composables/useOffers';
  import { useOfferWizardStore } from '@/modules/offers/stores/useOfferWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { useHierarchyEmployeeStore } from '@/store/useHierarchyEmployeeStore';
  import { storeToRefs } from 'pinia';
  import { computed, ref, watch } from 'vue';
  import { useRouter } from 'vue-router';

  const { t } = i18n.global;

  const { getTranslatedText } = useTranslatedText();

  const { tables, isFullReloading, isLoading } = useMasterData();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(true);
  theHeader.title('offers.offer', 'offers.newOffer');

  const store = useHierarchyEmployeeStore();
  const { loadOfferByGid } = useOfferWizardStore();

  const theFooter = useTheFooter();
  theFooter.enabled(true);
  theFooter.visible(true);

  const { agentId, hasChildren } = storeToRefs(store);
  const customers = ref<Array<CustomerDto>>([]);
  const priceLists = ref<Array<PriceListDto>>([]);
  const showAgentAlert = ref<boolean>(false);

  const loadCustomers = async function () {
    customers.value = await tables.customers.toArray();
  };

  const loadPriceLists = async function () {
    priceLists.value = await tables.priceLists.toArray();
  };

  const customerOptions = computed((): Array<AgSearchSelectOption> => {
    let customersFiltered = customers.value;
    if (agentId.value && agentId.value !== -1) {
      customersFiltered = customers.value.filter((c) => c.agentId === agentId.value);
    }
    return customersFiltered.map((c) => {
      const label: string[] = [c.code];
      if (c.searchName) {
        label.push(c.searchName);
      }
      label.push(c.name);

      let address: string = '';
      if (c.addresses && c.addresses.length > 0) {
        c.addresses
          .filter((a) => a.type.includes(CustomerAddressType.BILLING))
          .map((a) => {
            address = a.street + a.city;
            label.push(a.street);
            label.push(a.city);
            if (a.district) {
              address += a.district;
              label.push(a.district);
            }
          });
      }
      return {
        value: c.id,
        label: label.join(' / '),
        searchableString: c.name + c.code + c.fiscalCode + c.vatCode + c.searchName + address,
        disabled: c.blocked,
        class: c.salesBlocked ? 'italic text-warning' : '',
      };
    });
  });

  const priceListOptions = computed(() => {
    if (!agentId.value) {
      return priceLists.value.map((c) => {
        return {
          value: c.id,
          label: getTranslatedText(c.title),
          searchableString: getTranslatedText(c.title),
        };
      });
    }
    const currentAgentCustomers = customers.value.filter((customer) => customer.agentId === agentId.value);
    const priceListIds: Array<number | null> = currentAgentCustomers.map((c) => c.priceListId).filter((value) => value);
    const uniquePriceListIds = [...new Set(priceListIds)];
    const pls = priceLists.value.filter((priceList) => uniquePriceListIds.includes(priceList.id));
    return pls.map((c) => {
      return {
        value: c.id,
        label: getTranslatedText(c.title),
        searchableString: getTranslatedText(c.title),
      };
    });
  });

  const offerFactory = useOfferFactory();
  const offers = useOffers();
  const router = useRouter();

  const selectedCustomerId = ref<number>();

  const isDisabledCustomerButton = computed(() => {
    return !selectedCustomerId.value;
  });

  const selectedPriceListId = ref<number>();

  const isDisabledPriceListsButton = computed(() => {
    if (!selectedPriceListId.value) {
      return true;
    }
    if (hasChildren.value && !agentId.value) {
      return true;
    }
  });

  const createOfferForExistingCustomer = async function () {
    if (!selectedCustomerId.value) {
      return;
    }
    const customer = customers.value.find((entry) => {
      return entry.id === selectedCustomerId.value;
    });
    if (!customer) {
      return;
    }
    const offer = await offerFactory.createOfferForCustomer(customer.code);
    await offers.saveOffer(offer);
    await loadOfferByGid(offer.gid);

    await router.push({
      name: 'offer-wizard',
      params: { gid: offer.gid, step: 'customer' },
    });
  };

  const createOfferForNewCustomerUsingPriceList = async function () {
    if (!selectedPriceListId.value) {
      return;
    }
    const customer = offerFactory.createNewEmptyOfferCustomer();
    customer.priceListId = selectedPriceListId.value;

    let offer = null;
    if (agentId.value && hasChildren.value) {
      offer = await offerFactory.createOfferForOfferCustomer(customer, agentId.value);
    } else if (!hasChildren.value) {
      offer = await offerFactory.createOfferForOfferCustomer(customer);
    }
    if (offer) {
      await offers.saveOffer(offer);
      await router.push({
        name: 'offer-wizard',
        params: { gid: offer.gid, step: 'customer' },
      });
    }
  };

  const handlePriceListChange = function () {
    if (selectedPriceListId.value && hasChildren.value) {
      showAgentAlert.value = true;
    } else {
      showAgentAlert.value = false;
    }
  };

  const calculateDefaultPriceList = function () {
    if (priceListOptions.value.length === 1) {
      if (typeof priceListOptions.value[0].value === 'number') {
        selectedPriceListId.value = priceListOptions.value[0].value;
        if (hasChildren.value) {
          showAgentAlert.value = true;
        }
      }
    } else {
      selectedPriceListId.value = undefined;
      showAgentAlert.value = false;
    }
  };

  watch(
    [isFullReloading, isLoading],
    async ([fullReload, reload]) => {
      if (!fullReload && !reload) {
        await loadCustomers();
        await loadPriceLists();
        calculateDefaultPriceList();
      }
    },
    { immediate: true }
  );

  watch(agentId, () => {
    calculateDefaultPriceList();
    selectedCustomerId.value = undefined;
  });
</script>
