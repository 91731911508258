<template>
  <div class="py-12 md:mx-[80px] max-md:mx-16">
    <div class="flex items-center gap-12">
      <AgFormGroup class="w-full" :label="t('statistics.filter')">
        <AgSearchSelect :options="filterOptions" v-model="internalFilter" />
      </AgFormGroup>
    </div>

    <hr class="mt-24 mb-16 text-neutral-500" />

    <div class="flex items-center gap-12">
      <AgFormGroup class="w-full" :label="t('statistics.from')">
        <AgFormInput type="date" v-model="internalFromDate" :min="getMinDate()" :max="getMaxDate()" />
      </AgFormGroup>
      <AgFormGroup class="w-full" :label="t('statistics.to')">
        <AgFormInput type="date" v-model="internalToDate" :min="getMinDate()" :max="getMaxDate()" />
      </AgFormGroup>
      <AgButton class="mt-[20px] h-[40px]" @click="newSearch()">
        {{ t('statistics.search') }}
      </AgButton>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';

  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';

  import { useStatisticStore } from '@/modules/statistics/store/useStatisticStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed, onMounted, ref } from 'vue';

  const { t } = i18n.global;
  const { getFilterList } = useStatisticStore();

  const store = useStatisticStore();
  const { filters } = storeToRefs(store);

  interface Props {
    fromDate: string;
    toDate: string;
    filter: string;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:filter', 'update:fromDate', 'update:toDate', 'newSearch']);

  const internalFromDate = ref<string>(props.fromDate);
  const internalToDate = ref<string>(props.toDate);

  const filterOptions = ref<Array<AgSearchSelectOption>>([]);

  const internalFilter = computed({
    get: () => props.filter,
    set: (value) => emit('update:filter', value),
  });

  const newSearch = function () {
    emit('update:fromDate', internalFromDate.value);
    emit('update:toDate', internalToDate.value);
    emit('newSearch');
  };

  const loadFilterOptions = async function () {
    await getFilterList();

    filterOptions.value.push({ label: t('statistics.all'), value: '', searchableString: '' });
    filters.value.forEach((filter: string) => {
      filterOptions.value.push({
        label: filter + ' ',
        value: filter,
        searchableString: filter,
      });
    });
  };

  const getMinDate = function () {
    const today = new Date();
    const currentYear = today.getFullYear();
    const fiveYearsAgo = today.setFullYear(currentYear - 5);

    const minDate = new Date(fiveYearsAgo).toISOString().split('T')[0];
    return minDate;
  };

  const getMaxDate = function () {
    const maxDate = new Date().toISOString().split('T')[0];
    return maxDate;
  };

  onMounted(async () => await loadFilterOptions());
</script>
