<template>
  <div>
    <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
      <IcLoading />
    </div>
    <div v-else-if="customer">
      <TheAdminSubHeader has-not-delete-button @save="save" />
      <div class="md:mx-[80px]">
        <AgAlert variant="info" class="mt-16 h-32">
          <template #icon>
            <IcInfo />
          </template>
          {{ t('admin.customerChangesText') }}
        </AgAlert>
        <div class="table">
          <div>
            <div class="infoboxTitle">{{ t('admin.name').toUpperCase() }}</div>
            {{ customer.name?.length > 0 ? customer.name : '--' }}
          </div>

          <div>
            <div class="infoboxTitle">{{ t('admin.searchName').toUpperCase() }}</div>
            {{ customer.searchName?.length > 0 ? customer.searchName : '--' }}
          </div>

          <div>
            <div class="infoboxTitle">{{ t('admin.salesStatus').toUpperCase() }}</div>
            <AgButton variant="secondary" @click="customer.salesBlocked = !customer.salesBlocked">
              <span v-if="customer.salesBlocked">{{ t('admin.unlock') }}</span>
              <span v-else>{{ t('admin.lock') }}</span>
            </AgButton>
          </div>
        </div>
        <div class="table">
          <div>
            <div class="infoboxTitle">{{ t('admin.code').toUpperCase() }}</div>
            {{ customer.code?.length > 0 ? customer.code : '--' }}
          </div>
          <div>
            <div class="infoboxTitle">{{ t('admin.agent').toUpperCase() }}</div>
            {{ customer.agentName?.length > 0 ? customer.agentName : '--' }}
          </div>
          <div>
            <div class="infoboxTitle">{{ t('admin.language').toUpperCase() }}</div>
            {{ customer.language?.length > 0 ? customer.language : '--' }}
          </div>
        </div>

        <div class="table">
          <div>
            <div class="infoboxTitle">{{ t('admin.segment').toUpperCase() }}</div>
            {{ customer.segment?.length > 0 ? customer.segment : '--' }}
          </div>
          <div>
            <div class="infoboxTitle">{{ t('admin.fiscalCode').toUpperCase() }}</div>
            {{ customer.fiscalCode?.length > 0 ? customer.fiscalCode : '--' }}
          </div>
          <div>
            <div class="infoboxTitle">{{ t('admin.vatCode').toUpperCase() }}</div>
            {{ customer.vatCode?.length > 0 ? customer.vatCode : '--' }}
          </div>
        </div>

        <div class="table">
          <div>
            <div class="infoboxTitle">{{ t('admin.note').toUpperCase() }}</div>
            {{ customer.note?.length > 0 ? customer.note : '--' }}
          </div>
          <div>
            <div class="infoboxTitle">{{ t('admin.webSite').toUpperCase() }}</div>
            {{ customer.webSiteUrl?.length > 0 ? customer.webSiteUrl : '--' }}
          </div>
        </div>
        <div v-if="customerNotes.length > 0">
          <span class="font-bold">{{ t('admin.customerNotes') }}:</span>
          <AgBox variant="accent">
            <AgCollapsible
              v-for="(note, index) in customerNotes.sort((a, b) => b.dateTimeStamp - a.dateTimeStamp)"
              :key="note.id"
              showIcon
              :class="{ 'border-b border-neutral-550': index !== customerNotes.length - 1 }"
            >
              <template #header>
                <div class="my-12">{{ note.userName }} - {{ formatDate(note.dateTimeStamp) }}</div>
              </template>
              <template #default>
                <div class="px-8 py-12">
                  {{ note.text }}
                </div>
              </template>
            </AgCollapsible>
          </AgBox>
        </div>
      </div>
    </div>
    <div v-else class="py-32 px-64">
      <AgAlert variant="info">{{ t('admin.notFound') }}</AgAlert>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { CustomerDto } from '@/admin/domain/CustomerDto';
  import type { CustomerNoteDto } from '@/admin/domain/CustomerNoteDto';
  import TheAdminSubHeader from '@/components/framework/admin/the-admin-sub-header/TheAdminSubHeader.vue';
  import IcInfo from '@/components/icons/IcInfo.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgBox from '@/components/library/box/AgBox.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgCollapsible from '@/components/library/collapsible/AgCollapsible.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { i18n } from '@/plugins/i18n';
  import { onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useCustomers } from '../composables/useCustomers';

  const route = useRoute();
  const router = useRouter();
  const { t } = i18n.global;
  const { loadCustomers, updateCustomer, loadCustomerNotes } = useCustomers();
  const { formatDate } = useDateFormatting();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.customerDetail');

  const customer = ref<CustomerDto>();
  const customerNotes = ref<CustomerNoteDto[]>([]);
  const isLoading = ref<boolean>(true);

  const save = async function () {
    if (!customer.value) {
      return;
    }
    isLoading.value = true;
    await updateCustomer(customer.value);
    await router.push({ path: '/admin/customers' });
    isLoading.value = false;
  };

  onMounted(async () => {
    const customers = await loadCustomers();
    customer.value = customers.find((c) => c.id.toString() === route.params.customerId);
    if (customer.value) {
      customerNotes.value = await loadCustomerNotes(customer.value.id);
    }
    isLoading.value = false;
  });
</script>

<style scoped lang="scss">
  .infoboxTitle {
    @apply text-primary-light;
  }

  .name {
    @apply text-xl font-bold flex items-center;
  }

  .infoBox {
    @apply w-full justify-center my-12;
  }

  .table {
    @apply grid grid-cols-3 py-16  space-x-0;
  }

  .tableColumn {
    @apply flex items-center min-w-0 ml-48 w-1/2 py-4 space-x-12;
  }
</style>
