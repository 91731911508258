import { useApi } from '@/composables/api/useApi';
import { useLocalDatabase } from '@/composables/data/local-database/useLocalDatabase';
import { config } from '@/config/config';
import type { ContainerDto } from '@/domain/data/ContainerDto';
import { useDataTimestampStore } from '@/store/useDataTimestampStore';
import { useError } from '@/util/useError';
import { ref } from 'vue';

const TIMESTAMP_ORDERS = 'orders';
const MODIFY_TIMESTAMP_ORDERS = 'ordersModify';

const isLoading = ref(false);

let intervalOrders: number | null = null;

export function useOrderData() {
  const api = useApi();

  const { userData } = useLocalDatabase();
  const { handleCatchLocalError, handleCatchServerError } = useError();
  const { refreshOrdersDataTimestamp } = useDataTimestampStore();

  function currentTimestamp() {
    return Math.floor(Date.now() / 1000);
  }

  const getOrdersModifyTimestamp = async function () {
    const timestamp = await userData.timestamps.get(MODIFY_TIMESTAMP_ORDERS);

    if (!timestamp) {
      return 0;
    }
    return timestamp.value || 0;
  };

  const getNotSyncedOrders = async function () {
    return userData.orders.where('synced').equals(0).toArray();
  };

  const getDeletedOrders = async function () {
    return userData.deletedOrders.toArray();
  };

  const syncOrders = async function () {
    if (isLoading.value || !config.company.modules.ORDER) {
      return;
    }
    console.log('*** SYNC ORDER DATA ***', new Date().toISOString());

    isLoading.value = true;

    const timestamp = await getOrdersModifyTimestamp();

    const ordersToSync = await getNotSyncedOrders();
    const deletedOrders = await getDeletedOrders();

    try {
      const containerResult = await api.fetch<ContainerDto>('/ordersync?timestamp=' + timestamp, 'POST', {
        data: {
          orders: {
            upsert: ordersToSync,
            delete: deletedOrders.map((order) => order.gid),
          },
        },
      } as ContainerDto);
      if (containerResult.errors && containerResult.errors.length > 0) {
        isLoading.value = false;
        handleCatchServerError(containerResult.errors);
        return;
      }
      if (!containerResult.data || Object.keys(containerResult.data).length === 0 || !containerResult.data.orders) {
        isLoading.value = false;
        return;
      }
      await userData.orders.bulkPut(containerResult.data.orders.upsert);
      await userData.orders.bulkDelete(containerResult.data.orders.delete);

      await userData.deletedOrders.clear();

      const current_timestamp = currentTimestamp();

      userData.timestamps.bulkPut([
        {
          id: MODIFY_TIMESTAMP_ORDERS,
          value: containerResult.modifyTimestamp,
        },
        {
          id: TIMESTAMP_ORDERS,
          value: current_timestamp,
        },
      ]);
      refreshOrdersDataTimestamp(Date.now());

      isLoading.value = false;
    } catch (error) {
      isLoading.value = false;
      handleCatchLocalError(error);
    }
  };

  const setIntervalSyncOrders = function () {
    if (intervalOrders === null && config.company.modules.ORDER) {
      intervalOrders = window.setInterval(async () => await syncOrders(), 2 * 60 * 1000); // Every 2 minutes
    }
  };

  const clearSyncOrders = function () {
    if (intervalOrders !== null && config.company.modules.ORDER) {
      clearInterval(intervalOrders);
      intervalOrders = null;
    }
  };

  return {
    setIntervalSyncOrders,
    clearSyncOrders,
    syncOrders,
    isLoading,
    tables: { orders: userData.orders, deletedOrders: userData.deletedOrders },
  };
}
