<template>
  <div class="md:mx-[80px] max-md:mx-16 min-w-fit pt-8 max-h-[calc(100dvh-160px)] flex w-full gap-48">
    <AgFormGroup label="" class="w-full max-w-256">
      <AgFormSelect :options="orderOfferSelectOptions" v-model="internalOrderOfferSelect" />
    </AgFormGroup>
    <AgFormGroup label="" class="max-w-384 w-full">
      <AgSearchInput v-model="internalSearchText" />
    </AgFormGroup>
  </div>
</template>

<script setup lang="ts">
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import AgSearchInput from '@/components/library/search-input/AgSearchInput.vue';

  import { i18n } from '@/plugins/i18n';
  import { computed } from 'vue';

  const { t } = i18n.global;

  interface Props {
    searchText: string;
    orderOfferSelection: number;
  }

  const props = defineProps<Props>();

  const emit = defineEmits(['update:searchText', 'update:orderOfferSelection']);

  const orderOfferSelectOptions = computed(() => [
    { key: 0, label: t('admin.orders') },
    { key: 1, label: t('admin.offers') },
  ]);

  const internalSearchText = computed({
    get: () => props.searchText,
    set: (value) => emit('update:searchText', value),
  });

  const internalOrderOfferSelect = computed({
    get: () => props.orderOfferSelection,
    set: (value) => emit('update:orderOfferSelection', value),
  });
</script>
