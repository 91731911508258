<template>
  <div>
    <TheAdminSubHeader @save="createNewBusinessCategory" hasNotDeleteButton />
    <div class="md:mx-[80px]">
      <div class="flex gap-32">
        <div class="infoBox">
          <div class="infoboxTitle">{{ t('admin.code').toUpperCase() }}</div>
          <div>
            <AgFormInput
              v-model="businessCategory.code"
              class="w-256"
              @input="codeError = false"
              :validation-state="!codeError"
              :error-message="codeErrorText"
            />
          </div>
        </div>
      </div>

      <div class="table">
        <span class="text-primary-light">{{ t('admin.languageDescription').toUpperCase() }}</span>
        <div v-for="selectedLang in Object.values(config.company.availableLanguages)" :key="selectedLang">
          <div class="tableColumn">
            <AgFormInput :placeholder="selectedLang" disabled />
            <AgFormInput
              v-model="businessCategory.title[selectedLang]"
              :validation-state="hasDescValue(selectedLang)"
              @input="descriptionError = false"
            />
          </div>
        </div>
        <AgAlert v-if="descriptionError" variant="danger" dismissible>
          {{ t('admin.descriptionRequired') }}
        </AgAlert>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { BusinessCategoryDto } from '@/admin/domain/BusinessCategoryDto';

  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';

  import { useBusinnessCategories } from '@/admin/modules/businessCategories/composables/useBusinnessCategories';
  import TheAdminSubHeader from '@/components/framework/admin/the-admin-sub-header/TheAdminSubHeader.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { config } from '@/config/config';
  import { i18n } from '@/plugins/i18n';
  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';

  const { t } = i18n.global;
  const { createData, loadData } = useBusinnessCategories();
  const router = useRouter();
  const theHeader = useTheHeader();

  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('admin.newBusinessCategory');

  const codeError = ref<boolean>(false);
  const descriptionError = ref<boolean>(false);
  const codeErrorText = ref<string>('');

  const businessCategory = ref<BusinessCategoryDto>({
    code: '',
    title: {},
    id: -1,
  });
  const businessCategories = ref<Array<BusinessCategoryDto>>([]);

  const hasDescValue = function (lang: string) {
    if (!descriptionError.value) {
      return true;
    }
    if (!Object.keys(businessCategory.value.title).includes(lang)) {
      return false;
    }
    return businessCategory.value.title[lang].length > 0;
  };

  const createNewBusinessCategory = async function () {
    codeError.value = false;

    if (businessCategory.value?.code.length === 0) {
      codeError.value = true;
      codeErrorText.value = t('admin.codeRequired');
    }

    if (
      businessCategories.value.some(
        (category) =>
          category.code.toLowerCase() === businessCategory.value?.code.toLowerCase() &&
          category.id !== businessCategory.value?.id
      )
    ) {
      codeError.value = true;
      codeErrorText.value = t('admin.codeAlreadyExists');
    }

    if (Object.keys(businessCategory.value?.title ?? '').length === 0) {
      descriptionError.value = true;
    }
    if (Object.values(businessCategory.value?.title ?? '').every((value) => value.length === 0)) {
      descriptionError.value = true;
    }

    if (!codeError.value && !descriptionError.value) {
      sendRequestForCreate();
    }
  };

  const sendRequestForCreate = async function () {
    await createData(businessCategory.value);
    try {
      await router.push({
        path: '/admin/business-categories',
      });
    } catch (error) {
      console.error('Failed to navigate to business categories:', error);
    }
  };

  onMounted(async () => {
    businessCategories.value = await loadData();
  });
</script>

<style scoped lang="scss">
  .infoboxTitle {
    @apply text-primary-light;
  }
  .infoBox {
    @apply w-1/2 justify-center my-12 max-w-768;
  }

  .table {
    @apply grid grid-cols-1 py-16 space-x-0;
  }

  .tableColumn {
    @apply flex items-center min-w-0 py-4 gap-4;
  }
</style>
